import React from "react";
import Button from "@mui/material/Button";
import { Box, IconButton, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";

import { closeIcon } from "../navbar/closeIcon";

import CheckRound from "../../assets/icons/checkRound.png";
import { useSelector } from "react-redux";
import axios from "axios";
const baseURL = process.env.REACT_APP_BASEURL;

const Thanksdialog = ({ CancelSubscription, setCancelSubscription }) => {
  const [OpenSuccess, setOpenSuccess] = React.useState(false);
  const [OpenSuccessAlready, setOpenSuccessAlready] = React.useState(false);

  const authTokenSubscription = useSelector((state) => state.userAuth.payToken);

  //   console.log(authTokenSubscription.data.token, "authTokenSubscription");

  const onCancelClick = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${authTokenSubscription?.data?.token}`,
      },
    };

    axios
      .delete(`${baseURL}/api/v1/user/subscription/cancelSubscription`, config)
      .then((res) => {
        if (res.data.success) {
          setOpenSuccess(true);
        }
      })
      .catch((error) => {
        console.log(error?.response?.status, "errr");
        if (error?.response?.status === 401) {
          setOpenSuccess(false);
          setOpenSuccessAlready(true);
        }
      });
  };

  return (
    <>
      <Dialog open={CancelSubscription}>
        <Box align="center" sx={{ padding: "14px" }}>
          <IconButton
            sx={{
              position: "absolute",
              top: "4px",
              right: "4px",
            }}
            onClick={() => setCancelSubscription(false)}
          >
            {closeIcon()}
          </IconButton>
          <img src={CheckRound} alt="check" width={"90px"} />

          <Typography
            sx={{
              marginTop: "10px",
              fontFamily: "Kumbh Sans",
              fontSize: "20px",
              fontWeight: "bold",
            }}
          >
            {OpenSuccess
              ? "Subscription cancelled."
              : OpenSuccessAlready
              ? "Already Cancelled"
              : "Are you sure,"}
            {/* {OpenSuccessAlready && "Already Cancelled"} */}
          </Typography>

          <Typography
            sx={{
              marginY: "10px",
              fontFamily: "Kumbh Sans",
              fontSize: "16px",
            }}
          >
            You want to cancel auto subscription ?
          </Typography>
        </Box>

        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-evenly",
            margin: "0 10px 10px",
          }}
        >
          <Button
            onClick={() => setCancelSubscription(false)}
            // variant="contained"
            sx={button1}
            className="button"
          >
            No
          </Button>
          <Button
            onClick={onCancelClick}
            // variant="contained"
            sx={button2}
            className="button"
          >
            Yes, Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Thanksdialog;

const button1 = {
  backgroundColor: "#D1AB42",
  borderRadius: "8px",
  fonFamily: "Kumbh Sans",
  // fontWeight: "bold",
  fontSize: "10px",
  padding: "14px 50px",
  color: "white",
  "&:hover": {
    backgroundColor: "#D1AB42",
    color: "white",
    fontWeight: "bold",
  },
};

const button2 = {
  backgroundColor: "#3F292B",
  borderRadius: "8px",
  fonFamily: "Kumbh Sans",
  // fontWeight: "bold",
  fontSize: "10px",
  padding: "14px 50px",
  color: "white",

  ":hover": {
    backgroundColor: "#3F292B",
    color: "white",
    fontWeight: "bold",
  },
};
