import * as React from "react";
import "./firstpage.css";
import AllEventHome from "../Events/AllEventHome";

// ** router
import { Link } from "react-router-dom";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

const PopularEvents = () => {
  // const paragraph = {
  //   fontSize: "0.8rem",
  //   fontFamily: "Kumbh Sans",
  //   color: "#000000",
  //   marginRight: "40px",
  //   lineHeight: "25px",
  //   opacity: "0.6",
  // };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-evenly",
          padding: "65px 90px",
        }}
        className="mobile-Bodyy events"
      >
        <Box>
          <Typography
            sx={{
              fontSize: "1rem",
              fontFamily: "Kumbh Sans",
              fontWeight: "bold",
              color: "#D1AB42",
            }}
          >
            EVENTS
          </Typography>
          <Typography
            sx={{
              fontSize: "2.3rem",
              fontFamily: "Kumbh Sans",
              fontWeight: "bold",
              color: "#000000",
              marginRight: "50px",
              marginTop: "10px",
            }}
          >
            Popular events
          </Typography>
        </Box>

        <Box>
          {/* <Typography sx={paragraph}>
            Dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit
            <br />
            aspernaturaut odit aut fugit, sed quia consequuntur. Dicta sunt
            explicabo. <br />
            Nemo enim ipsam voluptatem quia voluptas.
          </Typography> */}
        </Box>

        <Box>
          <Link to="/events">
            <Button
              variant="outlined"
              sx={{
                color: "#ffffff",
                background: "#3F292B",
                fontFamily: "Kumbh Sans",
                fontSize: "10px",
                padding: "12px 35px",
                marginTop: "20px",
                "&:hover": {
                  border: "1px solid #3F292B",
                  color: "#3F292B",

                  boxShadow: "inset 10em 0 0 0 white",
                },
              }}
            >
              View All
            </Button>
          </Link>
        </Box>
      </Box>

      <Box className="mobile-responsive-body">
        <AllEventHome />
      </Box>
    </>
  );
};

export default PopularEvents;
