import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
// import DialogContent from "@mui/material/DialogContent";
// import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Slide, Box, Typography } from "@mui/material";
import TaskAltIcon from '@mui/icons-material/TaskAlt';

// ** router
import { Link } from "react-router-dom";
import { Label } from "@mui/icons-material";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide({ open, setOpen }) {
  //   const [open, setOpen] = React.useState(false);

  // const handleClickOpen = () => {
  //   setOpen(true);
  // };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
      sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}

    >
      <DialogTitle sx={{ fontFamily: "Kumbh Sans", textAlign: 'center' }}>
        Signup Successfully
      </DialogTitle>

      <Box sx={{ marginX: "auto", px: 2, textAlign: 'center' }}>
        <TaskAltIcon sx={{ marginX: "auto", color: "green", fontSize: "50px", fontFamily: "Kumbh Sans" }} />
        <Typography sx={{ fontSize: "13px" }}>
          Your account will need approval from the <br />administrator before you can Login.
        </Typography>
      </Box>

      <DialogActions>
        <Box sx={{ display: { xs: "none", sm: "block" }, marginX: "auto" }}>
          <Link to="/registration">
            <Button
              variant="outlined"
              sx={{
                mr: 1,
                color: "#ffffff",
                background: "#3F292B",
                border: "none",
                textTransform: "capitalize",
                fontFamily: "Kumbh Sans",
                "&:hover": {
                  border: "none",
                  outline: "none",
                  background: "#D1AB42",
                }
              }}
            >
              Ok, Home
            </Button>
          </Link>

          {/* <Link to="/">
            <Button
              variant="outlined"
              onClick={() => setOpen(true)}
              href="/"
              sx={{
                color: "#ffffff",
                background: "#D1AB42",
                border: "none",
                textTransform: "capitalize",
                fontFamily: "Kumbh Sans",
                "&:hover": {
                  border: "none",
                  outline: "none",
                  background: "#3F292B",
                },
              }}
            >
              Login now
            </Button>
          </Link> */}
        </Box>
      </DialogActions>
    </Dialog>
  );
}

