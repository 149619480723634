import React, { useState, useEffect } from "react";
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  DirectionsRenderer,
  InfoWindow,
} from "@react-google-maps/api";
import { useParams } from "react-router-dom";
import axios from "axios";
import DirectionsIcon from "@mui/icons-material/Directions";

const containerStyle = {
  width: "100%",
  height: "95vh",

  position: "relative",
};

function GoogleMapApi() {
  let { lati, longi, ename } = useParams();

  const [userCoords, setUserCoords] = useState(null);
  // const [directionsResponse, setDirectionsResponse] = useState(null);

  const API_KEY = process.env.REACT_APP_GOOGLE_MAP_KEY;
  // const API_ENDPOINT = `https://www.googleapis.com/geolocation/v1/geolocate?key=${API_KEY}`;
  const destination = { lat: +lati, lng: +longi };

  const [selectedMarker, setSelectedMarker] = useState(null);
  const googleMapsUrl = `https://www.google.com/maps/dir/?api=1&destination=${destination.lat},${destination.lng}`;

  const onMarkerClick = (marker) => {
    setSelectedMarker(marker);
  };

  React.useEffect(() => {
    onMarkerClick(true);
  }, []);

  const onCloseClick = () => {
    setSelectedMarker(null);
  };

  // useEffect(() => {
  //   getLocations();
  // }, []);
  // const getLocations = async () => {
  //   let config = {
  //     url: API_ENDPOINT,
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     body: JSON.stringify({}),
  //   };

  //   let { data } = await axios(config);
  //   console.dir({ data }, { depth: null });
  //   setUserCoords(data?.location);
  // };

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: API_KEY,
  });

  // async function calculateRoute() {
  //   // eslint-disable-next-line no-undef
  //   const directionsService = new google.maps.DirectionsService();
  //   const results = await directionsService.route({
  //     origin: userCoords,
  //     destination: destination,
  //     travelMode: "DRIVING",
  //   });
  //   setDirectionsResponse(results);
  //   console.log({ results });
  // }

  return (
    isLoaded && (
      <>
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={destination}
          zoom={18}
        >
          {/* {directionsResponse ? (
            <DirectionsRenderer directions={directionsResponse} />
          ) : (
            <Marker position={destination} />
          )} */}

          {/* <InfoWindow position={destination}>
            <div>
             
              <button onClick={calculateRoute} style={linkStyle}>
                View Direction Path.
              </button>
            </div>
          </InfoWindow> */}

          {/* ********************************* */}

          <Marker
            position={destination}
            onClick={() => onMarkerClick("marker")}
          >
            {selectedMarker && (
              <InfoWindow onCloseClick={onCloseClick} position={destination}>
                <div>
                  <h3
                    style={{
                      display: "flex",
                      alignContent: "center",
                      gap: "10px",
                    }}
                  >
                    {ename ? ename : "Event Name"}
                    <a href={googleMapsUrl} rel="noopener noreferrer">
                      <DirectionsIcon />
                    </a>
                  </h3>
                  <a
                    href={googleMapsUrl}
                    rel="noopener noreferrer"
                    style={linkStyle}
                  >
                    View routes direction <br /> (Google Maps)
                  </a>
                </div>
              </InfoWindow>
            )}
          </Marker>
        </GoogleMap>

        {/* ********************************* */}

        {/* <Marker position={destination} onClick={(e) => onMarkerClick(e)} />
          {selectedMarker &&
            selectedMarker.position.lat === destination.lat &&
            selectedMarker.position.lng === destination.lng && (
              <InfoWindow anchor={selectedMarker} onCloseClick={onCloseClick}>
                <div>
                  <h3>Marker Title</h3>
                  <p>This is the content of the InfoWindow.</p>
                  <a
                    href={googleMapsUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Click here to get directions to this marker on Google Maps
                  </a>
                </div>
              </InfoWindow>
            )} */}

        {/* <a
          href={googleMapsUrl}
          rel="noopener noreferrer"
          style={linkStyle}
        >
          <button
            style={btnStyle}
            onMouseOver={(e) => (
              (e.target.style.backgroundColor = "lightgrey"),
              (e.target.style.color = "black")
            )}
            onMouseOut={(e) => (
              (e.target.style.backgroundColor = "DodgerBlue"),
              (e.target.style.color = "white")
            )}
          >
            Get Direction
          </button>
        </a> */}
      </>
    )
  );
}

export default React.memo(GoogleMapApi);

const linkStyle = {
  border: "none",
  background: "transparent",
  color: "blue",
  cursor: "pointer",
  fontWeight: "bold",
  ":hover": {
    color: "red",
  },
};

// const btnStyle = {
//   boxSizing: `border-box`,
//   border: `1px solid transparent`,
//   width: `170px`,
//   background: `DodgerBlue`,
//   color: `white`,
//   height: `40px`,
//   padding: `0 12px`,
//   borderRadius: `3px`,
//   boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
//   fontSize: `14px`,
//   outline: `none`,
//   textOverflow: `ellipses`,
//   cursor: "pointer",

//   position: "absolute",
//   top: "12px",
//   left: "200px",
// };

// import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
// import { useParams } from "react-router-dom";

// const containerStyle = {
//   width: "100%",
//   height: "90vh",
// };

// function GoogleMapApi() {
//   let { lati, longi } = useParams();

//   const userCoords = {
//     lat: lati && Number(lati),
//     lng: longi && Number(longi),
//   };

//   console.log({ userCoords });
//   const { isLoaded } = useJsApiLoader({
//     id: "google-map-script",
//     googleMapsApiKey: "AIzaSyCYFU_PnPrWNmK4iaskyRfR_llE7mN9uMo",
//   });

//   return (
//     isLoaded && (
//       <GoogleMap
//         mapContainerStyle={containerStyle}
//         zoom={18}
//         center={userCoords}
//       >
//         <Marker position={userCoords} />
//       </GoogleMap>
//     )
//   );
// }
// export default GoogleMapApi;
