import * as React from "react";
import PropTypes from "prop-types";
// import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useNavigate } from "react-router-dom";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";

import LoadingButton from "@mui/lab/LoadingButton";
// import Box from "@mui/material/Box";
import SendIcon from "@mui/icons-material/Send";

import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import MyLocationIcon from "@mui/icons-material/MyLocation";

import { useFormik } from "formik";
import * as yup from "yup";
import { Box, Button, Link, MenuItem, Select, TextField } from "@mui/material";
import { Country, State, City } from "country-state-city";
import { religionItem } from "../../constant/ReligionItems";
// import AddEventGoogleMap from "./AddEventGoogleMap";
import GoogleMapFullAddress from "./GoogleMapFullAddress";

import axios from "axios";
import { useSelector } from "react-redux";
const baseURL = process.env.REACT_APP_BASEURL;

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function CustomizedDialogs({ openAdd, setOpenAdd, userRole }) {
  // console.log("countries >>>", Country.getAllCountries());
  // console.log("countries state >>>", State.getAllStates());
  // console.log("countries city >>>", City.getAllCities());
  // console.log(" state >>>", State.getStatesOfCountry("IN"));
  // console.log("countries city >>>", City.getCitiesOfState("IN", "MH"));
  let cordinates = useSelector((state) => state.userAuth.latlng);

  let loadeded = useSelector((state) => state.userAuth.load);

  console.log({ cordinates, loadeded });
  const [userData, setUserData] = React.useState();
  const [eventImgFile, setEventImgFile] = React.useState(null);
  const [speakerImgFile, setSpeakerImgFile] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  // const [load, setLoad] = React.useState(false);

  // const navigate = useNavigate();

  const [openToastSuccess, setOpenToastSuccess] = React.useState(false);

  const handleCloseToastSuccess = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenToastSuccess(false);
  };

  // ** Address dropdown values
  const [countryList, setCountryList] = React.useState([]);
  const [countryCode, setCountryCode] = React.useState("");
  const [newAddress, setNewAddress] = React.useState(false);
  // const [openGeoMap, setOpenGeoMap] = React.useState(false);

  const [stateList, setStateList] = React.useState([]);
  const [stateCode, setStateCode] = React.useState("");
  const [PaymentLink, setPaymentLink] = React.useState("");

  const [address, setAddress] = React.useState({
    street: "",
    area: "",
    zipcode: "",

    country: "",
    state: "",
    city: "",

    lat: "",
    lng: "",
  });

  console.log({ datadatadata: address });

  const [GetDataFromLocal, setGetDataFromLocal] = React.useState();
  const [AddressError, setAddressError] = React.useState(false);

  const [cityList, setCityList] = React.useState([]);
  // const [cityCode, setCityCode] = React.useState("");

  const [tabVisibility, setTabVisibility] = React.useState(true);

  React.useEffect(() => {
    function handleVisibilityChange() {
      setTabVisibility(document.visibilityState === "visible");
    }

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  React.useEffect(() => {
    setGetDataFromLocal(getMapData);
  }, [tabVisibility]);

  React.useEffect(() => {
    if (GetDataFromLocal) {
      setAddress({
        street: GetDataFromLocal.street,
        area: GetDataFromLocal.area,

        zipcode: GetDataFromLocal?.zipcode
          ? GetDataFromLocal?.zipcode
          : GetDataFromLocal.zipcode,

        country: GetDataFromLocal?.country
          ? GetDataFromLocal?.country
          : GetDataFromLocal?.country,

        state: GetDataFromLocal?.state
          ? GetDataFromLocal?.state
          : GetDataFromLocal?.state,

        city: GetDataFromLocal?.city
          ? GetDataFromLocal?.city
          : GetDataFromLocal?.city,

        lat: GetDataFromLocal?.lat,

        lng: GetDataFromLocal?.lng,
      });
    }
  }, [GetDataFromLocal]);

  const getMapData = JSON.parse(localStorage.getItem("mapData"));

  React.useEffect(() => {
    setCountryList(Country.getAllCountries());
    setStateList(State.getStatesOfCountry(countryCode));
    setCityList(City.getCitiesOfState(countryCode, stateCode));
  }, [countryCode, countryList, stateCode]);

  const validationSchema = yup.object({
    name: yup
      .string("Enter title name")
      .min(5, "min 5 character required")
      .required("Title name is required."),

    description: yup
      .string("Enter description")
      //.min(300, "Description must be at least 300 characters long.")
      .required("Description is required."),

    contact: yup
      .string("Enter contact contact")
      .min(10, "Wrong input required")
      .required("Mobile number is required."),
    email: yup
      .string("Enter your email")
      .email("Enter a valid email")
      .required("Email is required."),

    fromDate: yup.string("Select from date").required("Select from date."),
    toDate: yup.string("Select to date").required("Select to date."),

    // eventTheme: yup
    //   .string("Select dress theme")
    //   .required("Select dress theme."),
    parking: yup.string("Select yes / no").required("Select yes / no."),
    // ageGroup: yup.string("Select age group").required("Select age group."),
    // category: yup.string("Select category").required(" Select category."),
    // Religion: yup.string("Select religion").required(" Select religion."),

    speakerName: yup
      .string("Enter title name")
      .min(5, "min 5 character required")
      .required("Speaker name is required."),

    speakerDescription: yup
      .string("Enter description")
      //.min(300, "Description must be at least 300 characters long.")
      .required(" SpeakerDescription is required."),

    // street: yup.string("street").required("required"),
    // area: yup.string("street").required("Area required"),
    // city: yup.string("street").required("City required"),
    // state: yup.string("street").required("State required"),
    // zipCode: yup.string("street").required("Zipcode"),
    // country: yup.string("street").required("Country required"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      description: "",

      contact: "",
      email: "",

      fromDate: "",
      toDate: "",

      eventTheme: "",
      parking: true,
      ageGroup: "",

      category: "",
      type: "",
      Religion: "",

      speakerName: "",
      speakerDescription: "",

      street: "",
      area: "",

      zipCode: "",
      city: "",
      state: "",
      country: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      const {
        name,
        description,
        // eventImage,

        contact,
        email,

        fromDate,
        toDate,

        eventTheme,
        parking,
        ageGroup,

        category,
        Religion,
        type,

        speakerName,
        // speakerImage,
        speakerDescription,

        street,
        area,
        city,
        zipCode,
        country,
        state,
      } = values;

      const payData = {
        name,
        description,
        eventImage: eventImgFile,

        contact,
        email,

        fromDate,
        toDate,

        eventTheme,
        parking,
        ageGroup,

        category,
        eventType: type,
        religion: Religion,

        speakerImage: speakerImgFile,
        speakerDetails: {
          name: speakerName,
          description: speakerDescription,
        },

        // speakerImage,

        newAddress: newAddress,

        address: {
          // location: {
          //   type: "Point",
          //   coordinates: cordinates,
          // },

          street: address.street,
          zipCode: address?.zipcode,
          city: address?.city,
          state: address?.state,
          country: address?.country,
        },
      };

      if ([address?.lat, address?.lng].filter(Boolean).length === 2) {
        payData.address.location = {
          type: "Point",
          coordinates: [address?.lng, address?.lat],
        };
      }

      let payData1 = new FormData();

      payData1.append("name", name);
      payData1.append("desc", description);
      payData1.append("eventImage", eventImgFile);
      payData1.append("speakerImage", speakerImgFile);

      if (category) payData1.append("category", category);
      if (Religion) payData1.append("religion", Religion);
      payData1.append("eventType", type);

      payData1.append("fromDate", fromDate);
      payData1.append("toDate", toDate);

      payData1.append("contact", contact);
      payData1.append("email", email);
      payData1.append("paymentLink", PaymentLink);

      payData1.append("eventTheme", eventTheme);
      if (ageGroup) payData1.append("ageGroup", ageGroup);
      payData1.append("parking", parking);

      if (userRole !== "Church") {
        payData1.append("newAddress", true);
      } else {
        payData1.append("newAddress", newAddress);
      }

      payData1.append("speakerDetails", JSON.stringify(payData.speakerDetails));
      payData1.append("address", JSON.stringify(payData.address));

      if (
        (address?.country && address.street) ||
        (userRole === "Church" ? !newAddress : newAddress)
      ) {
        addEvent(payData1);
        resetForm({ values: "" });
      } else {
        setAddressError(true);
      }
    },
  });

  // ** Check Role Auth
  React.useEffect(() => {
    const getloginLocalInfo = JSON.parse(
      localStorage.getItem("loginLocalInfo")
    );
    setUserData(getloginLocalInfo.token);
  }, [userData]);

  const config = {
    headers: {
      Authorization: `Bearer ${userData}`,
      "Content-Type": "multipart/form-data;",
    },
  };

  const addEvent = async (payData1) => {
    setLoading(true);

    await axios
      .post(`${baseURL}/api/v1/event`, payData1, config)
      .then((response) => {
        if (response.data.success) {
          setOpenToastSuccess(true);
          setOpenAdd(false);
          setLoading(false);
          cordinates = [];
          localStorage.removeItem("mapData");
          setGetDataFromLocal(null);
          setAddress(null);
          setAddressError(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <BootstrapDialog
        onClose={() => {
          setOpenAdd(false);
          setLoading(false);
        }}
        aria-labelledby="customized-dialog-title"
        open={openAdd}
        sx={{ width: "100%" }}
      >
        <form onSubmit={formik.handleSubmit}>
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={() => setOpenAdd(false)}
            sx={tableHeading}
          >
            {"Event Details"}
          </BootstrapDialogTitle>

          <DialogContent dividers>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={2}
            >
              <Grid item xs={12} sm={6}>
                <Typography sx={labelText}>Event Image</Typography>
                <TextField
                  id="imageUpload"
                  type="file"
                  name="imageUpload"
                  sx={{ width: "100%" }}
                  variant="outlined"
                  onChange={(e) => {
                    setEventImgFile(e.target.files[0]);
                  }}
                  error={
                    formik.touched.imageUpload &&
                    Boolean(formik.errors.imageUpload)
                  }
                  helperText={
                    formik.touched.imageUpload && formik.errors.imageUpload
                  }
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography sx={labelText}>Event Name / Title</Typography>
                <TextField
                  id="name"
                  name="name"
                  sx={{ width: "100%" }}
                  variant="outlined"
                  placeholder="Type here"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                />
              </Grid>
            </Grid>

            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={2}
            >
              <Grid item xs={12} sm={userRole !== "Ministry" ? 6 : 12}>
                <Typography sx={labelText}>Event Type</Typography>
                <Select
                  id="type"
                  name="type"
                  sx={{ width: "100%" }}
                  variant="outlined"
                  value={formik.values.type}
                  onChange={formik.handleChange}
                  error={formik.touched.type && Boolean(formik.errors.type)}
                  helperText={formik.touched.type && formik.errors.type}
                >
                  {eventType?.map((data) => {
                    return <MenuItem value={data.value}>{data.value}</MenuItem>;
                  })}
                </Select>
              </Grid>

              {userRole !== "Ministry" && (
                <Grid item xs={12} sm={6}>
                  <Typography sx={labelText}>Category</Typography>
                  <Select
                    id="category"
                    name="category"
                    sx={{
                      width: "100%",
                    }}
                    variant="outlined"
                    value={formik.values.category}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.category && Boolean(formik.errors.category)
                    }
                    helperText={
                      formik.touched.category && formik.errors.category
                    }
                  >
                    {userRole !== "Artist"
                      ? categoryList?.map((data) => {
                          return (
                            <MenuItem value={data.value}>{data.value}</MenuItem>
                          );
                        })
                      : categoryListArtist?.map((data) => {
                          return (
                            <MenuItem value={data.value}>{data.value}</MenuItem>
                          );
                        })}
                  </Select>
                </Grid>
              )}

              {userRole === "Church" && (
                <Grid item xs={12} sm={6}>
                  <Typography sx={labelText}>Event Religion</Typography>
                  <Select
                    id="Religion"
                    name="Religion"
                    sx={{
                      width: "100%",
                    }}
                    variant="outlined"
                    value={formik.values.Religion}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.Religion && Boolean(formik.errors.Religion)
                    }
                    helperText={
                      formik.touched.Religion && formik.errors.Religion
                    }
                  >
                    {religionItem?.map((data) => {
                      return (
                        <MenuItem value={data.value}>{data.value}</MenuItem>
                      );
                    })}
                  </Select>
                </Grid>
              )}

              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={2}
                sx={{ ml: 0.5 }}
              >
                <Grid item xs={12} sm={6}>
                  <Typography sx={labelText}>Start Time</Typography>
                  <TextField
                    disablePast
                    id="datetime-local"
                    type="datetime-local"
                    name="fromDate"
                    sx={{ width: "100%" }}
                    variant="outlined"
                    placeholder="Type here"
                    value={formik.values.fromDate}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.fromDate && Boolean(formik.errors.fromDate)
                    }
                    helperText={
                      formik.touched.fromDate && formik.errors.fromDate
                    }
                    inputProps={{
                      min: new Date().toISOString().slice(0, 16),
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Typography sx={labelText}>End Time</Typography>

                  <TextField
                    id="datetime-local"
                    type="datetime-local"
                    name="toDate"
                    sx={{ width: "100%" }}
                    variant="outlined"
                    placeholder="Type here"
                    value={formik.values.toDate}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.toDate && Boolean(formik.errors.toDate)
                    }
                    helperText={formik.touched.toDate && formik.errors.toDate}
                    inputProps={{
                      min: new Date().toISOString().slice(0, 16),
                    }}
                  />
                </Grid>
              </Grid>

              {userRole !== "Church" && (
                <Grid item xs={12} sm={12}>
                  <Box sx={{ display: "flex" }}>
                    <Typography sx={labelText}>Payment Link &nbsp; </Typography>
                    <Typography sx={labelTextColor}>(Optional) </Typography>
                  </Box>

                  <TextField
                    id="paymentlink"
                    type="url"
                    name="paymentlink"
                    sx={{ width: "100%" }}
                    variant="outlined"
                    placeholder="Example : https://faithlens.org/Payment-Link"
                    value={PaymentLink}
                    onChange={(e) => setPaymentLink(e.target.value)}
                  />
                </Grid>
              )}

              <Grid item xs={12} sm={12}>
                <Typography sx={labelText}>Event Description</Typography>

                <TextField
                  id="description"
                  multiline
                  name="description"
                  sx={{ width: "100%" }}
                  variant="outlined"
                  placeholder="Please Add Description "
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  // error={
                  //   formik.touched.description &&
                  //   Boolean(formik.errors.description)
                  // }
                  // helperText={
                  //   formik.touched.description && formik.errors.description
                  // }
                />
              </Grid>
            </Grid>

            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={2}
            >
              {userRole === "Church" && (
                <Grid item xs={12} sm={4}>
                  <Typography sx={labelText}>Event Theme</Typography>
                  <TextField
                    id="eventTheme"
                    name="eventTheme"
                    placeholder="Type here"
                    sx={{
                      width: "100%",
                    }}
                    variant="outlined"
                    value={formik.values.eventTheme}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.eventTheme &&
                      Boolean(formik.errors.eventTheme)
                    }
                    helperText={
                      formik.touched.eventTheme && formik.errors.eventTheme
                    }
                  ></TextField>
                </Grid>
              )}

              {userRole !== "Artist" && (
                <Grid item xs={12} sm={userRole === "Ministry" ? 6 : 4}>
                  <Typography sx={labelText}>Age Group</Typography>
                  <Select
                    id="ageGroup"
                    name="ageGroup"
                    sx={{
                      width: "100%",
                      color:
                        formik.values.ageGroup === "selectAge"
                          ? "rgba(1, 1,1, 0.4)"
                          : "black",
                    }}
                    variant="outlined"
                    value={formik.values.ageGroup}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.ageGroup && Boolean(formik.errors.ageGroup)
                    }
                    helperText={
                      formik.touched.ageGroup && formik.errors.ageGroup
                    }
                  >
                    <MenuItem value={"selectAge"} sx={{ display: "none" }}>
                      Select here
                    </MenuItem>
                    <MenuItem value={"All Ages"}>All Ages</MenuItem>
                    <MenuItem value={"0-20"}>0 to 20</MenuItem>
                    <MenuItem value={"21-40"}>20 to 40</MenuItem>
                    <MenuItem value={"41-60"}>40 to 60</MenuItem>
                    <MenuItem value={"61-80"}>60 to 80</MenuItem>
                  </Select>
                </Grid>
              )}

              <Grid
                item
                xs={12}
                sm={
                  userRole === "Artist" ? 12 : userRole === "Ministry" ? 6 : 4
                }
              >
               <Box sx={{ display: "flex" }}>
            <Typography sx={labelText}>Parking Space &nbsp;</Typography>
              <Typography sx={labelTextColor}>(Optional) </Typography>
            </Box>
                <Select
                  id="parking"
                  name="parking"
                  variant="outlined"
                  value={formik.values.parking}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.parking && Boolean(formik.errors.parking)
                  }
                  helperText={formik.touched.parking && formik.errors.parking}
                  sx={{
                    width: "100%",
                    color:
                      formik.values.parking === "Available"
                        ? "rgba(1, 1,1, 0.4)"
                        : "black",
                  }}
                >
                  <MenuItem value={true} sx={{ display: "none" }}>
                    Select here
                  </MenuItem>
                  <MenuItem value={true}>Available</MenuItem>
                  <MenuItem value={false}>Not Available</MenuItem>
                </Select>
              </Grid>
            </Grid>

            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={2}
            >
              <Grid item xs={12} sm={6}>
                <Box sx={{ display: "flex" }}>
            <Typography sx={labelText}>Contact Mobile &nbsp;</Typography>
              <Typography sx={labelTextColor}>(Optional) </Typography>
            </Box>
                <TextField
                  id="contact"
                  name="contact"
                  sx={{ width: "100%" }}
                  variant="outlined"
                  placeholder="Type here"
                  value={formik.values.contact}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.contact && Boolean(formik.errors.contact)
                  }
                  helperText={formik.touched.contact && formik.errors.contact}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography sx={labelText}>Contact Email</Typography>

                <TextField
                  id="email"
                  name="email"
                  sx={{ width: "100%" }}
                  variant="outlined"
                  placeholder="Type here"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
              </Grid>
            </Grid>

            {/* ///////////////////////// */}

            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={2}
            >
              <Grid item xs={12} sm={6}>
                <Typography sx={labelText}>
                  {userRole === "Artist" ? "Performer Name" : "Speaker Name"}
                </Typography>
                <TextField
                  id="speakerName"
                  name="speakerName"
                  sx={{ width: "100%" }}
                  variant="outlined"
                  placeholder="Type here"
                  value={formik.values.speakerName}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.speakerName &&
                    Boolean(formik.errors.speakerName)
                  }
                  helperText={
                    formik.touched.speakerName && formik.errors.speakerName
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography sx={labelText}>
                  {" "}
                  {userRole === "Artist" ? "Performer Image" : "Speaker Image"}
                </Typography>
                <TextField
                  id="speakerImage"
                  type="file"
                  name="speakerImage"
                  sx={{ width: "100%" }}
                  variant="outlined"
                  onChange={(e) => {
                    setSpeakerImgFile(e.target.files[0]);
                  }}
                  error={
                    formik.touched.speakerImage &&
                    Boolean(formik.errors.speakerImage)
                  }
                  helperText={
                    formik.touched.speakerImage && formik.errors.speakerImage
                  }
                />
              </Grid>
            </Grid>

            <Grid item xs={12} sm={12}>
              <Typography sx={labelText}>
                {userRole === "Artist"
                  ? "Performer Description"
                  : "Speaker Description"}
              </Typography>

              <TextField
                id="speakerDescription"
                multiline
                name="speakerDescription"
                sx={{ width: "100%" }}
                variant="outlined"
                placeholder="Please Add Description "
                value={formik.values.speakerDescription}
                onChange={formik.handleChange}
                error={
                  formik.touched.speakerDescription &&
                  Boolean(formik.errors.speakerDescription)
                }
                helperText={
                  formik.touched.speakerDescription &&
                  formik.errors.speakerDescription
                }
              />
            </Grid>

            {/* ///////////////////////// */}

            {userRole === "Church" && (
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                defaultValue={newAddress}
              >
                <FormControlLabel
                  onClick={() => setNewAddress(false)}
                  value={false}
                  control={<Radio />}
                  label="Same As Church Address"
                />
                <FormControlLabel
                  onClick={() => setNewAddress(true)}
                  value={true}
                  control={<Radio />}
                  label="New Address for this Event"
                />
              </RadioGroup>
            )}

            {/* <AddEventGoogleMap
              setOpenGeoMap={setOpenGeoMap}
              openGeoMap={openGeoMap}
            /> */}

            {/* <GoogleMapFullAddress
              setOpenGeoMap={setOpenGeoMap}
              openGeoMap={openGeoMap}
            /> */}

            {userRole !== "Church" ? (
              <Typography sx={labelText}>Event Address</Typography>
            ) : null}

            {(userRole !== "Church" || newAddress === true) && (
              <>
                {/* <Button sx={mapBtn} onClick={() => setOpenGeoMap(true)} /> */}

                <Link underline="none" href="/map1" target="_blank">
                  <Button sx={mapBtn}>
                    Add Map Location <MyLocationIcon />
                  </Button>
                </Link>

                {AddressError && (
                  <Typography sx={{ color: "red", fontSize: "11px" }}>
                    Address & Street is required.*
                  </Typography>
                )}

                {GetDataFromLocal?.country && (
                  <Box
                    sx={{
                      border: "1px solid lightgrey",
                      padding: "0 10px",
                      marginBottom: "10px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        gap: "5px",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography sx={labelTextYellow}>Address :</Typography>
                      <CancelOutlinedIcon
                        sx={labelTextClear}
                        onClick={() => {
                          localStorage.removeItem("mapData");
                          setGetDataFromLocal(null);
                          setAddress(null);
                        }}
                      />
                    </Box>

                    <Typography sx={labelTextYellow}>
                      {GetDataFromLocal?.area}
                    </Typography>
                    <Typography sx={labelTextYellow}>
                      {GetDataFromLocal?.city} {GetDataFromLocal?.zipcode},{" "}
                      {GetDataFromLocal?.state}, {GetDataFromLocal?.country}
                    </Typography>
                  </Box>
                )}
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={2}
                >
                  <Grid item xs={12} sm={12}>
                    <TextField
                      sx={{ width: "100%" }}
                      variant="outlined"
                      placeholder="Street / Line / Area"
                      value={address?.street}
                      onFocus={() => setAddressError(false)}
                      onChange={(e) =>
                        setAddress({
                          ...address,
                          street: e.target.value,
                        })
                      }
                      error={
                        formik.touched.street && Boolean(formik.errors.street)
                      }
                      helperText={formik.touched.street && formik.errors.street}
                    />
                  </Grid>

                  {/* <Grid item xs={12} sm={4}>
                    <TextField
                      sx={{ width: "100%" }}
                      variant="outlined"
                      placeholder="Zipcode (optional)"
                      value={address?.zipcode}
                      onChange={(e) =>
                        setAddress({
                          ...address,
                          zipcode: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <TextField
                      sx={{ width: "100%" }}
                      variant="outlined"
                      placeholder="City"
                      value={address?.city}
                      onChange={(e) =>
                        setAddress({
                          ...address,
                          city: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <TextField
                      sx={{ width: "100%" }}
                      variant="outlined"
                      placeholder="State"
                      value={address?.state}
                      onChange={(e) =>
                        setAddress({
                          ...address,
                          state: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <TextField
                      sx={{ width: "100%" }}
                      variant="outlined"
                      placeholder="Country"
                      value={address?.country}
                      onChange={(e) =>
                        setAddress({
                          ...address,
                          country: e.target.value,
                        })
                      }
                    />
                  </Grid> */}
                </Grid>
              </>
            )}
          </DialogContent>

          <DialogActions>
            <LoadingButton
              type="submit"
              sx={loading ? loginBtnLoading : loginBtn}
              endIcon={<SendIcon />}
              loading={loading}
              loadingPosition="end"
            >
              <span>{loading ? "Adding Event" : " Add Event "}</span>
            </LoadingButton>
          </DialogActions>
        </form>
      </BootstrapDialog>
      <Snackbar
        open={openToastSuccess}
        autoHideDuration={2000}
        onClose={handleCloseToastSuccess}
      >
        <Alert
          onClose={handleCloseToastSuccess}
          severity="success"
          sx={{ width: "100%" }}
        >
          Event Created Successfully.
        </Alert>
      </Snackbar>
    </>
  );
}

const labelText = {
  fontSize: "14px",
  fontFamily: "Kumbh Sans",
  fontWeight: 500,
  marginTop: "20px",
  marginBottom: "8px",
};

const labelTextColor = {
  fontSize: "14px",
  fontFamily: "Kumbh Sans",
  fontWeight: 500,
  marginTop: "20px",
  marginBottom: "8px",
  color: "#D1AB42",
};

// const labelTextMenu = {
//   fontSize: "14px",
//   fontFamily: "Kumbh Sans",
//   fontWeight: 500,
//   // color: "white",
// };

const loginBtn = {
  gap: "10px",
  color: "#ffffff",
  background: "#D1AB42",
  textTransform: "capitalize",
  border: "1px solid #D1AB42",
  fontSize: "14px",
  fontFamily: "Kumbh Sans",
  fontWeight: 500,
  px: 2,
  "&:hover": {
    border: "1px solid #3F292B",
    color: "#3F292B",
  },
};

const mapBtn = {
  gap: "10px",
  color: "#ffffff",
  background: "#D1AB42",
  textTransform: "capitalize",
  border: "1px solid #D1AB42",
  fontSize: "14px",
  fontFamily: "Kumbh Sans",
  fontWeight: 500,
  px: 2,
  my: 2,
  "&:hover": {
    border: "1px solid #3F292B",
    color: "#3F292B",
  },
};

const loginBtnLoading = {
  gap: "10px",
  color: "#ffffff",
  // background: "#D1AB42",
  textTransform: "capitalize",
  border: "1px solid grey",
  fontSize: "14px",
  fontFamily: "Kumbh Sans",
  fontWeight: 500,
  px: 2,
};
const labelTextYellow = {
  fontSize: "14px",
  fontFamily: "Kumbh Sans",
  fontWeight: 500,
  marginTop: "8px",
  marginBottom: "5px",
  color: "orange",
};

const labelTextClear = {
  fontSize: "20px",
  fontFamily: "Kumbh Sans",
  fontWeight: 500,
  marginTop: "8px",
  marginBottom: "5px",
  color: "red",
  cursor: "pointer",
};

// const registerBtn = {
//   color: "#ffffff",
//   background: "#3F292B",
//   fontFamily: "Kumbh Sans",
//   fontSize: "10px",
//   padding: "12px 32px",
//   border: "1px solid #3F292B",
//   "&:hover": {
//     border: "1px solid #3F292B",
//     color: "#3F292B",
//   },
// };

const tableHeading = {
  fontSize: "18px",
  fontFamily: "Kumbh Sans",
  fontWeight: "bold",
  padding: "15px",
};

const categoryList = [
  {
    value: "Youth Service",
    label: "Youth Service",
  },
  {
    value: "Youth Prayer Night",
    label: "Youth Prayer Night",
  },
  {
    value: "Youth Social",
    label: "Youth Social",
  },
  {
    value: "Adult Prayer Night",
    label: "Adult Prayer Night",
  },
  {
    value: "Adult Church Service",
    label: "Adult Church Service",
  },
  {
    value: "Women Prayer Group",
    label: "Women Prayer Group",
  },
  {
    value: " Women bible study",
    label: " Women bible study",
  },
  {
    value: "Male Prayer Group",
    label: "Male Prayer Group",
  },
  {
    value: "Concert",
    label: "Concert",
  },
  {
    value: " Week of Prayer",
    label: " Week of Prayer",
  },
  {
    value: "Crusades",
    label: "Crusades",
  },
  {
    value: " Conference",
    label: " Conference",
  },

  {
    value: " CoED Bible Study",
    label: " CoED Bible Study",
  },
  {
    value: " CoEd Prayer Group",
    label: " CoEd Prayer Group",
  },
];

const eventType = [
  {
    label: "Music",
    value: "Music",
  },
  {
    label: "Church",
    value: "Church",
  },
  {
    label: "Ministry Focused",
    value: "Ministry Focused",
  },
];

const categoryListArtist = [
  {
    value: "Concert",
    label: "Concert",
  },
  {
    value: "Worship Session",
    label: "Worship Session",
  },
  {
    value: "Album Release",
    label: "Album Release",
  },
];
