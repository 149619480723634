import React from "react";
import SearchEvents from "./searchEvents";
import AllEvents from "./allEvents";
import Footer from "../footer";
import InsideNavbar from "../navbar/InsideNavbar";
import "./events.css";
import Box from "@mui/material/Box";

const Index = () => {

  const [Trig, setTrig] = React.useState(false);
  const [params, setParams] = React.useState({});
  const [zipCode, setZipCode] = React.useState("");
  const [category, setCategory] = React.useState("");
  const [eventType, setEventType] = React.useState("Church");

  const [eventDate, setEventDate] = React.useState("");
  const [ChurchName, setChurchName] = React.useState("");
  const [Religion, setReligion] = React.useState("");

  const [eventError, setEventError] = React.useState(false);

  return (
    <>
      <InsideNavbar />
      <Box className="mobile-responsive-body">
        <SearchEvents
          zipCode={zipCode}
          setZipCode={setZipCode}
          category={category}
          setCategory={setCategory}
          eventDate={eventDate}
          setEventDate={setEventDate}
          params={params}
          setParams={setParams}
          setTrig={setTrig}
          Trig={Trig}
          eventError={eventError}
          setEventError={setEventError}
          setChurchName={setChurchName}
          ChurchName={ChurchName}
          setReligion={setReligion}
          Religion={Religion}

          eventType={eventType}
          setEventType={setEventType}

        />
        <AllEvents
          params={params}
          setParams={setParams}
          setTrig={setTrig}
          Trig={Trig}
          eventError={eventError}
          setEventError={setEventError}
        />
      </Box>

      <Footer />
    </>
  );
};

export default Index;
