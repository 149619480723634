function SampleNextArrow({ onClick }) {
  return <div className="slick-arrow slick-next nextBackBlog" onClick={onClick} />;
}

function SamplePrevArrow({ onClick }) {
  return <div className="slick-arrow slick-prev preBackBlog" onClick={onClick} />;
}

const resp = [
  {
    breakpoint: 600,
    settings: {
      slidesToShow: 1,
    },
  },
  {
    breakpoint: 768,
    settings: {
      slidesToShow: 2,
      arrow: true,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
    },
  },
  {
    breakpoint: 1024,
    settings: {
      slidesToShow: 3,
      arrow: true,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
    },
  },

  {
    breakpoint: 1800,
    settings: {
      slidesToShow: 4,
      arrow: true,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
    },
  },

  {
    breakpoint: 2500,
    settings: {
      slidesToShow: 5,
      arrow: true,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
    },
  },
];

export const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  arrow: true,
  autoplaySpeed: 3000,
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />,
  responsive: resp,
};
