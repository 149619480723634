import React from "react";
import Button from "@mui/material/Button";
import { Box, Divider, Grid, IconButton, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import { closeIcon } from "../../navbar/closeIcon";
// import CheckRound from "../../../assets/icons/checkRound.png";
import homePageCross from "../../../assets/images/homePageCross.png";
import Speaker1 from "../../../assets/images/speaker1.jpg";

import { MdOutlineMailOutline } from "react-icons/md";
import { SlLocationPin } from "react-icons/sl";

import { BsTelephone } from "react-icons/bs";

import axios from "axios";
const baseURL = process.env.REACT_APP_BASEURL;

const ViewDetails = (props) => {
  const {
    openViewDetialpopUp,
    setOpenViewDetialpopUp,
    handleClose,
    particularId,

    openApprovepopUp,
    setApprovepopUp,

    openRejectpopUp,
    setRejectpopUp,
  } = props;

  const [eventData, seteventData] = React.useState([]);
  const [expand, setExpand] = React.useState([]);


  React.useEffect(() => {
    const getloginLocalInfo = JSON.parse(
      localStorage.getItem("loginLocalInfo")
    );
    const config = {
      headers: { Authorization: `Bearer ${getloginLocalInfo.token}` },
    };

    const getAllEventsRequest = () => {
      axios
        .get(`${baseURL}/api/v1/event/${particularId}`, config)
        .then((response) => {
          if (response.data.success) {
            seteventData(response?.data?.data);
            handleClose();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };
    getAllEventsRequest();
  }, [openViewDetialpopUp]);

  return (
    <>
      <Dialog open={openViewDetialpopUp}>
        <Box sx={{ padding: "14px" }}>
          <IconButton
            sx={{
              position: "absolute",
              top: "4px",
              right: "4px",
            }}
            onClick={() => setOpenViewDetialpopUp(false)}
          >
            {closeIcon()}
          </IconButton>

          <Grid container spacing={2} sx={{ p: 4 }}>
            <Grid xs={12} sm={12}>
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexWrap: "wrap",
                  }}
                >
                  <Typography sx={eventsHeading}>{eventData.name}</Typography>
                </Box>

                <Typography sx={paragraph}> {eventData.desc?.length > 250 ? (
                  <span>
                    {expand
                      ? eventData?.desc
                      : `${eventData?.desc.slice(0,250)} `}
                    <p
                      onClick={() => setExpand(!expand)}
                      style={{ cursor: "pointer" }}
                    >
                      {expand ? "See less" : "See more.."}
                    </p>
                  </span>
                ) : (
                  eventData?.desc
                )}</Typography>
              </Box>

              <Typography sx={titleHeading}>Event Details</Typography>

              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "space-between",
                }}
              >
                <Box>
                  <Typography sx={eventDetailsSubHeading}>
                    {eventData.eventType ? eventData.eventType : "N/A"}
                  </Typography>
                  <Typography sx={paragraph}>Event type</Typography>
                </Box>

                <Box>
                  <Typography sx={eventDetailsSubHeading}>
                    {eventData.eventTheme ? eventData.eventTheme : "N/A"}
                  </Typography>
                  <Typography sx={paragraph}>Theme of event</Typography>
                </Box>
                <Box>
                  <Typography sx={eventDetailsSubHeading}>
                    {eventData.ageGroup ? eventData?.ageGroup : "N/A"}
                  </Typography>
                  <Typography sx={paragraph}>Age group</Typography>
                </Box>
                <Box>
                  <Typography sx={eventDetailsSubHeading}>
                    {eventData.parking ? "Available" : "Not Available"}
                  </Typography>
                  <Typography sx={paragraph}>Parking space</Typography>
                </Box>
              </Box>

              <Box>
                <Typography sx={eventDetailsSubHeading}>{eventData?.address?.street}
                  {eventData?.address?.city}, {eventData?.address?.state},{" "}
                  {eventData?.address?.country}
                </Typography>
                <Typography sx={paragraph}>Address</Typography>
              </Box>

              {/* <Divider sx={{ marginY: "20px" }} />

              <Typography sx={titleHeading}>Contact Details</Typography>

              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "space-between",
                }}
              >
                <Box>
                  <Typography sx={eventDetailsSubHeading}>
                    <BsTelephone className="iconStyle2" />
                    {eventData.contact}
                  </Typography>
                  <Typography sx={paragraph}>Contact number</Typography>
                </Box>
                <Box>
                  <Typography sx={eventDetailsSubHeading}>
                    <MdOutlineMailOutline className="iconStyle2" />
                    {eventData.email}
                  </Typography>
                  <Typography sx={paragraph}>Email</Typography>
                </Box>
              </Box> */}

              <Divider sx={{ marginY: "20px" }} />

              <Typography sx={titleHeading}>Speaker Details</Typography>

              <Box
                sx={{
                  display: "flex",
                  // flexWrap: "wrap",
                }}
                className="flexMobile"
              >
                <Box sx={{ display: "inline" }}>
                  <img
                    src={
                      eventData?.speakerDetails?.imageUrl
                        ? eventData?.speakerDetails?.imageUrl
                        : Speaker1
                    }
                    alt="img"
                    width={100}
                    className="speakerImage"
                  />
                </Box>
                <Box sx={{ margin: "20px" }}>
                  <Typography sx={eventDetailsSubHeading}>
                    {eventData.speakerDetails?.name}
                  </Typography>
                  <Typography sx={paragraph}>
                  {eventData.speakerDetails?.description?.length > 250 ? (
                  <span>
                    {expand
                      ? eventData.speakerDetails?.description
                      : `${eventData.speakerDetails?.description.slice(0,250)} `}
                    <p
                      onClick={() => setExpand(!expand)}
                      style={{ cursor: "pointer" }}
                    >
                      {expand ? "See less" : "See more.."}
                    </p>
                  </span>
                ) : (
                  eventData.speakerDetails?.description
                )}                  </Typography>
                </Box>
              </Box>

              <Divider sx={{ marginY: "20px" }} />

              <Typography sx={titleHeading}>Hosted by</Typography>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                }}
                className="flexMobile"
              >
                <Box sx={{ display: "inline" }}>
                  <img
                    src={
                      eventData.hostedBy?.imageUrl
                        ? eventData.hostedBy?.imageUrl
                        : homePageCross
                    }
                    alt="img"
                    width={100}
                    className="img-responsive"
                  />
                </Box>
                <Box sx={{ margin: "20px" }}>
                  <Typography sx={eventDetailsSubHeading}>
                    {eventData.hostedBy?.fullName}
                  </Typography>
                  <Typography
                    sx={{
                      marginX: "0",
                      marginBottom: "9px",
                      fontFamily: "Kumbh Sans",
                      fontSize: "12px",
                      fontWeight: "bold",
                    }}
                  >
                    <SlLocationPin style={{ fontSize: "13px" }} />
                    {eventData.address?.street} &nbsp; {eventData.address?.city}, &nbsp;
                    {eventData.address?.state}, &nbsp;
                    {eventData.address?.country}
                  </Typography>
                  <Typography sx={paragraph}>
                    {eventData.speakerDetails?.description?.length > 250 ? (
                  <span>
                    {expand
                      ? eventData.speakerDetails?.description
                      : `${eventData.speakerDetails?.description.slice(0,250)} `}
                    <p
                      onClick={() => setExpand(!expand)}
                      style={{ cursor: "pointer" }}
                    >
                      {expand ? "See less" : "See more.."}
                    </p>
                  </span>
                ) : (
                  eventData.speakerDetails?.description
                )}
                  </Typography>
                </Box>
              </Box>

              <Divider />
            </Grid>
          </Grid>
        </Box>

        <DialogActions
          sx={{
            display: "flex",
            // justifyContent: "space-between",
            flexWrap: "wrap",
            margin: "0 10px 10px",
          }}
        >
          <Button
            onClick={() => setApprovepopUp(true)}
            variant="contained"
            sx={approve}
            className="button"
          >
            Approve Event
          </Button>

          <Button
            onClick={() => setRejectpopUp(true)}
            variant="contained"
            sx={reject}
            className="button"
          >
            Reject Event
          </Button>
          {/* <Button
            onClick={() => setOpenViewDetialpopUp(false)}
            variant="contained"
            sx={button2}
            className="button"
          >
            Close
          </Button> */}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ViewDetails;

const approve = {
  backgroundColor: "#3F292B",
  fonFamily: "Kumbh Sans",
  fontWeight: "bold",
  fontSize: "9px",
  padding: "8px 20px",

  ":hover": {
    backgroundColor: "green",
    color: "white",
  },
};

const reject = {
  backgroundColor: "darkred",
  fonFamily: "Kumbh Sans",
  fontWeight: "bold",
  fontSize: "9px",
  padding: "8px 20px",

  ":hover": {
    backgroundColor: "darkred",
    color: "white",
  },
};

const button2 = {
  backgroundColor: "#3F292B",
  fonFamily: "Kumbh Sans",
  fontWeight: "bold",
  fontSize: "8px",
  padding: "8px 20px",

  ":hover": {
    backgroundColor: "#3F292B",
    color: "white",
  },
};

const eventsHeading = {
  fontSize: "1.5rem",
  fontFamily: "Kumbh Sans",
  fontWeight: "bold",
  color: "#000000",
  marginRight: "30px",
};
const paragraph = {
  marginBottom: "4px",
  marginRight: "12px",
  fontFamily: "Kumbh Sans",
  fontSize: "13px",
  opacity: 0.6,
};

const eventDetailsSubHeading = {
  fontFamily: "Kumbh Sans",
  fontSize: "16px",
  fontWeight: "bold",
};

const titleHeading = {
  marginX: "0",
  marginBottom: "9px",
  fontFamily: "Kumbh Sans",
  fontSize: "18px",
  fontWeight: "bold",
};
