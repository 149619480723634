import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

// import { useFormik } from "formik";
import { TextField } from "@mui/material";

import axios from "axios";
const baseURL = process.env.REACT_APP_BASEURL;

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function CustomizedDialogs({
  onActionOpen,
  setOnActionOpen,
  tokenApi,
  EditId,
  data,
}) {
  const [MemberImage, setMemberImage] = React.useState({});
  const [fullname, setFullname] = React.useState("");
  const [role, setRole] = React.useState("");

  React.useEffect(() => {
    if (EditId) {
      setFullname(data?.name);
      setRole(data?.staffRole);
    }
  }, [EditId]);

  const onEditSubmit = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${tokenApi}`,
      },
    };

    const data = new FormData();
    data.append("name", fullname);
    data.append("staffRole", role);
    data.append("staffImage", MemberImage ? MemberImage : null);

    axios
      .put(`${baseURL}/api/v1/church/staff/${EditId}`, data, config)
      .then((response) => {
        if (response.data.success) {
          setOnActionOpen(false);
          // alert("update");
        }
      })
      .catch((error) => {
        console.log(error);
        alert(error);
      });
  };

  const deleteMember = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${tokenApi}`,
      },
    };
    axios
      .delete(`${baseURL}/api/v1/church/staff/${EditId}`, config)
      .then((response) => {
        if (response.data.success) {
          setOnActionOpen(false);
          // alert("deleted");
        }
      })
      .catch((error) => {
        console.log(error);
        alert(error);
      });
  };

  return (
    <BootstrapDialog
      onClose={() => setOnActionOpen(false)}
      aria-labelledby="customized-dialog-title"
      open={onActionOpen}
    >
      <BootstrapDialogTitle
        id="customized-dialog-title"
        onClose={() => setOnActionOpen(false)}
      >
        Edit Staff Member
      </BootstrapDialogTitle>

      <DialogContent dividers>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs={12} sm={6}>
            <Typography sx={labelText}>Staff member name</Typography>
            <TextField
              id="staffMember"
              name="staffMember"
              sx={{ width: "100%" }}
              variant="outlined"
              placeholder="Type here"
              value={fullname}
              onChange={(e) => setFullname(e.target.value)}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Typography sx={labelText}>Role</Typography>
            <TextField
              id="Role"
              name="Role"
              sx={{ width: "100%" }}
              variant="outlined"
              placeholder="Type here"
              value={role}
              onChange={(e) => setRole(e.target.value)}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography sx={labelText}>Image Upload</Typography>
          <TextField
            id="imageUpload"
            type="file"
            sx={{ width: "100%" }}
            variant="outlined"
            onChange={(e) => setMemberImage(e.target.files[0])}
          />
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button onClick={onEditSubmit} sx={registerBtn}>
          Edit & Submit Member
        </Button>
        <Button sx={deleteBtn} onClick={deleteMember}>
          Delete Member
        </Button>
      </DialogActions>
    </BootstrapDialog>
  );
}

const labelText = {
  fontSize: "14px",
  fontFamily: "Kumbh Sans",
  fontWeight: 500,
  marginTop: "20px",
  marginBottom: "8px",
};

const registerBtn = {
  color: "#ffffff",
  background: "#3F292B",
  fontFamily: "Kumbh Sans",
  fontSize: "10px",
  padding: "8px 20px",
  border: "1px solid #3F292B",
  "&:hover": {
    border: "1px solid #3F292B",
    color: "#3F292B",
  },
};

const deleteBtn = {
  color: "#ffffff",
  background: "darkred",
  fontFamily: "Kumbh Sans",
  fontSize: "10px",
  padding: "8px 20px",
  border: "1px solid #3F292B",
  "&:hover": {
    border: "1px solid #3F292B",
    color: "#3F292B",
  },
};
