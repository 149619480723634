import React, { useMemo } from "react";
import InsideNavbar from "../navbar/InsideNavbar";
import Footer from "../footer";
import "./registration.css";

// ** images
import visa from "../../assets/payment/visa1.png";
import master from "../../assets/payment/master1.png";
import american from "../../assets/payment/american1.png";
import PaymentSuccess from "./successPaymentDialog";

// ** router
import CardRight from "./cardDetailsRightPart";
import useResponsiveFontSize from "./useResponsiveFontSize";
import { useSelector } from "react-redux";

// ** MUI
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useStripe, useElements } from "@stripe/react-stripe-js";

import {
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from "@stripe/react-stripe-js";

import axios from "axios";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const baseURL = process.env.REACT_APP_BASEURL;

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const useOptions = () => {
  const fontSize = useResponsiveFontSize();
  const options = useMemo(
    () => ({
      style: {
        base: {
          fontSize,
          color: "#424770",
          letterSpacing: "0.025em",
          fontFamily: "Source Code Pro, monospace",
          border: "1px solid lightgrey",
          padding: "12px",
          margin: "10px",

          "::placeholder": {
            color: "#aab7c4",
          },
        },
        invalid: {
          color: "#9e2146",
        },
      },
    }),
    [fontSize]
  );

  return options;
};

const CardDetails = () => {
  const options = useOptions();
  const stripe = useStripe();
  const elements = useElements();

  const [PayToken, setPayToken] = React.useState("");
  const [PayTokenStatus, setPayTokenStatus] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const [openToast, setOpenToast] = React.useState({ status: false, msg: "" });
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenToast({ status: false, msg: "" });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const { token, error } = await stripe.createToken(
      elements.getElement(CardNumberElement)
    );

    if (error) {
      console.log(error, "error from pay token");
    } else {
      setPayToken(token.id);
      setPayTokenStatus(true);
      setLoading(true);
    }
  };

  let reduxPayToken = useSelector((state) => state.userAuth.payToken);

  React.useEffect(() => {
    if (PayTokenStatus) {
      const data = {
        token: PayToken,
        amount: 30,
        days: 30,
      };

      const config = {
        headers: { Authorization: `Bearer ${reduxPayToken.data.token}` },
      };

      axios
        .post(`${baseURL}/api/v1/user/subscription`, data, config)
        .then((res) => {
          if (res.data.success) {
            // alert("success");
            setPayTokenStatus(false);
            setOpen(true);
            setLoading(false);
          }
        })
        .catch((error) => {
          // alert(error);
          setPayTokenStatus(false);
          setOpenToast({ status: true, msg: error.response.data.message });
        });
    }
  }, [PayToken, PayTokenStatus]);

  return (
    <>
      <InsideNavbar />

      <Box className="mobile-responsive-body">
        <Box sx={{ display: "flex", gap: "50px" }} className="wrap">
          <Box className="leftPart">
            <Typography sx={cardDetailsHeading} className="cardDetailsHeading">
              Please add your card <br />
              Details below
            </Typography>
            <Box
              sx={{
                display: "flex",
                marginTop: "20px",
                gap: "15px",
              }}
            >
              <Box sx={paymentAvailableOptionStyle}>
                <img src={visa} alt="payment" width="45px" />
              </Box>

              <Box sx={paymentAvailableOptionStyle}>
                <img src={master} alt="payment" width="45px" />
              </Box>
              <Box sx={paymentAvailableOptionStyle}>
                <img src={american} alt="payment" width="45px" />
              </Box>
            </Box>

            <Box className="fields" sx={{ py: 3 }}>
              <form onSubmit={handleSubmit}>
                <Grid
                  container
                  direction="row"
                  justifyContent="left"
                  alignItems="center"
                  spacing={2}
                >
                  <Grid item xs={12} sm={12}>
                    <Typography sx={labelText}>Card Number*</Typography>
                    <CardNumberElement
                      className="textStyleStripe"
                      options={options}
                      onReady={() => {
                        console.log("CardNumberElement [ready]");
                      }}
                      onChange={(event) => {
                        console.log("CardNumberElement [change]", event);
                      }}
                      onBlur={() => {
                        console.log("CardNumberElement [blur]");
                      }}
                      onFocus={() => {
                        console.log("CardNumberElement [focus]");
                      }}
                    />
                  </Grid>
                </Grid>

                <Grid
                  container
                  direction="row"
                  justifyContent="left"
                  alignItems="center"
                  spacing={2}
                >
                  <Grid item xs={12} sm={6}>
                    <Typography sx={labelText}>Expiry Month & Year*</Typography>
                    <CardExpiryElement
                      className="textStyleStripe"
                      options={options}
                      onReady={() => {
                        console.log("CardNumberElement [ready]");
                      }}
                      onChange={(event) => {
                        console.log("CardNumberElement [change]", event);
                      }}
                      onBlur={() => {
                        console.log("CardNumberElement [blur]");
                      }}
                      onFocus={() => {
                        console.log("CardNumberElement [focus]");
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Typography sx={labelText}>Security Code**</Typography>
                    <CardCvcElement
                      className="textStyleStripe"
                      options={options}
                      onReady={() => {
                        console.log("CardNumberElement [ready]");
                      }}
                      onChange={(event) => {
                        console.log("CardNumberElement [change]", event);
                      }}
                      onBlur={() => {
                        console.log("CardNumberElement [blur]");
                      }}
                      onFocus={() => {
                        console.log("CardNumberElement [focus]");
                      }}
                    />
                  </Grid>
                </Grid>

                <button className="btnStyle" type="submit" disabled={!stripe}>
                  {loading ? "Checking... " : "Checkout"}
                </button>
              </form>
            </Box>
          </Box>

          <Box>
            <CardRight />
          </Box>
        </Box>
      </Box>

      <PaymentSuccess open={open} setOpen={setOpen} />

      <Snackbar
        open={openToast.status}
        autoHideDuration={4000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {openToast.msg}
        </Alert>
      </Snackbar>

      <Footer />
    </>
  );
};

export default CardDetails;

// const btn = {
//   color: "#ffffff",
//   background: "#3F292B",
//   fontFamily: "Kumbh Sans",
//   fontSize: "10px",
//   padding: "12px 35px",
//   "&:hover": {
//     border: "1px solid #3F292B",
//     color: "#3F292B",
//   },
// };
const labelText = {
  fontSize: "14px",
  fontFamily: "Kumbh Sans",
  fontWeight: 500,
  marginTop: "20px",
  marginBottom: "8px",
  // paddingX: "40px",
};
const cardDetailsHeading = {
  fontFamily: "Kumbh Sans",
  fontSize: "33px",
  fontWeight: "bold",
  display: "flex",
  justifyContent: "left",
  // paddingX: "40px",
};

const paymentAvailableOptionStyle = {
  background: "#FAF6F4",
  borderRadius: "5px",
  paddingY: "4px",
  paddingX: "10px",
};
