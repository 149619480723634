import * as React from "react";

// import OurEventsAdd from "./OurEventsAdd";

import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import moment from "moment";
import Typography from "@mui/material/Typography";
// import Paper from "@mui/material/Paper";
// import Checkbox from "@mui/material/Checkbox";
// import IconButton from "@mui/material/IconButton";
// import Tooltip from "@mui/material/Tooltip";
// import FormControlLabel from "@mui/material/FormControlLabel";
// import Switch from "@mui/material/Switch";
// import DeleteIcon from "@mui/icons-material/Delete";
// import FilterListIcon from "@mui/icons-material/FilterList";
// import { visuallyHidden } from "@mui/utils";
// import moment from "moment";
import axios from "axios";
const baseURL = process.env.REACT_APP_BASEURL;

// import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
// import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
// import { Button, Stack } from "@mui/material";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  //   {
  //     id: "Name",
  //     numeric: false,
  //     disablePadding: true,
  //     label: "Name",
  //   },
  {
    id: "Contact",
    numeric: false,
    disablePadding: false,
    label: "From Contact",
  },
  {
    id: "ToChurch",
    numeric: false,
    disablePadding: false,
    label: "To Church",
  },
  {
    id: "Topic",
    numeric: false,
    disablePadding: true,
    label: "Topic",
  },

  {
    id: "Date",
    numeric: false,
    disablePadding: true,
    label: "Date",
  },

  {
    id: "Question",
    numeric: false,
    disablePadding: false,
    label: "Asked Inquiry",
  },

  // {
  //   id: "actions",
  //   numeric: false,
  //   disablePadding: false,
  //   label: "Actions",
  // },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={colHeading}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {/* {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null} */}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      <Typography
        sx={tableHeading}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        My Asked Inquiries
      </Typography>
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function InqueryAsked({ tokenApi }) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [openAdd, setOpenAdd] = React.useState(false);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rowData.map((n) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // const isSelected = (name) => selected.indexOf(name) !== -1;

  const [rowData, setRowData] = React.useState([]);

  function createData(Name, Contact, ToChurch, Topic, Date, Question, Id) {
    return {
      Name,
      Contact,
      ToChurch,
      Topic,
      Date,
      Question,
      Id,
    };
  }

  React.useEffect(() => {
    const config = {
      headers: { Authorization: `Bearer ${tokenApi}` },
    };

    const getAllEventsRequest = async () => {
      await axios
        .get(`${baseURL}/api/v1/inquiry/getAllInquiryOfLoggedInUser`, config)
        .then((response) => {
          // console.log(response.data.data, "<<< responseeeeee");

          if (response.data.success) {
            const value = response.data.data.map((i) => {
              return {
                render: createData(
                  i._id,
                  i.contact,
                  i.church?.fullName,
                  i.inquiryType,
                  i.updatedAt,
                  i.inquiryDescription,
                  i._id
                ),
              };
            });

            setRowData(value);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };
    getAllEventsRequest();
  }, []);

  //   console.log("rowsdatadata", rowData);

  return (
    <>
      <Box
        sx={{
          borderRadius: "20px",
          padding: "20px",
          background: "#ffffff",
          height: "100%",
          width: "auto",
          overflow: "auto",
          m: 2,
        }}
      >
        <EnhancedTableToolbar
          numSelected={selected.length}
          openAdd={openAdd}
          setOpenAdd={setOpenAdd}
        />
        <TableContainer>
          <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rowData.length}
            />
            <TableBody>
              {stableSort(rowData, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((rowx, index) => {
                  // const isItemSelected = isSelected(row.name);
                  // const labelId = `enhanced-table-checkbox-${index}`;
                  const row = rowx.render;
                  return (
                    <TableRow hover key={row.name}>
                      {/* <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            "aria-labelledby": labelId,
                          }}
                        />
                      </TableCell> */}

                      <TableCell
                        component="th"
                        scope="row"
                        padding="none"
                        sx={colData}
                        align="left"
                      >
                        {row.Contact}
                      </TableCell>

                      <TableCell align="left" sx={colData}>
                        {row.ToChurch}
                      </TableCell>

                      <TableCell align="left" sx={colData}>
                        {row.Topic}
                      </TableCell>

                      <TableCell align="left" sx={colData}>
                        {moment(row.Date).format("lll")}
                      </TableCell>

                      <TableCell align="left" sx={colData}>
                        {row.Question}
                      </TableCell>

                      {/* <TableCell
                        sx={{
                          display: "flex",
                          justifyContent: "space-evenly",
                          color: "#D1AB42",
                        }}
                      >
                        <DeleteOutlinedIcon />
                      </TableCell> */}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 15, 25]}
          component="div"
          count={rowData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
      {/* <OurEventsAdd openAdd={openAdd} setOpenAdd={setOpenAdd} /> */}
    </>
  );
}

const tableHeading = {
  fontSize: "18px",
  fontFamily: "Kumbh Sans",
  fontWeight: "bold",
  padding: "15px",
};
const colHeading = {
  fontSize: "14px",
  fontFamily: "Kumbh Sans",
  fontWeight: "bold",
};

const colData = {
  fontSize: "12px",
  fontFamily: "Kumbh Sans",
};
// const loginBtn = {
//   gap: "10px",
//   color: "#ffffff",
//   background: "#D1AB42",
//   textTransform: "capitalize",
//   fontFamily: "Kumbh Sans",
//   border: "1px solid #D1AB42",
//   fontSize: "8px",
//   mr: 1,
//   padding: "5px 1px",
//   "&:hover": {
//     border: "1px solid #3F292B",
//     color: "#3F292B",
//   },
// };
