import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logout } from "../../redux/userAuthSlice";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CancelSubscriptionComp from "./CancelSubscription";

import MenuItem from "@mui/material/MenuItem";
// import ListItemIcon from "@mui/material/ListItemIcon";
// import Divider from "@mui/material/Divider";
// import IconButton from "@mui/material/IconButton";
// import Tooltip from "@mui/material/Tooltip";
// import PersonAdd from "@mui/icons-material/PersonAdd";
// import Settings from "@mui/icons-material/Settings";
// import Logout from "@mui/icons-material/Logout";
import Avatar from "@mui/material/Avatar";
import { styled, alpha } from "@mui/material/styles";
import Menu from "@mui/material/Menu";
import { Button } from "@mui/material";

// function stringAvatar(name) {
//   return {
//     sx: {
//       bgcolor: "#D1AB42",
//       fontSize: "14px",
//       width: "35px",
//       height: "35px",
//     },
//     children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
//   };
// }

export default function LoginUserAvatar({ isLogout, setIsLogout }) {
  const navigate = useNavigate();

  // ** Getting from Redux
  // const UserDataFromRedux = useSelector((state) => state.userAuth.logoutStatus);
  const dispatch = useDispatch();

  // console.log({ UserDataFromRedux });

  // ** UserDataAuth
  const [UserData, setUserData] = React.useState([]);
  const [userRole1, setuserRole1] = React.useState();

  React.useEffect(() => {
    const getloginLocalInfo = JSON.parse(
      localStorage.getItem("loginLocalInfo")
    );

    if (getloginLocalInfo) {
      setUserData(getloginLocalInfo);
      setuserRole1(getloginLocalInfo.role);
    }
  }, []);

  // ** view profile popup
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  localStorage.setItem("logoutStatus", isLogout);

  // ** CSS style
  const labelStyle = {
    color: "#3F292B",
    fontSize: "14px",
    fontFamily: "Kumbh Sans",
  };

  const labelStyleLoginAs = {
    color: "#3F292B",
    fontSize: "14px",
    fontFamily: "Kumbh Sans",
    marginLeft: "15px",
    paddingY: "5px",
  };

  const loginBtn = {
    gap: "10px",
    color: "#ffffff",
    background: "#D1AB42",
    textTransform: "capitalize",
    fontFamily: "Kumbh Sans",
    border: "1px solid #D1AB42",
    padding: "3px 30%",

    "&:hover": {
      border: "1px solid #3F292B",
      color: "#3F292B",
    },
  };
  const viewAccStyle = {
    color: "#ffffff",
    opacity: 0.5,
    fontSize: "9px",
  };
  const viewAccNameStyle = {
    color: "#ffffff",
    fontSize: "11px",
    textTransform: "capitalize",
  };

  const StyledMenu = styled((props) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      {...props}
    />
  ))(({ theme }) => ({
    "& .MuiPaper-root": {
      // borderRadius: 6,
      marginTop: theme.spacing(1),
      minWidth: 180,
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      boxShadow:
        "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
      "& .MuiMenu-list": {
        // padding: "10px 0",
      },
      // "& .MuiMenuItem-root": {
      //   "& .MuiSvgIcon-root": {
      //     fontSize: 18,
      //     color: theme.palette.text.secondary,
      //     marginRight: theme.spacing(1.5),
      //   },
      //   "&:active": {
      //     backgroundColor: alpha(
      //       theme.palette.primary.main,
      //       theme.palette.action.selectedOpacity
      //     ),
      //   },
      // },
    },
  }));

  const [CancelSubscription, setCancelSubscription] = React.useState(false);

  return (
    <>
      {UserData?.fullName ? (
        <>
          <Box sx={{ display: { xs: "none", sm: "none", md: "block" } }}>
            <Button
              id="demo-customized-button"
              aria-controls={open ? "demo-customized-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              disableElevation
              onClick={handleClick}
              sx={{
                background: "#3F292B",
                borderRadius: "35px",
                display: "flex",
                gap: "5px",
                px: 1,
                "&:hover": {
                  background: "#3F292B",
                },
              }}
            >
              <Avatar
                sx={{
                  bgcolor: "#D1AB42",
                  fontSize: "14px",
                  width: "35px",
                  height: "35px",
                }}
              />
              <Box>
                <Typography sx={viewAccNameStyle}>
                  {UserData && UserData?.fullName}
                </Typography>
                <Typography sx={viewAccStyle}>View account</Typography>
              </Box>
            </Button>
            <StyledMenu
              id="demo-customized-menu"
              MenuListProps={{
                "aria-labelledby": "demo-customized-button",
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
            >
              <Typography sx={labelStyleLoginAs}>
                Login as : {userRole1}
              </Typography>
              <MenuItem
                sx={labelStyle}
                onClick={() => {
                  if (UserData?.role === "User") {
                    navigate("/userProfile");
                  }

                  if (UserData?.role === "Church" || "Ministry" || "Artist") {
                    navigate("/churchProfile");
                  }

                  if (UserData?.role === "Admin") {
                    navigate("/superAdminProfile");
                  }
                }}
                disableRipple
              >
                Dashboard
              </MenuItem>
              {/* subscription */}
              {/* {(UserData?.role !== "User" || "Admin") && (
                <MenuItem
                  sx={labelStyle}
                  onClick={() => {
                    setCancelSubscription(true);
                  }}
                  disableRipple
                >
                  Cancel Subscription
                </MenuItem>
              )} */}
              <MenuItem
                sx={labelStyle}
                onClick={async () => {
                  setIsLogout(true);
                  localStorage.removeItem("loginLocalInfo");
                  localStorage.clear();
                  navigate("/");
                  // alert("logout")
                  window.location.reload();
                }}
                disableRipple
              >
                {isLogout ? "Logout" : "Logout"}
              </MenuItem>
            </StyledMenu>
          </Box>

          {/* Mobile Avatar */}
          <Box sx={{ display: { xs: "block", sm: "block", md: "none" } }}>
            <Box>
              <Box
                sx={{
                  background: "#3F292B",
                  borderRadius: "10px",
                  display: "flex",
                  padding: "5px",
                  margin: "8px",
                }}
              >
                <Avatar
                  sx={{
                    bgcolor: "#D1AB42",
                    fontSize: "14px",
                    width: "35px",
                    height: "35px",
                  }}
                />
                <Box sx={{ marginX: "5px" }}>
                  <Typography sx={{ color: "#ffffff", fontSize: "12px" }}>
                    {UserData && UserData?.fullName}
                  </Typography>

                  <Typography
                    onClick={() => {
                      if (UserData?.role === "User") {
                        navigate("/userProfile");
                      }

                      if (
                        UserData?.role === ("Church" || "Ministry" || "Artist")
                      ) {
                        navigate("/churchProfile");
                      }

                      if (UserData?.role === "Admin") {
                        navigate("/superAdminProfile");
                      }
                    }}
                    sx={{
                      color: "#ffffff",
                      opacity: 0.5,
                      fontSize: "10px",
                      cursor: "pointer",
                    }}
                  >
                    View Profile
                  </Typography>
                </Box>
              </Box>
              <Button
                sx={loginBtn}
                onClick={async () => {
                  setIsLogout(true);
                  localStorage.removeItem("loginLocalInfo");
                  localStorage.clear();
                  navigate("/");
                  dispatch(logout(true));
                }}
              >
                Logout
              </Button>
            </Box>
          </Box>
        </>
      ) : null}

      <CancelSubscriptionComp
        CancelSubscription={CancelSubscription}
        setCancelSubscription={setCancelSubscription}
      />
    </>
  );
}
