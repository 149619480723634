import * as React from "react";
import moment from "moment";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Box from "@mui/material/Box";
import { Button, Typography, Stack } from "@mui/material";
//  import { StyledMenu } from "./ActionButtonStyle";

// ** Popups
// import ViewDetailsPopup from "./AllPopUp/ViewEventDetailsPopup";
// import ApproveEventPopup from "./AllPopUp/ApproveEventPopUp";
// import RejectEventPopup from "./AllPopUp/RejectEventPopUp";

import axios from "axios";
import { RowingSharp } from "@mui/icons-material";
const baseURL = process.env.REACT_APP_BASEURL;

// const Alert = React.forwardRef(function Alert(props, ref) {
//   return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
// });

function SiteEvents({ activeItem, setActiveItem }) {
  // const [openViewDetialpopUp, setOpenViewDetialpopUp] = React.useState(false);
  // const [openApprovepopUp, setApprovepopUp] = React.useState(false);
  // const [openRejectpopUp, setRejectpopUp] = React.useState(false);

  // const [anchorEl, setAnchorEl] = React.useState(null);
  // const [CurrentId, setCurrentId] = React.useState("");

  // const open = Boolean(anchorEl);
  // const handleClick = (event, id) => {
  //   // console.log("kkkkkkk", event.currentTarget);
  //   setAnchorEl(event.currentTarget);
  //   setCurrentId(id);
  //   setOpenViewDetialpopUp(true);
  // };

  // const handleClose = () => {
  //   setAnchorEl(null);
  // };

  // *** Pagination
  // const options = [2, 5, 10, 15, 20];
  // const [value, setValue] = React.useState(options[2]);
  // const [page, setPage] = React.useState(1);
  // const [total, setTotal] = React.useState();

  // let count = Math.ceil(total / value);
  // let start = (page - 1) * value;
  // let last = Math.min(start + value - 1, total - 1);

  function createData(Transaction, Amount, Currency, Date) {
    return { Transaction, Amount, Currency, Date };
  }

  const [rowx, setRowX] = React.useState([]);
  const [afterId, setAfterId] = React.useState("");
  const [beforeId, setBeforeId] = React.useState("");
  const [Load, setLoad] = React.useState(false);

  React.useEffect(() => {
    const getloginLocalInfo = JSON.parse(
      localStorage.getItem("loginLocalInfo")
    );

    const config = {
      headers: { Authorization: `Bearer ${getloginLocalInfo.token}` },
    };

    axios
      .get(
        `${baseURL}/api/v1/admin/getRevenue?after=${afterId}&before=${beforeId}
      `,
        config
      )
      .then((response) => {
        if (response.data.success) {
          setLoad(false);
          const valx = response.data.data.map((i) => {
            return {
              render: createData(
                i.transaction_id,
                i.ammount,
                i.currency,
                i.createdDate
              ),
            };
          });
          setRowX(valx);
        }
      })
      .catch((error) => {
        if (error.response.status) {
          console.log("no data found 404");
          setLoad(false);
        }
        setLoad(false);
      });
  }, [Load]);

  React.useEffect(() => {
    setAfterId(rowx[rowx.length - 1]?.render?.Transaction);
    setBeforeId(rowx[0]?.render?.Transaction);
  }, [rowx]);

  // console.log(afterId, beforeId, "xyz");

  return (
    <Box
      sx={{
        borderRadius: "20px",
        marginY: "25px",
        padding: "20px",
        background: "#ffffff",
        overflow: "auto",
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
      >
        <Typography sx={tableHeading}>Revenue Transactions</Typography>
        <Box>
          <Button
            variant="outlined"
            sx={activeItem === "Events" ? { display: "none" } : loginBtn}
            onClick={() => setActiveItem("Events")}
          >
            View all
          </Button>
        </Box>
      </Stack>

      <Table sx={{ minWidth: "auto" }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell sx={colHeading} align="left">
              Transaction Id
            </TableCell>
            <TableCell sx={colHeading} align="left">
              Amount
            </TableCell>

            <TableCell sx={colHeading} align="left">
              Currency
            </TableCell>

            <TableCell sx={colHeading} align="left">
              Date
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {rowx.map((rown, i) => {
            const row = rown.render;

            return (
              <TableRow
                key={i}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell sx={colData} component="th" scope="row">
                  {row.Transaction}
                </TableCell>

                <TableCell sx={colData} align="left">
                  ${row.Amount}
                </TableCell>

                <TableCell sx={colData} align="left">
                  {row.Currency.toUpperCase()}
                </TableCell>

                <TableCell sx={colData} align="left">
                  {moment(row.Date).format("lll")}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>

      <Stack direction="row" justifyContent="space-between">
        {/* <Typography sx={paginationStyle}>
          Showing {total === 0 ? 0 : start + 1} to {last + 1} of {total} entries
        </Typography>

        <Pagination
          count={count}
          page={page}
          onChange={(event, newValue) => {
            setPage(newValue);
          }}
        /> */}

        <Button
          variant="outlined"
          sx={loginBtn}
          onClick={() => {
            setAfterId("");
            setLoad(true);
          }}
        >
          Previous
        </Button>
        <Button
          variant="outlined"
          sx={loginBtn}
          onClick={() => {
            setBeforeId("");
            setLoad(true);
          }}
        >
          Next
        </Button>
      </Stack>

      {/* <Snackbar
        open={openToastReject}
        autoHideDuration={2000}
        onClose={handleCloseToastReject}
      >
        <Alert
          onClose={handleCloseToastReject}
          severity="error"
          sx={{ width: "100%" }}
        >
          Event Rejected
        </Alert>
      </Snackbar>

      <Snackbar
        open={openToastSuccess}
        autoHideDuration={2000}
        onClose={handleCloseToastSuccess}
      >
        <Alert
          onClose={handleCloseToastSuccess}
          severity="success"
          sx={{ width: "100%" }}
        >
          Event Approved
        </Alert> 
      </Snackbar> */}

      {/* <ViewDetailsPopup
        handleClose={handleClose}
        openViewDetialpopUp={openViewDetialpopUp}
        setOpenViewDetialpopUp={setOpenViewDetialpopUp}
        particularId={CurrentId}
        openApprovepopUp={openApprovepopUp}
        setApprovepopUp={setApprovepopUp}
        openRejectpopUp={openRejectpopUp}
        setRejectpopUp={setRejectpopUp}
      />

      <ApproveEventPopup
        handleClose={handleClose}
        openApprovepopUp={openApprovepopUp}
        setApprovepopUp={setApprovepopUp}
        particularId={CurrentId}
        setOpenViewDetialpopUp={setOpenViewDetialpopUp}
        openViewDetialpopUp={openViewDetialpopUp}
      />

      <RejectEventPopup
        handleClose={handleClose}
        openRejectpopUp={openRejectpopUp}
        setRejectpopUp={setRejectpopUp}
        particularId={CurrentId}
        setOpenViewDetialpopUp={setOpenViewDetialpopUp}
        openViewDetialpopUp={openViewDetialpopUp}
      /> */}
    </Box>
  );
}

export default SiteEvents;

const tableHeading = {
  fontSize: "16px",
  fontFamily: "Kumbh Sans",
  fontWeight: "bold",
  padding: "15px",
};
const colHeading = {
  fontSize: "14px",
  fontFamily: "Kumbh Sans",
  fontWeight: "bold",
};

const colData = {
  fontSize: "12px",
  fontFamily: "Kumbh Sans",
};

// const paginationStyle = {
//   fontSize: "12px",
//   fontFamily: "Kumbh Sans",
// };

// const colDataPending = {
//   fontSize: "12px",
//   fontFamily: "Kumbh Sans",
//   color: "orange",
// };

// const colDataRejected = {
//   fontSize: "12px",
//   fontFamily: "Kumbh Sans",
//   color: "red",
// };

// const colDataApproved = {
//   fontSize: "12px",
//   fontFamily: "Kumbh Sans",
//   color: "green",
// };

// const darkBtn = {
//   fontSize: "10px",
//   color: "#ffffff",
//   background: "#3F292B",
//   textTransform: "capitalize",
//   fontFamily: "Kumbh Sans",
//   border: "1px solid #3F292B",
//   mr: 1,
//   "&:hover": {
//     border: "1px solid #D1AB42",
//     background: "#D1AB42",
//   },
// };

const loginBtn = {
  gap: "10px",
  color: "#ffffff",
  background: "#D1AB42",
  textTransform: "capitalize",
  fontFamily: "Kumbh Sans",
  border: "1px solid #D1AB42",
  fontSize: "10px",
  "&:hover": {
    border: "1px solid #3F292B",
    color: "#3F292B",
  },
};
