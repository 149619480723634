export const closeIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    viewBox="0 0 16.917 16.917"
  >
    <g
      id="Icon_feather-plus"
      data-name="Icon feather-plus"
      transform="translate(-4.216 8.458) rotate(-45)"
    >
      <path
        id="Path_74392"
        data-name="Path 74392"
        d="M0,0V17.924"
        transform="translate(8.962 0)"
        fill="none"
        stroke="#91857f"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="3"
      />
      <path
        id="Path_74393"
        data-name="Path 74393"
        d="M0,0H17.924"
        transform="translate(0 8.962)"
        fill="none"
        stroke="#91857f"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="3"
      />
    </g>
  </svg>
);
