import React from "react";
import "../FirstPage/firstpage.css";

// ** MUI
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
// import Typography from "@mui/material/Typography";
// import Link from "@mui/material/Link";
import DialogAttending from "./dialogAttending";
import DialogMayBe from "./dialogMaybe";

import DialogRegister from "./dialogNotRegister";
// import MyLocationIcon from "@mui/icons-material/MyLocation";

import axios from "axios";
// import moment from "moment";
const baseURL = process.env.REACT_APP_BASEURL;

const AddAttendance = ({ fromDate, toDate, id, name, location }) => {
  const [open, setOpen] = React.useState(false);
  const [openMaybe, setOpenMaybe] = React.useState(false);
  const [openRegister, setOpenRegister] = React.useState(false);

  const AttendingEvent = (response) => {
    const getloginLocalInfo = JSON.parse(
      localStorage.getItem("loginLocalInfo")
    );

    if (getloginLocalInfo) {
      const config = {
        headers: { Authorization: `Bearer ${getloginLocalInfo.token}` },
      };
      axios
        .put(
          `${baseURL}/api/v1/event/bookEvent/${id}`,
          {
            status: response,
          },
          config
        )
        .then((res) => {
          if (res.data.success) {
            setOpen(true);
          } else {
            setOpenRegister(true);
          }
        })
        .catch((error) => {
          console.log(error);
          setOpenRegister(true);
        });
    } else {
      setOpenRegister(true);
    }
  };

  const MayBeAttendEvent = (response) => {
    const getloginLocalInfo = JSON.parse(
      localStorage.getItem("loginLocalInfo")
    );

    if (getloginLocalInfo) {
      const config = {
        headers: { Authorization: `Bearer ${getloginLocalInfo.token}` },
      };
      axios
        .put(
          `${baseURL}/api/v1/event/bookEvent/${id}`,
          {
            status: response,
          },
          config
        )
        .then((res) => {
          // console.log(res, "<< res >>");
          if (res.data.success) {
            setOpenMaybe(true);
          }
        })
        .catch((error) => {
          console.log(error);
          setOpenRegister(true);
        });
    } else {
      setOpenRegister(true);
    }
  };

  const NotAttendEvent = (response) => {
    const getloginLocalInfo = JSON.parse(
      localStorage.getItem("loginLocalInfo")
    );

    if (getloginLocalInfo) {
      const config = {
        headers: { Authorization: `Bearer ${getloginLocalInfo.token}` },
      };
      axios
        .put(
          `${baseURL}/api/v1/event/bookEvent/${id}`,
          {
            status: response,
          },
          config
        )
        .then((res) => {
          // console.log(res, "<< res >>");
          if (res.data.success) {
            setOpenMaybe(true);
          }
        })
        .catch((error) => {
          console.log(error);
          setOpenRegister(true);
        });
    } else {
      setOpenRegister(true);
    }
  };

  return (
    <Box sx={addAttendanceStyle}>
      {/* <Typography
        sx={{
          marginX: "0",
          marginBottom: "9px",
          fontFamily: "Kumbh Sans",
          fontSize: "18px",
          fontWeight: "bold",
        }}
      >
        Add attendance
      </Typography>

      <Typography
        sx={{
          marginX: "0",
          marginBottom: "9px",
          fontFamily: "Kumbh Sans",
          fontSize: "12px",
        }}
      >
        {moment(fromDate).format("lll")} - {moment(toDate).format("lll")}
      </Typography>

      <Typography
        sx={{
          marginX: "0",
          marginBottom: "9px",
          fontFamily: "Kumbh Sans",
          fontSize: "12px",
          fontWeight: "bold",
        }}
      >
        {location?.city}, {location?.state}, {location?.country}
      </Typography> */}

      {/* <Box sx={{ display: "flex" }}>
        <MyLocationIcon sx={{ fontSize: "19px" }} />
        <Box>
          <Link
            sx={{
              marginX: "0",
              marginBottom: "9px",
              fontFamily: "Kumbh Sans",
              fontSize: "11px",
              color: "#000000",
            }}
            underline="hover"
          >
            &nbsp;View route options 
          </Link>
        </Box>
      </Box> */}

      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          // justifyContent: "space-between",
          marginTop: "10px",
          width: "100%",
        }}
      >
        <Button
          onClick={() => AttendingEvent("Attending")}
          sx={Attend}
          className="attendance-btn-Width"
        >
          Attending
        </Button>

        <Button
          onClick={() => MayBeAttendEvent("MayAttend")}
          sx={May}
          className="attendance-btn-Width"
        >
          May Attend
        </Button>

        <Button
          onClick={() => NotAttendEvent("NotAttend")}
          sx={Not}
          className="attendance-btn-Width"
        >
          Not Attending
        </Button>
      </Box>

      <DialogAttending open={open} setOpen={setOpen} />
      <DialogMayBe openMaybe={openMaybe} setOpenMaybe={setOpenMaybe} />

      <DialogRegister
        openRegister={openRegister}
        setOpenRegister={setOpenRegister}
      />
    </Box>
  );
};

export default AddAttendance;

const addAttendanceStyle = {
  background: "#FAF6F4",
  padding: "15px",
  borderRadius: "10px",
  width: "auto",
  marginLeft: "20px",
  marginTop: "20px",
};

const Not = {
  color: "#3F292B",
  background: "transparent",
  border: 1,
  fontFamily: "Kumbh Sans",
  fontSize: "10px",
  padding: "10px 10px",

  minWidth: "70px",

  "&:hover": {
    border: "1px solid #3F292B",
    color: "#3F292B",
    boxShadow: "inset 12em 0 0 0 white",
  },
};

const May = {
  color: "#ffffff",
  background: "#3F292B",
  fontFamily: "Kumbh Sans",
  fontSize: "10px",
  padding: "10px 16px",
  marginX: "10px",
  border: "1px solid #3F292B",

  "&:hover": {
    border: "1px solid #3F292B",
    color: "#3F292B",
    boxShadow: "inset 10em 0 0 0 white",
  },
};

const Attend = {
  color: "#ffffff",
  background: "#D1AB42",
  fontFamily: "Kumbh Sans",
  fontSize: "10px",
  padding: "10px 16px",
  border: "1px solid #D1AB42",

  "&:hover": {
    border: "1px solid #3F292B",
    color: "#3F292B",
    boxShadow: "inset 10em 0 0 0 white",
  },
};
