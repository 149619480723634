import React, { Suspense } from "react";
import InsideNavbar from "../navbar/InsideNavbar";
import Footer from "../footer";

// import ContactHead from "./contactHead";
// import ContactForm from "./contactform";

// Lazy-loaded
const ContactHead = React.lazy(() => import("./contactHead"));
const ContactForm = React.lazy(() => import("./contactform"));

const Spinner = () => {
  return (
    <div className="spinner-container">
      <div className="spinner"></div>
    </div>
  );
};

const Index = () => {
  return (
    <>
      <InsideNavbar />

      <Suspense fallback={<Spinner />}>
        <ContactHead />
        <ContactForm />
      </Suspense>

      <Footer />
    </>
  );
};

export default Index;
