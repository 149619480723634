import React, { useState, useEffect, useRef } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";

import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
// import AddLocation from "../GoogleMap/AddLocation";
// import MapSet from "../GoogleMap/MapSetter";
import axios from "axios";

import {
  GoogleMap,
  Autocomplete,
  Marker,
  useJsApiLoader,
} from "@react-google-maps/api";
import MyLocationIcon from "@mui/icons-material/MyLocation";
// import { useDispatch } from "react-redux";
// import { coordinate } from "../../redux/userAuthSlice";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenDialog({ setOpenGeoMap, openGeoMap }) {
  const handleClose = () => {
    setOpenGeoMap(false);
  };

  const [autocomplete, setAutocomplete] = useState(null);
  const [userCoords, setUserCoords] = useState(null);
  const [address, setAddress] = useState(null);

  const API_KEY = process.env.REACT_APP_GOOGLE_MAP_KEY;
  const API_ENDPOINT = `https://www.googleapis.com/geolocation/v1/geolocate?key=${API_KEY}`;

  useEffect(() => {
    getLocations();
  }, []);

  const getLocations = async () => {
    let config = {
      url: API_ENDPOINT,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({}),
    };

    let { data } = await axios(config);
    console.dir({ data }, { depth: null });
    setUserCoords(data?.location);
  };

  // const getLocations = () => {
  //   console.log("my locationsssss");
  //   navigator.geolocation.getCurrentPosition(
  //     (position) => {
  //       setUserCoords({
  //         lat: position.coords.latitude,
  //         lng: position.coords.longitude,
  //       });
  //     },
  //     (error) => {
  //       console.error(error);
  //     },
  //     {
  //       enableHighAccuracy: true,
  //       maximumAge: 1000,
  //       timeout: 5000,
  //     }
  //   );
  // };

  const handleClick = (e) => {
    setUserCoords(
      {
        lat: e.latLng.lat(),
        lng: e.latLng.lng(),
      },
      () => {
        getAddressData();
      }
    );
  };

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_KEY,
    libraries: ["places", "geometry"],
  });

  

  const onLoads = (autocomplete) => {
    setAutocomplete(autocomplete);
  };

  const getFullAddress = (address) => {
    let obj = {};
    address.forEach((item) => {
      if (item.types.includes("country")) {
        obj.country = item.long_name;
      }
      if (item.types.includes("administrative_area_level_1")) {
        obj.state = item.long_name;
      }
      if (item.types.includes("administrative_area_level_3")) {
        obj.city = item.long_name;
      }
      if (item.types.includes("postal_code")) {
        obj.zipcode = item.long_name;
      }
    });
    return obj;
  };

  const onPlaceChanged = () => {
    if (autocomplete !== null) {
      const place = autocomplete.getPlace();
      if (place.address_components && place.geometry) {
        let area = place.formatted_address;
        setAddress({
          ...getFullAddress(place?.address_components),
          area,
        });
        setUserCoords({
          lat: place?.geometry.location.lat(),
          lng: place?.geometry.location.lng(),
        });
      }
    } else {
      console.log("Autocomplete is not loaded yet!");
    }
  };

  useEffect(() => {
    if (userCoords && isLoaded) {
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ location: userCoords }, (results, status) => {
        if (status === "OK") {
          let area = results[0]?.formatted_address;
          setAddress({
            ...getFullAddress(results[0]?.address_components),
            area,
          });
        } else {
          console.error(
            "Geocode was not successful for the following reason:",
            status
          );
        }
      });
    }
  }, [userCoords, isLoaded]);

  console.log({ userCoords, address });

  const getAddressData = () => {
    localStorage.setItem(
      "mapData",
      JSON.stringify({ ...address, ...userCoords })
    );
    // window.close();
  };

  return (
    <>
      <Dialog
        fullScreen
        open={openGeoMap}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Back
            </Typography>
            <Button autoFocus color="inherit" onClick={handleClose}>
              save
            </Button>
          </Toolbar>
        </AppBar>

        {isLoaded && (
          <>
            <button onClick={() => getLocations()} style={mylocBtn}>
              <MyLocationIcon />
            </button>

            <GoogleMap
              mapContainerStyle={containerStyle}
              zoom={17}
              center={userCoords}
              onClick={handleClick}
            >
              <Marker position={userCoords} />
              <Autocomplete onLoad={onLoads} onPlaceChanged={onPlaceChanged}>
                <input
                  type="text"
                  placeholder="Search Your Location"
                  style={textStyle}
                />
              </Autocomplete>
            </GoogleMap>

            <button onClick={() => getAddressData()} style={saveBtn}>
              Save & Confirm 2
            </button>
          </>
        )}
      </Dialog>
    </>
  );
}

const containerStyle = {
  width: "98%",
  height: "97vh",
  margin: "10px",
};

const saveBtn = {
  boxSizing: `border-box`,
  border: `1px solid transparent`,
  width: `170px`,
  background: `DodgerBlue`,
  color: `white`,
  height: `40px`,
  padding: `0 12px`,
  borderRadius: `3px`,
  boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
  fontSize: `14px`,
  outline: `none`,
  textOverflow: `ellipses`,
  position: "absolute",
  left: "22%",
  top: "20px",
  marginLeft: "200px",
  zIndex: 9999,
  cursor: `pointer`,
};

const mylocBtn = {
  boxSizing: `border-box`,
  border: `1px solid transparent`,
  width: `auto`,
  background: `DodgerBlue`,
  color: `white`,
  height: `40px`,
  padding: `0 12px`,
  borderRadius: `3px`,
  boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
  fontSize: `14px`,
  outline: `none`,
  textOverflow: `ellipses`,
  position: "absolute",
  right: "2%",
  top: "20px",
  marginLeft: "400px",
  zIndex: 9999,
  cursor: `pointer`,
};

const textStyle = {
  boxSizing: `border-box`,
  border: `1px solid transparent`,
  width: `300px`,
  height: `40px`,
  padding: `0 12px`,
  borderRadius: `3px`,
  boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
  fontSize: `14px`,
  outline: `none`,
  textOverflow: `ellipses`,
  position: "absolute",
  left: "22%",
  top: "10px",
  marginLeft: "-120px",
};
