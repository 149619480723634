import { styled } from "@mui/material/styles";
import Menu from "@mui/material/Menu";

export const StyledMenu = styled((props) => (
  <Menu
    elevation={3}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    border: 0,
    marginTop: theme.spacing(1),
    minWidth: 150,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow: "0px 5px 5px #0000001A",

    "& .MuiMenu-list": {
      padding: "4px 0",
      fontSize: 10,
    },
    "& .MuiMenuItem-root": {
      fontSize: 14,
      fontFamily: "Kumbh Sans",
      "& .MuiSvgIcon-root": {
        fontSize: 18,

        color: "#D1AB42",
        fontFamily: "Kumbh Sans",
        marginRight: theme.spacing(1.5),
      },
    },
  },
}));
