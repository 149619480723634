import * as React from "react";

import Box from "@mui/material/Box";
import { Button, Typography, Stack } from "@mui/material";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import { styled } from "@mui/material/styles";
import axios from "axios";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import Card from "@mui/joy/Card";
import CardCover from "@mui/joy/CardCover";
import CardContent from "@mui/joy/CardContent";
import MyVideoAdd from "./myVideoAdd";

const Input = styled("input")({
  display: "none",
});

const baseURL = process.env.REACT_APP_BASEURL;

export default function OurPhotos({
  setActiveItem,
  activeItem,
  userData,
  tokenApi,
  upload,
  setUpload,
  setLoader,
  Loader,
}) {
  const [ImagesUpload, setImagesUpload] = React.useState([]);
  const [removing, setRemoving] = React.useState(false);

  React.useEffect(() => {
    if (upload) {
      let formData = new FormData();

      for (let i = 0; i < ImagesUpload.length; i++) {
        formData.append("images", ImagesUpload[i]);
      }

      uploadPic(formData);
      // console.log({ ImagesUpload });
    }
  }, [upload]);

  const uploadPic = async (data) => {
    setUpload(true);
    const config = {
      headers: {
        Authorization: `Bearer ${tokenApi}`,
      },
    };
    if (data) {
      await axios
        .post(`${baseURL}/api/v1/church/uploadChurchImages`, data, config)
        .then((response) => {
          if (response.data.success) {
            // alert("uploaded");
            setUpload(false);
          }
        })
        .catch((error) => {
          console.log(error);
          // alert(error);
        });
    }
  };

  const onRemovePhoto = async (imgId) => {
    setRemoving(true);

    const config = {
      headers: {
        Authorization: `Bearer ${tokenApi}`,
      },
    };

    const data = {
      imageArray: [imgId],
    };

    await axios
      .post(`${baseURL}/api/v1/church/deleteChurchImages`, data, config)
      .then((response) => {
        if (response.data.success) {
          setLoader(true);
          setRemoving(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  ///// ***** Video
  const [openAddVideo, setOpenAddVideo] = React.useState(false);

  const [play, setPlay] = React.useState(false);
  // const [removing, setRemoving] = React.useState(false);

  const onRemoveVideo = async (vdoId) => {
    setRemoving(true);
    if (tokenApi && baseURL && vdoId) {
      const config = {
        headers: {
          Authorization: `Bearer ${tokenApi}`,
        },
      };

      const data = {
        imageArray: [vdoId],
      };

      await axios
        .post(`${baseURL}/api/v1/church/deleteChurchImages`, data, config)
        .then((response) => {
          if (response.data.success) {
            setLoader(true);
            setRemoving(false);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      alert("elseeeee");
    }
  };

  return (
    <Box
      sx={{
        borderRadius: "20px",
        padding: "20px",
        background: "#ffffff",
        height: "100%",
        width: "auto",
        margin: "0 10px",
        overflow: "auto",
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
      >
        <Typography sx={tableHeading}>
          {removing ? "Removing" : "Album"}
        </Typography>

        <Stack direction="row" alignItems="center" spacing={2}>
          <label htmlFor="contained-button-file">
            <Input
              accept="image/*"
              id="contained-button-file"
              multiple
              type="file"
              onChange={(e) => {
                setImagesUpload(e.target.files);
                setUpload(true);
              }}
            />
            <Button variant="outlined" sx={loginBtn} component="span">
              {upload ? "Uploading.... " : "Add Photo"}
            </Button>
          </label>

          <Button
            variant="outlined"
            sx={loginBtn}
            onClick={() => setOpenAddVideo(true)}
          >
            Upload&nbsp;Video
          </Button>
        </Stack>
      </Stack>

      <ImageList variant="masonry" cols={4} gap={5}>
        {userData?.image?.map((img, ind) => {
          return (
            <Box sx={{ position: "relative" }}>
              <ImageListItem key={ind}>
                <img
                  src={img?.imageUrl}
                  alt={`album${ind}`}
                  width="100%"
                  style={{ borderRadius: "10px" }}
                />
              </ImageListItem>

              <DeleteForeverIcon
                sx={removeBtn}
                onClick={() => onRemovePhoto(img?.imageId)}
              />
            </Box>
          );
        })}

        {userData?.video?.map((img, ind) => {
          return (
            <ImageListItem key={ind} sx={{ borderRadius: "20px" }}>
              <video
                autoPlay
                loop
                muted
                poster="https://assets.codepen.io/6093409/river.jpg"
                style={{ borderRadius: "10px", width: "100%" }}
              >
                <source src={img?.videoUrl} type="video/mp4" />
              </video>

              <DeleteForeverIcon
                sx={removeBtn}
                onClick={() => onRemoveVideo(img?.videoId)}
              />

            </ImageListItem>
          );
        })}
      </ImageList>

      {/* <ImageList variant="masonry" cols={4} gap={5}>
        {userData?.video?.map((data, ind) => {
          return (
            <Card
              component="li"
              sx={{
                minWidth: 230,
                maxWidth: 300,
                flexGrow: 1,
                position: "relative",
                border: "1px solid grey",
              }}
            >
              <CardCover>
                <video
                  autoPlay
                  loop
                  muted
                  poster="https://assets.codepen.io/6093409/river.jpg"
                >
                  <source src={data.videoUrl} type="video/mp4" />
                </video>
              </CardCover>

              <DeleteForeverIcon
                sx={removeBtn}
                onClick={() => onRemoveVideo(data?.videoId)}
              />

              <CardContent>
                <Typography
                  level="h6"
                  fontWeight="lg"
                  textColor="#fff"
                  mt={{ xs: 12, sm: 18 }}
                  sx={{
                    background: "rgba(1, 1,1, 0.5)",
                    p: 1.5,
                    color: "white",
                  }}
                >
                  {data.videoTitle}
                </Typography>
              </CardContent>
            </Card>
          );
        })}
      </ImageList> */}

      {/* <OurPhotosAdd
        setOpenAddPhotos={setOpenAddPhotos}
        openAddPhotos={openAddPhotos}
      /> */}

      <MyVideoAdd
        setOpenAddVideo={setOpenAddVideo}
        openAddVideo={openAddVideo}
        baseURL={baseURL}
        tokenApi={tokenApi}
        Loader={Loader}
        setLoader={setLoader}
      />
    </Box>
  );
}

const tableHeading = {
  fontSize: "20px",
  fontFamily: "Kumbh Sans",
  fontWeight: "bold",
  padding: "15px",
};

const loginBtn = {
  gap: "10px",
  color: "#ffffff",
  background: "#D1AB42",
  textTransform: "capitalize",
  fontFamily: "Kumbh Sans",
  border: "1px solid #D1AB42",
  fontSize: "10px",
  "&:hover": {
    border: "1px solid #3F292B",
    color: "#3F292B",
  },
};

const removeBtn = {
  position: "absolute",
  top: "10px",
  right: "10px",
  color: "#ffffff",
  background: "#D1AB42",
  textTransform: "capitalize",
  fontFamily: "Kumbh Sans",
  border: "1px solid #D1AB42",
  borderRadius: "50%",
  fontSize: "10px",
  height: "24px",
  width: "24px",
  "&:hover": {
    border: "1px solid #3F292B",
    color: "#3F292B",
  },
};
