import React from "react";
import Button from "@mui/material/Button";
import { Box, IconButton, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";

import { closeIcon } from "../navbar/closeIcon";
import axios from "axios";
const baseURL = process.env.REACT_APP_BASEURL;

const DeletePopUp = ({ openDelete, setOpenDelete, particalarId, tokenApi }) => {
  const onComplete = (id) => {
    if (tokenApi) {
      console.log("tokennnn", tokenApi);
    }

    const config = {
      headers: { Authorization: `Bearer ${tokenApi}` },
    };

    axios
      .put(
        `${baseURL}/api/v1/church/completeEvent/${id}`,
        {
          status: "Completed",
        },
        config
      )
      .then((response) => {
        if (response.data.success) {
          setOpenDelete(false);
        }
      });
  };

  return (
    <>
      <Dialog open={openDelete}>
        <Box align="center" sx={{ padding: "14px" }}>
          <IconButton
            sx={{
              position: "absolute",
              top: "4px",
              right: "4px",
            }}
            onClick={() => setOpenDelete(false)}
          >
            {closeIcon()}
          </IconButton>

          <Typography
            sx={{
              marginTop: "10px",
              fontFamily: "Kumbh Sans",
              fontSize: "20px",
              fontWeight: "bold",
            }}
          >
            Are you sure, <br />
            You want to complete & delete event ?
          </Typography>
        </Box>

        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
            margin: "0 10px 10px",
          }}
        >
          <Button
            onClick={() => onComplete(particalarId)}
            variant="contained"
            sx={button1}
            className="button"
          >
            Completed
          </Button>
          <Button
            onClick={() => setOpenDelete(false)}
            variant="contained"
            sx={button2}
            className="button"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DeletePopUp;

const button1 = {
  backgroundColor: "#D1AB42",
  fonFamily: "Kumbh Sans",
  fontWeight: "bold",
  fontSize: "10px",
  padding: "10px 20px",
  "&:hover": {
    backgroundColor: "#D1AB42",
    color: "white",
  },
};

const button2 = {
  backgroundColor: "darkred",
  fonFamily: "Kumbh Sans",
  fontWeight: "bold",
  fontSize: "10px",
  padding: "10px 30px",

  ":hover": {
    backgroundColor: "#3F292B",
    color: "white",
  },
};
