import React from "react";
import "./navbar.css";

import HomePageCross from "../../assets/images/homePageCross.png";

import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { userAuth, logout } from "../../redux/userAuthSlice";
import { paymentToken } from "../../redux/userAuthSlice";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Divider, IconButton, Typography } from "@mui/material";
import Link from "@mui/material/Link";
import { closeIcon } from "./closeIcon";
// ** router
// import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";

// import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const baseURL = process.env.REACT_APP_BASEURL;

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

// const baseURL = process.env.BASEURL // || process.env.REACT_APP_BASEURL
// console.log({baseURL})

const Loginform = ({ setOpen, open, userData }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [usersData, setUsersData] = React.useState();
  const [showForgot, setShowForgot] = React.useState(false);
  const [ForgotPassMail, setForgotPassMail] = React.useState("");
  const [sentForgot, setSentForgot] = React.useState(false);

  // *** Toast Message
  const [openToast, setOpenToast] = React.useState({ status: false, msg: "" });
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenToast({ status: false, msg: "" });
  };

  // const userDataFromRedux = useSelector((state) => state.userAuth.logoutStatus);

  const validationSchema = yup.object({
    email: yup
      .string("Enter your email")
      .email("Enter a valid email")
      .required("Email is required."),
    password: yup
      .string("Enter your password")
      .min(8, "Password should be of minimum 8 characters")
      .required("Password is required."),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      const { email, password } = values;
      const payData = {
        email,
        password,
      };
      userlogin(payData);
      resetForm({ values: "" });
    },
  });

  const userlogin = async (payData) => {
    await axios
      .post(`${baseURL}/api/v1/auth/signin`, payData)
      .then((response) => {
        console.log(response, "wewewewewewewewe");

        if (response?.status === 298) {
          dispatch(paymentToken(response?.data));
          navigate("/paymentDetails");
        }

        if (response.data.success) {
          dispatch(logout(false));

          localStorage.setItem(
            "loginLocalInfo",
            JSON.stringify(response.data.data)
          );

          dispatch(paymentToken(response?.data));

          setOpen(false);

          if (response?.data?.data?.role === "User") {
            navigate("/userProfile");
            dispatch(userAuth(response.data.data));
            setUsersData(response.data.data);
            dispatch(paymentToken(response?.data));
          }
          if (
            response?.data?.data?.role === "Church" ||
            "Ministry" ||
            "Artist"
          ) {
            navigate("/churchProfile");
            dispatch(userAuth(response.data.data));
            setUsersData(response.data.data);
            dispatch(paymentToken(response?.data));
          }

          if (response?.data?.data?.role === "Admin") {
            navigate("/superAdminProfile");
            dispatch(userAuth(response.data.data));
            setUsersData(response.data.data);
            dispatch(paymentToken(response?.data));
          }

          // if (response?.data?.data?.role === "Artist") {
          //   navigate("/artistProfile");
          //   dispatch(userAuth(response.data.data));
          //   setUsersData(response.data.data);
          //   dispatch(paymentToken(response?.data));
          // }

          // if (response?.data?.data?.role === "Ministry") {
          //   navigate("/ministryProfile");
          //   dispatch(userAuth(response.data.data));
          //   setUsersData(response.data.data);
          //   dispatch(paymentToken(response?.data));
          // }
        }
      })
      .catch((error) => {
        console.log(error.response.status, "erererererere");
        setOpenToast({ status: true, msg: error.response.data.message });
      });
  };

  const sendResetPassMail = () => {
    axios
      .post(`${baseURL}/api/v1/auth/forgotPassword`, {
        email: ForgotPassMail,
      })
      .then((response) => {
        if (response.data.success) {
          setShowForgot(false);
          setSentForgot(true);
        }
      })
      .catch((error) => {
        setOpenToast({ status: true, msg: error.response.data.message });
      });
  };

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <Box sx={{ display: "flex" }}>
          <Box sx={{ display: { xs: "none", sm: "block" } }}>
            <img src={HomePageCross} alt="rightImage" width="300px" />
          </Box>
          <Box>
            <DialogTitle
              align="center"
              sx={{
                color: "#D1AB42",
                fontFamily: "Kumbh Sans",
                fontWeight: "bold",
              }}
            >
              Faith Lens Login
              <IconButton
                sx={{
                  position: "absolute",
                  top: "15px",
                  right: "4px",
                }}
                onClick={() => setOpen(false)}
              >
                {closeIcon()}
              </IconButton>
            </DialogTitle>
            <Divider />
            <DialogContent>
              <form onSubmit={formik.handleSubmit}>
                <TextField
                  id="email"
                  name="email"
                  variant="outlined"
                  placeholder="Email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                  sx={{ marginTop: "8px" }}
                />
                <br />
                <TextField
                  autoFocus
                  id="password"
                  type="password"
                  name="password"
                  sx={{ marginY: "20px" }}
                  variant="outlined"
                  placeholder="Password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.password && Boolean(formik.errors.password)
                  }
                  helperText={formik.touched.password && formik.errors.password}
                />
                <br />

                <Button type="submit" fullWidth sx={btn}>
                  Login
                </Button>
              </form>

              <Box sx={{ display: "flex", justifyContent: "end" }}>
                <Link
                  underline="none"
                  onClick={() => {
                    setShowForgot(true);
                    setOpen(false);
                  }}
                  sx={link}
                >
                  forgot password?
                </Link>
              </Box>
            </DialogContent>
          </Box>
        </Box>
      </Dialog>

      {/* // ** forgot ** // */}

      <Dialog open={showForgot} onClose={() => setShowForgot(false)}>
        <Box sx={{ display: "flex" }}>
          <Box sx={{ display: { xs: "none", sm: "block" } }}>
            <img src={HomePageCross} alt="rightImage" width="300px" />
          </Box>
          <Box>
            <DialogTitle
              align="center"
              sx={{
                color: "#D1AB42",
                fontFamily: "Kumbh Sans",
                fontWeight: "bold",
              }}
            >
              <IconButton
                sx={{
                  position: "absolute",
                  top: "15px",
                  right: "4px",
                }}
                onClick={() => setShowForgot(false)}
              >
                {closeIcon()}
              </IconButton>
              Forgot Password
            </DialogTitle>
            <Divider />
            <DialogContent>
              <TextField
                id="email"
                name="email"
                variant="outlined"
                placeholder="Email"
                type="email"
                value={ForgotPassMail}
                onChange={(e) => setForgotPassMail(e.target.value)}
                // error={formik.touched.email && Boolean(formik.errors.email)}
                // helperText={formik.touched.email && formik.errors.email}
                sx={{ marginTop: "8px" }}
              />

              <br />
              <br />

              <Button onClick={sendResetPassMail} fullWidth sx={btn}>
                Send & Check Mail
              </Button>

              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Link
                  underline="none"
                  onClick={() => {
                    setOpen(true);
                    setShowForgot(false);
                  }}
                  sx={link}
                >
                  Back to Login
                </Link>
              </Box>
            </DialogContent>
          </Box>
        </Box>
      </Dialog>

      {/* // ** success Forgot ** // */}
      <Dialog open={sentForgot} onClose={() => setSentForgot(false)}>
        <Box sx={{ display: "flex" }}>
          <Box sx={{ display: { xs: "none", sm: "block" } }}>
            <img src={HomePageCross} alt="rightImage" width="300px" />
          </Box>
          <Box>
            <DialogTitle
              align="center"
              sx={{
                color: "#9f6840",
                fontFamily: "Kumbh Sans",
                fontWeight: "bold",
                mt: 10,
                fontSize: "18px",
              }}
            >
              <IconButton
                sx={{
                  position: "absolute",
                  top: "15px",
                  right: "4px",
                }}
                onClick={() => setSentForgot(false)}
              >
                {closeIcon()}
              </IconButton>
              <Typography
                sx={{
                  color: "#80c132",
                  fontFamily: "Kumbh Sans",
                  fontWeight: "bold",
                  fontSize: "22px",
                }}
              >
                Link Sent.
              </Typography>
              <br />
              Check your mail & set new password by clicking on link,
            </DialogTitle>
          </Box>
        </Box>
      </Dialog>

      <Snackbar
        open={openToast.status}
        autoHideDuration={4000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {openToast.msg}
        </Alert>
      </Snackbar>
    </>
  );
};

export default Loginform;

const btn = {
  color: "#ffffff",
  background: "#3F292B",
  border: "1px solid #3F292B",
  textTransform: "capitalize",
  fontFamily: "Kumbh Sans",
  marginBottom: "8px",

  "&:hover": {
    border: "1px solid #3F292B",
    color: "#3F292B",
  },
};

const link = {
  color: "#240C00",
  fontFamily: "Kumbh Sans",
  fontSize: "12px",
  cursor: "pointer",
};
