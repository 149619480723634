import React from "react";
import InsideNavbar from "../navbar/InsideNavbar";
import Footer from "../footer";
import BlogHead from "./BlogHead";
import LatestBlog from "./LatestBlog";
import Box from "@mui/material/Box";
import TrendingPodcasts from "./TrendingPodcasts";

const Index = () => {
  return (
    <>
      <InsideNavbar />
      <Box className="mobile-responsive-body">
        <BlogHead />
        <LatestBlog />
        <TrendingPodcasts />
      </Box>
      <Footer />
    </>
  );
};

export default Index;
