import React from "react";
import UserPic from "../../assets/images/userPhoto.png";
import UserPicCard from "../../assets/images/profilePicCard1.png";
import "./userProfile.css";
import { religionItem } from "../../constant/ReligionItems";

// ** MUI
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";

// import Grid from "@mui/material/Grid";
// import Drawer from "@mui/material/Drawer";
// import IconButton from "@mui/material/IconButton";
// import List from "@mui/material/List";
// import ListItem from "@mui/material/ListItem";
// import ListItemButton from "@mui/material/ListItemButton";
// import ListItemText from "@mui/material/ListItemText";
// import MenuIcon from "@mui/icons-material/Menu";
// import Toolbar from "@mui/material/Toolbar";

// ** Action Button

import MenuItem from "@mui/material/MenuItem";
// import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
// import DeleteSweepOutlinedIcon from "@mui/icons-material/DeleteSweepOutlined";
// import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
// import OpenInNewOutlinedIcon from "@mui/icons-material/OpenInNewOutlined";
import { StyledMenu } from "../SuperAdmin/ActionButtonStyle";
import axios from "axios";
const baseURL = process.env.REACT_APP_BASEURL;

const UserPicFile = ({ tokenApi, countData, userData }) => {
  const [Religion, setReligion] = React.useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);

  const [status, setStatus] = React.useState(false);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  React.useEffect(() => {
    const getloginLocalInfo = JSON.parse(
      localStorage.getItem("loginLocalInfo")
    );

    if (getloginLocalInfo.token) {
      const config = {
        headers: { Authorization: `Bearer ${getloginLocalInfo.token}` },
      };
      axios
        .get(`${baseURL}/api/v1/user`, config)
        .then((res) => {
          if (res.data?.success) {
            setReligion(res?.data?.data?.religion);
            setStatus(false);
          }
        })
        .catch((err) => {
          // alert("hit api user");
        });
    } else {
      setReligion("Religion");
    }
  }, [status]);

  const onSubmitClick = (rrr) => {
    if (rrr) {
      const config = {
        headers: { Authorization: `Bearer ${tokenApi}` },
      };

      const body = {
        religion: rrr,
      };

      axios
        .put(`${baseURL}/api/v1/user`, body, config)
        .then((res) => {
          if (res.data.success) {
            setStatus(true);
          }
        })
        .catch((err) => {
          // setOpenToastFailed(true);
        });
    }
  };

  return (
    <Box align="center" height="auto">
      <Box>
        <img
          src={userData?.imageUrl ? userData?.imageUrl : UserPic}
          alt="img"
          width="140"
          className="imageStyle"
        />

        <Typography
          sx={{
            fontSize: "20px",
            fontFamily: "Kumbh Sans",
            fontWeight: "bold",
          }}
        >
          {userData?.fullName}
        </Typography>
        <Typography sx={labelText}>{userData?.email}</Typography>
      </Box>

      <Box sx={{ display: "flex", margin: "25px" }} align="center">
        <Box>
          <Typography sx={countStyle}>{countData?.event}</Typography>
          <Typography sx={labelText}>Events attended</Typography>
        </Box>
        <Divider
          orientation="vertical"
          variant="middle"
          flexItem
          sx={{ marginX: "30px" }}
        />
        <Box>
          <Typography sx={countStyle}>{countData?.inquiry}</Typography>
          <Typography sx={labelText}>Total inquiries</Typography>
        </Box>
      </Box>

      <Box sx={{ maxWidth: "300px", position: "relative" }} align="left">
        <img src={UserPicCard} alt="img" className="cardDiv" />
        <Box
          sx={{
            position: "absolute",
            top: "15px",
            left: "15px",
          }}
        >
          <Typography sx={labelTextWhite}>My Religion</Typography>
          <Typography sx={countStyleWhite}>{Religion}</Typography>
          <Box>
            <Button
              id="demo-customized-button"
              aria-controls={open ? "demo-customized-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              disableElevation
              variant="outlined"
              sx={loginBtn}
              onClick={handleClick}
            >
              Edit&nbsp;religion
            </Button>

            <StyledMenu anchorEl={anchorEl} open={open} onClose={handleClose}>
              {religionItem?.map((data) => {
                return (
                  <MenuItem
                    onClick={() => {
                      // setReligion(data.label);
                      handleClose();
                      onSubmitClick(data.label);
                    }}
                    disableRipple
                  >
                    {data.label}
                  </MenuItem>
                );
              })}
            </StyledMenu>
          </Box>
        </Box>
      </Box>

      <Box
        align="left"
        sx={{
          background: "white",
          maxWidth: "300px",
          borderRadius: "10px",
          marginY: "20px",
          padding: "20px",
          display: "flex",
          justifyContent: "space-between",
          alignItem: "center",
          alignContent: "center",
          overflow: "auto",
        }}
      >
        <Box>
          <Typography sx={labelText}>Selected language</Typography>
          <Typography sx={countStyle}>English, US</Typography>
        </Box>
        <Box>
          <Button sx={loginBtn}>Edit&nbsp;language</Button>
        </Box>
      </Box>
    </Box>
  );
};

export default UserPicFile;

const countStyle = {
  fontSize: "24px",
  fontFamily: "Kumbh Sans",
  fontWeight: "bold",
};

const labelText = {
  fontSize: "14px",
  fontFamily: "Kumbh Sans",
  opacity: 0.5,
};

const countStyleWhite = {
  color: "#ffffff",
  fontSize: "24px",
  fontFamily: "Kumbh Sans",
  fontWeight: "bold",
};

const labelTextWhite = {
  color: "#ffffff",
  fontSize: "12px",
  fontFamily: "Kumbh Sans",
  opacity: 0.5,
};

const loginBtn = {
  gap: "10px",
  color: "#ffffff",
  background: "#D1AB42",
  textTransform: "capitalize",
  fontFamily: "Kumbh Sans",
  border: "1px solid #D1AB42",
  fontSize: "10px",
  marginTop: "10px",

  "&:hover": {
    border: "1px solid #D1AB42",
    color: "#D1AB42",
  },
};
