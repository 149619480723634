import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import OurEventsDelete from "./OurEventsDelete";
import { Country, State, City } from "country-state-city";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

import LoadingButton from "@mui/lab/LoadingButton";
import SendIcon from "@mui/icons-material/Send";
import CircularProgress from "@mui/material/CircularProgress";
import { Link, MenuItem, Select, TextField } from "@mui/material";
import axios from "axios";
import { Box } from "@mui/system";
import AddEventGoogleMap from "./AddEventGoogleMap";
import { useSelector } from "react-redux";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";

const baseURL = process.env.REACT_APP_BASEURL;

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function CustomizedDialogs({
  openEdit,
  setOpenEdit,
  particalarId,
  tokenApi,
  openDelete,
  setOpenDelete,
  setTrig,
  trig,
  WholeData,
  userRole,
}) {
  let cordinates = useSelector((state) => state.userAuth.latlng);
  console.log({ cordinates });

  // const [openDelete, setOpenDelete] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [loaderOn1, setLoaderOn1] = React.useState(false);
  const [sameAddress, setNewAddress] = React.useState(false);

  const [loaderOn2, setLoaderOn2] = React.useState(false);
  const [openGeoMap, setOpenGeoMap] = React.useState(false);

  const [editData, setEditData] = React.useState([]);

  const [speakerImage, setSpeakerImage] = React.useState();
  const [eventImage, setEventImage] = React.useState();

  // const [trig, setTrig] = React.useState(false);

  const [SpeakerRun, setSpeakerRun] = React.useState(false);
  const [EventRun, setEventRun] = React.useState(false);

  // *** formdata
  const [name, setName] = React.useState();
  const [desc, setDesc] = React.useState();

  const [fromDate, setFromDate] = React.useState();
  const [toDate, setToDate] = React.useState();

  const [theme, setTheme] = React.useState();
  const [ageGroup, setAgeGroup] = React.useState();
  const [parking, setParking] = React.useState();

  const [category, setCategory] = React.useState();
  const [religion, setReligion] = React.useState();
  const [eventType, setEventType] = React.useState();

  const [PaymentLink, setPaymentLink] = React.useState("");

  const [contact, setContact] = React.useState();
  const [email, setEmail] = React.useState();

  const [speaker, setSpeaker] = React.useState();
  const [speakerDesc, setSpeakerDesc] = React.useState();

  const [address, setAddress] = React.useState({
    street: "",
    area: "",
    zipcode: "",

    country: "",
    state: "",
    city: "",

    countryCode: "",
    stateCode: "",

    lat: "",
    lng: "",
  });

  // ** Address dropdown values
  const [countryList, setCountryList] = React.useState([]);
  const [countryCode, setCountryCode] = React.useState("");
  // const [stateList, setStateList] = React.useState([]);
  const [stateCode, setStateCode] = React.useState("");

  const [GetDataFromLocal, setGetDataFromLocal] = React.useState();

  React.useEffect(() => {
    setCountryList(Country?.getAllCountries());
    // setStateList(State?.getStatesOfCountry(countryCode));
    // setCityList(City?.getCitiesOfState(countryCode, stateCode));
  }, [countryCode, countryList, stateCode, address]);

  const [tabVisibility, setTabVisibility] = React.useState(true);

  React.useEffect(() => {
    function handleVisibilityChange() {
      setTabVisibility(document.visibilityState === "visible");
    }
    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  React.useEffect(() => {
    setGetDataFromLocal(JSON.parse(localStorage.getItem("mapData")));
  }, [tabVisibility]);

  const getMapData = JSON.parse(localStorage.getItem("mapData"));
  console.log({ getMapDataEdit: getMapData });

  React.useEffect(() => {
    const config = {
      headers: { Authorization: `Bearer ${tokenApi}` },
    };
    axios
      .get(`${baseURL}/api/v1/church/getChurchEvent/${particalarId}`, config)
      .then((response) => {
        if (response.data.success) {
          setEditData(response.data.data[0]);
          setTrig(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [openEdit, trig]);

  const onEditSubmit = () => {
    setLoading(true);
    const config = {
      headers: { Authorization: `Bearer ${tokenApi}` },
    };

    const paydata = {
      name: name,
      desc: desc,

      fromDate: fromDate,
      toDate: toDate,

      contact: contact,
      email: email,
      ageGroup: ageGroup,
      parking: parking,

      category: category,
      religion: religion,
      eventType: eventType,
      paymentLink: PaymentLink,

      newAddress: sameAddress,

      "speakerDetails.name": speaker,
      "speakerDetails.description": speakerDesc,

      // {
      //   name: speaker,
      //   description: speakerDesc,
      // },

      address: {
        location: {
          type: "Point",
          coordinates: [address?.lng, address?.lat],
        },

        street: address.street,
        area: address.area,
        zipCode: address.zipcode,

        city: address.city,
        state: address.state,
        country: address.country,

        // countryCode: address?.countryCode,
        // stateCode: address?.stateCode,
      },
    };

    // if (address?.location?.length === 2) {
    //   paydata.address.location = {
    //     type: "Point",
    //     coordinates: [address?.lng, address?.lat],
    //   };
    // }


    axios
      .put(`${baseURL}/api/v1/event/${particalarId}`, paydata, config)
      .then((res) => {
        if (res.data.success) {
          setLoading(false);
          setOpenEdit(false);
          setTrig(true);
          cordinates = [];
          localStorage.removeItem("mapData");
          setGetDataFromLocal(null);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  React.useEffect(() => {
    console.log({ editdatafromevent: editData });
    if (editData) {
      // console.log({ editData });
      setName(editData.name);
      setDesc(editData.desc);

      // setFromDate("2017-05-24T10:30");
      setFromDate(editData.fromDate?.replace(".000Z", ""));
      setToDate(editData.toDate?.replace(".000Z", ""));

      setTheme(editData.eventTheme);
      setAgeGroup(editData.ageGroup);
      setParking(editData?.parking);

      setCategory(editData?.category);
      setReligion(editData?.religion);
      setEventType(editData?.eventType);
      setPaymentLink(editData?.paymentLink);

      setContact(editData.contact);
      setEmail(editData.email);

      setNewAddress(editData?.newAddress);

      setSpeaker(editData?.speakerDetails?.name);
      setSpeakerDesc(editData?.speakerDetails?.description);

      setSpeakerImage(editData?.speakerDetails?.imageUrl);

      setCountryCode(editData?.address?.countryCode);
      setStateCode(editData?.address?.stateCode);

      setAddress({
        street: editData.address?.street,
        area: editData.address?.area,

        zipcode: GetDataFromLocal?.zipcode
          ? GetDataFromLocal?.zipcode
          : editData.address?.zipCode,

        country: GetDataFromLocal?.country
          ? GetDataFromLocal?.country
          : editData?.address?.country,

        state: GetDataFromLocal?.state
          ? GetDataFromLocal?.state
          : editData?.address?.state,
        city: GetDataFromLocal?.city
          ? GetDataFromLocal?.city
          : editData?.address?.city,

        lat: GetDataFromLocal?.lat
          ? GetDataFromLocal?.lat
          : editData.address?.location?.coordinates[1],

        lng: GetDataFromLocal?.lng
          ? GetDataFromLocal?.lng
          : editData.address?.location?.coordinates[0],

        // countryCode: editData.address?.countryCode,
        // stateCode: editData.address?.stateCode,
      });
    }
  }, [editData, GetDataFromLocal]);

  // ****** Speaker Image
  React.useEffect(() => {
    if (SpeakerRun) {
      setLoaderOn2(true);

      const config = {
        headers: {
          Authorization: `Bearer ${tokenApi}`,
        },
      };

      const data = new FormData();
      data.append("speakerImage", speakerImage);

      axios
        .put(
          `${baseURL}/api/v1/event/updateSpeakerImage/${particalarId}`,
          data,
          config
        )
        .then((response) => {
          if (response.data.success) {
            setLoaderOn2(false);
            setTrig(true);
            setLoaderOn2(false);
            localStorage.removeItem("mapData");
            setGetDataFromLocal(null);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [SpeakerRun]);

  // ****** Event Image
  React.useEffect(() => {
    if (EventRun) {
      setLoaderOn1(true);
      const config = {
        headers: {
          Authorization: `Bearer ${tokenApi}`,
        },
      };

      const data = new FormData();
      data.append("eventImage", eventImage);

      axios
        .put(
          `${baseURL}/api/v1/event/updateEventImage/${particalarId}`,
          data,
          config
        )
        .then((response) => {
          if (response.data.success) {
            setEventRun(false);
            setTrig(true);
            setLoaderOn1(false);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [EventRun]);

  const loader = () => {
    return <CircularProgress sx={{ color: "#D1AB42" }} />;
  };

  return (
    <BootstrapDialog
      onClose={() => setOpenEdit(false)}
      aria-labelledby="customized-dialog-title"
      open={openEdit}
      sx={{ width: "100%" }}
    >
      <BootstrapDialogTitle
        id="customized-dialog-title"
        onClose={() => setOpenEdit(false)}
        sx={tableHeading}
      >
        Event Details{" "}
      </BootstrapDialogTitle>

      <DialogContent dividers>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs={6} sm={6}>
            <Box>
              {loaderOn1 ? (
                loader()
              ) : (
                <img
                  src={editData?.imageUrl}
                  height="140px"
                  width="200px"
                  alt="Event"
                  style={{ borderRadius: "10px" }}
                />
              )}
            </Box>
          </Grid>
          <Grid item xs={6} sm={6}>
            <Box>
              {loaderOn2 ? (
                loader()
              ) : (
                <img
                  src={editData?.speakerDetails?.imageUrl}
                  height="140px"
                  width="200px"
                  alt="Speaker"
                  style={{ borderRadius: "10px" }}
                />
              )}
            </Box>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Typography sx={labelText}>Event Image</Typography>
            <TextField
              id="imageUpload"
              type="file"
              name="imageUpload"
              sx={{ width: "100%" }}
              variant="outlined"
              onChange={(e) => {
                setEventImage(e.target.files[0]);
                setEventRun(true);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography sx={labelText}>
              {" "}
              {userRole === "Artist" ? "Performer Image" : "Speaker Image"}
            </Typography>
            <TextField
              id="speakerImage"
              type="file"
              name="speakerImage"
              sx={{ width: "100%" }}
              variant="outlined"
              onChange={(e) => {
                setSpeakerImage(e.target.files[0]);
                setSpeakerRun(true);
              }}
            />
          </Grid>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Typography sx={labelText}>Event Name</Typography>
          <TextField
            id="titleName"
            name="titleName"
            sx={{ width: "100%" }}
            variant="outlined"
            placeholder="Type here"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </Grid>

        {userRole !== "Church" && (
          <Grid item xs={12} sm={12}>
            <Box sx={{ display: "flex" }}>
              <Typography sx={labelText}>Payment Link &nbsp; </Typography>
              <Typography sx={labelTextColor}>(Optional) </Typography>
            </Box>

            <TextField
              id="paymentlink"
              type="url"
              name="paymentlink"
              sx={{ width: "100%" }}
              variant="outlined"
              placeholder="Example : https://faithlens.org/Payment-Link"
              value={PaymentLink}
              onChange={(e) => setPaymentLink(e.target.value)}
            />
          </Grid>
        )}

        <Grid item xs={12} sm={6}>
          <Typography sx={labelText}>Event Description</Typography>
          <TextField
            id="description"
            multiline
            name="description"
            sx={{ width: "100%" }}
            variant="outlined"
            placeholder="Type here"
            value={desc}
            onChange={(e) => setDesc(e.target.value)}
          />
        </Grid>

        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs={12} sm={6}>
            <Typography sx={labelText}>Start Time</Typography>
            <TextField
              id="datetime-local"
              type="datetime-local"
              name="fromDate"
              sx={{ width: "100%" }}
              variant="outlined"
              placeholder="Type here"
              value={fromDate}
              defaultValue={fromDate}
              onChange={(e) => setFromDate(e.target.value)}
              closeOnSelect
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Typography sx={labelText}>End Time</Typography>

            <TextField
              id="datetime-local"
              type="datetime-local"
              name="toDate"
              sx={{ width: "100%" }}
              variant="outlined"
              placeholder="Type here"
              value={toDate}
              defaultValue={toDate}
              onChange={(e) => setToDate(e.target.value)}
            />
          </Grid>
        </Grid>

        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs={12} sm={userRole !== "Artist" ? 4 : 6}>
            <Typography sx={labelText}>Event Type</Typography>
            <Select
              id="type"
              name="type"
              sx={{
                width: "100%",
              }}
              variant="outlined"
              value={eventType ? eventType : "Even Type"}
              onChange={(e) => setEventType(e.target.value)}
            >
              {eventTypeItem?.map((ele) => (
                <MenuItem sx={labelTextMenuItem} value={ele.label}>
                  {ele.label}
                </MenuItem>
              ))}
            </Select>
          </Grid>

          {userRole !== "Ministry" && (
            <Grid item xs={12} sm={userRole === "Church" ? 4 : 6}>
              <Typography sx={labelText}>Category</Typography>
              <Select
                id="theme"
                name="theme"
                sx={{
                  width: "100%",
                }}
                variant="outlined"
                value={category ? category : "Category"}
                onChange={(e) => setCategory(e.target.value)}
              >
                {categoryList?.map((ele) => (
                  <MenuItem sx={labelTextMenuItem} value={ele.value}>
                    {ele.value}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          )}

          {userRole === "Church" && (
            <Grid item xs={12} sm={4}>
              <Typography sx={labelText}>Religion</Typography>
              <Select
                id="theme"
                name="theme"
                sx={{
                  width: "100%",
                }}
                variant="outlined"
                value={religion ? religion : "religion"}
                onChange={(e) => setReligion(e.target.value)}
              >
                {religionItem?.map((ele) => (
                  <MenuItem sx={labelTextMenuItem} value={ele.value}>
                    {ele.value}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          )}
          {userRole === "Church" && (
            <Grid item xs={12} sm={4}>
              <Typography sx={labelText}>Event Theme</Typography>
              <TextField
                id="theme"
                name="theme"
                sx={{
                  width: "100%",
                }}
                variant="outlined"
                value={theme}
                onChange={(e) => setTheme(e.target.value)}
              >
                {/* <MenuItem value={"Formal"}>Formal</MenuItem>
              <MenuItem value={"Formal2"}>Formal 2</MenuItem>
              <MenuItem value={"Formal3"}>Formal 3</MenuItem>
              <MenuItem value={"Formal4"}>Formal 4</MenuItem> */}
              </TextField>
            </Grid>
          )}

          {userRole !== "Artist" && (
            <Grid item xs={12} sm={4}>
              <Typography sx={labelText}>Age Group</Typography>
              <Select
                id="ageGroup"
                name="ageGroup"
                sx={{
                  width: "100%",
                }}
                variant="outlined"
                value={ageGroup ? ageGroup : "Age"}
                onChange={(e) => setAgeGroup(e.target.value)}
              ><MenuItem value={"All Ages"}>All Ages</MenuItem>

                <MenuItem value={"0-20"}>0 to 20</MenuItem>
                <MenuItem value={"21-40"}>20 to 40</MenuItem>
                <MenuItem value={"41-60"}>40 to 60</MenuItem>
                <MenuItem value={"61-80"}>60 to 80</MenuItem>
              </Select>
            </Grid>
          )}
          <Grid item xs={12} sm={userRole !== "Artist" ? 4 : 6}>
          <Box sx={{ display: "flex" }}>
            <Typography sx={labelText}>Parking Space &nbsp;</Typography>
              <Typography sx={labelTextColor}>(Optional) </Typography>
            </Box>
            <Select
              id="parking"
              name="parking"
              variant="outlined"
              sx={{
                width: "100%",
              }}
              value={parking ? parking : false}
              onChange={(e) => setParking(e.target.value)}
            >
              <MenuItem value={true}>Available</MenuItem>
              <MenuItem value={false}>NotAvailable</MenuItem>
            </Select>
          </Grid>
        </Grid>

        {/* <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs={12} sm={6}>
            <Typography sx={labelText}>Contact mobile</Typography>
            <TextField
              id="mobile"
              name="mobile"
              sx={{ width: "100%" }}
              variant="outlined"
              placeholder="Type here"
              value={contact}
              onChange={(e) => setContact(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography sx={labelText}>Contact Email</Typography>

            <TextField
              id="email"
              name="email"
              sx={{ width: "100%" }}
              variant="outlined"
              placeholder="Type here"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Grid>
        </Grid> */}

        {/* ///////////////////////// */}

        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs={12} sm={12}>
            <Typography sx={labelText}>
              {userRole === "Artist" ? "Performer Name" : "Speaker Name"}
            </Typography>
            <TextField
              id="speakerName"
              name="speakerName"
              sx={{ width: "100%" }}
              variant="outlined"
              placeholder="Type here"
              value={speaker}
              onChange={(e) => setSpeaker(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography sx={labelText}>
              {userRole === "Artist"
                ? "Performer Description"
                : "Speaker Description"}
            </Typography>

            <TextField
              id="speakerDescription"
              multiline
              name="speakerDescription"
              sx={{ width: "100%" }}
              variant="outlined"
              placeholder="Type here"
              value={speakerDesc}
              onChange={(e) => setSpeakerDesc(e.target.value)}
            />
          </Grid>
        </Grid>

        {/* ///////////////////////// */}

        {userRole === "Church" && (
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            defaultValue={sameAddress && sameAddress}
          >
            <FormControlLabel
              onClick={() => setNewAddress(false)}
              value={false}
              control={<Radio />}
              label="Same As Church Address"
            />
            <FormControlLabel
              onClick={() => setNewAddress(true)}
              value={true}
              control={<Radio />}
              label="New Address for this Event"
            />
          </RadioGroup>
        )}

        <AddEventGoogleMap
          setOpenGeoMap={setOpenGeoMap}
          openGeoMap={openGeoMap}
        />

        {userRole !== "Church" && (
          <Typography sx={labelText}>Event Address</Typography>
        )}

        {(userRole !== "Church" || sameAddress === true) && (
          <>
            {/* <Button sx={mapBtn} onClick={() => setOpenGeoMap(true)}>
              Add Map Location <MyLocationIcon />
            </Button> */}

            <Link underline="none" href="/map1" target="_blank">
              <Button sx={mapBtn}>
                Update Location <MyLocationIcon />
              </Button>
            </Link>

            {/* {address?.country && (
              <Box>
                <Typography sx={labelText}> {address?.area}</Typography>
                <Typography sx={labelText}>
                  {address?.city} {address?.zipcode}, {address?.state},{" "}
                  {address?.country}
                </Typography>
              </Box>
            )} */}

            {getMapData?.country && (
              <Box
                sx={{
                  border: "1px solid lightgrey",
                  padding: "0 10px",
                  marginBottom: "10px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    gap: "5px",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography sx={labelTextYellow}>New Address :</Typography>
                  <CancelOutlinedIcon
                    sx={labelTextClear}
                    onClick={() => {
                      localStorage.removeItem("mapData");
                      setGetDataFromLocal(null);
                    }}
                  />
                </Box>

                <Typography sx={labelTextYellow}>{getMapData?.street}</Typography>
                <Typography sx={labelTextYellow}>
                  {getMapData?.city} {getMapData?.zipcode}, {getMapData?.state},{" "}
                  {getMapData?.country}
                </Typography>
              </Box>
            )}

            {address?.country && (
              <Box
                sx={{
                  border: "1px solid lightgrey",
                  padding: "0 10px",
                  marginBottom: "10px",
                }}
              >
                <Typography sx={labelText}>{address?.area}</Typography>
                <Typography sx={labelText}>
                  {address?.city} {address?.zipcode}, {address?.state},{" "}
                  {address?.country}
                </Typography>
              </Box>
            )}

            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={2}
            >
              <Grid item xs={12} sm={12}>
                <TextField
                  id="street"
                  name="street"
                  sx={{ width: "100%" }}
                  variant="outlined"
                  placeholder="Street / Line (Optional)"
                  value={address.street}
                  onChange={(e) =>
                    setAddress({
                      ...address,
                      street: e.target.value,
                    })
                  }
                />
              </Grid>

              {/* <Grid item xs={12} sm={6}>
                <TextField
                  sx={{ width: "100%" }}
                  variant="outlined"
                  placeholder="Zipcode (Optional)"
                  value={address.zipcode}
                  onChange={(e) =>
                    setAddress({
                      ...address,
                      zipcode: e.target.value,
                    })
                  }
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <TextField
                  sx={{ width: "100%" }}
                  variant="outlined"
                  placeholder="City"
                  value={address.city}
                  onChange={(e) =>
                    setAddress({
                      ...address,
                      city: e.target.value,
                    })
                  }
                />
              </Grid> */}

              {/* <Grid item xs={12} sm={4}>
                <TextField
                  sx={{ width: "100%" }}
                  variant="outlined"
                  placeholder="State"
                  value={address.state}
                  onChange={(e) =>
                    setAddress({
                      ...address,
                      state: e.target.value,
                    })
                  }
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <TextField
                  sx={{ width: "100%" }}
                  variant="outlined"
                  placeholder="Country"
                  value={address.country}
                  onChange={(e) =>
                    setAddress({
                      ...address,
                      country: e.target.value,
                    })
                  }
                />
              </Grid> */}

              {/* <Grid item xs={12} sm={4}>
                <Select
                  id="country"
                  name="country"
                  variant="outlined"
                  placeholder="Country"
                  sx={{
                    width: "100%",
                  }}
                  value={address?.country}
                  onChange={(e) =>
                    setAddress({
                      ...address,
                      country: e.target.value,
                    })
                  }
                >
                  {countryList?.map((ele) => (
                    <MenuItem
                      value={ele.name}
                      onClick={() => setCountryCode(ele.isoCode)}
                      sx={labelTextMenu}
                    >
                      {ele.name}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>

              <Grid item xs={12} sm={4}>
                <Select
                  id="state"
                  name="state"
                  variant="outlined"
                  placeholder="State"
                  sx={{
                    width: "100%",
                  }}
                  value={address?.state ? address?.state : "fetching"}
                  onChange={(e) =>
                    setAddress({
                      ...address,
                      state: e.target.value,
                    })
                  }
                >
                  {stateList?.map((ele) => (
                    <MenuItem
                      value={ele.name}
                      onClick={() => setStateCode(ele.isoCode)}
                      sx={labelTextMenu}
                    >
                      {ele.name}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>

              <Grid item xs={12} sm={4}>
                <Select
                  id="city"
                  name="city"
                  variant="outlined"
                  placeholder="City"
                  sx={{
                    width: "100%",
                  }}
                  value={address?.city}
                  onChange={(e) =>
                    setAddress({
                      ...address,
                      city: e.target.value,
                    })
                  }
                >
                  {cityList?.map((ele) => (
                    <MenuItem value={ele.name} sx={labelTextMenu}>
                      {ele?.name}
                    </MenuItem>
                  ))}
                </Select>
              </Grid> */}
            </Grid>
          </>
        )}
      </DialogContent>

      <DialogActions sx={{ display: "flex", justifyContent: "space-between" }}>
        {editData.status === "Approved" && (
          <Button sx={registerBtn} onClick={() => setOpenDelete(true)}>
            Completed
          </Button>
        )}
        <LoadingButton
          onClick={onEditSubmit}
          sx={loading ? loginBtnLoading : registerBtn}
          endIcon={<SendIcon />}
          loading={loading}
          loadingPosition="end"
        >
          <span>{loading ? "Submiting" : "Edit & Submit"}</span>
        </LoadingButton>
      </DialogActions>

      <OurEventsDelete
        openDelete={openDelete}
        setOpenDelete={setOpenDelete}
        particalarId={particalarId}
        tokenApi={tokenApi}
      />
    </BootstrapDialog>
  );
}

const labelText = {
  fontSize: "14px",
  fontFamily: "Kumbh Sans",
  fontWeight: 500,
  marginTop: "20px",
  marginBottom: "8px",
};

const labelTextYellow = {
  fontSize: "14px",
  fontFamily: "Kumbh Sans",
  fontWeight: 500,
  marginTop: "8px",
  marginBottom: "5px",
  color: "orange",
};

const labelTextMenuItem = {
  fontSize: "14px",
  fontFamily: "Kumbh Sans",
  fontWeight: 500,
  // marginTop: "20px",
  // marginBottom: "8px",
};

const labelTextClear = {
  fontSize: "20px",
  fontFamily: "Kumbh Sans",
  fontWeight: 500,
  marginTop: "8px",
  marginBottom: "5px",
  color: "red",
  cursor: "pointer",
};

const registerBtn = {
  color: "#ffffff",
  background: "#3F292B",
  fontFamily: "Kumbh Sans",
  fontSize: "10px",
  padding: "10px 26px",
  border: "1px solid #3F292B",
  "&:hover": {
    border: "1px solid #3F292B",
    color: "#3F292B",
  },
};

const tableHeading = {
  fontSize: "18px",
  fontFamily: "Kumbh Sans",
  fontWeight: "bold",
  padding: "15px",
};
const labelTextMenu = {
  fontSize: "14px",
  fontFamily: "Kumbh Sans",
  fontWeight: 500,
};

const loginBtnLoading = {
  gap: "10px",
  color: "#ffffff",
  // background: "#D1AB42",
  textTransform: "capitalize",
  border: "1px solid grey",
  fontSize: "14px",
  fontFamily: "Kumbh Sans",
  fontWeight: 500,
  px: 2,
};

const mapBtn = {
  gap: "10px",
  color: "#ffffff",
  background: "#D1AB42",
  textTransform: "capitalize",
  border: "1px solid #D1AB42",
  fontSize: "14px",
  fontFamily: "Kumbh Sans",
  fontWeight: 500,
  px: 2,
  my: 2,
  "&:hover": {
    border: "1px solid #3F292B",
    color: "#3F292B",
  },
};

const categoryList = [
  {
    value: "Youth Service",
    label: "Youth Service",
  },
  {
    value: "Youth Prayer Night",
    label: "Youth Prayer Night",
  },
  {
    value: "Youth Social",
    label: "Youth Social",
  },
  {
    value: "Adult Prayer Night",
    label: "Adult Prayer Night",
  },
  {
    value: "Adult Church Service",
    label: "Adult Church Service",
  },
  {
    value: "Women Prayer Group",
    label: "Women Prayer Group",
  },
  {
    value: " Women bible study",
    label: " Women bible study",
  },
  {
    value: "Male Prayer Group",
    label: "Male Prayer Group",
  },
  {
    value: "Concert",
    label: "Concert",
  },
  {
    value: " Week of Prayer",
    label: " Week of Prayer",
  },
  {
    value: "Crusades",
    label: "Crusades",
  },
  {
    value: " Conference",
    label: " Conference",
  },

  {
    value: " CoED Bible Study",
    label: " CoED Bible Study",
  },
  {
    value: " CoEd Prayer Group",
    label: " CoEd Prayer Group",
  },
];

const religionItem = [
  {
    label: "Anglicanism Church",
    value: "Anglicanism Church",
  },
  {
    label: "Baptist Church",
    value: "Baptist Church",
  },
  {
    label: "Catholic Church",
    value: "Catholic Church",
  },
  {
    label: "Episcopal Church",
    value: "Episcopal Church",
  },
  {
    label: "Evangelicalism Church",
    value: "Evangelicalism Church",
  },
  {
    label: "Pentecostalism Church",
    value: "Pentecostalism Church",
  },
  {
    label: "Seventh Day Adventist Church",
    value: "Seventh Day Adventist Church",
  },
];

const eventTypeItem = [
  {
    label: "Music",
    value: "Music",
  },
  {
    label: "Church",
    value: "Church",
  },
  {
    label: "Ministry Focused",
    value: "Ministry Focused",
  },
];

const labelTextColor = {
  fontSize: "14px",
  fontFamily: "Kumbh Sans",
  fontWeight: 500,
  marginTop: "20px",
  marginBottom: "8px",
  color: "#D1AB42",
};
