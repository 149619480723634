import * as React from "react";
import "./firstpage.css";

// ** images
// import blogIcon from "../../assets/icons/blogIcon.png";
import calendarIcon from "../../assets/icons/calendarIcon.png";
// import mediaIcon from "../../assets/icons/mediaIcon.png";
// import gameIcon from "../../assets/icons/gameIcon.png";
import HomePageCross from "../../assets/images/homePageCross.png";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

// ** router
import { useNavigate } from "react-router-dom";

const FaithlensHome = () => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        height: "auto",
        width: "100%",
        background: "#FAF6F4",
        padding: "100px",
        display: "flex",

        justifyContent: "space-between",
        alignItems: "center",
        alignContent: "center",
        marginTop: "-15px",
      }}
      className="mobile-responsive-body homepage-align"
    >
      <Box
        sx={{
          marginLeft: {
            xs: "none",
            sm: "50px",
            md: "100px",
            lg: "100px",
            xl: "150px",
          },
        }}
      >
        <Box>
          <Typography style={styles.shortHead} className="shortHead-mobile">
            FAITH LENS
          </Typography>
          <Typography style={styles.bigHead} className="bigHead-mobile">
            Keep in Touch with<br></br>
            Your{" "}
            <Typography style={styles.span} className="bigHead-mobile">
              Faith
            </Typography>
          </Typography>

          <Box
            align="center"
            sx={{ display: { xs: "block", sm: "block", md: "none" } }}
          >
            <img src={HomePageCross} alt="img" className="crossPicture" />
          </Box>

          <Box style={styles.quickDiv}>
            <Box
              style={styles.quickDivLinks}
              className={"quickDivMobile"}
              onClick={() => navigate("/events")}
            >
              <img src={calendarIcon} alt="img" width={29} />
              <Typography style={styles.quickDivLinksText}>
                View Events
              </Typography>
            </Box>

            {/* <Box
            style={styles.quickDivLinks}
            className={"quickDivMobile"}
            onClick={() => navigate("/media")}
          >
            <img src={mediaIcon} alt="img" width={29} />
            <Typography style={styles.quickDivLinksText}>View Media</Typography>
          </Box> */}
          </Box>

          {/* <Box style={styles.quickDiv}>
          <Box
            style={styles.quickDivLinks}
            className={"quickDivMobile"}
            // onClick={() => navigate("/blog-podcast")}
          >
            <img src={blogIcon} alt="img" width={29} />

            <Typography style={styles.quickDivLinksText}>
              Blogs/Podcasts
            </Typography>
          </Box>
          <Box
            style={styles.quickDivLinks}
            className={"quickDivMobile"}
            // onClick={() => navigate("/games")}
          >
            <img src={gameIcon} alt="img" width={29} />
            <Typography style={styles.quickDivLinksText}>Games</Typography>
          </Box>
        </Box> */}
        </Box>
      </Box>

      <Box
        align="center"
        sx={{ display: { xs: "none", sm: "none", md: "block" } }}
      >
        <img src={HomePageCross} alt="img" className="crossPicture" />
      </Box>
    </Box>
  );
};

export default FaithlensHome;

const styles = {
  shortHead: {
    color: "#D1AB42",
    fontFamily: "Kumbh Sans",
    fontSize: "14px",
    fontWeight: "bold",
  },
  bigHead: {
    color: "#000000",
    fontFamily: "Kumbh Sans",
    fontSize: "3.5rem",
    fontWeight: "bold",
  },
  span: {
    display: "inline",
    color: "#D1AB42",
    fontFamily: "Kumbh Sans",
    fontSize: "3.5rem",
    fontWeight: "bold",
  },
  quickDiv: {
    display: "flex",
    flexWrap: "wrap",
  },
  quickDivLinks: {
    background: "#ffffff",
    padding: "30px 16px",
    width: "150px",
    height: "auto",
    margin: "10px",
    borderRadius: "8px",
    borderColor: "black",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  quickDivLinksText: {
    color: "#000000",
    fontFamily: "Kumbh Sans",
    fontSize: "14px",
    fontWeight: 600,
    marginLeft: "8px",
  },
};
