import * as React from "react";
import OurStaffAdd from "./OurStaffAdd";
import OurStafEdit from "./OurStaffEdit";

// import Table from "@mui/material/Table";
// import TableBody from "@mui/material/TableBody";
// import TableCell from "@mui/material/TableCell";
// import TableHead from "@mui/material/TableHead";
// import TableRow from "@mui/material/TableRow";
import Box from "@mui/material/Box";
import { Button, Typography, Stack } from "@mui/material";

import DummyStaffImage from "../../assets/About/staffperson.png";
// import { useSelector, useDispatch } from "react-redux";
// import axios from "axios";
// import { staffMember } from "../../redux/userAuthSlice";
// const baseURL = process.env.REACT_APP_BASEURL;

export default function OurStaff({
  tokenApi,
  StaffMemberChurch,
  openAddStaff,
  setOpenAddStaff,
  onActionOpen,
  setOnActionOpen,
}) {
  // const dispatch = useDispatch();
  const [EditId, setEditId] = React.useState("");
  const [data, setData] = React.useState({});

  return (
    <Box
      sx={{
        borderRadius: "20px",
        padding: "20px",
        background: "#ffffff",
        height: "100%",
        width: "auto",
        margin: "0 10px",
        overflow: "auto",
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
      >
        <Typography sx={tableHeading}>Our Staff</Typography>
        <Button
          variant="outlined"
          sx={loginBtnAdd}
          onClick={() => setOpenAddStaff(true)}
        >
          Add staff
        </Button>
      </Stack>

      <Box
        sx={{
          display: "flex",
          gap: "20px",
          flexWrap: "wrap",
          justifyContent: "center",
        }}
      >
        {StaffMemberChurch?.map((data) => {
          return (
            <Box
              sx={{
                background: "#FAF6F4",
                borderRadius: "10px",
                padding: "10px",
                position: "relative",
              }}
              align="center"
            >
              <img
                src={data.imageUrl ? data.imageUrl : DummyStaffImage}
                alt="staff"
                width="140px"
                height="180px"
              />
              <Typography sx={staffName}>{data.name}</Typography>
              <Typography sx={staffNameRole}>{data.staffRole}</Typography>
              <Button
                onClick={() => {
                  setEditId(data._id);
                  setData(data);
                  setOnActionOpen(true);
                }}
                sx={[
                  loginBtn,
                  { position: "absolute", top: "15px", right: "15px" },
                ]}
              >
                Edit
              </Button>
            </Box>
          );
        })}
      </Box>
      <OurStaffAdd
        openAddStaff={openAddStaff}
        setOpenAddStaff={setOpenAddStaff}
        tokenApi={tokenApi}
      />
      <OurStafEdit
        onActionOpen={onActionOpen}
        setOnActionOpen={setOnActionOpen}
        tokenApi={tokenApi}
        StaffMemberChurch={StaffMemberChurch}
        EditId={EditId}
        data={data}
      />
    </Box>
  );
}

const tableHeading = {
  fontSize: "18px",
  fontFamily: "Kumbh Sans",
  fontWeight: "bold",
  padding: "15px",
};

const loginBtn = {
  color: "#ffffff",
  // background: "#D1AB42",
  background: "transparent",

  textTransform: "capitalize",
  fontFamily: "Kumbh Sans",
  border: "1px solid #ffffff",
  fontSize: "8px",
  padding: "2px",
  px: 0,
  "&:hover": {
    border: "1px solid #3F292B",
    color: "#3F292B",
  },
};

const loginBtnAdd = {
  color: "#ffffff",
  background: "#D1AB42",
  textTransform: "capitalize",
  fontFamily: "Kumbh Sans",
  border: "1px solid #D1AB42",
  fontSize: "12px",
  "&:hover": {
    border: "1px solid #3F292B",
    color: "#3F292B",
  },
};

const staffName = {
  fontSize: "13px",
  fontFamily: "Kumbh Sans",
  fontWeight: "bold",
};

const staffNameRole = {
  fontSize: "11px",
  fontFamily: "Kumbh Sans",
};
