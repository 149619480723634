import React from "react";
import ChurchPic from "../../assets/images/churchPic.png";
import "./userProfile.css";

// ** MUI
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";

const userPicFile = ({
  userData,
  setActiveItem,
  activeItem,
  CountData,
  userRole,
}) => {
  return (
    <Box align="center">
      <Box sx={{ borderRadius: "5%" }}>
        <img
          src={userData?.user?.imageUrl ? userData.user?.imageUrl : ChurchPic}
          alt="img"
          width="140"
          className="imageStyle"
        />
      </Box>

      <Typography
        sx={{
          fontSize: "20px",
          fontFamily: "Kumbh Sans",
          fontWeight: "bold",
        }}
      >
        {userData.user?.fullName}
      </Typography>
      <Typography sx={labelText}>{userData.user?.email}</Typography>
      <Box
        sx={{
          display: "flex",
          margin: "25px",
          width: "280px",
          justifyContent: "center",
        }}
        align="center"
      >
        <Box>
          <Typography sx={countStyle}>{CountData?.event}</Typography>
          <Typography sx={labelText}>Events Created</Typography>
        </Box>
        {userRole !== "Artist" && (
          <>
            <Divider
              orientation="vertical"
              variant="middle"
              flexItem
              sx={{ marginX: "30px" }}
            />

            <Box>
              <Typography sx={countStyle}>{CountData?.inquiry}</Typography>
              <Typography sx={labelText}>Inquiries recieved</Typography>
            </Box>
          </>
        )}
      </Box>

      <Box
        sx={{
          background: "white",
          borderRadius: "10px",
          marginY: "20px",
          padding: "20px",
        }}
      >
        <Box align="start">
          <Typography sx={lineHeading}>
            {userData.user?.role === "Artist"
              ? "Artist Details"
              : userData.user?.role === "Church" ? "Church Details" : 'Ministry Details'}
          </Typography>

          <Box sx={lineText}>
            <Typography sx={lineText1}>Email</Typography>
            <Typography sx={lineText2}>{userData.user?.email}</Typography>
          </Box>
          <Box sx={lineText}>
            <Typography sx={lineText1}>Number</Typography>
            <Typography sx={lineText2}>{userData.user?.mobile}</Typography>
          </Box>
          <Box sx={lineText}>
            <Typography sx={lineText1}>Website</Typography>
            <Typography sx={lineText2}>{userData?.website}</Typography>
          </Box>
          {userRole !== "Artist" && (
            <>
              {" "}
              <Typography sx={lineHeading}>Business hours</Typography>
              <Box sx={lineText}>
                <Typography sx={lineText1}>Monday - Friday</Typography>
                <Typography sx={lineText2}>
                  {userData?.workingDays?.from}am - {userData?.workingDays?.to}
                  pm
                </Typography>
              </Box>
              <Box sx={lineText}>
                <Typography sx={lineText1}>Saturday - Sunday</Typography>
                <Typography sx={lineText2}>
                  {userData?.weekendDays?.open ? "Open" : "Close"}
                </Typography>
              </Box>
            </>
          )}

          <Button sx={loginBtn} onClick={() => setActiveItem("Settings")}>
            Edit information
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default userPicFile;

const lineHeading = {
  fontSize: "14px",
  fontFamily: "Kumbh Sans",
  fontWeight: "bold",
  opacity: 0.5,
};

const lineText = {
  display: "flex",
  justifyContent: "space-between",
  my: 1,
};

const lineText1 = {
  fontSize: "13px",
  fontFamily: "Kumbh Sans",
  opacity: 0.5,
};

const lineText2 = {
  fontSize: "13px",
  fontFamily: "Kumbh Sans",
  fontWeight: "bold",
};

const countStyle = {
  fontSize: "24px",
  fontFamily: "Kumbh Sans",
  fontWeight: "bold",
};

const labelText = {
  fontSize: "14px",
  fontFamily: "Kumbh Sans",
  opacity: 0.5,
};

const loginBtn = {
  color: "#ffffff",
  background: "#D1AB42",
  textTransform: "capitalize",
  fontFamily: "Kumbh Sans",
  border: "1px solid #D1AB42",
  fontSize: "11px",
  "&:hover": {
    border: "1px solid #3F292B",
    color: "#3F292B",
  },
};
