/* eslint-disable no-undef */
import React, { useState, useEffect } from "react";
import {
  GoogleMap,
  Autocomplete,
  Marker,
  useJsApiLoader,
} from "@react-google-maps/api";

import MyLocationIcon from "@mui/icons-material/MyLocation";
import axios from "axios";
import "./MapStyles.css";

function GoogleMapApi() {
  const [autocomplete, setAutocomplete] = useState(null);
  const [userCoords, setUserCoords] = useState(null);
  const [address, setAddress] = useState(null);

  const API_KEY = process.env.REACT_APP_GOOGLE_MAP_KEY;
  const API_ENDPOINT = `https://www.googleapis.com/geolocation/v1/geolocate?key=${API_KEY}`;

  useEffect(() => {
    getLocations();
  }, []);

  const getLocations = async () => {
    let config = {
      url: API_ENDPOINT,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({}),
    };

    let { data } = await axios(config);
    console.dir({ data }, { depth: null });
    setUserCoords(data?.location);
  };

  // const getLocations = () => {
  //   console.log("my locationsssss");
  //   navigator.geolocation.getCurrentPosition(
  //     (position) => {
  //       setUserCoords({
  //         lat: position.coords.latitude,
  //         lng: position.coords.longitude,
  //       });
  //     },
  //     (error) => {
  //       console.error(error);
  //     },
  //     {
  //       enableHighAccuracy: true,
  //       maximumAge: 1000,
  //       timeout: 5000,
  //     }
  //   );
  // };

  const handleClick = (e) => {
    setUserCoords(
      {
        lat: e.latLng.lat(),
        lng: e.latLng.lng(),
      },
      () => {
        getAddressData();
      }
    );
  };

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_KEY,
    libraries: ["places", "geometry"],
  });

  const onLoads = (autocomplete) => {
    setAutocomplete(autocomplete);
  };

  const getFullAddress = (address) => {
    let obj = {};
    address.forEach((item) => {
      if (item.types.includes("country")) {
        obj.country = item.long_name;
      }
      if (item.types.includes("administrative_area_level_1")) {
        obj.state = item.long_name;
      }
      if (item.types.includes("administrative_area_level_3")) {
        obj.city = item.long_name;
      }
      if (item.types.includes("postal_code")) {
        obj.zipcode = item.long_name;
      }
    });
    return obj;
  };

  const onPlaceChanged = () => {
    if (autocomplete !== null) {
      const place = autocomplete.getPlace();
      if (place.address_components && place.geometry) {
        let area = place.formatted_address;
        setAddress({
          ...getFullAddress(place?.address_components),
          area,
        });
        setUserCoords({
          lat: place?.geometry.location.lat(),
          lng: place?.geometry.location.lng(),
        });
      }
    } else {
      console.log("Autocomplete is not loaded yet!");
    }
  };

  useEffect(() => {
    if (userCoords && isLoaded) {
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ location: userCoords }, (results, status) => {
        if (status === "OK") {
          let area = results[0]?.formatted_address;
          setAddress({
            ...getFullAddress(results[0]?.address_components),
            area,
          });
        } else {
          console.error(
            "Geocode was not successful for the following reason:",
            status
          );
        }
      });
    }
  }, [userCoords, isLoaded]);

  console.log({ userCoords, address });

  const getAddressData = () => {
    localStorage.setItem(
      "mapData",
      JSON.stringify({ ...address, ...userCoords })
    );
    window.close();
  };

  return (
    isLoaded && (
      <>
        <button onClick={() => getLocations()} style={mylocBtn}>
          <MyLocationIcon />
        </button>

        <GoogleMap
          mapContainerStyle={containerStyle}
          zoom={17}
          center={userCoords}
          onClick={handleClick}
        >
          <Marker position={userCoords} />
          <Autocomplete onLoad={onLoads} onPlaceChanged={onPlaceChanged}>
            <input
              type="text"
              placeholder="Search Your Location"
              // style={textStyle}
              className="textStyle"
            />
          </Autocomplete>
        </GoogleMap>

        <button onClick={() => getAddressData()} className="confirmBtnStyle">
          Ok, Submit
        </button>
      </>
    )
  );
}

export default GoogleMapApi;

const containerStyle = {
  width: "98%",
  height: "97vh",
  margin: "10px",
};

// const saveBtn = {
//   boxSizing: `border-box`,
//   border: `1px solid transparent`,
//   width: `170px`,
//   background: `DodgerBlue`,
//   color: `white`,
//   height: `40px`,
//   padding: `0 12px`,
//   borderRadius: `3px`,
//   boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
//   fontSize: `14px`,
//   outline: `none`,
//   textOverflow: `ellipses`,

//   position: "absolute",
//   left: "22%",
//   top: "20px",
//   marginLeft: "200px",
//   zIndex: 9999,
//   cursor: `pointer`,
// };

const mylocBtn = {
  boxSizing: `border-box`,
  border: `1px solid transparent`,
  width: `auto`,
  background: `DodgerBlue`,
  color: `white`,
  height: `40px`,
  padding: `0 12px`,
  borderRadius: `3px`,
  boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
  fontSize: `14px`,
  outline: `none`,
  textOverflow: `ellipses`,
  position: "absolute",
  right: "2%",
  top: "20px",
  marginLeft: "400px",
  zIndex: 9999,
  cursor: `pointer`,
};

// const textStyle = {
//   boxSizing: `border-box`,
//   border: `1px solid transparent`,
//   width: `300px`,
//   height: `40px`,
//   padding: `0 12px`,
//   borderRadius: `3px`,
//   boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
//   fontSize: `14px`,
//   outline: `none`,
//   textOverflow: `ellipses`,
//   position: "absolute",
//   left: "22%",
//   top: "10px",
//   marginLeft: "-120px",
// };

// import React, { useState, useEffect, useRef } from "react";
// import {
//   GoogleMap,
//   Autocomplete,
//   Marker,
//   useJsApiLoader
// } from "@react-google-maps/api";

// const containerStyle = {
//   width: "100%",
//   height: "80vh"
// };

// const getFullAddress = (address) => {
//   let obj = {};
//   address.forEach((item) => {
//     if (item.types.includes("country")) {
//       obj.country = item.long_name;
//     }
//     if (item.types.includes("administrative_area_level_1")) {
//       obj.state = item.long_name;
//     }
//     if (item.types.includes("administrative_area_level_3")) {
//       obj.city = item.long_name;
//     }
//     if (item.types.includes("postal_code")) {
//       obj.zipcode = item.long_name;
//     }
//   });
//   return obj;
// };

// function GoogleMapApi() {
//   const inputRef = useRef(null);
//   const [autocomplete, setAutocomplete] = useState(null);
//   const [userCoords, setUserCoords] = useState(null);
//   const [address, setAddress] = useState(null);

//   useEffect(() => {
//     navigator.geolocation.getCurrentPosition(
//       (position) => {
//         setUserCoords({
//           lat: position.coords.latitude,
//           lng: position.coords.longitude
//         });
//       },
//       (error) => {
//         console.error(error);
//       }
//     );
//   }, []);

//   const handleClick = (e) => {
//     setUserCoords({
//       lat: e.latLng.lat(),
//       lng: e.latLng.lng()
//     });
//   };

//   const { isLoaded } = useJsApiLoader({
//     id: "google-map-script",
//     googleMapsApiKey: "AIzaSyCYFU_PnPrWNmK4iaskyRfR_llE7mN9uMo",
//     libraries: ["places"]
//   });

//   const onLoads = (autocomplete) => {
//     setAutocomplete(autocomplete);
//   };

//   const onPlaceChanged = () => {
//     if (autocomplete !== null) {
//       const place = autocomplete.getPlace();
//       setUserCoords({
//         lat: place.geometry.location.lat(),
//         lng: place.geometry.location.lng()
//       });
//     } else {
//       console.log("Autocomplete is not loaded yet!");
//     }
//   };

//   useEffect(() => {
//     if (userCoords && isLoaded) {
//       const geocoder = new window.google.maps.Geocoder();
//       geocoder.geocode({ location: userCoords }, (results, status) => {
//         if (status === "OK") {
//           let area = results[0]?.formatted_address;
//           setAddress({
//             ...getFullAddress(results[0]?.address_components),
//             area
//           });
//         } else {
//           console.error(
//             "Geocode was not successful for the following reason:",
//             status
//           );
//         }
//       });
//     }
//   }, [userCoords]);
//   console.log({ userCoords, address });

//   return (
//     isLoaded && (
//       <GoogleMap
//         mapContainerStyle={containerStyle}
//         zoom={15}
//         center={userCoords}
//         onClick={handleClick}
//       >
//         <Marker position={userCoords} />
//         <Autocomplete onLoad={onLoads} onPlaceChanged={onPlaceChanged}>
//           <input
//             ref={inputRef}
//             type="text"
//             placeholder="Search Your Location"
//             style={{
//               boxSizing: `border-box`,
//               border: `1px solid transparent`,
//               width: `240px`,
//               height: `32px`,
//               padding: `0 12px`,
//               borderRadius: `3px`,
//               boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
//               fontSize: `14px`,
//               outline: `none`,
//               textOverflow: `ellipses`,
//               position: "absolute",
//               left: "50%",
//               marginLeft: "-120px"
//             }}
//           />
//         </Autocomplete>
//       </GoogleMap>
//     )
//   );
// }

// export default GoogleMapApi;
