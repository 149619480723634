import React from "react";
// import { staffMembers } from "./StaffMembersData";

// Import slider & css files
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { settings } from "../blog-podcast/sliderProps";
import "../blog-podcast/blog.css";

// ** MUI
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import { CardActionArea } from "@mui/material";

const StaffMember = ({ ChurchData }) => {
  const subHeading = {
    fontFamily: "Kumbh Sans",
    fontSize: "20px",
    fontWeight: "bold",
    marginY: "20px",
    display: "flex",
    justifyContent: "space-between",
  };

  return (
    <Box sx={{ position: "relative" }}>
      <Typography sx={subHeading}>Staff Members</Typography>

      <Box>
        <Slider {...settings}>
          {ChurchData?.staff?.map((data, ind) => {
            return (
              <Card sx={{ maxWidth: "90%" }} key={ind}>
                <CardActionArea sx={{ position: "relative" }}>
                  <CardMedia
                    component="img"
                    height="330"
                    width="130"
                    image={data?.imageUrl}
                    alt="man"
                    sx={{ borderRadius: "12px" }}
                  />

                  <Box
                    sx={{
                      background: "#3F292B",
                      padding: "20px",
                      Width: "150px",
                      borderRadius: "0 10px 0 10px",
                      position: "absolute",
                      bottom: 0,
                      left: 0,
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "14px",
                        color: "white",
                        fontFamily: "Kumbh Sans",
                        fontWeight: "bold",
                      }}
                    >
                      {data?.name}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "11px",
                        color: "white",
                        fontFamily: "Kumbh Sans",
                      }}
                    >
                      {data?.staffRole}
                    </Typography>
                  </Box>
                </CardActionArea>
              </Card>
            );
          })}
        </Slider>
      </Box>
    </Box>
  );
};

export default StaffMember;
