import React from "react";

import "./events.css";
import "../FirstPage/firstpage.css";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";

// ** MUI
import Box from "@mui/material/Box";
import { Button, Typography } from "@mui/material";

// import { useNavigate } from "react-router-dom";

const ChurchAlbum = ({ ChurchData, show, setShow }) => {
  // const navigate = useNavigate();

  let [img_vid, setImg_vid] = React.useState([]);

  React.useEffect(() => {
    let img = ChurchData?.image;
    let video = ChurchData?.video;
    console.log({ img, video });
    if (img || video) {
      setImg_vid([...img, ...video].sort(() => Math.random() - 0.5));
    }
  }, [ChurchData]);

  // console.log({ img_vid });
  return (
    <>
      {show && (
        <Typography sx={eventsHeadingg} className="heading-album">
          Album of&nbsp;
          <Typography sx={eventsHeading} className="heading-album">
            {ChurchData?.user?.fullName}
          </Typography>
        </Typography>
      )}

      {ChurchData?.image?.length > 0 ? (
        <Box sx={{ position: "relative" }}>
          <Box
            sx={{
              marginBottom: "30px",
              maxHeight: show ? "auto" : 500,
              minHeight: 100,
              overflowY: show ? "scroll" : "hidden",
            }}
          >
            <Button
              onClick={() => setShow(!show)}
              sx={{
                background: "white",
                color: "#000000",
                border: "1px solid white",
                fontSize: "10px",
                px: 3,
                fontFamily: "Kumbh Sans",

                position: "absolute",
                top: "30px",
                left: "20px",
                zIndex: 99999999,

                "&:hover": {
                  border: "1px solid #ffffff",
                  color: "#ffffff",
                  background: "transparent",
                },
              }}
            >
              {show ? "Close Album" : "Show Album"}
            </Button>

            {/* <ImageList variant="masonry" cols={4} gap={10}>
              {ChurchData?.image?.map((img, ind) => {
                return (
                  <ImageListItem key={ind} sx={{ borderRadius: "20px" }}>
                    <img
                      src={img?.imageUrl}
                      alt={`album${ind}`}
                      width="100%"
                      style={{ borderRadius: "10px" }}
                    />
                  </ImageListItem>
                );
              })}

              {ChurchData?.video?.map((img, ind) => {
                return (
                  <ImageListItem key={ind} sx={{ borderRadius: "20px" }}>
                    <video
                      autoPlay
                      loop
                      muted
                      poster="https://assets.codepen.io/6093409/river.jpg"
                      style={{ borderRadius: "10px", width: "100%" }}
                    >
                      <source src={img?.videoUrl} type="video/mp4" />
                    </video>
                  </ImageListItem>
                );
              })}
            </ImageList> */}

            <ImageList variant="masonry" cols={4} gap={10}>
              {img_vid?.map((img, ind) => {
                if (img?.imageUrl) {
                  return (
                    <ImageListItem key={ind} sx={{ borderRadius: "20px" }}>
                      <img
                        src={img?.imageUrl}
                        alt={`album${ind}`}
                        width="100%"
                        style={{ borderRadius: "10px" }}
                      />
                    </ImageListItem>
                  );
                } else {
                  return (
                    <ImageListItem key={ind} sx={{ borderRadius: "20px" }}>
                      <video
                        autoPlay
                        loop
                        muted
                        poster="https://assets.codepen.io/6093409/river.jpg"
                        style={{ borderRadius: "10px", width: "100%" }}
                      >
                        <source src={img?.videoUrl} type="video/mp4" />
                      </video>
                    </ImageListItem>
                  );
                }
              })}
            </ImageList>
          </Box>
        </Box>
      ) : null}
    </>
  );
};

export default ChurchAlbum;
const eventsHeading = {
  fontSize: "2rem",
  fontFamily: "Kumbh Sans",
  fontWeight: "bold",
  color: "#D1AB42",
  display: "flex",
  justifyContent: "center",
};
const eventsHeadingg = {
  fontSize: "2rem",
  fontFamily: "Kumbh Sans",
  fontWeight: "bold",
  color: "#000000",
  justifyContent: "center",
  display: "flex",
  flexWrap: "wrap",
};

// const style = {
//   imageStyle: {
//     borderRadius: "20px",
//   },
// };
