import * as React from "react";
import moment from "moment";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Box from "@mui/material/Box";
import { Button, Typography, Stack, Pagination, MenuItem } from "@mui/material";
import { StyledMenu } from "./ActionButtonStyle";
import VisibilityIcon from "@mui/icons-material/Visibility";
// ** Popups
import ViewDetailsPopup from "./AllPopUp/ViewEventDetailsPopup";
import ApproveEventPopup from "./AllPopUp/ApproveEventPopUp";
import RejectEventPopup from "./AllPopUp/RejectEventPopUp";
import AdminCreateEvent from "./AllPopUp/AdminCreateEventPopUp";
import AdminEventsEditPopUp from "./AllPopUp/AdminEventsEditPopUp";
import EditIcon from '@mui/icons-material/Edit';

import axios from "axios";
const baseURL = process.env.REACT_APP_BASEURL;

// const Alert = React.forwardRef(function Alert(props, ref) {
//   return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
// });

function SiteEvents({ activeItem, setActiveItem ,tokenAPI}) {
  const [openViewDetialpopUp, setOpenViewDetialpopUp] = React.useState(false);
  const [openApprovepopUp, setApprovepopUp] = React.useState(false);
  const [openRejectpopUp, setRejectpopUp] = React.useState(false);
  const [FilterStatus, setFilterStatus] = React.useState("");
  const [opencreatepopup, setOpencreatepopup] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [CurrentId, setCurrentId] = React.useState("");
  const [openEdit, setOpenEdit] = React.useState(false);
  const [trig, setTrig] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);

  const open = Boolean(anchorEl);

  const handleClickView = (event, id) => {
    setCurrentId(id);
    setOpenEdit(true)
  };

  const handleClick = (event, id) => {
    setAnchorEl(event.currentTarget);
    //  setCurrentId(id);
    //  setOpenViewDetialpopUp(true);
  };
  const handleClick2 = (event, id) => {
     setCurrentId(id);
     setOpenViewDetialpopUp(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // *** Pagination
  const options = [2, 5, 10, 15, 20];
  const [value, setValue] = React.useState(options[2]);
  const [page, setPage] = React.useState(1);
  const [total, setTotal] = React.useState();

  let count = Math.ceil(total / value);
  let start = (page - 1) * value;
  let last = Math.min(start + value - 1, total - 1);

  const [rowx, setRowX] = React.useState([]);

  function createData(
    id,
    name,
    postedby,
    time,
    status,
    category,
    role,

    Attending,
    MayAttending
  ) {
    return {
      id,
      name,
      postedby,
      time,
      status,
      category,
      role,

      Attending,
      MayAttending,
    };
  }

  React.useEffect(() => {
    const getloginLocalInfo = JSON.parse(
      localStorage.getItem("loginLocalInfo")
    );
    const config = {
      headers: { Authorization: `Bearer ${getloginLocalInfo.token}` },
    };

    axios
      .get(
        `${baseURL}/api/v1/admin/getAllEvents?page_no=${page}&records_per_page=${value}&status=${FilterStatus}`,
        config
      )
      .then((response) => {
        if (response.data.success) {
          setTotal(response.data.count);
          console.log({ admindata: response.data.data });
          const valx = response.data.data.map((i) => {
            return {
              rend: createData(
                i._id,
                i.name,
                i.hostedBy?.fullName,
                i.fromDate,
                i.status,
                i.category,
                i.hostedBy?.role,

                i.Attending,
                i.MayAttend
              ),
            };
          });
          setRowX(valx);
          setAnchorEl(null);
          // setFilterStatus("");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [openRejectpopUp, openApprovepopUp, page, FilterStatus]);

  return (
    <Box
      sx={{
        borderRadius: "20px",
        marginY: "25px",
        padding: "20px",
        background: "#ffffff",
        overflow: "auto",
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
      >
        <Typography sx={tableHeading}>Site Events</Typography>
        <Box>
          {/* <Button variant="outlined" sx={darkBtn}>
            Add new event
          </Button> */}
          <Button
            variant="outlined"
            sx={activeItem === "Events" ? { display: "none" } : loginBtn}
            onClick={() => setOpencreatepopup(true)}
          >
            Add Event
          </Button>
          &nbsp;
          <Button
            variant="outlined"
            sx={activeItem === "Events" ? { display: "none" } : loginBtn}
            onClick={() => setActiveItem("Events")}
          >
            View all
          </Button>
        </Box>
      </Stack>

      <Table sx={{ minWidth: "auto" }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell sx={colHeading} align="left">
              Events Name
            </TableCell>
            <TableCell sx={colHeading} align="left">
              Hosted By
            </TableCell>

            <TableCell sx={colHeading} align="left">
              Category
            </TableCell>

            <TableCell sx={colHeading} align="left">
              Start Date
            </TableCell>

            <TableCell sx={colHeading} align="left">
              Responses
            </TableCell>

            <TableCell sx={colHeading} align="left">
              Type
            </TableCell>

            <TableCell sx={colHeading} align="left">
              <Button
                id="demo-customized-button"
                aria-controls={open ? "demo-customized-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                disableElevation
                variant="outlined"
                sx={darkBtn}
                onClick={(event) => handleClick(event)}
              >
                {FilterStatus ? FilterStatus : "Status"}
              </Button>
              <StyledMenu anchorEl={anchorEl} open={open} onClose={handleClose}>
                <MenuItem
                  onClick={() => {
                    setFilterStatus("Approved");
                  }}
                >
                  Approved
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setFilterStatus("Pending");
                  }}
                >
                  Pending
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setFilterStatus("Rejected");
                  }}
                >
                  Rejected
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setFilterStatus("");
                  }}
                >
                  All
                </MenuItem>
              </StyledMenu>
            </TableCell>

            <TableCell sx={colHeading} align="left">
              Action
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {rowx.map((rown, i) => {
            const row = rown.rend;
            // const idd = rown.id;
            // console.log(row.id, "<< iiddiidd");
            return (
              <TableRow
                key={i}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell sx={colData} component="th" scope="row">
                  {row.name}
                </TableCell>

                <TableCell sx={colData} align="left">
                  {row.postedby}
                </TableCell>

                <TableCell sx={colData} align="left">
                  {row.category ? row.category : "no category"}
                </TableCell>

                <TableCell sx={colData} align="left">
                  {moment(row.time).format("lll")}
                </TableCell>

                <TableCell sx={colData} align="left">
                  {row?.Attending} attending, {row?.MayAttending} May attend
                </TableCell>

                <TableCell sx={colData} align="left">
                  {row?.role}
                </TableCell>

                <TableCell align="left">
                  {row.status === "Pending" ? (
                    <Typography sx={colDataPending}>{row.status}</Typography>
                  ) : row.status === "Approved" ? (
                    <Typography sx={colDataApproved}>{row.status}</Typography>
                  ) : (
                    <Typography sx={colDataRejected}>{row.status}</Typography>
                  )}
                </TableCell>

                <TableCell sx={colData} align="left">
                  {/* <Button
                    id="demo-customized-button"
                    aria-controls={open ? "demo-customized-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    disableElevation
                    variant="outlined"
                    sx={darkBtn}
                    onClick={(event) => handleClick(event, row.id)}
                  > */}
                  <VisibilityIcon
                    // id="demo-customized-button"
                    // aria-controls={open ? "demo-customized-menu" : undefined}
                    // aria-haspopup="true"
                    // aria-expanded={open ? "true" : undefined}
                    // disableElevation
                    // variant="outlined"
                    sx={ViewBtn}
                    onClick={(event) => handleClick2(event, row.id)}
                  />
                  <EditIcon
                  sx={ViewBtn}                  
                  onClick={(event) => handleClickView(event, row.id)}
                  />
                  {/* </Button> */}

                  {/* <StyledMenu
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                  >
                    <MenuItem onClick={() => setOpenViewDetialpopUp(true)}>
                      <OpenInNewOutlinedIcon />
                      View Details
                    </MenuItem>

                    <MenuItem onClick={() => setApprovepopUp(true)}>
                      <CheckCircleOutlineOutlinedIcon />
                      Approve
                    </MenuItem>

                    <MenuItem onClick={() => setRejectpopUp(true)}>
                      <DeleteSweepOutlinedIcon />
                      Reject
                    </MenuItem>
                  </StyledMenu> */}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>

      <Stack direction="row" justifyContent="space-between">
        <Typography sx={paginationStyle}>
          Showing {total === 0 ? 0 : start + 1} to {last + 1} of {total} entries
        </Typography>

        <Pagination
          count={count}
          page={page}
          onChange={(event, newValue) => {
            setPage(newValue);
          }}
        />
      </Stack>

      {/* <Snackbar
        open={openToastReject}
        autoHideDuration={2000}
        onClose={handleCloseToastReject}
      >
        <Alert
          onClose={handleCloseToastReject}
          severity="error"
          sx={{ width: "100%" }}
        >
          Event Rejected
        </Alert>
      </Snackbar>

      <Snackbar
        open={openToastSuccess}
        autoHideDuration={2000}
        onClose={handleCloseToastSuccess}
      >
        <Alert
          onClose={handleCloseToastSuccess}
          severity="success"
          sx={{ width: "100%" }}
        >
          Event Approved
        </Alert> 
      </Snackbar> */}

      <ViewDetailsPopup
        handleClose={handleClose}
        openViewDetialpopUp={openViewDetialpopUp}
        setOpenViewDetialpopUp={setOpenViewDetialpopUp}
        particularId={CurrentId}
        openApprovepopUp={openApprovepopUp}
        setApprovepopUp={setApprovepopUp}
        openRejectpopUp={openRejectpopUp}
        setRejectpopUp={setRejectpopUp}
      />

      <ApproveEventPopup
        handleClose={handleClose}
        openApprovepopUp={openApprovepopUp}
        setApprovepopUp={setApprovepopUp}
        particularId={CurrentId}
        setOpenViewDetialpopUp={setOpenViewDetialpopUp}
        openViewDetialpopUp={openViewDetialpopUp}
      />

      <RejectEventPopup
        handleClose={handleClose}
        openRejectpopUp={openRejectpopUp}
        setRejectpopUp={setRejectpopUp}
        particularId={CurrentId}
        setOpenViewDetialpopUp={setOpenViewDetialpopUp}
        openViewDetialpopUp={openViewDetialpopUp}
      />
      <AdminCreateEvent
      opencreatepopup={opencreatepopup}
      setOpencreatepopup={setOpencreatepopup}
      />

<AdminEventsEditPopUp
        // userRole={userRole}
         openEdit={openEdit}
         setOpenEdit={setOpenEdit}
         particularId={CurrentId}
        // particalarId={particalarId}
         tokenApi={tokenAPI}
         openDelete={openDelete}
         setOpenDelete={setOpenDelete}
         trig={trig}
         setTrig={setTrig}
        // WholeData={WholeData}
      />

    </Box>
  );
}

export default SiteEvents;

const tableHeading = {
  fontSize: "16px",
  fontFamily: "Kumbh Sans",
  fontWeight: "bold",
  padding: "15px",
};
const colHeading = {
  fontSize: "14px",
  fontFamily: "Kumbh Sans",
  fontWeight: "bold",
};

const colData = {
  fontSize: "12px",
  fontFamily: "Kumbh Sans",
};

const paginationStyle = {
  fontSize: "12px",
  fontFamily: "Kumbh Sans",
};

const colDataPending = {
  fontSize: "12px",
  fontFamily: "Kumbh Sans",
  color: "orange",
};

const colDataRejected = {
  fontSize: "12px",
  fontFamily: "Kumbh Sans",
  color: "red",
};

const colDataApproved = {
  fontSize: "12px",
  fontFamily: "Kumbh Sans",
  color: "green",
};

const darkBtn = {
  fontSize: "12px",
  fontFamily: "Kumbh Sans",
  fontWeight: "bold",
  color: "#000000",
  background: "transparent",
  textTransform: "capitalize",
  border: "1px solid #000000",
  // border: "none",

  mr: 1,
  "&:hover": {
    border: "1px solid #D1AB42",
    // border: "none",
    background: "#D1AB42",
    color: "#ffffff",
  },
};

const ViewBtn = {
  fontSize: "22px",
  color: "#D1AB42",
  ml: 2,
  "&:hover": {
    color: "#3F292B",
  },
};

const loginBtn = {
  gap: "10px",
  color: "#ffffff",
  background: "#D1AB42",
  textTransform: "capitalize",
  fontFamily: "Kumbh Sans",
  border: "1px solid #D1AB42",
  fontSize: "10px",
  "&:hover": {
    border: "1px solid #3F292B",
    color: "#3F292B",
  },
};
