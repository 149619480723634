import * as React from "react";

// import OurEventsAdd from "./OurEventsAdd";

import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
// import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import { visuallyHidden } from "@mui/utils";

// import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
// import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import { Button, Stack } from "@mui/material";
import axios from "axios";
import moment from "moment";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Events Name",
  },
  {
    id: "date",
    numeric: true,
    disablePadding: false,
    label: "Start Date & Time",
  },

  {
    id: "speakar",
    numeric: true,
    disablePadding: false,
    label: "Speakar",
  },
  // {
  //   id: "time",
  //   numeric: true,
  //   disablePadding: false,
  //   label: "Time",
  // },
  {
    id: "location",
    numeric: true,
    disablePadding: false,
    label: "Location",
  },

  {
    id: "status",
    numeric: true,
    disablePadding: false,
    label: "Status",
  },

  {
    id: "actions",
    numeric: false,
    disablePadding: false,
    label: "Actions",
  },
];

function EnhancedTableHead(props) {
  const {
    // onSelectAllClick,
    order,
    orderBy,
    // numSelected,
    // rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell> */}
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "center"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={colHeading}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const { numSelected,
    //  setOpenAdd 
    } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <Typography
            sx={tableHeading}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            My Attending Events
          </Typography>
        </Stack>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : null}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function EnhancedTable({ baseURL, tokenApi }) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [openAdd, setOpenAdd] = React.useState(false);
  const [RowsData, setRowsData] = React.useState([]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = RowsData.map((n) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  function createData(id, name, date, speakar, location, status) {
    return {
      id,
      name,
      date,
      speakar,
      location,
      status,
    };
  }

  const [Status, setStatus] = React.useState(false);

  React.useEffect(() => {
    const config = {
      headers: { Authorization: `Bearer ${tokenApi}` },
    };

    const getAllEventsRequest = async () => {
      await axios
        .get(
          `${baseURL}/api/v1/user/getUserEvents?page_no=${page}&records_per_page=${rowsPerPage}`,
          config
        )
        .then((response) => {
          if (response.data.success) {
            setStatus(false);
            const value = response.data.data.map((i) => {
              return {
                render: createData(
                  i.event?._id,
                  i.event?.name,
                  i.event?.toDate,
                  i.event?.speakerDetails?.name,
                  i.event?.address,
                  i.status
                ),
              };
            });
            setRowsData(value);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };
    getAllEventsRequest();
  }, [Status]);

  // console.log({ RowsData });

  const NotAttendEvent = (response, id) => {
    const config = {
      headers: { Authorization: `Bearer ${tokenApi}` },
    };

    // console.log(id, response, "statussssss");

    axios
      .put(
        `${baseURL}/api/v1/event/bookEvent/${id}`,
        {
          status: response,
        },
        config
      )
      .then((res) => {
        if (res.data.success) {
          // alert("not attendddd....");
          setStatus(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <Box
        sx={{
          borderRadius: "20px",
          padding: "20px",
          background: "#ffffff",
          height: "100%",
          width: "auto",
          overflow: "auto",
          m: 2,
        }}
      >
        <EnhancedTableToolbar
          numSelected={selected.length}
          openAdd={openAdd}
          setOpenAdd={setOpenAdd}
        />
        <TableContainer>
          <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={RowsData.length}
            />
            <TableBody>
              {stableSort(RowsData, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((rows, index) => {
                  const isItemSelected = isSelected(rows.name);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  const row = rows.render;
                  return (
                    <TableRow
                      hover
                      // onClick={(event) => handleClick(event, row.name)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.name}
                      selected={isItemSelected}
                    >
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                        sx={colData}
                        align="center"
                      >
                        {row.name}
                      </TableCell>

                      <TableCell align="right" sx={colData}>
                        {moment(row.date).format("lll")}
                      </TableCell>

                      <TableCell align="right" sx={colData}>
                        {row.speakar}
                      </TableCell>

                      <TableCell align="right" sx={colData}>
                        {row.location?.city}, {row.location?.state},{" "}
                        {row.location?.country}
                      </TableCell>

                      <TableCell align="right" sx={colData}>
                        {row.status}
                      </TableCell>

                      <TableCell
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          gap: "5px",
                          color: "#D1AB42",
                        }}
                      >
                        {row.status === "MayAttend" && (
                          <>
                            <Button
                              variant="outlined"
                              sx={loginBtn}
                              onClick={() =>
                                NotAttendEvent("Attending", row.id)
                              }
                            >
                              Attending
                            </Button>

                            <Button
                              variant="outlined"
                              sx={deleteBtn}
                              onClick={() =>
                                NotAttendEvent("NotAttend", row.id)
                              }
                            >
                              Not&nbsp;Attending
                            </Button>
                          </>
                        )}

                        {row.status === "Attending" && (
                          <>
                            <Button
                              variant="outlined"
                              sx={loginBtn}
                              onClick={() =>
                                NotAttendEvent("MayAttend", row.id)
                              }
                            >
                              May Attend
                            </Button>

                            <Button
                              variant="outlined"
                              sx={deleteBtn}
                              onClick={() =>
                                NotAttendEvent("NotAttend", row.id)
                              }
                            >
                              Not Attending
                            </Button>
                          </>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[10, 15, 25]}
          component="div"
          count={RowsData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
    </>
  );
}

const tableHeading = {
  fontSize: "18px",
  fontFamily: "Kumbh Sans",
  fontWeight: "bold",
  padding: "15px",
};
const colHeading = {
  fontSize: "14px",
  fontFamily: "Kumbh Sans",
  fontWeight: "bold",
};

const colData = {
  fontSize: "12px",
  fontFamily: "Kumbh Sans",
};
const loginBtn = {
  gap: "10px",
  color: "#ffffff",
  background: "#D1AB42",
  textTransform: "capitalize",
  fontFamily: "Kumbh Sans",
  border: "1px solid #D1AB42",
  fontSize: "8px",
  mr: 1,
  padding: "3px 3px",
  "&:hover": {
    border: "1px solid #3F292B",
    color: "#3F292B",
  },
};

const deleteBtn = {
  gap: "10px",
  color: "#ffffff",
  background: "darkred",
  textTransform: "capitalize",
  fontFamily: "Kumbh Sans",
  border: "1px solid darkred",
  fontSize: "8px",
  mr: 1,
  padding: "3px 3px",
  "&:hover": {
    border: "1px solid #3F292B",
    color: "#3F292B",
  },
};

// const deleteBtn = {
//   color: "#ffffff",
//   background: "darkred",
//   textTransform: "capitalize",
//   fontFamily: "Kumbh Sans",
//   border: "1px solid darkred",
//   fontSize: "10px",
//   mr: 1,
//   "&:hover": {
//     border: "1px solid #3F292B",
//     color: "#3F292B",
//   },
// };
