import React from "react";
import Button from "@mui/material/Button";
import { Box, IconButton, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";

import { closeIcon } from "../navbar/closeIcon";

import CheckRound from "../../assets/icons/checkRound.png";
import { useNavigate } from "react-router-dom";

const Thanksdialog = ({ open, setOpen }) => {
  const navigate = useNavigate();

  const button1 = {
    backgroundColor: "#D1AB42",
    borderRadius: "8px",
    fonFamily: "Kumbh Sans",
    fontWeight: "bold",
    fontSize: "10px",
    padding: "14px 50px",
    "&:hover": {
      backgroundColor: "#D1AB42",
      color: "white",
    },
  };

  //   const button2 = {
  //     backgroundColor: "#3F292B",
  //     borderRadius: "8px",
  //     fonFamily: "Kumbh Sans",
  //     fontWeight: "bold",
  //     fontSize: "10px",
  //     padding: "14px 50px",

  //     ":hover": {
  //       backgroundColor: "#3F292B",
  //       color: "white",
  //     },
  //   };

  return (
    <>
      <Dialog open={open}>
        <Box align="center" sx={{ padding: "14px" }}>
          <IconButton
            sx={{
              position: "absolute",
              top: "4px",
              right: "4px",
            }}
            onClick={() => setOpen(false)}
          >
            {closeIcon()}
          </IconButton>
          <img src={CheckRound} alt="check" width={"90px"} />

          <Typography
            sx={{
              marginTop: "10px",
              fontFamily: "Kumbh Sans",
              fontSize: "24px",
              fontWeight: "bold",
            }}
          >
            Payment Successfull.
          </Typography>

          <Typography
            sx={{
              marginY: "10px",
              fontFamily: "Kumbh Sans",
              fontSize: "14px",
            }}
          >
            Thank you for your taking subscription.
          </Typography>
        </Box>

        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-evenly",
            margin: "0 10px 10px",
          }}
        >
          <Button
            onClick={() => navigate("/")}
            variant="contained"
            sx={button1}
            className="button"
          >
            Login
          </Button>
          {/* <Button
            onClick={() => setOpen(false)}
            variant="contained"
            sx={button2}
            className="button"
          >
            ON ZOOM
          </Button> */}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Thanksdialog;
