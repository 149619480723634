import * as React from "react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
// import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
// import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import { Button, Typography, Stack, Divider } from "@mui/material";
import axios from "axios";
import moment from "moment";

export default function AttedingEvent({
  setActiveItem,
  activeItem,
  baseURL,
  tokenApi,
}) {
  const [RowsData, setRowsData] = React.useState([]);
  const [RowsData1, setRowsData1] = React.useState([]);

  function createData(name, date, status) {
    return { name, date, status };
  }

// const rows = [
//   createData("Judgement day", "23 may, 2020", "09.00pm", "Attending"),
//   createData("Judgement day1", "23 may, 2020", "09.00pm", "Attending"),
//   createData("Judgement day2", "23 may, 2020", "09.00pm", "Attending"),
//   createData("Judgement day3", "23 may, 2020", "09.00pm", "Attending"),
//   createData("Judgement day4", "23 may, 2020", "09.00pm", "Attending"),
//   createData("Judgement day5", "23 may, 2020", "09.00pm", "Attending"),
// ];

  function createData1(toChurch, topic, date) {
    return { toChurch, topic, date };
  }

  // const inq = [
  //   createData1("Judgement day", "23 may, 2020", "Attending"),
  //   createData1("church", "23 may, 2020", "Attending"),
  //   createData1("Judgement day2", "23 may, 2020", "Attending"),
  // ];

  React.useEffect(() => {
    const getloginLocalInfo = JSON.parse(
      localStorage.getItem("loginLocalInfo")
    );

    const config = {
      headers: {
        Authorization: `Bearer ${getloginLocalInfo.token}`,
      },
    };

    axios
      .get(
        `${baseURL}/api/v1/user/getUserEvents?page_no=${1}&records_per_page=${5}`,
        config
      )
      .then((response) => {
        if (response.data.success) {
          // setStatus(false);
          const value = response.data.data.map((i) => {
            return {
              render: createData(
                // i.event?._id,
                i.event?.name,
                i.event?.toDate,
                // i.event?.speakerDetails?.name,
                // i.event?.address,
                i.status
              ),
            };
          });
          setRowsData(value);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  React.useEffect(() => {
    // const config = {
    //   headers: { Authorization: `Bearer ${tokenApi}` },
    // };

    const getloginLocalInfo = JSON.parse(
      localStorage.getItem("loginLocalInfo")
    );

    const config = {
      headers: {
        Authorization: `Bearer ${getloginLocalInfo.token}`,
      },
    };

    const getAllEventsRequest = async () => {
      await axios
        .get(`${baseURL}/api/v1/inquiry/getAllInquiryOfLoggedInUser`, config)
        .then((response) => {
          if (response.data.success) {
            const value = response.data.data.map((i) => {
              return {
                render: createData1(
                  // i._id,
                  // i.contact,
                  i.church?.fullName,
                  i.inquiryType,
                  i.updatedAt
                  // i.inquiryDescription,
                  // i._id
                ),
              };
            });

            setRowsData1(value);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };
    getAllEventsRequest();
  }, []);

  return (
    <Box
      sx={{
        borderRadius: "20px",
        padding: "20px",
        background: "#ffffff",
        height: "100%",
        // width: "100%",
        overflow: "auto",
      }}
      className="AttendingStyle"
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
      >
        <Typography sx={tableHeading}>Attending Events</Typography>
        <Button
          variant="outlined"
          sx={loginBtn}
          onClick={() => setActiveItem("My Events")}
        >
          View all
        </Button>
      </Stack>
      <Table sx={{ minWidth: "auto" }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell sx={colHeading}>Events name</TableCell>
            <TableCell sx={colHeading} align="right">
              Start date & time
            </TableCell>
            {/* <TableCell sx={colHeading} align="right">
              Time
            </TableCell> */}
            <TableCell sx={colHeading} align="right">
              Status
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {RowsData?.map((rows) => {
            const row = rows.render;
            return (
              <TableRow
                key={row.name}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell sx={colData} component="th" scope="row">
                  {row.name}
                </TableCell>
                <TableCell sx={colData} align="right">
                  {moment(row.date).format("lll")}
                </TableCell>
                <TableCell sx={colData} align="right">
                  {row.status}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <Divider sx={{ marginY: "20px" }} />

      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
      >
        <Typography sx={tableHeading}>My Inquiries</Typography>
        <Button
          variant="outlined"
          sx={loginBtn}
          onClick={() => setActiveItem("My Inquiries")}
        >
          View all
        </Button>
      </Stack>
      <Table sx={{ minWidth: "auto" }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell sx={colHeading}>To Church</TableCell>
            <TableCell sx={colHeading} align="right">
              Topic
            </TableCell>
            <TableCell sx={colHeading} align="right">
              Date & Time
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {RowsData1.map((rows) => {
            const row = rows.render;
            return (
              <TableRow
                key={row.name}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell sx={colData} component="th" scope="row">
                  {row.toChurch}
                </TableCell>
                <TableCell sx={colData} align="right">
                  {row.topic}
                </TableCell>
                <TableCell sx={colData} align="right">
                  {moment(row.date).format("lll")}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Box>
  );
}
const tableHeading = {
  fontSize: "16px",
  fontFamily: "Kumbh Sans",
  fontWeight: "bold",
  padding: "15px",
};
const colHeading = {
  fontSize: "14px",
  fontFamily: "Kumbh Sans",
  fontWeight: "bold",
};

const colData = {
  fontSize: "12px",
  fontFamily: "Kumbh Sans",
};

const loginBtn = {
  gap: "10px",
  color: "#ffffff",
  background: "#D1AB42",
  textTransform: "capitalize",
  fontFamily: "Kumbh Sans",
  border: "1px solid #D1AB42",
  fontSize: "10px",
  "&:hover": {
    border: "1px solid #3F292B",
    color: "#3F292B",
  },
};
