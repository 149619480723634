import React from "react";
import InsideNavbar from "../navbar/InsideNavbar";
import Footer from "../footer";

import AboutFaithlens from "./AboutFaithlens";
// import WhatWeDo from "./WhatWeDo";
import WhatWeAre from "./WhatWeAre";
// import OurTeam from "./OurTeam";

const Index = () => {
  return (
    <>
      <InsideNavbar />
      <AboutFaithlens />
      {/* <WhatWeDo /> */}
      <WhatWeAre />
      {/* <OurTeam /> */}
      <Footer />
    </>
  );
};

export default Index;
