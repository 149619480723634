// ** Routes Pages
import Firstpage from "./components/FirstPage";
import Registration from "./components/registration";
import RegisterUser from "./components/registration/userRegister";
import RegisterChurch from "./components/registration/churchRegister";
import RegisterChurchCardDetails from "./components/registration/PaymentDetails";
import Events from "./components/Events";
import RegisterEvents from "./components/Events/registerEvent";
// import Games from "./components/Games";

// Profiles Imports
import UserProfile from "./components/UserProfile";
import ChurchProfile from "./components/ChurchProfile";
// import ChurchProfileAddEvent from "./components/ChurchProfile/AddEventPage";
import ArtistProfile from "./components/ArtistProfile";
import MinistryProfile from "./components/MinistryProfile";
import SuperAdminProfile from "./components/SuperAdmin";

import Error from "./components/Error";
import Map1 from "./components/GoogleMap/MapSetter";
import Map2 from "./components/GoogleMap/MapGetter";

import ResetPassword from "./components/navbar/setResetPassword";
import BlogPodcast from "./components/blog-podcast";

import ChurchDetails from "./components/Events/ChurchProfile";
import ChurchDetailsAlbum from "./components/Events/churchAlbumFull";
import ChurchDetailsInquery from "./components/Events/InqueryPage";

import About from "./components/About";
import ContactUs from "./components/ContactUs";

// import Media from "./components/Media";
// import Movies from "./components/Media/Movie";
// import TVShows from "./components/Media/TV";
// import Tube from "./components/Media/Tube";

// ** footer
import TermService from "./components/footer/TermServices";
import PrivacyPolicy from "./components/footer/PrivacyPolicy";

import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

// const stripePromise = loadStripe(
//   "pk_test_51MesghGZ2SfYHXF40L4RhuC0sCxkCJz2GzK4VfZVpABvwgOe3eVBnYnO6I1WmQARtcqSx711OrkUIVZapJRCTbII00hvju0dUc"
// );

const stripePromise = loadStripe(
  "pk_live_51MesghGZ2SfYHXF48Jfp9YJutsWTMWqQ504mzKgaEMukwoxjxqGyEKtellCyPHPw9iNru89xNFgwmRuHeS7ne4QD00CWOdOWcn"
);

export const routes = [
  { name: "Map1", component: <Map1 />, paths: "/map1" },
  {
    name: "Map2",
    component: <Map2 />,
    paths: "/events/eventRegister/eventLocation/:lati/:longi/:ename",
  },

  { name: "Error", component: <Error />, paths: "*" },

  {
    name: "firstpage",
    component: <Firstpage />,
    paths: "/",
  },
  {
    name: "registration",
    component: <Registration />,
    paths: "/registration",
  },
  {
    name: "Register User",
    component: <RegisterUser />,
    paths: "/registrationUser",
  },
  {
    name: "Register Church",
    component: <RegisterChurch />,
    paths: "/registrationChurch",
  },
  {
    name: "RegisterChurchCardDetails",
    component: (
      <Elements stripe={stripePromise}>
        <RegisterChurchCardDetails />
      </Elements>
    ),
    paths: "/paymentDetails",
  },
  {
    name: "Events",
    component: <Events />,
    paths: "/events",
  },
  {
    name: "Register Events",
    component: <RegisterEvents />,
    paths: "/events/eventRegister/:id",
  },

  // Profiles
  {
    name: "User Profile",
    component: <UserProfile />,
    paths: "/userProfile",
  },
  {
    name: "Church Profile",
    component: <ChurchProfile />,
    paths: "/churchProfile",
  },

  // {
  //   name: "Church Profile event",
  //   component: <ChurchProfileAddEvent />,
  //   paths: "/churchProfile/AddEvent",
  // },

  {
    name: "Artist Profile",
    component: <ArtistProfile />,
    paths: "/artistProfile",
  },
  {
    name: "Ministry Profile",
    component: <MinistryProfile />,
    paths: "/ministryProfile",
  },
  ///////////////////

  {
    name: "Super Admin Profile",
    component: <SuperAdminProfile />,
    paths: "/superAdminProfile",
  },

  {
    name: "Church Inquery",
    component: <ChurchDetailsInquery />,
    paths: "/churchInquery",
  },
  {
    name: "Reset Password",
    component: <ResetPassword />,
    paths: "/ResetPassword",
  },
  {
    name: "Blog",
    component: <BlogPodcast />,
    paths: "/blog-podcast",
  },
  {
    name: "Church Details",
    component: <ChurchDetails />,
    paths: "/events/eventRegister/churchDetails/:id",
  },
  {
    name: "Church Details Album",
    component: <ChurchDetailsAlbum />,
    paths: "/events/eventRegister/churchDetails/album",
  },
  {
    name: "Church Inquery",
    component: <ChurchDetailsInquery />,
    paths: "/events/eventRegister/churchDetails/churchInquery/:id",
  },

  // ** Media
  // {
  //   name: "Media",
  //   component: <Media />,
  //   paths: "/media",
  // },
  // {
  //   name: "MediaMovies",
  //   component: <Movies />,
  //   paths: "/media/movies",
  // },
  // {
  //   name: "Tube",
  //   component: <Tube />,
  //   paths: "/media/tube",
  // },
  // {
  //   name: "TV shows",
  //   component: <TVShows />,
  //   paths: "/media/tv",
  // },

  {
    name: "About",
    component: <About />,
    paths: "/about",
  },

  {
    name: "ContactUs",
    component: <ContactUs />,
    paths: "/contactus",
  },

  {
    name: "TermServices",
    component: <TermService />,
    paths: "/TermServices",
  },

  {
    name: "PrivacyPolicy",
    component: <PrivacyPolicy />,
    paths: "/PrivacyPolicy",
  },

  // {
  //   name: "Games",
  //   component: <Games />,
  //   paths: "/games",
  // },
];
