import React from "react";
import {
  Button,
  Divider,
  Pagination,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import "./events.css";
import Post from "../../assets/About/postImg.png";
// import { useSelector } from "react-redux";
import axios from "axios";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

const EventComments = ({ baseURL, id }) => {
  const [commentsPost, setCommentsPost] = React.useState([]);
  const [commentsPostCount, setCommentsPostCount] = React.useState("");
  const [UserComments, setUserComments] = React.useState("");
  const [ShowOptions, setShowOptions] = React.useState(false);

  const [DeleteLoader, setDeleteLoader] = React.useState(false);
  const [PostLoader, setPostLoader] = React.useState(false);
  const [trig, setTrig] = React.useState(false);

  // *** Pagination
  const options = [5, 10, 15];
  const [value, setValue] = React.useState(options[0]);
  const [page, setPage] = React.useState(1);

  let count = Math.ceil(commentsPostCount / value);

  const getloginLocalInfo = JSON.parse(localStorage.getItem("loginLocalInfo"));

  // console.log({ getloginLocalInfo });

  React.useEffect(() => {
    setTrig(false);

    if (id) {
      axios
        .get(
          `${baseURL}/api/v1/comment/event/${id}?page_no=${page}&records_per_page=${value}`
        )
        .then((res) => {
          if (res.data.success) {
            setCommentsPostCount(res.data.count);
            setCommentsPost(res.data.data);
          }
        })
        .catch((error) => {
          console.log(error, "error from get comments");
        });
    }
  }, [trig, id, page]);

  const onDeleteComment = (cid) => {
    const getloginLocalInfo = JSON.parse(
      localStorage.getItem("loginLocalInfo")
    );

    const config = {
      headers: {
        Authorization: `Bearer ${getloginLocalInfo.token}`,
      },
    };

    setDeleteLoader(true);

    axios
      .delete(`${baseURL}/api/v1/comment/event/${id}/${cid}`, config)
      .then((res) => {
        if (res.data.success) {
          setDeleteLoader(false);
          setTrig(true);
        }
      })
      .catch((error) => {
        console.log(error, "error from delete comments");
        setDeleteLoader(false);
      });
  };

  const onPostSubmit = () => {
    const getloginLocalInfo = JSON.parse(
      localStorage.getItem("loginLocalInfo")
    );

    const config = {
      headers: {
        Authorization: `Bearer ${getloginLocalInfo.token}`,
      },
    };

    setPostLoader(true);

    const post = {
      comment: UserComments,
    };

    if (config) {
      axios
        .post(`${baseURL}/api/v1/comment/event/${id}`, post, config)
        .then((res) => {
          if (res.data.success) {
            setUserComments("");
            setPostLoader(false);
            setTrig(true);
          }
        })
        .catch((error) => {
          console.log(error, "error from post comments");
        });
    }
  };

  return (
    <Box onClick={() => setShowOptions(!ShowOptions)}>
      <Box sx={{ my: 1 }}>
        <Pagination
          variant="outlined"
          count={count}
          page={page}
          onChange={(event, newValue) => {
            setPage(newValue);
          }}
        />
      </Box>
      <Typography sx={titleHeading}>
        {commentsPostCount > 0
          ? `Comments (${commentsPostCount})`
          : "No Comments"}
      </Typography>

      {commentsPostCount > 0 && (
        <Box>
          {commentsPost?.map((data, ind) => {
            return (
              <>
                <Box
                  key={ind}
                  sx={{
                    display: "flex",
                    justifyContent: "start",
                    gap: "20px",
                  }}
                >
                  <Box>
                    <img
                      src={
                        data.comment?.imageUrl ? data.comment?.imageUrl : Post
                      }
                      alt="postImg"
                      width="60px"
                      height="60px"
                      className="imagestyle"
                    />
                  </Box>
                  <Box>
                    <Box
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <Typography sx={posterName}>
                        {data.comment?.fullName}
                      </Typography>
                    </Box>

                    <Box
                    // sx={
                    //   {
                    // display: "flex",
                    // justifyContent: "space-between",
                    // flexWrap: 'wrap',
                    // maxWidth: "750px",
                    // overflow: 'auto'
                    // }
                    // }
                    >
                      <Typography sx={comments}>
                        {data.comment?.comment}
                      </Typography>
                      {ShowOptions && (
                        <Box>
                          {DeleteLoader ? (
                            <Typography sx={comments}>Deleting</Typography>
                          ) : data.comment?.user === getloginLocalInfo?._id ? (
                            <DeleteOutlineIcon
                              fontSize="small"
                              onClick={() => onDeleteComment(data.comment?._id)}
                            />
                          ) : null}
                        </Box>
                      )}
                    </Box>
                  </Box>
                </Box>
                <Divider sx={{ my: 1 }} />
              </>
            );
          })}
        </Box>
      )}

      {getloginLocalInfo?._id && (
        <>
          <Box>
            <TextField
              multiline
              rows={3}
              placeholder="Leave Comment"
              sx={{ width: "100%" }}
              value={UserComments}
              onChange={(e) => setUserComments(e.target.value)}
            />
          </Box>

          <Button
            sx={postBtn}
            className="post-btn-Width"
            onClick={onPostSubmit}
          >
            {PostLoader ? "Posting...." : "Post"}
          </Button>
        </>
      )}
    </Box>
  );
};

export default EventComments;

const titleHeading = {
  marginX: "0",
  marginBottom: "9px",
  fontFamily: "Kumbh Sans",
  fontSize: "18px",
  fontWeight: "bold",
};
const postBtn = {
  color: "#ffffff",
  background: "#3F292B",
  fontFamily: "Kumbh Sans",
  fontSize: "10px",
  marginTop: "10px",
  padding: "10px 32px",
  border: "1px solid #3F292B",

  "&:hover": {
    border: "1px solid #3F292B",
    color: "#3F292B",
  },
};

const posterName = {
  fontFamily: "Kumbh Sans",
  fontSize: "14px",
  fontWeight: "bold",
};

const comments = {
  fontFamily: "Kumbh Sans",
  fontSize: "13px",
  opacity: 0.5,
};

// const commentsPost = [
//   {
//     img: Post,
//     username: "John Stallion",
//     comment: "comments ",
//   },
//   {
//     img: Post,
//     username: "John Stallion",
//     comment: " comments",
//   },
//   {
//     img: Post,
//     username: "John Stallion",
//     comment: " comments ",
//   },
// ];
