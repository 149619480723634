import * as React from "react";
import "./navbar.css";
import Loginform from "./Loginform";
import LoginUserAvatar from "./LoginUserAvatar";
import { useNavigate } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";
import { activeVerticalItem } from "../../redux/userAuthSlice";

// ** MUI
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import MenuIcon from "@mui/icons-material/Menu";
import Drawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";

import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { Icon, Link } from "@mui/material";

// ** Icons
// import GridViewIcon from "@mui/icons-material/GridView";
// import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
// import InsertCommentOutlinedIcon from "@mui/icons-material/InsertCommentOutlined";
// import VideocamOutlinedIcon from "@mui/icons-material/VideocamOutlined";
// import HeadsetMicOutlinedIcon from "@mui/icons-material/HeadsetMicOutlined";
// import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";

// ** router
// import { Link } from "react-router-dom";

const drawerWidth = 240;

const navItems = [
  { name: "Home", paths: "/" },
  { name: "About Us", paths: "/about" },
  { name: "Events", paths: "/events" },
  { name: "Contact Us", paths: "/contactus" },
  // { name: "Blogs & Podcasts", paths: "/blog-podcast" },
  // { name: "Games", paths: "/games" },
];

function DrawerAppBar(props) {
  const navigate = useNavigate();

  const { window } = props;
  const [open, setOpen] = React.useState(false);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [activeItem, setActiveItem] = React.useState("Dashboard");

  // ** Logout Status
  const [isLogout, setIsLogout] = React.useState();
  React.useEffect(() => {
    const getLogoutStatus = localStorage.getItem("logoutStatus");
    setIsLogout(getLogoutStatus);
  }, []);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const activeVerticalItem1 = useSelector((state) => state.userAuth.item);
  const dispatch = useDispatch();

  React.useEffect(() => {
    setActiveItem(activeVerticalItem1);
  }, []);

  // ** Styles
  const signupBtn = {
    color: "#ffffff",
    background: "#3F292B",
    textTransform: "capitalize",
    fontFamily: "Kumbh Sans",
    border: "1px solid #3F292B",

    "&:hover": {
      border: "1px solid #3F292B",
      color: "#3F292B",
    },
  };

  const loginBtn = {
    gap: "10px",
    color: "#ffffff",
    background: "#D1AB42",
    textTransform: "capitalize",
    fontFamily: "Kumbh Sans",
    border: "1px solid #D1AB42",

    "&:hover": {
      border: "1px solid #3F292B",
      color: "#3F292B",
    },
  };

  // ** UserDataAuth
  const [userData, setUserData] = React.useState({});

  React.useEffect(() => {
    const getloginLocalInfo = JSON.parse(
      localStorage.getItem("loginLocalInfo")
    );
    if (getloginLocalInfo) {
      setUserData(getloginLocalInfo);
    }
  }, []);

  const drawer = (
    <Box
      onClick={handleDrawerToggle}
      sx={{
        textAlign: "center",
        padding: "10px",
        position: "relative",
      }}
    >
      <Typography variant="h6" sx={{ my: 2, fontFamily: "Kumbh Sans" }}>
        Faith Lens
      </Typography>

      <Divider sx={{ marginBottom: "10px" }} />

      <List sx={{ padding: "10px", height: "80vh" }}>
        <>
          {navItems?.map((item, i) => (
            <ListItem
              key={i}
              onClick={() => {
                navigate(item.paths);
                dispatch(activeVerticalItem(item.name));
              }}
              disablePadding
            >
              <ListItemButton
                align="center"
                sx={
                  activeItem === item.name && {
                    background: "#3F292B",
                    color: "#FAF6F4",
                    my: 1,
                    borderRadius: "8px",
                  }
                }
              >
                <Icon>{item.icon}</Icon>
                <ListItemText
                  sx={{ fontFamily: "Kumbh Sans" }}
                  primary={item.name}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </>
        <Box
          sx={{
            position: "absolute",
            bottom: "20px",
            left: "15px",
            right: "15px",
          }}
        >
          {userData?.fullName ? (
            <LoginUserAvatar isLogout={isLogout} setIsLogout={setIsLogout} />
          ) : (
            <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
              <Button
                variant="outlined"
                onClick={() => setOpen(true)}
                sx={loginBtn}
              >
                Login
              </Button>
              <Button
                onClick={() => navigate("/registration")}
                variant="outlined"
                sx={signupBtn}
              >
                Sign Up
              </Button>
            </Box>
          )}
        </Box>
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex", height: "64px" }}>
      <AppBar
        component="nav"
        sx={{ background: "#FFFFFF", color: "#000000", boxShadow: 0 }}
      >
        <Toolbar>
          <Typography
            variant="h6"
            onClick={() => {
              navigate("/");
            }}
            // component="div"
            sx={{
              flexGrow: 1,
              display: { xs: "block", sm: "block" },
              fontFamily: "Kumbh Sans",
              cursor: "pointer",
            }}
          >
            Faith Lens
          </Typography>

          <IconButton
            color="inherit"
            aria-label="open drawer"
            className="menu-icon-position"
            onClick={handleDrawerToggle}
            sx={{ display: { md: "none" }, justifyContent: "end" }}
          >
            <MenuIcon />
          </IconButton>

          <Box sx={{ display: { xs: "none", sm: "none", md: "block" } }}>
            <Box
              sx={{
                display: "flex",
                fontSize: "14px",
                gap: "16px",
                mx: 2,
              }}
            >
              {/* {navItems?.map((item, i) => (
                <Link to={item.paths} key={i}>
                  {item.name}
                </Link>
              ))} */}

              <Link
                onClick={() => navigate("/")}
                underline="none"
                sx={linkUnderline}
              >
                Home
              </Link>
              <Link
                onClick={() => navigate("/about")}
                underline="none"
                sx={linkUnderline}
              >
                About Us
              </Link>
              <Link
                onClick={() => navigate("/events")}
                underline="none"
                sx={linkUnderline}
              >
                Events
              </Link>
              <Link
                onClick={() => navigate("/contactus")}
                underline="none"
                sx={linkUnderline}
              >
                Contact Us
              </Link>
            </Box>
          </Box>

          <Box sx={{ display: { xs: "none", sm: "none", md: "block" } }}>
            {userData?.fullName ? (
              <LoginUserAvatar isLogout={isLogout} setIsLogout={setIsLogout} />
            ) : (
              <Box sx={{ display: { xs: "none", sm: "block" }, gap: "10px" }}>
                <Button
                  variant="outlined"
                  onClick={() => setOpen(true)}
                  sx={[loginBtn, { mr: 1 }]}
                >
                  Login
                </Button>
                <Button
                  onClick={() => navigate("/registration")}
                  variant="outlined"
                  sx={signupBtn}
                >
                  Sign Up
                </Button>
              </Box>
            )}
          </Box>
        </Toolbar>
      </AppBar>

      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { sm: "block", md: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>

      <Loginform setOpen={setOpen} open={open} />
    </Box>
  );
}

DrawerAppBar.propTypes = {
  window: PropTypes.func,
};

export default DrawerAppBar;

const linkUnderline = {
  color: "black",
  position: "relative",
  cursor: "pointer",


  "&:before": {
    content: "''",
    position: "absolute",
    width: "0",
    height: "2px",
    bottom: "-3px",
    left: "50%",
    transform: "translate(-50%,0%)",
    backgroundColor: "#D1AB42",
    transformOrigin: "center",
    visibility: "hidden",
    transition: "all 0.3s ease-in-out",
  },
  "&:hover:before": {
    visibility: "visible",
    width: "100%",
  },

  // "&:hover": {
  //   color: "#D1AB42",
  // },
};
