import React from "react";
import StartNav from "../navbar/StartNavbar";
import Footer from "../footer";
import FaithlensHome from "./FaithlensHome";
import PopularEvents from "./PopularEvents";

import Box from "@mui/material/Box";
import axios from "axios";

const Index = ({ usersData }) => {
  React.useEffect(() => {
    axios
      .get("https://ipapi.co/json")
      .then((res) => {
        // setIp(res.data.ip);
        if (res.data.ip) {
          postIP(res.data.ip);
        }
      })
      .catch((err) => console.error(err));
  }, []);

  const postIP = (IP) => {
    axios
      .post(`${process.env.REACT_APP_BASEURL}/api/v1/visitor`, {
        ip: IP,
      })
      .then((res) => {
        if (res.data.data) {
          console.log("visitor count : ", res.data.data);
        }
      })
      .catch((err) => {
        console.log("visitor error", err);
      });
  };

  return (
    <>
      <StartNav setUsersData={usersData} />
      <FaithlensHome />
      <Box sx={{ display: { xs: "none", sm: "block" } }}>
        <PopularEvents />
      </Box>
      <Footer />
    </>
  );
};

export default Index;
