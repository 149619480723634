import React from "react";
import { Policy } from "../../constant/Policy";
import InsideNavbar from "../navbar/InsideNavbar";
import Footer from "../footer";
import { Box } from "@mui/system";

const TermServices = () => {
  return (
    <>
      <InsideNavbar />
      <Box sx={{ m: 4 }}>
        <div
          dangerouslySetInnerHTML={{
            __html: Policy,
          }}
        />
      </Box>
      <Footer />
    </>
  );
};

export default TermServices;
