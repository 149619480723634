import { Box, CircularProgress, Typography } from "@mui/material";
import React from "react";
import TrendingUpOutlinedIcon from "@mui/icons-material/TrendingUpOutlined";
import axios from "axios";

const DashboardHeader = ({ CountDataChurch, CountDataEvent, RevenueCount }) => {
  const [VisitorCount, setVisitorCount] = React.useState("");

  React.useEffect(() => {
    const getloginLocalInfo = JSON.parse(
      localStorage.getItem("loginLocalInfo")
    );

    const config = {
      headers: {
        Authorization: `Bearer ${getloginLocalInfo.token}`,
      },
    };

    axios
      .get(
        `${process.env.REACT_APP_BASEURL}/api/v1/visitor/getCurrentMonthVisitor`,
        config
      )
      .then((res) => {
        console.log("visitor count admin : ", res.data.data);
        setVisitorCount(res.data.data);
      })
      .catch((err) => {
        console.log("visitor error admin", err);
      });
  }, []);

  const loader = () => {
    return (
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <CircularProgress sx={{ color: "#D1AB42", fontSize: "20px" }} />
      </Box>
    );
  };

  return (
    <Box sx={numberDashboardStyle}>
      <Box>
        <Typography sx={numberStyle}>
          {/* ${RevenueCount ? RevenueCount : loader()} */}
          {RevenueCount ? `$ ${RevenueCount?.toFixed(4)}` : loader()}
        </Typography>
        <Typography sx={numberTextStyle}> Subscription revenue</Typography>
      </Box>
      <Box>
        <Typography sx={numberStyle}>
          {VisitorCount ? VisitorCount : "0"}
          <TrendingUpOutlinedIcon sx={IconStyle} />
        </Typography>
        <Typography sx={numberTextStyle}> Total visitors this month</Typography>
      </Box>
      <Box>
        <Typography sx={numberStyle}>
          {CountDataEvent ? CountDataEvent : "0"}{" "}
          <TrendingUpOutlinedIcon sx={IconStyle} />
        </Typography>
        <Typography sx={numberTextStyle}> Events posted this month</Typography>
      </Box>
      <Box>
        <Typography sx={numberStyle}>
          {CountDataChurch ? CountDataChurch : "0"}{" "}
          <TrendingUpOutlinedIcon sx={IconStyle} />
        </Typography>
        <Typography sx={numberTextStyle}>
          Churches registered this month
        </Typography>
      </Box>
    </Box>
  );
};

export default DashboardHeader;

const numberDashboardStyle = {
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "space-between",
  background: "#ffffff",
  padding: "40px",
  borderRadius: "10px",
  width: "auto",
};
const numberStyle = {
  fontFamily: "Kumbh Sans",
  fontSize: "26px",
  fontWeight: "bold",
};

const numberTextStyle = {
  fontFamily: "Kumbh Sans",
  fontSize: "13px",
  opacity: 0.4,
};

const IconStyle = {
  fontSize: "18px",
  color: "#D1AB42",
  alignItems: "end",
  marginBottom: "-4px",
};
