import React from "react";
import { routes } from "./Routes";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

// import Button from '@mui/material/Button';
// import TextField from '@mui/material/TextField';
// import Dialog from '@mui/material/Dialog';
// import DialogActions from '@mui/material/DialogActions';
// import DialogContent from '@mui/material/DialogContent';
// import LockOpenIcon from '@mui/icons-material/LockOpen';
// import DialogTitle from '@mui/material/DialogTitle';
// import { Typography } from "@mui/material";

function App() {

  // const [key, setKey] = React.useState('')
  // const [open, setOpen] = React.useState(false);
  // const [pass, setPass] = React.useState('');
  // const [error, setError] = React.useState(false);

  // const handleClickOpen = () => {
  //   setOpen(true);
  // };

  // const handleClose = () => {
  //   if (key === "breakthrough") {
  //     setPass(key)
  //     sessionStorage.setItem("myKey", "breakthrough");
  //     setOpen(false);

  //   } else {
  //     setOpen(true);
  //     setError(true)
  //   }
  // };

  // React.useEffect(() => {
  //   if (pass) {
  //     setOpen(false);
  //   } else {
  //     handleClickOpen()
  //   }
  // }, [pass])

  // React.useEffect(() => {
  //   setPass(sessionStorage.getItem("myKey"));
  // }, []);


  return (
    <>

      <Router>
        <Routes>
          {routes?.map((data) => (
            <Route key={data.name} path={data.paths} element={data.component}></Route>
          ))}
        </Routes>
      </Router>

      {/* <Dialog open={open}>

        <DialogTitle align='center' sx={{ display: 'flex', justifyContent: 'center' }}>
          <LockOpenIcon />
          <Typography sx={{ fontFamily: "Kumbh Sans", }}>
            Password
          </Typography>
        </DialogTitle>

        <DialogContent>

          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Enter Password"
            type="password"
            fullWidth
            variant="standard"

            value={key}
            onChange={(e) => setKey(e.target.value)}
            onFocus={() => setError(false)}

            error={error}
            helperText={error ? "Invalid Key or Password" : ""}
          />
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose} sx={btnStyle}>Submit</Button>
        </DialogActions>
      </Dialog> 

      {pass === "breakthrough" ? <Router>
        <Routes>
          {routes?.map((data) => (
            <Route key={data.name} path={data.paths} element={data.component}></Route>
          ))}
        </Routes>
      </Router> : null
      }*/}
    </>
  );
}

export default App;


// const btnStyle = { border: "1px solid black", color: 'black', textTransform: 'capitalize', fontFamily: "Kumbh Sans", }