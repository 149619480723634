import React from "react";
import InsideNavbar from "../navbar/InsideNavbar";
import Footer from "../footer";
import homePageCross from "../../assets/images/homePageCross.png"

import { useNavigate, useParams } from "react-router-dom";

import "./events.css";
import "../FirstPage/firstpage.css";
import Admin from "../../assets/icons/admin.png";

// ** MUI
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

// ** Icons
import EmailIcon from "@mui/icons-material/Email";
import { SlLocationPin } from "react-icons/sl";
import { FaFacebookF } from "react-icons/fa";
import { MdOutlineMailOutline } from "react-icons/md";

// ** React-Share
import {
  FacebookShareButton,
  TwitterShareButton,
  // LinkedinShareButton,
  EmailShareButton,
  // InstapaperShareButton,
} from "react-share";

import {
  BsTwitter,
  BsInstagram,
  // BsLinkedin,
  BsTelephone,
} from "react-icons/bs";
import ParticularChurchEvent from "./ParticularEvent";
import StaffMember from "./StaffMebers";
import ChurchAlbum from "./ChurchAlbum";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import axios from "axios";
const baseURL = process.env.REACT_APP_BASEURL;

const ChurchProfile = () => {
  const navigate = useNavigate();
  let { id } = useParams();

  const [ChurchData, setChurchData] = React.useState([]);
  const [show, setShow] = React.useState(false);

  React.useEffect(() => {
    const getChurch = async () => {
      await axios
        .get(`${baseURL}/api/v1/church/getChurch/${id}`)
        .then((response) => {
          setChurchData(response.data.data);
        })
        .catch((error) => {
          console.log(error);
        });
    };
    getChurch(id);
  }, [id]);

  React.useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page
  }, []);

  const fb_shareUrl = window.location.href;

  const handleCopy = () => {
    navigator.clipboard.writeText(fb_shareUrl);
    showToastMessage();
  };

  const showToastMessage = () => {
    toast.success("Copied Church Link.", {
      position: toast.POSITION.BOTTOM_LEFT,
    });
  };

  console.log({ kafhkjfhjkaFD: ChurchData?.user?.role });

  return (
    <>
      <InsideNavbar />

      <Box className="mobile-responsive-body">
        <ChurchAlbum ChurchData={ChurchData} show={show} setShow={setShow} />

        {!show && (
          <>
            <Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexWrap: "wrap",
                }}
              >
                <Typography sx={eventsHeading}>
                  {ChurchData?.user?.fullName}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    marginRight: "20px",
                  }}
                >
                  <Typography className="iconsStyle" sx={paragraph}>
                    Share:
                  </Typography>

                  <EmailShareButton url={fb_shareUrl}>
                    <EmailIcon fontSize="small" className="iconsStyle" />
                  </EmailShareButton>

                  <FacebookShareButton url={fb_shareUrl}>
                    <FaFacebookF className="iconsStyle" />
                  </FacebookShareButton>

                  <TwitterShareButton url={fb_shareUrl}>
                    <BsTwitter className="iconsStyle" />
                  </TwitterShareButton>

                  <BsInstagram className="iconsStyle" onClick={handleCopy} />

                  <ToastContainer />

                  {ChurchData?.user?.role !== "Artist" && (
                    <Button
                      variant="outlined"
                      sx={inqueryBtn}
                      onClick={() =>
                        navigate(
                          `/events/eventRegister/churchDetails/churchInquery/${id}`
                        )
                      }
                    >
                      INQUIRE
                    </Button>
                  )}
                </Box>
              </Box>

              {ChurchData.address?.country && (
                <Typography sx={eventsTextLocation}>
                  <SlLocationPin style={{ fontSize: "13px" }} />
                  &nbsp; {ChurchData.address?.city},
                  <Typography sx={eventsText}>
                    &nbsp; {ChurchData.address?.state},{" "}
                    {ChurchData.address?.country}
                  </Typography>
                </Typography>
              )}

              <Typography sx={paragraph}>{ChurchData?.churchDesc}</Typography>

              {/* Church Timing */}
              {ChurchData?.workingDays?.from && (
                <Typography sx={eventsTextLocation}>
                  Mon-Fri :&nbsp;
                  <Typography sx={eventsText}>
                    {ChurchData?.workingDays?.from}am to&nbsp;
                    {ChurchData?.workingDays?.to}pm&nbsp;
                  </Typography>
                  Weekend :&nbsp;
                  <Typography sx={eventsText}>
                    {ChurchData?.weekendDays?.open ? "Open" : "Close"}
                  </Typography>
                </Typography>
              )}
            </Box>

            <Divider sx={{ marginY: "20px" }} />

            <Typography sx={titleHeading}>Contact Details</Typography>

            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "start",
                gap: "50px",
              }}
            >
              <Box sx={{ marginRight: "50px" }}>
                <Typography sx={eventDetailsSubHeading}>
                  <BsTelephone className="iconStyle2" />
                  {ChurchData?.user?.mobile ? ChurchData?.user?.mobile :  "888-873-8796"}
                 
                </Typography>
                <Typography sx={paragraph}>Contact number</Typography>
              </Box>
              <Box>
                <Typography sx={eventDetailsSubHeading}>
                  <MdOutlineMailOutline className="iconStyle2" />
                  {ChurchData?.user?.email ? ChurchData?.user?.email :  "FaithLensCo@gmail.com"}
                 
                </Typography>
                <Typography sx={paragraph}>Email</Typography>
              </Box>

              <Box>
                {ChurchData?.website && (
                  <>
                    <Typography sx={eventDetailsSubHeading}>
                      <MdOutlineMailOutline className="iconStyle2" />
                      {ChurchData?.website}
                    </Typography>
                    <Typography sx={paragraph}>Website</Typography>
                  </>
                )}
              </Box>
            </Box>

            <>
              <Divider sx={{ marginY: "20px" }} />
              <Typography sx={titleHeading}>
                {ChurchData?.user?.role === "Artist"
                  ? "Artist Details"
                  : ChurchData?.user?.role === "Ministry"
                  ? " Founder"
                  : "Admin Lead"}
              </Typography>

              <Box
                sx={{
                  display: "flex",
                }}
                className="flexMobile"
              >
                <Box sx={{ display: "inline" }}>
                  <img
                    loading="lazy"
                    src={
                      ChurchData?.leadImageUrl
                        ? ChurchData?.leadImageUrl
                        : homePageCross
                    }
                    alt="img"
                    width={140}
                    style={{ borderRadius: "10px" }}
                  />
                </Box>
                <Box sx={{ margin: "20px" }}>
                  <Typography sx={eventDetailsSubHeading}>
                    {ChurchData?.leadName}
                  </Typography>
                  <Typography sx={paragraph}>{ChurchData?.leadDesc}</Typography>
                </Box>
              </Box>
            </>

            {ChurchData?.staff?.length >= 4 && (
              <>
                <Divider sx={{ marginY: "20px" }} />
                <StaffMember ChurchData={ChurchData} />
              </>
            )}
            <Divider sx={{ marginY: "20px" }} />

            <ParticularChurchEvent partcularId={id} />
          </>
        )}
      </Box>

      <Footer />
    </>
  );
};

export default ChurchProfile;

const eventsHeading = {
  fontSize: "1.5rem",
  fontFamily: "Kumbh Sans",
  fontWeight: "bold",
  color: "#000000",
  marginRight: "30px",
};
const paragraph = {
  marginBottom: "3px",
  marginRight: "10px",
  fontFamily: "Kumbh Sans",
  fontSize: "14px",
  opacity: 0.6,
};

const eventDetailsSubHeading = {
  fontFamily: "Kumbh Sans",
  fontSize: "18px",
  fontWeight: "bold",
};

const titleHeading = {
  marginX: "0",
  marginBottom: "20px",
  fontFamily: "Kumbh Sans",
  fontSize: "18px",
  fontWeight: "bold",
};

const eventsText = {
  fontSize: "0.8rem",
  fontFamily: "Kumbh Sans",
  color: "#000000",
};

const eventsTextLocation = {
  fontSize: "0.8rem",
  fontFamily: "Kumbh Sans",
  color: "#000000",
  marginY: "8px",
  fontWeight: "bold",
  display: "flex",
};

const inqueryBtn = {
  ml: 2,
  px: 3,
  py: 1,
  color: "#ffffff",
  background: "#D1AB42",
  textTransform: "capitalize",
  fontFamily: "Kumbh Sans",
  fontSize: "10px",
  border: "1px solid #D1AB42",

  "&:hover": {
    border: "1px solid #3F292B",
    color: "#3F292B",
  },
};
