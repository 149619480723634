import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
// ** router
import { Link } from "react-router-dom";

const index = () => {
  return (
    <Box sx={{ background: "#FAF6F4" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "end",
          height: "50vh",
          fontSize: "2rem",
          fontWeight: "bold",
          color: "#3F292B",
        }}
      >
        404 | Not found
        <br />
      </Box>
      <Link to="/">
        <Typography
          sx={{
            color: "#D1AB42",
            fontSize: "1rem",
            fontWeight: "bold",
            display: "flex",
            justifyContent: "center",
            margin: "1rem",
            alignItems: "start",
            height: "45vh",
            "&:hover": {
              color: "#3F292B",
            },
          }}
        >
          Click to Home
        </Typography>
      </Link>
    </Box>
  );
};

export default index;
