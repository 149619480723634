import React, { Suspense } from "react";
import InsideNavbar from "../navbar/InsideNavbar";
import Footer from "../footer";
import "./registration.css";
import { useNavigate } from "react-router-dom";

// ** images
import Church from "../../assets/images/church1.jpg";
import UserDark from "../../assets/images/userDark1.jpg";

// ** MUI
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

const Index = () => {
  const navigate = useNavigate();

  const textUser = {
    color: "#ffffff",
    position: "absolute",
    top: 30,
    left: 30,
  };

  // const textChurch = {
  //   color: "#ffffff",
  //   position: "absolute",
  //   top: 30,
  //   left: 30,
  // };

  const cardHeading = {
    fontSize: "24px",
    fontWeight: "bold",
    fontFamily: "Kumbh Sans",
    maxWidth: "40px",
  };

  // const cardText = {
  //   fontSize: "13px",
  //   fontFamily: "Kumbh Sans",
  //   opacity: "0.7",
  // };

  const Spinner = () => {
    return (
      <div className="spinner-container">
        <div className="spinner"></div>
      </div>
    );
  };

  return (
    <>
      <InsideNavbar />

      <Suspense fallback={<Spinner />}>
        <Box
          sx={{
            marginY: "5%",
            height: "auto",
            minHeight: "50vh",
          }}
          className="mobile-Body"
        >
          <Typography
            variant="h1"
            align="center"
            sx={{
              fontFamily: "Kumbh Sans",
              fontSize: "30px",
              fontWeight: "bold",
            }}
          >
            Sign Up
          </Typography>
          {/* <Typography
          align="center"
          sx={{
            marginX: "15%",
            marginTop: "13px",
            fontFamily: "Kumbh Sans",
            fontSize: "16px",
            opacity: 0.5,
          }}
        >
          Dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit
          aspernaturaut odit aut fugit, sed quia consequuntur. Dicta sunt
          explicabo. Nemo enim ipsam voluptatem quia voluptas.{" "}
        </Typography> */}

          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              marginY: "30px",
            }}
          >
            <Box
              sx={{ marginRight: "20px", position: "relative" }}
              className="space"
            >
              <Box>
                <img
                  src={Church}
                  width={400}
                  alt="img"
                  className="img-mobile"
                />
              </Box>

              <Typography sx={[cardHeading, textUser]}>
                Church/&nbsp;Artist/ Ministry
              </Typography>
              {/* <Typography sx={cardText} className="card-Text">
                Dicta sunt explicabo. Nemo enim ipsam
                <br /> voluptatem quia volup
              </Typography> */}

              <Button
                onClick={() => navigate("/registrationChurch")}
                variant="outlined"
                className="btn-mobile"
                sx={{
                  color: "#ffffff",
                  background: "#3F292B",
                  textTransform: "capitalize",
                  fontFamily: "Kumbh Sans",
                  fontSize: "10px",
                  padding: "8px 28px",
                  border: "1px solid #3F292B",

                  position: "absolute",
                  bottom: "30px",
                  left: "30px",

                  "&:hover": {
                    border: "1px solid #3F292B",
                    color: "#3F292B",
                  },
                }}
              >
                Register
              </Button>
            </Box>

            <Box sx={{ position: "relative" }}>
              <Box>
                <img
                  src={UserDark}
                  alt="img"
                  width={400}
                  className="img-mobile"
                />
              </Box>
              <Typography sx={[cardHeading, textUser]}>Subscriber</Typography>
              {/* <Typography sx={cardText} className="card-Text">
                Dicta sunt explicabo. Nemo enim
                <br /> ipsam voluptatem quia volup
              </Typography> */}

              <Button
                variant="outlined"
                onClick={() => navigate("/registrationUser")}
                className="btn-mobile"
                sx={{
                  color: "#ffffff",
                  background: "#D1AB42",
                  textTransform: "capitalize",
                  fontFamily: "Kumbh Sans",
                  fontSize: "10px",
                  padding: "8px 28px",
                  border: "1px solid #D1AB42",

                  position: "absolute",
                  bottom: "30px",
                  left: "30px",

                  "&:hover": {
                    border: "1px solid #D1AB42",
                    color: "#D1AB42",
                  },
                }}
              >
                Register
              </Button>
            </Box>
          </Box>
        </Box>
      </Suspense>

      <Footer />
    </>
  );
};

export default Index;
