import React from "react";

// ** MUI
import Box from "@mui/material/Box";

import Typography from "@mui/material/Typography";

import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";

const BlogHead = () => {
  const heading = {
    fontFamily: "Kumbh Sans",
    fontSize: "30px",
    fontWeight: "bold",
    display: "flex",
    justifyContent: "center",
  };

  const paragraph = {
    marginTop: "12px",
    fontFamily: "Kumbh Sans",
    fontSize: "16px",
    opacity: 0.5,
  };

  return (
    <>
      <Box className="mobile-responsive-body">
        <Typography variant="h1" align="center" sx={heading}>
          Our blogs and podcasts
        </Typography>
        {/* <Typography align="center" sx={paragraph}>
          Dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit
          aspernaturaut odit aut fugit, sed quia consequuntur. Dicta sunt
          explicabo. Nemo enim ipsam voluptatem quia voluptas.
        </Typography> */}

        <Box
          align="center"
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            margin: "40px auto",
          }}
        >
          <Paper
            elevation={2}
            sx={{
              pl: "5px",
              display: "flex",
              alignItems: "center",
              width: "400px",
              height: "45px",
              //   boxShadow: "0px 23px 99px #00000017",
            }}
            className="search-width"
          >
            <InputBase sx={{ ml: 1, flex: 1 }} placeholder="Search" />
            <IconButton
              type="button"
              sx={{
                background: "#3F292B",
                borderRadius: "0 8px 5px 0",
                height: "45px",
                "&:hover": {
                  background: "#3F292B",
                },
              }}
            >
              <SearchIcon sx={{ color: "white" }} />
            </IconButton>
          </Paper>
        </Box>
      </Box>
    </>
  );
};

export default BlogHead;
