// ** Icons
import GridViewIcon from "@mui/icons-material/GridView";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import InsertCommentOutlinedIcon from "@mui/icons-material/InsertCommentOutlined";
// import VideocamOutlinedIcon from "@mui/icons-material/VideocamOutlined";
// import HeadsetMicOutlinedIcon from "@mui/icons-material/HeadsetMicOutlined";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import CropOriginalOutlinedIcon from "@mui/icons-material/CropOriginalOutlined";

export const dashboardItem = [
  {
    name: "Dashboard",
    icon: <GridViewIcon fontSize="small" />,
    isHide: "All",
  },
  {
    name: "Our Events",
    icon: <CalendarMonthIcon fontSize="small" />,
    isHide: "All",
  },
  {
    name: "Our Album",
    icon: <CropOriginalOutlinedIcon fontSize="small" />,
    isHide: "All",
  },
  // {
  //   name: "My Videos",
  //   icon: <VideocamOutlinedIcon fontSize="small" />,
  // },
  {
    name: "Inquiry Recieved",
    icon: <InsertCommentOutlinedIcon fontSize="small" />,
    isHide: "Artist",
  },
  {
    name: "Our Staff",
    icon: <PeopleAltOutlinedIcon fontSize="small" />,
    isHide: "Artist",
  },
  // {
  //   name: "Contact us",
  //   icon: <HeadsetMicOutlinedIcon fontSize="small" />,
  // },
  {
    name: "Settings",
    icon: <TuneOutlinedIcon fontSize="small" />,
    isHide: "All",
  },
];
