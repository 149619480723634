import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

import { useFormik } from "formik";
import * as yup from "yup";
import { TextField } from "@mui/material";
import axios from "axios";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function CustomizedDialogs({
  openAddVideo,
  setOpenAddVideo,
  tokenApi,
  baseURL,
}) {
  const [BlogImage, setBlogImage] = React.useState({});
  const [loader, setloader] = React.useState(false);

  const validationSchema = yup.object({
    Title: yup
      .string("Enter Title")
      .min(6, "Min 6 character required")
      .required("Title is required."),

    Description: yup
      .string("Enter Blog Description")
      .required("Description is required."),

    // Image: yup.string("upload image only").required("Image is required."),
  });

  const formik = useFormik({
    initialValues: {
      Title: "",
      Description: "",
      //   Image: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      const { Title, Description } = values;

      const formdata = new FormData();
      formdata.append("title", Title);
      formdata.append("body", Description);
      formdata.append("blogImage", BlogImage);
      formdata.append("isPublished", true);

      addBlog(formdata);
      resetForm({ values: "" });
    },
  });

  const addBlog = (data) => {
    setloader(true);
    const config = {
      headers: { Authorization: `Bearer ${tokenApi}` },
    };

    axios
      .post(`${baseURL}/api/v1/blog`, data, config)
      .then((response) => {
        if (response.data.success) {
          setloader(false);
          setOpenAddVideo(false);
        }
      })
      .catch((error) => {
        console.log(error);
        alert(error);
        setloader(false);
      });
  };

  return (
    <BootstrapDialog
      onClose={() => setOpenAddVideo(false)}
      aria-labelledby="customized-dialog-title"
      open={openAddVideo}
    >
      <form onSubmit={formik.handleSubmit}>
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={() => setOpenAddVideo(false)}
        >
          Add Blog
        </BootstrapDialogTitle>

        <DialogContent dividers>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <Grid item xs={12} sm={6}>
              <Typography sx={labelText}>Blog Title</Typography>
              <TextField
                id="Title"
                name="Title"
                sx={{ width: "100%" }}
                variant="outlined"
                placeholder="Type here"
                value={formik.values.Title}
                onChange={formik.handleChange}
                error={formik.touched.Title && Boolean(formik.errors.Title)}
                helperText={formik.touched.Title && formik.errors.Title}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography sx={labelText}>Blog Image</Typography>
              <TextField
                id="Image"
                type="file"
                name="Image"
                sx={{ width: "100%" }}
                variant="outlined"
                // value={BlogImage}
                onChange={(e) => setBlogImage(e.target.files[0])}
                // error={formik.touched.Image && Boolean(formik.errors.Image)}
                // helperText={formik.touched.Image && formik.errors.Image}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography sx={labelText}>Blog Description</Typography>
            <TextField
              id="Description"
              name="Description"
              sx={{ width: "100%" }}
              variant="outlined"
              multiline
              placeholder="Type here"
              value={formik.values.Description}
              onChange={formik.handleChange}
              error={
                formik.touched.Description && Boolean(formik.errors.Description)
              }
              helperText={
                formik.touched.Description && formik.errors.Description
              }
            />
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button type="submit" sx={registerBtn}>
            {loader ? "Adding wait" : "Add Blog"}
          </Button>
        </DialogActions>
      </form>
    </BootstrapDialog>
  );
}

const labelText = {
  fontSize: "14px",
  fontFamily: "Kumbh Sans",
  fontWeight: 500,
  marginTop: "20px",
  marginBottom: "8px",
};

const uploadPicSection = {
  border: "1px solid",
  width: "90px",
  height: "90px",
  padding: "20px 20px",
  display: "flex",
  borderRadius: 2,
  borderColor: "grey.500",
  borderStyle: "dashed",
  justifyContent: "center",
  margin: "10px 45%",
  flexDirection: "column",
};

const cardHeading1 = {
  color: "#ffffff",
  fontSize: "14px",
  fontWeight: "bold",
  fontFamily: "Kumbh Sans",
};

const cardHeading2 = {
  color: "#ffffff",
  fontSize: "20px",
  fontWeight: "bold",
  fontFamily: "Kumbh Sans",
};

const cardText = {
  color: "#ffffff",
  fontSize: "11px",
  fontFamily: "Kumbh Sans",
  opacity: "0.7",
};

const registerBtn = {
  color: "#ffffff",
  background: "#3F292B",
  fontFamily: "Kumbh Sans",
  fontSize: "10px",
  padding: "9px 18px",
  border: "1px solid #3F292B",
  "&:hover": {
    border: "1px solid #3F292B",
    color: "#3F292B",
  },
};
