import * as React from "react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Box from "@mui/material/Box";
import { Button, Typography, Stack, Divider } from "@mui/material";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import DummyStaffImage from "../../assets/About/staffperson.png";
import moment from "moment";
import axios from "axios";
// import ChurchAlbum from "../../assets/About/ChurchAlbum.png";

export default function Attending({
  setActiveItem,
  activeItem,
  staffDataRedux,
  userData,
  baseURL,
  userRole,
}) {
  const [rows, setRowData] = React.useState([]);

  function createData(name, date, status, speakerDetails) {
    return { name, date, status, speakerDetails };
  }

  React.useEffect(() => {
    const getloginLocalInfo = JSON.parse(
      localStorage.getItem("loginLocalInfo")
    );

    const config = {
      headers: {
        Authorization: `Bearer ${getloginLocalInfo.token}`,
      },
    };

    axios
      .get(
        `${baseURL}/api/v1/church/getChurchEvent?page_no=${1}&records_per_page=${5}`,
        config
      )
      .then((response) => {
        if (response.data.success) {
          const valxxx = response.data.data.map((i) => {
            return {
              render: createData(
                i.name,
                i.fromDate,
                i.status,
                i.speakerDetails?.name
              ),
            };
          });

          setRowData(valxxx);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  // console.log(rows, "data from dashboard");

  return (
    <Box
      sx={{
        borderRadius: "20px",
        padding: "20px",
        background: "#ffffff",
        height: "100%",
        width: "auto",
        margin: "0 10px",
        overflow: "auto",
      }}
      className="AttendingStyle"
    >
      {/* STAFF */}
      {userRole !== "Artist" && (
        <>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            <Typography sx={tableHeading}>Our Staff</Typography>
            <Button
              variant="outlined"
              sx={loginBtn}
              onClick={() => setActiveItem("Our Staff")}
            >
              Edit Info
            </Button>
          </Stack>

          <Box>
            <Box
              sx={{
                display: "flex",
                gap: "10px",
                maxWidth: {
                  xs: "280px",
                  sm: "400px",
                  md: "600px",
                  lg: "700px",
                },
                flexWrap: "wrap",
              }}
            >
              {staffDataRedux?.map((data) => {
                return (
                  <Box
                    sx={{
                      background: "#FAF6F4",
                      borderRadius: "10px",
                      padding: "10px",
                    }}
                    align="center"
                  >
                    <img
                      src={data.imageUrl ? data.imageUrl : DummyStaffImage}
                      alt="staff"
                      width="60px"
                      height="60px"
                      style={{ borderRadius: "10px" }}
                    />
                    <Typography sx={staffName}>{data.name}</Typography>
                    <Typography sx={staffNameRole}>{data.staffRole}</Typography>
                  </Box>
                );
              })}
            </Box>
          </Box>
        </>
      )}

      {/* EVENTS */}
      {rows.length > 0 && (
        <>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            <Typography sx={tableHeading}>Our Events</Typography>
            <Button
              variant="outlined"
              sx={loginBtn}
              onClick={() => setActiveItem("Our Events")}
            >
              Manage
            </Button>
          </Stack>
          <Table sx={{ minWidth: "auto" }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell sx={colHeading}>Event Name</TableCell>
                <TableCell sx={colHeading} align="right">
                  Speaker
                </TableCell>
                <TableCell sx={colHeading} align="right">
                  Start Date & Time
                </TableCell>
                <TableCell sx={colHeading} align="right">
                  Status
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((data) => {
                const row = data.render;
                return (
                  <TableRow
                    key={row.name}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell sx={colData} component="th" scope="row">
                      {row.name}
                    </TableCell>
                    <TableCell sx={colData} align="right">
                      {row.speakerDetails}
                    </TableCell>
                    <TableCell sx={colData} align="right">
                      {moment(row.date).format("lll")}
                    </TableCell>
                    <TableCell sx={colData} align="right">
                      {row.status}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </>
      )}

      {/* ALBUM */}
      <Divider sx={{ marginY: "20px" }} />

      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
      >
        <Typography sx={tableHeading}>Album</Typography>
        <Button
          variant="outlined"
          sx={loginBtn}
          onClick={() => setActiveItem("Our Album")}
        >
          Edit info
        </Button>
      </Stack>

      <ImageList variant="masonry" cols={5} gap={5}>
        {userData?.image?.map((img, ind) => {
          return (
            <ImageListItem key={ind} sx={{ maxWidth: "100px" }}>
              <img
                src={img?.imageUrl}
                alt={`album${ind}`}
                width="100%"
                style={{ borderRadius: "10px" }}
              />
            </ImageListItem>
          );
        })}
      </ImageList>
    </Box>
  );
}

const tableHeading = {
  fontSize: "16px",
  fontFamily: "Kumbh Sans",
  fontWeight: "bold",
  padding: "15px",
};
const colHeading = {
  fontSize: "14px",
  fontFamily: "Kumbh Sans",
  fontWeight: "bold",
};

const colData = {
  fontSize: "12px",
  fontFamily: "Kumbh Sans",
};
const loginBtn = {
  gap: "10px",
  color: "#ffffff",
  background: "#D1AB42",
  textTransform: "capitalize",
  fontFamily: "Kumbh Sans",
  border: "1px solid #D1AB42",
  fontSize: "10px",
  "&:hover": {
    border: "1px solid #3F292B",
    color: "#3F292B",
  },
};

const staffName = {
  fontSize: "11px",
  fontFamily: "Kumbh Sans",
  fontWeight: "bold",
};

const staffNameRole = {
  fontSize: "10px",
  fontFamily: "Kumbh Sans",
};
