import * as React from "react";
import { dashboardItem } from "./dashboardData";

// ** Icons
// import GridViewIcon from "@mui/icons-material/GridView";
// import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
// import InsertCommentOutlinedIcon from "@mui/icons-material/InsertCommentOutlined";
// import VideocamOutlinedIcon from "@mui/icons-material/VideocamOutlined";
// import HeadsetMicOutlinedIcon from "@mui/icons-material/HeadsetMicOutlined";
// import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
// import ListItemText from "@mui/material/ListItemText";
import { Icon, Box } from "@mui/material";
import Typography from "@mui/material/Typography";

export default function VerticalNavbar({ activeItem, setActiveItem }) {
  const menuItem = {
    fontFamily: "Kumbh Sans",
    fontSize: "14px",
    marginX: "5px",
    marginY: "5px",
  };

  const menuItemHover = {
    background: "#3F292B",
    color: "#FAF6F4",
    borderRadius: "10px",
    fontFamily: "Kumbh Sans",
    fontSize: "10px",
    "&:hover": {
      background: "#3F292B",
      color: "#FAF6F4",
    },
  };

  return (
    <Box
      sx={{
        background: "#FAF6F4",
        height: "100vh",
        width: "230px",
        borderRight: "0.3px solid white",
      }}
    >
      <Typography
        variant="h6"
        sx={{ mt: 2, fontFamily: "Kumbh Sans" }}
        align="center"
      >
        Faith Lens
      </Typography>

      <List sx={{ padding: "20px", mt: 3 }}>
        {dashboardItem.map((item, i) => (
          <ListItem
            key={i}
            onClick={() => {
              setActiveItem(item.name);
            }}
            disablePadding
            sx={activeItem === item.name && menuItemHover}
          >
            <ListItemButton
              align="left"
              sx={{ display: "flex", alignItems: "center" }}
              // sx={activeItem === item.name && menuItemHover}
            >
              <Icon>{item.icon}</Icon>
              <Typography sx={menuItem}>{item.name}</Typography>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );
}
