import React from "react";
import Button from "@mui/material/Button";
import { Box, IconButton, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";

import { closeIcon } from "../../navbar/closeIcon";
import axios from "axios";
const baseURL = process.env.REACT_APP_BASEURL;

const Approve = (props) => {
  const { openRejectpopUp, setRejectpopUp, handleClose, particularId, setOpenViewDetialpopUp } = props;

  // ** Rejected
  const rejectEvents = async (id) => {
    const getloginLocalInfo = JSON.parse(
      localStorage.getItem("loginLocalInfo")
    );
    const config = {
      headers: { Authorization: `Bearer ${getloginLocalInfo.token}` },
    };

    await axios
      .put(
        `${baseURL}/api/v1/admin/verifyEvent/${id}`,
        {
          status: "Rejected",
        },
        config
      )
      .then((response) => {
        if (response.data.success) {
          setRejectpopUp(false);
          setOpenViewDetialpopUp(false)
          handleClose();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <Dialog open={openRejectpopUp}>
        <Box align="center" sx={{ padding: "14px" }}>
          <IconButton
            sx={{
              position: "absolute",
              top: "4px",
              right: "4px",
            }}
            onClick={() => setRejectpopUp(false)}
          >
            {closeIcon()}
          </IconButton>

          <Typography
            sx={{
              marginTop: "10px",
              fontFamily: "Kumbh Sans",
              fontSize: "20px",
              fontWeight: "bold",
              color: "darkred",
            }}
          >
            Are you sure you want to Reject event request?
          </Typography>
        </Box>

        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-evenly",
            margin: "0 10px 10px",
          }}
        >
          <Button
            onClick={() => rejectEvents(particularId)}
            variant="contained"
            sx={button2}
            className="button"
          >
            Reject
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Approve;

const button2 = {
  backgroundColor: "#3F292B",
  borderRadius: "8px",
  fonFamily: "Kumbh Sans",
  fontWeight: "bold",
  fontSize: "10px",
  padding: "14px 50px",

  ":hover": {
    backgroundColor: "darkred",
    color: "white",
  },
};
