import React, { useState } from "react";
// import InsideNavbar from "../navbar/InsideNavbar";
// import Footer from "../footer";
// import "./registration.css";
// import SuccessDialog from "./successDialog";

// import { makeStyles } from "@material-ui/core/styles";
import { useNavigate } from "react-router-dom";

import axios from "axios";
import { useFormik } from "formik";
import * as yup from "yup";

// ** images
// import Church from "../../assets/images/church1.jpg";
// import uploadIcon from "../../assets/images/uploadIcon.png";

// ** router
// import { Link } from "react-router-dom";

// ** MUI
import Box from "@mui/material/Box";
// import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { Troubleshoot } from "@mui/icons-material";
// import Select from "@mui/material/Select";
// import MenuItem from "@mui/material/MenuItem";

const baseURL = process.env.REACT_APP_BASEURL;

const SetResetPassword = () => {
  const navigate = useNavigate();
  const [NewPassword, setNewPassword] = React.useState("");
  const [CNewPassword, setCNewPassword] = React.useState("");

  const [ID, setID] = React.useState("");
  const [Code, setCode] = React.useState("");

  const [Error, setError] = React.useState(false);
  const [Success, setSuccess] = React.useState(false);

  const queryParameters = new URLSearchParams(window.location.search);

  React.useEffect(() => {
    setID(queryParameters.get("id"));
    setCode(queryParameters.get("code"));
  }, [queryParameters]);

  const setSubmit = () => {
    if (NewPassword.length >= 8 && NewPassword === CNewPassword) {
      const data = {
        password: NewPassword,
      };
      axios
        .post(`${baseURL}/api/v1/auth/setPAssword/${ID}/${Code}`, data)
        .then((response) => {
          if (response.data.success) {
            setSuccess(true);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setError(true);
    }
  };

  return (
    <Box
      sx={{
        background: "#FAF6F4",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <Box
        sx={{
          background: "white",
          width: "auto",
          padding: "20px",
          borderRadius: "10px",
          display: "flex",
          flexDirection: "column",
          minHeight: "200px",
        }}
      >
        {!Success && (
          <>
            <Typography sx={mainhead}>Faith Lens</Typography>
            <TextField
              autoFocus
              id="password"
              name="password"
              variant="outlined"
              placeholder="New Password"
              value={NewPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              onFocus={() => setError(false)}
            />

            <TextField
              autoFocus
              id="cpassword"
              name="cpassword"
              sx={{ marginY: "20px" }}
              variant="outlined"
              placeholder="Conferm New Password"
              value={CNewPassword}
              onChange={(e) => setCNewPassword(e.target.value)}
              onFocus={() => setError(false)}
            />

            {Error && (
              <Typography sx={error}>
                Password should be minimum 8 characters <br />& both password
                should be same,
              </Typography>
            )}

            <Button onClick={setSubmit} fullWidth sx={Btn}>
              Set Password
            </Button>
          </>
        )}

        {Success && (
          <>
            <Typography sx={mainhead}>
              Password <br />
              Successfully Changed.
            </Typography>

            <Button fullWidth onClick={() => navigate("/")} sx={Btn}>
              Login
            </Button>
          </>
        )}
      </Box>
    </Box>
  );
};

export default SetResetPassword;

const mainhead = {
  color: "#D1AB42",
  fontFamily: "Kumbh Sans",
  fontWeight: "bold",
  fontSize: "22px",
  textAlign: "center",
  margin: "10px",
};

const error = {
  color: "darkred",
  fontFamily: "Kumbh Sans",
  fontSize: "12px",
  textAlign: "center",
  marginBottom: "5px",
};

const Btn = {
  color: "#ffffff",
  background: "#3F292B",
  border: "none",
  textTransform: "capitalize",
  fontFamily: "Kumbh Sans",
  marginBottom: "8px",
};
