import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: [],
  item: "",
  logoutStatus: "",
  staffMemberData: [],
  payToken: "",
  latlng: [],
  load: false,
};

export const userAuthSlice = createSlice({
  name: "userAuth1",
  initialState,
  reducers: {
    userAuth: (state, action) => {
      state.value = action.payload;
    },
    activeVerticalItem: (state, action) => {
      state.item = action.payload;
    },

    logout: (state, action) => {
      state.logoutStatus = action.payload;
    },

    staffMember: (state, action) => {
      state.staffMemberData = action.payload;
    },

    paymentToken: (state, action) => {
      state.payToken = action.payload;
    },

    coordinate: (state, action) => {
      state.latlng = action.payload;
      //console.log("jhdfjksdhgfhsdgdhf gskldjfg", state.latlng)
    },

    eventRefresh: (state, action) => {
      state.load = action.payload;
    },
  },
});

export const {
  userAuth,
  activeVerticalItem,
  logout,
  staffMember,
  paymentToken,
  coordinate,
  eventRefresh,
} = userAuthSlice.actions;

export default userAuthSlice.reducer;
