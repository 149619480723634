import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import "./about.css";
// import WhatWeArePnG from "../../assets/About/WhatWeAre.png";

const AboutFaithlens = () => {
  const headingShort = {
    fontFamily: "Kumbh Sans",
    fontSize: "28px",
    fontWeight: "bold",
    color: "#240C00",
    // color: "#ffffff",
  };

  // const paragraph = {
  //   marginTop: "3px",
  //   fontFamily: "Kumbh Sans",
  //   fontSize: "14px",
  //   color: "#ffffff",
  //   opacity: 0.8,
  //   alignItems: "start",
  // };

  const paragraphText = {
    marginTop: "3px",
    fontFamily: "Kumbh Sans",
    fontSize: "16px",
    // color: "#ffffff",
    color: "#000000",

    opacity: 0.8,
    alignItems: "start",
    maxWidth: "55em",

    whiteSpace: "wrap",
    overflow: "hidden",
    textOverflow: "clip",
  };

  const headingShortText = {
    fontFamily: "Kumbh Sans",
    fontSize: "18px",
    fontWeight: "bold",
    // color: "#ffffff",
    color: "#000000",
  };

  return (
    <>
      <Box
        sx={{
          // backgroundImage: `url(${WhatWeArePnG})`,
          // background: "white",
          // backgroundRepeat: "no-repeat",
          // backgroundSize: "cover",
          // backgroundPosition: "bottom",
          minHeight: "700px",
        }}
        className="mobile-responsive-body eventss"
      >
        <Typography sx={paragraphText}>About Us</Typography>
        <Typography sx={headingShort}>
          Helping Churches Succeed Through the Power of Our Platform{" "}
        </Typography>

        <Box className="WhoWeAreStyle">
          <Typography sx={paragraphText}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;On
            April 2nd, 2016, our founder Karen Gustave was sitting in the church
            cafeteria, scrolling through social media, and realized she had
            missed the youth explosion service at another church. At this time
            church events were mainly notified through social media or old,
            rarely updated church websites. The painful realization was that not
            only did she miss the service, but she felt lost trying to find
            spiritual resources such as bible study groups and prayer ministries
            since the Christian community lacked a centralized platform.
          </Typography>
          <br />

          <Typography sx={paragraphText}>
            And it was at that moment, God placed it on her heart to create what
            we now have as Faith Lens. The solution to communication amongst the
            Christian community
            <Typography sx={headingShortText}>GLOBALLY.</Typography>
          </Typography>
          <br />

          <Typography sx={paragraphText}>
            In 2016, we started out as the Advent Calendar and by 2017, it was
            an app called The Religious Square on all app stores. Now after our
            final rebrand, in 2023, we’re “Faith Lens, the digital Christian
            platform hub”.
          </Typography>
          <br />

          <Typography sx={headingShortText}>
            <i>
              Sticking with us is sticking with Jesus and hunny, we’re going
              places!
            </i>
          </Typography>

          {/* <Typography sx={paragraphText}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;It
            was April 2nd, 2016, and our founder Karen Gustave was bored in the
            church cafeteria. She saw through social media that other youth had
            gone to another youth church service somewhere in New York. Upset
            and annoyed because she felt unfulfilled at church that day, due to
            the language barriers between French vs. English. She wasn’t
            notified because she did not follow that particular church’s social
            media account. And it was at that moment, God placed it on her heart
            to create what we now have as Faith Lens. The solution to no longer
            having a central platform for all Christian churches to post their
            services, bible studies, prayer groups, conferences and so much
            more. A religious network was founded and started out as the Advent
            Calendar in 2016. By 2017, it was an app called The Religious Square
            on all app stores. And now after our final rebrand, we are here in
            2023, as Faith Lens, the digital Christian platform hub.
            <Typography sx={headingShortText}>
              Stick with us, we’re going places
            </Typography>
          </Typography> */}

          {/* <Typography sx={headingShort}>Who we are</Typography> */}

          {/* <Typography sx={paragraph}>
            Qnsectetur adipiscing elit, sed do eiusm onsectetur adipiscing elit,
            <br />
            eiusm od tempor ut labore. ectetur adipiscing elit, sed do eiusm
            <br />
            onsectetur adipiscing elit, sed do eiusm od tempo
          </Typography> */}
        </Box>

        {/*  <Box>
          <Typography sx={headingShort}>What we believe</Typography>
           <Typography sx={paragraph}>
            Qnsectetur adipiscing elit, sed do eiusm onsectetur adipiscing elit,
            <br />
            eiusm od tempor ut labore. ectetur adipiscing elit, sed do eiusm
            <br />
            onsectetur adipiscing elit, sed do eiusm od tempo
          </Typography> 
        </Box>*/}
      </Box>
    </>
  );
};

export default AboutFaithlens;
