import React from "react";
import "./adminProfile.css";

// ** Page Content
import DashboardHeader from "./DashboardHeader";

// import { useSelector } from "react-redux";
// import { useNavigate } from "react-router-dom";

import InsideNavbarLight from "../navbar/InsideNavbarLight";
// import Navbar from "./Navbar";
import Footer from "../footer";
import VerticalNavbar from "./verticalNavbar";
// import UserPicFile from "./userPicFile";
// import AttendingEventsList from "./AttendingEventsList";
import MyVideos from "./myVideos";
// import ContactUS from "./contactUs";
import Inquiries from "./Inquiries";
import Revenue from "./Revenue";
import AdminSetting from "./AdminSetting";

// ** MUI
import Box from "@mui/material/Box";
import SiteEvents from "./SiteEvents";
// import TubeVideo from "./TubeVideo";
import ChurchRequest from "./ChurchRequest";
import axios from "axios";

// import axios from "axios";
const baseURL = process.env.REACT_APP_BASEURL;

const Index = () => {
  const [activeItem, setActiveItem] = React.useState("Dashboard");
  const [tokenAPI, setTokenAPI] = React.useState("");
  const [CountDataChurch, setCountDataChurch] = React.useState("");
  const [CountDataEvent, setCountDataEvent] = React.useState("");
  const [RevenueCount, setRevenueCount] = React.useState();

  // ** Getting from Redux
  // const userDetails = useSelector((state) => state.userAuth.value);

  // ** User Local Auth
  const [userData, setUserData] = React.useState({});
  // const navigate = useNavigate();

  // ** Check Role Auth
  React.useEffect(() => {
    const getloginLocalInfo = JSON.parse(
      localStorage.getItem("loginLocalInfo")
    );

    const config = {
      headers: {
        Authorization: `Bearer ${getloginLocalInfo.token}`,
      },
    };

    setUserData(getloginLocalInfo);
    setTokenAPI(getloginLocalInfo.token);
    getDashboardCount_MonthRegisterChurch(config);
    getDashboardCount_MonthRegisterEvent(config);
    getDashboardCount_RevenueCount(config);

    // if (getloginLocalInfo?.role === "Admin") {
    //   navigate("/superAdminProfile");
    // }

    // if (getloginLocalInfo?.role === "Church" || "User") {
    //   navigate("/");
    // }
    //   if (getloginLocalInfo?.role === "User") {
    //     navigate("/userProfile");
    //   }
  }, []);

  const getDashboardCount_MonthRegisterChurch = async (config1) => {
    await axios
      .get(`${baseURL}/api/v1/admin/getThisMonthRegisteredChurch`, config1)
      .then((response) => {
        if (response.data.success) {
          setCountDataChurch(response.data?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getDashboardCount_MonthRegisterEvent = async (config1) => {
    await axios
      .get(`${baseURL}/api/v1/admin/getThisMonthRegisteredEvent`, config1)
      .then((response) => {
        if (response.data.success) {
          setCountDataEvent(response.data?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getDashboardCount_RevenueCount = async (config1) => {
    await axios
      .get(`${baseURL}/api/v1/admin/getThisMonthRevenue`, config1)
      .then((response) => {
        if (response.data.success) {
          setRevenueCount(response.data?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <Box
        className="mobile-body"
        sx={{ display: "flex", background: "#FAF6F4" }}
      >
        <Box sx={{ display: { xs: "none", sm: "none", md: "block" } }}>
          <VerticalNavbar
            activeItem={activeItem}
            setActiveItem={setActiveItem}
          />
        </Box>
        <Box sx={{ width: "100%" }}>
          {/* <Navbar activeItem={activeItem} setActiveItem={setActiveItem} /> */}
          <InsideNavbarLight />

          {activeItem === "Dashboard" && (
            <Box className="admin-responsive-body">
              <DashboardHeader
                CountDataChurch={CountDataChurch}
                CountDataEvent={CountDataEvent}
                RevenueCount={RevenueCount}
              />
              <SiteEvents
                activeItem={activeItem}
                setActiveItem={setActiveItem}
                tokenAPI={tokenAPI}

              />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
                className="dashboard-table profile-body"
              >
                {/* <TubeVideo /> */}
                <ChurchRequest
                  activeItem={activeItem}
                  setActiveItem={setActiveItem}
                />
              </Box>
            </Box>
          )}

          {activeItem === "Events" && (
            <Box className="admin-responsive-body">
              <SiteEvents />
            </Box>
          )}

          {activeItem === "Church requests" && (
            <Box className="admin-responsive-body">
              <ChurchRequest />
            </Box>
          )}

          {activeItem === "Videos" && (
            <Box className="admin-responsive-body">
              <MyVideos />
            </Box>
          )}

          {activeItem === "Settings" && (
            <Box className="admin-responsive-body">
              <AdminSetting
                userData={userData}
                tokenAPI={tokenAPI}
                baseURL={baseURL}
              />
            </Box>
          )}

          {activeItem === "Revenue" && (
            <Box className="admin-responsive-body">
              <Revenue userData={userData} />
            </Box>
          )}

          {activeItem === "Inquiries" && (
            <Box className="admin-responsive-body">
              <Inquiries userData={userData} />
            </Box>
          )}
        </Box>
      </Box>
      <Footer />
    </>
  );
};

export default Index;
