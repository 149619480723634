import Blog1 from "../../assets/images/blog1.png";
import Blog2 from "../../assets/images/blog1.png";
import Blog3 from "../../assets/images/blog1.png";
import DP from "../../assets/About/contactus2.png";

export const myUploadVideos = [
  {
    title: "Sermon at Saints Peter",
    img: DP,
    imgName: "The Saint Peters Union",
    video: Blog1,
    time: "1h 15min | 15 hours ago",
  },
  {
    title: "Sermon at Saints Peter",
    img: DP,
    imgName: "The Saint Peters Union",
    video: Blog2,
    time: "1h 15min | 15 hours ago",
  },
  {
    title: "Sermon at Saints Peter",
    img: DP,
    imgName: "The Saint Peters Union",
    video: Blog3,
    time: "1h 15min | 15 hours ago",
  },
  {
    title: "Sermon at Saints Peter",
    img: DP,
    imgName: "The Saint Peters Union",
    video: Blog1,
    time: "1h 15min | 15 hours ago",
  },

  {
    title: "Sermon at Saints Peter",
    img: DP,
    imgName: "The Saint Peters Union",
    video: Blog2,
    time: "1h 15min | 15 hours ago",
  },

  {
    title: "Sermon at Saints Peter",
    img: DP,
    imgName: "The Saint Peters Union",
    video: Blog3,
    time: "1h 15min | 15 hours ago",
  },
  {
    title: "Sermon at Saints Peter",
    img: DP,
    imgName: "The Saint Peters Union",
    video: Blog1,
    time: "1h 15min | 15 hours ago",
  },

  {
    title: "Sermon at Saints Peter",
    img: DP,
    imgName: "The Saint Peters Union",
    video: Blog2,
    time: "1h 15min | 15 hours ago",
  },
];
