import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

// import { useFormik } from "formik";
// import * as yup from "yup";
import { TextField } from "@mui/material";
import axios from "axios";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function CustomizedDialogs({
  openAddVideo,
  setOpenAddVideo,
  baseURL,
  tokenApi,
  Loader,
  setLoader,
}) {
  const [videoTitle, setVideoTitle] = React.useState("");
  const [videoImage, setVideoImage] = React.useState("");
  const [video, setVideo] = React.useState("");

  const [Loaderr, setLoaderr] = React.useState(false);

  const addVideos = () => {
    setLoaderr(true);

    const config = {
      headers: { Authorization: `Bearer ${tokenApi}` },
    };

    const formData = new FormData();
    formData.append("videoTitle", videoTitle);
    formData.append("video", video);

    axios
      .post(`${baseURL}/api/v1/church/uploadChurchVideos`, formData, config)
      .then((res) => {
        if (res.data.success) {
          // alert("upload");
          setLoader(true);
          setLoaderr(false);
          setOpenAddVideo(false);
        }
      })
      .catch((err) => {
        console.log(err);
        // alert("not");
        setLoader(false);
      });
  };

  return (
    <BootstrapDialog
      onClose={() => setOpenAddVideo(false)}
      aria-labelledby="customized-dialog-title"
      open={openAddVideo}
    >
      {/* <form onSubmit={formik.handleSubmit}> */}
      <BootstrapDialogTitle
        id="customized-dialog-title"
        onClose={() => setOpenAddVideo(false)}
      >
        Add My Video
      </BootstrapDialogTitle>

      <DialogContent dividers>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs={12} sm={6}>
            <Typography sx={labelText}>Video Title</Typography>
            <TextField
              id="staffMember"
              name="staffMember"
              sx={{ width: "100%" }}
              variant="outlined"
              placeholder="Type here"
              value={videoTitle}
              onChange={(e) => setVideoTitle(e.target.value)}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Typography sx={labelText}>Thumbnail Image</Typography>
            <TextField
              id="thumbnailImage"
              type="file"
              name="thumbnailImage"
              sx={{ width: "100%" }}
              variant="outlined"
              value={videoImage}
              onChange={(e) => setVideoImage(e.target.value)}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography sx={labelText}>Video</Typography>
          <TextField
            id="video"
            type="file"
            name="video"
            sx={{ width: "100%" }}
            variant="outlined"
            // value={video}
            onChange={(e) => setVideo(e.target.files[0])}
          />
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button onClick={addVideos} sx={registerBtn}>
          {Loaderr ? "Wait, Video Uploading..." : "Upload"}
        </Button>
      </DialogActions>
      {/* </form> */}
    </BootstrapDialog>
  );
}

const labelText = {
  fontSize: "14px",
  fontFamily: "Kumbh Sans",
  fontWeight: 500,
  marginTop: "20px",
  marginBottom: "8px",
};

const registerBtn = {
  color: "#ffffff",
  background: "#3F292B",
  fontFamily: "Kumbh Sans",
  fontSize: "10px",
  padding: "9px 18px",
  border: "1px solid #3F292B",
  "&:hover": {
    border: "1px solid #3F292B",
    color: "#3F292B",
  },
};
