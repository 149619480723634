import * as React from "react";
import moment from "moment";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Box from "@mui/material/Box";
import { Button, Typography, Stack, Pagination } from "@mui/material";
// import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

// import Snackbar from "@mui/material/Snackbar";
// import MuiAlert from "@mui/material/Alert";

// ** Action Button
// import MenuItem from "@mui/material/MenuItem";
// import DeleteSweepOutlinedIcon from "@mui/icons-material/DeleteSweepOutlined";
// import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
// import OpenInNewOutlinedIcon from "@mui/icons-material/OpenInNewOutlined";
// import { StyledMenu } from "./ActionButtonStyle";

// ** Popups
// import ViewDetailsPopup from "./AllPopUp/ViewEventDetailsPopup";
// import ApproveEventPopup from "./AllPopUp/ApproveEventPopUp";
// import RejectEventPopup from "./AllPopUp/RejectEventPopUp";

import axios from "axios";
const baseURL = process.env.REACT_APP_BASEURL;

// const Alert = React.forwardRef(function Alert(props, ref) {
//   return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
// });

function Inquiries({ activeItem, setActiveItem }) {
  // const [openToastReject, setOpenToastReject] = React.useState(false);
  // const [openToastSuccess, setOpenToastSuccess] = React.useState(false);

  // const handleCloseToastSuccess = (event, reason) => {
  //   if (reason === "clickaway") {
  //     return;
  //   }
  //   setOpenToastSuccess(false);
  // };

  // const handleCloseToastReject = (event, reason) => {
  //   if (reason === "clickaway") {
  //     return;
  //   }
  //   setOpenToastReject(false);
  // };
  // const [openViewDetialpopUp, setOpenViewDetialpopUp] = React.useState(false);
  const [openApprovepopUp, setApprovepopUp] = React.useState(false);
  const [openRejectpopUp, setRejectpopUp] = React.useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);
  // const [CurrentId, setCurrentId] = React.useState("");
  // const open = Boolean(anchorEl);
  // const handleClick = (event, id) => {
  //   // console.log("kkkkkkk", event.currentTarget);
  //   setAnchorEl(event.currentTarget);
  //   setCurrentId(id);
  //   setOpenViewDetialpopUp(true);
  // };

  // const handleClose = () => {
  //   setAnchorEl(null);
  // };

  // *** Pagination
  const options = [2, 5, 10, 15, 20];
  const [value, setValue] = React.useState(options[2]);
  const [page, setPage] = React.useState(1);
  const [total, setTotal] = React.useState();

  let count = Math.ceil(total / value);
  let start = (page - 1) * value;
  let last = Math.min(start + value - 1, total - 1);

  const [rowx, setRowX] = React.useState([]);

  function createData(id, name, email, query, time) {
    return { id, name, email, query, time };
  }

  React.useEffect(() => {
    const getloginLocalInfo = JSON.parse(
      localStorage.getItem("loginLocalInfo")
    );
    const config = {
      headers: { Authorization: `Bearer ${getloginLocalInfo.token}` },
    };

    const getAllEventsRequest = async () => {
      await axios
        .get(
          `${baseURL}/api/v1/admin/getAllContacts?page_no=${page}&records_per_page=${value}`,
          config
        )
        .then((response) => {
          if (response.data.success) {
            setTotal(response.data.count);
            const valx = response.data.data.map((i) => {
              return {
                rend: createData(i._id, i.name, i.email, i.query, i.fromDate),
              };
            });
            // console.log({ valx });
            setRowX(valx);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };
    getAllEventsRequest();
  }, [anchorEl, openRejectpopUp, openApprovepopUp, page]);

  return (
    <Box
      sx={{
        borderRadius: "20px",
        marginY: "25px",
        padding: "20px",
        background: "#ffffff",
        overflow: "auto",
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
      >
        <Typography sx={tableHeading}>Inquiries Recieved By Email</Typography>
        <Box>
          {/* <Button variant="outlined" sx={darkBtn}>
            Add new event
          </Button> */}

          <Button
            variant="outlined"
            sx={activeItem === "Events" ? { display: "none" } : loginBtn}
            onClick={() => setActiveItem("Events")}
          >
            View all
          </Button>
        </Box>
      </Stack>

      <Table sx={{ minWidth: "auto" }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell sx={colHeading} align="left">
              Name
            </TableCell>
            <TableCell sx={colHeading} align="left">
              Email
            </TableCell>

            <TableCell sx={colHeading} align="left">
              Query
            </TableCell>

            <TableCell sx={colHeading} align="left">
              Start date & Time
            </TableCell>

            {/* <TableCell sx={colHeading} align="left">
              Status
            </TableCell>

            <TableCell sx={colHeading} align="left">
              Request
            </TableCell>

            <TableCell sx={colHeading} align="left">
              Action
            </TableCell> */}
          </TableRow>
        </TableHead>

        <TableBody>
          {rowx.map((rown, i) => {
            const row = rown.rend;
            // const idd = rown.id;
            // console.log(row.id, "<< iiddiidd");
            return (
              <TableRow
                key={i}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell sx={colData} component="th" scope="row">
                  {row.name}
                </TableCell>

                <TableCell sx={colData} align="left">
                  {row.email}
                </TableCell>

                <TableCell sx={colData} align="left">
                  {row.query}
                </TableCell>

                <TableCell sx={colData} align="left">
                  {moment(row.time).format("lll")}
                </TableCell>

                {/* <TableCell sx={colData} align="left">
                  {"231 attending, 23 May attend"}
                </TableCell>

                <TableCell align="left">
                  {row.status === "Pending" ? (
                    <Typography sx={colDataPending}>{row.status}</Typography>
                  ) : row.status === "Approved" ? (
                    <Typography sx={colDataApproved}>{row.status}</Typography>
                  ) : (
                    <Typography sx={colDataRejected}>{row.status}</Typography>
                  )}
                </TableCell>

                <TableCell sx={colData} align="left">
                  <Button
                    id="demo-customized-button"
                    aria-controls={open ? "demo-customized-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    disableElevation
                    variant="outlined"
                    sx={darkBtn}
                    onClick={(event) => handleClick(event, row.id)}
                  >
                    View
                  </Button> */}

                {/* <StyledMenu
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                  >
                    <MenuItem onClick={() => setOpenViewDetialpopUp(true)}>
                      <OpenInNewOutlinedIcon />
                      View Details
                    </MenuItem>

                    <MenuItem onClick={() => setApprovepopUp(true)}>
                      <CheckCircleOutlineOutlinedIcon />
                      Approve
                    </MenuItem>

                    <MenuItem onClick={() => setRejectpopUp(true)}>
                      <DeleteSweepOutlinedIcon />
                      Reject
                    </MenuItem>
                  </StyledMenu> 
                </TableCell>*/}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>

      <Stack direction="row" justifyContent="space-between">
        <Typography sx={paginationStyle}>
          Showing {total === 0 ? 0 : start + 1} to {last + 1} of {total} entries
        </Typography>

        <Pagination
          count={count}
          page={page}
          onChange={(event, newValue) => {
            setPage(newValue);
          }}
        />
      </Stack>

      {/* <Snackbar
        open={openToastReject}
        autoHideDuration={2000}
        onClose={handleCloseToastReject}
      >
        <Alert
          onClose={handleCloseToastReject}
          severity="error"
          sx={{ width: "100%" }}
        >
          Event Rejected
        </Alert>
      </Snackbar>

      <Snackbar
        open={openToastSuccess}
        autoHideDuration={2000}
        onClose={handleCloseToastSuccess}
      >
        <Alert
          onClose={handleCloseToastSuccess}
          severity="success"
          sx={{ width: "100%" }}
        >
          Event Approved
        </Alert> 
      </Snackbar> */}

      {/* <ViewDetailsPopup
        handleClose={handleClose}
        openViewDetialpopUp={openViewDetialpopUp}
        setOpenViewDetialpopUp={setOpenViewDetialpopUp}
        particularId={CurrentId}
        openApprovepopUp={openApprovepopUp}
        setApprovepopUp={setApprovepopUp}
        openRejectpopUp={openRejectpopUp}
        setRejectpopUp={setRejectpopUp}
      />

      <ApproveEventPopup
        handleClose={handleClose}
        openApprovepopUp={openApprovepopUp}
        setApprovepopUp={setApprovepopUp}
        particularId={CurrentId}
        setOpenViewDetialpopUp={setOpenViewDetialpopUp}
        openViewDetialpopUp={openViewDetialpopUp}
      />

      <RejectEventPopup
        handleClose={handleClose}
        openRejectpopUp={openRejectpopUp}
        setRejectpopUp={setRejectpopUp}
        particularId={CurrentId}
        setOpenViewDetialpopUp={setOpenViewDetialpopUp}
        openViewDetialpopUp={openViewDetialpopUp}
      /> */}
    </Box>
  );
}

export default Inquiries;

const tableHeading = {
  fontSize: "16px",
  fontFamily: "Kumbh Sans",
  fontWeight: "bold",
  padding: "15px",
};
const colHeading = {
  fontSize: "14px",
  fontFamily: "Kumbh Sans",
  fontWeight: "bold",
};

const colData = {
  fontSize: "12px",
  fontFamily: "Kumbh Sans",
};

const paginationStyle = {
  fontSize: "12px",
  fontFamily: "Kumbh Sans",
};

// const colDataPending = {
//   fontSize: "12px",
//   fontFamily: "Kumbh Sans",
//   color: "orange",
// };

// const colDataRejected = {
//   fontSize: "12px",
//   fontFamily: "Kumbh Sans",
//   color: "red",
// };

// const colDataApproved = {
//   fontSize: "12px",
//   fontFamily: "Kumbh Sans",
//   color: "green",
// };

// const darkBtn = {
//   fontSize: "10px",
//   color: "#ffffff",
//   background: "#3F292B",
//   textTransform: "capitalize",
//   fontFamily: "Kumbh Sans",
//   border: "1px solid #3F292B",
//   mr: 1,
//   "&:hover": {
//     border: "1px solid #D1AB42",
//     background: "#D1AB42",
//   },
// };

const loginBtn = {
  gap: "10px",
  color: "#ffffff",
  background: "#D1AB42",
  textTransform: "capitalize",
  fontFamily: "Kumbh Sans",
  border: "1px solid #D1AB42",
  fontSize: "10px",
  "&:hover": {
    border: "1px solid #3F292B",
    color: "#3F292B",
  },
};
