import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Switch from "@mui/material/Switch";

import { useFormik } from "formik";
import * as yup from "yup";
import { TextField } from "@mui/material";
import axios from "axios";
import { Box } from "@mui/system";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function CustomizedDialogs({
  ViewOpen,
  setViewOpen,
  tokenApi,
  baseURL,
  ViewData,
}) {
  const [BlogImage, setBlogImage] = React.useState({});
  const [BlogImage1, setBlogImage1] = React.useState({});
  const [image, setImage] = React.useState(false);

  const [BlogTitle, setBlogTitle] = React.useState("");
  const [BlogBody, setBlogBody] = React.useState("");

  //   const [BlogPublish, setBlogPublish] = React.useState("");
  const [BlogPublishChecked, setBlogPublishChecked] = React.useState(false);

  const [loader, setloader] = React.useState(false);
  const [DeleteClick, setDeleteClick] = React.useState(false);

  React.useEffect(() => {
    setBlogTitle(ViewData.title);
    setBlogBody(ViewData.body);
    setBlogImage(ViewData.imageUrl);
    setBlogPublishChecked(ViewData.isPublished);
  }, [ViewData]);

  const onEditClick = () => {
    setloader(true);
    const config = {
      headers: { Authorization: `Bearer ${tokenApi}` },
    };

    const data = {
      title: BlogTitle,
      body: BlogBody,
      isPublished: BlogPublishChecked,
    };

    axios
      .put(`${baseURL}/api/v1/blog/${ViewData._id}`, data, config)
      .then((response) => {
        if (response.data.success) {
          setloader(false);
          setViewOpen(false);
        }
      })
      .catch((error) => {
        console.log(error);
        alert(error);
        setloader(false);
      });
  };

  const onDeleteClick = () => {
    setloader(true);
    const config = {
      headers: { Authorization: `Bearer ${tokenApi}` },
    };

    // const data = {
    //   title: BlogTitle,
    //   body: BlogBody,
    //   isPublished: BlogPublish,
    // };

    axios
      .delete(`${baseURL}/api/v1/blog/${ViewData._id}`, config)
      .then((response) => {
        if (response.data.success) {
          setloader(false);

          setDeleteClick(false);
          setViewOpen(false);
        }
      })
      .catch((error) => {
        console.log(error);
        alert(error);
        setloader(false);
      });
  };

  const onAddImage = (file) => {
    setBlogImage1(file.target.files[0]);
    setImage(true);
  };

  React.useEffect(() => {
    if (image) {
      const config = {
        headers: { Authorization: `Bearer ${tokenApi}` },
      };

      const data = new FormData();
      data.append("blogImage", BlogImage1);

      axios
        .put(`${baseURL}/api/v1/blog/updateImage/${ViewData._id}`, data, config)
        .then((response) => {
          if (response.data.success) {
            //   setloader(false);
            //   setDeleteClick(false);
            //   setViewOpen(false);
            setImage(false);
          }
        })
        .catch((error) => {
          console.log(error);
          //   alert(error);
          //   setloader(false);
          setImage(false);
        });
    }
  }, [image]);

  // console.log("BlogPublishChecked", BlogPublishChecked);

  return (
    <BootstrapDialog
      onClose={() => setViewOpen(false)}
      aria-labelledby="customized-dialog-title"
      open={ViewOpen}
    >
      {/* <form onSubmit={formik.handleSubmit}> */}
      <BootstrapDialogTitle
        id="customized-dialog-title"
        onClose={() => setViewOpen(false)}
      >
        Edit Blog
      </BootstrapDialogTitle>

      <DialogContent dividers>
        <Box align="center" component="label">
          <Box>
            <img
              src={BlogImage}
              alt="kk"
              style={{ maxHeight: "200px", width: "300px" }}
            />
            <TextField
              type="file"
              onChange={(e) => {
                onAddImage(e);
              }}
            />
          </Box>
        </Box>

        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs={12} sm={12}>
            <Typography sx={labelText}>Blog Title</Typography>
            <TextField
              id="Title"
              name="Title"
              sx={{ width: "100%" }}
              variant="outlined"
              placeholder="Type here"
              value={BlogTitle}
              onChange={(e) => setBlogTitle(e.target.value)}
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <Typography sx={labelText}>Blog Publish</Typography>
            <Switch
              checked={BlogPublishChecked}
              onChange={() => setBlogPublishChecked(!BlogPublishChecked)}
              inputProps={{ "aria-label": "controlled" }}
            />
          </Grid>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Typography sx={labelText}>Blog Description</Typography>
          <TextField
            id="Description"
            name="Description"
            sx={{ width: "100%" }}
            variant="outlined"
            multiline
            placeholder="Type here"
            value={BlogBody}
            onChange={(e) => setBlogBody(e.target.value)}
          />
        </Grid>
      </DialogContent>

      <DialogActions>
        {/* <Button onClick={onEditClick} sx={publishBtn}>
          {BlogPublish ? "Published" : "Publish It"}
        </Button> */}

        <Button onClick={onEditClick} sx={registerBtn}>
          {loader ? "Updating...." : "Update"}
        </Button>

        <Button onClick={() => setDeleteClick(true)} sx={deleteBtn}>
          Delete
        </Button>
      </DialogActions>

      <Dialog
        open={DeleteClick}
        onClose={() => DeleteClick(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Are you sure, you want to delete this blog ?
        </DialogTitle>

        <DialogActions>
          <Button onClick={() => setDeleteClick(false)} sx={registerBtn}>
            Cancel
          </Button>
          <Button onClick={onDeleteClick} sx={deleteBtn}>
            {loader ? "Deleting..." : "Delete"}
          </Button>
        </DialogActions>
      </Dialog>
    </BootstrapDialog>
  );
}

const labelText = {
  fontSize: "14px",
  fontFamily: "Kumbh Sans",
  fontWeight: 500,
  marginTop: "20px",
  marginBottom: "8px",
};

// const uploadPicSection = {
//   border: "1px solid",
//   width: "90px",
//   height: "90px",
//   padding: "20px 20px",
//   display: "flex",
//   borderRadius: 2,
//   borderColor: "grey.500",
//   borderStyle: "dashed",
//   justifyContent: "center",
//   margin: "10px 45%",
//   flexDirection: "column",
// };

// const cardHeading1 = {
//   color: "#ffffff",
//   fontSize: "14px",
//   fontWeight: "bold",
//   fontFamily: "Kumbh Sans",
// };

// const cardHeading2 = {
//   color: "#ffffff",
//   fontSize: "20px",
//   fontWeight: "bold",
//   fontFamily: "Kumbh Sans",
// };

// const cardText = {
//   color: "#ffffff",
//   fontSize: "11px",
//   fontFamily: "Kumbh Sans",
//   opacity: "0.7",
// };

const registerBtn = {
  color: "#ffffff",
  background: "#3F292B",
  fontFamily: "Kumbh Sans",
  fontSize: "10px",
  padding: "9px 18px",
  border: "1px solid #3F292B",
  "&:hover": {
    border: "1px solid #3F292B",
    color: "#3F292B",
  },
};

const publishBtn = {
  color: "#ffffff",
  background: "darkgreen",
  fontFamily: "Kumbh Sans",
  fontSize: "10px",
  padding: "9px 18px",
  border: "1px solid darkgreen",
  "&:hover": {
    border: "1px solid #3F292B",
    color: "#3F292B",
  },
};

const deleteBtn = {
  color: "#ffffff",
  background: "darkred",
  fontFamily: "Kumbh Sans",
  fontSize: "10px",
  padding: "9px 18px",
  border: "1px solid darkred",
  "&:hover": {
    border: "1px solid #3F292B",
    color: "#3F292B",
  },
};
