import * as React from "react";
import "./footer.css";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
// import Divider from "@mui/material/Divider";
// import { styled } from "@mui/material/styles";
import Loginform from "../navbar/Loginform";
import { useNavigate } from "react-router-dom";
// import { FaFacebookF } from "react-icons/fa";
// import { BsTwitter, BsInstagram } from "react-icons/bs";
import { BsInstagram } from "react-icons/bs";

// import { Link } from "react-router-dom";

export default function Footer() {
  const [open, setOpen] = React.useState(false);
  const getloginLocalInfo = JSON.parse(localStorage.getItem("loginLocalInfo"));

  const navigate = useNavigate();
  return (
    <Box
      sx={{
        flexGrow: 2,
        color: "#ffffff",
        backgroundColor: "#240C00",
        // height: "auto",
        fontFamily: "Kumbh Sans",
        padding: "25px 60px",
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={3}>
          <Typography
            onClick={() => navigate("/")}
            sx={{
              color: "#ffffff",
              fontFamily: "Kumbh Sans",
              fontSize: "17px",
              fontWeight: "bold",
              cursor: "pointer",
            }}
          >
            Faith Lens
          </Typography>
          <Typography sx={sloganHeading}>
            We'll Be Happy <br />
            To Help!
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={3}
          sx={{
            flexDirection: "column",
            spacing: "5",
            textDecoration: "none",
            color: "white",
          }}
        >
          <Typography sx={mainLinks}>QUICK LINKS</Typography>
          <Link
            href="#"
            underline="none"
            sx={links}
            onClick={() => navigate("/")}
          >
            Home
          </Link>
          <br />

          {/*  <Link
            href="#"
            underline="none"
            sx={links}
            onClick={() => navigate("/events")}
          >
            Events
          </Link>
          <br />
          <Link
            href="#"
            underline="none"
            sx={links}
            onClick={() => navigate("/media")}
          >
            Media
          </Link>
          <br />
          <Link
            href="#"
            underline="none"
            sx={links}
            onClick={() => navigate("/blog-podcast")}
          >
            Blogs & Podcasts
          </Link>
          <br />
           <Link
            href="#"
            underline="none"
            sx={links}
            onClick={() => navigate("/games")}
          >
            Games
          </Link>
          <br /> */}

          <Link
            href="#"
            underline="none"
            sx={links}
            onClick={() => navigate("/contactus")}
          >
            Contact Us
          </Link>
          <br />
          <Link
            href="#"
            underline="none"
            sx={links}
            onClick={() => navigate("/about")}
          >
            About Us
          </Link>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Typography sx={mainLinks}>COMPANY LINKS</Typography>
          {/* <Link
            href="#"
            underline="none"
            sx={links}
            onClick={() => navigate("/")}
          >
            Careers
          </Link>
          <br /> */}
          {!getloginLocalInfo?._id && (
            <>
              <Link
                underline="none"
                sx={links}
                onClick={() => navigate("/registration")}
              >
                Register
              </Link>
              <br />

              <Link underline="none" sx={links} onClick={() => setOpen(true)}>
                Login
              </Link>
              <br />
            </>
          )}
          <Link
            underline="none"
            sx={links}
            onClick={() => navigate("/TermServices")}
            href="/TermServices"
            // target="_blank"
          >
            Terms of Services
          </Link>
          <br />
          <Link
            underline="none"
            sx={links}
            onClick={() => navigate("/PrivacyPolicy")}
            href="/PrivacyPolicy"
            // target="_blank"
          >
            Privacy Policy
          </Link>
        </Grid>

        <Grid item xs={12} sm={3}>
          <Typography sx={mainLinks}>CONTACT US</Typography>
          <Typography sx={subLinks}>Telephone:</Typography>
          <Typography sx={linksContact}>
            <a href="tel:888-873-8796" style={{ color: "white" }}>
              888-873-8796
            </a>
          </Typography>

          <Typography sx={subLinks}>Location:</Typography>
          <Typography sx={linksContact}>
            260 Madison Ave, 8th Fl, New York, NY, 10016
          </Typography>
        </Grid>
      </Grid>

      <Box
        sx={{
          borderTop: "0.7px solid grey",
          padding: "10px 0",
          mt: 2,
          display: "flex",

          justifyContent: "space-between",
        }}
        className="socialIcon"
      >
        <Typography
          sx={{
            fontSize: "11px",
            fontFamily: "Kumbh Sans",
            opacity: "0.5",
          }}
        >
          &copy; Copyright Faith Lens {new Date().getFullYear()}
        </Typography>

        <Box sx={socialIcons} className="socialIconCss">
          {/* <Link href="#" variant="body2" sx={{ color: "#ffffff" }}>
            <FaFacebookF />
          </Link>

          <Link href="#" variant="body2" sx={{ mx: 2, color: "#ffffff" }}>
            <BsTwitter />
          </Link> */}

          <Link
            href="https://www.instagram.com/faithlens/"
            target="_blank"
            variant="body2"
            sx={{ color: "#ffffff" }}
          >
            <BsInstagram />
          </Link>
        </Box>
      </Box>

      <Loginform setOpen={setOpen} open={open} />
    </Box>
  );
}

const linksContact = {
  marginBottom: "25px",
  color: "#ffffff",
  fontFamily: "Kumbh Sans",
  fontSize: "12px",
};

const links = {
  // marginBottom: "25px",
  // color: "#ffffff",
  fontFamily: "Kumbh Sans",
  fontSize: "12px",

  color: "white",
  position: "relative",

  "&:before": {
    content: "''",
    position: "absolute",
    width: "0",
    height: "1px",
    bottom: "-3px",
    left: "50%",
    transform: "translate(-50%,0%)",
    backgroundColor: "#ffffff",
    transformOrigin: "center",
    visibility: "hidden",
    transition: "all 0.3s ease-in-out",
  },
  "&:hover:before": {
    visibility: "visible",
    width: "100%",
  },
};

const subLinks = {
  color: "#ffffff",
  fontFamily: "Kumbh Sans",
  fontSize: "12px",
  fontWeight: "bold",
  marginY: 0,
};

const mainLinks = {
  marginBottom: "8px",
  color: "#ffffff",
  fontFamily: "Kumbh Sans",
  fontSize: "13px",
  fontWeight: "bold",
};

const sloganHeading = {
  marginTop: "20px",
  color: "#ffffff",
  fontFamily: "Kumbh Sans",
  fontSize: "24px",
  fontWeight: "bold",
};

const socialIcons = {
  color: "#ffffff !important",
};
