import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

import { useFormik } from "formik";
import * as yup from "yup";
import { TextField } from "@mui/material";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function CustomizedDialogs({ openAddVideo, setOpenAddVideo }) {
  const validationSchema = yup.object({
    staffMember: yup
      .string("Enter Fullname")
      .min(5, "min 5 character required")
      .required("Name is required."),

    Role: yup.string("Enter Role").required("Role is required."),

    imageUpload: yup.string("upload image only").required("Image is required."),
  });

  const formik = useFormik({
    initialValues: {
      staffMember: "",
      imageUpload: "",
      Role: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      // const { staffMember, imageUpload, Role } = values;
      //   const payData = {};
      resetForm({ values: "" });
    },
  });

  return (
    <BootstrapDialog
      onClose={() => setOpenAddVideo(false)}
      aria-labelledby="customized-dialog-title"
      open={openAddVideo}
    >
      <form onSubmit={formik.handleSubmit}>
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={() => setOpenAddVideo(false)}
        >
          Add My Video
        </BootstrapDialogTitle>

        <DialogContent dividers>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <Grid item xs={12} sm={6}>
              <Typography sx={labelText}>Video name</Typography>
              <TextField
                id="staffMember"
                name="staffMember"
                sx={{ width: "100%" }}
                variant="outlined"
                placeholder="Type here"
                value={formik.values.staffMember}
                onChange={formik.handleChange}
                error={
                  formik.touched.staffMember &&
                  Boolean(formik.errors.staffMember)
                }
                helperText={
                  formik.touched.staffMember && formik.errors.staffMember
                }
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography sx={labelText}>Thumbnail Image</Typography>
              <TextField
                id="thumbnailImage"
                type="file"
                name="thumbnailImage"
                sx={{ width: "100%" }}
                variant="outlined"
                value={formik.values.thumbnailImage}
                onChange={formik.handleChange}
                error={
                  formik.touched.thumbnailImage &&
                  Boolean(formik.errors.thumbnailImage)
                }
                helperText={
                  formik.touched.thumbnailImage && formik.errors.thumbnailImage
                }
              />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography sx={labelText}>Video</Typography>
            <TextField
              id="video"
              type="file"
              name="video"
              sx={{ width: "100%" }}
              variant="outlined"
              value={formik.values.video}
              onChange={formik.handleChange}
              error={
                formik.touched.video && Boolean(formik.errors.video)
              }
              helperText={
                formik.touched.video && formik.errors.video
              }
            />
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button type="submit" sx={registerBtn}>
            Upload
          </Button>
        </DialogActions>
      </form>
    </BootstrapDialog>
  );
}

const labelText = {
  fontSize: "14px",
  fontFamily: "Kumbh Sans",
  fontWeight: 500,
  marginTop: "20px",
  marginBottom: "8px",
};

// const uploadPicSection = {
//   border: "1px solid",
//   width: "90px",
//   height: "90px",
//   padding: "20px 20px",
//   display: "flex",
//   borderRadius: 2,
//   borderColor: "grey.500",
//   borderStyle: "dashed",
//   justifyContent: "center",
//   margin: "10px 45%",
//   flexDirection: "column",
// };

// const cardHeading1 = {
//   color: "#ffffff",
//   fontSize: "14px",
//   fontWeight: "bold",
//   fontFamily: "Kumbh Sans",
// };

// const cardHeading2 = {
//   color: "#ffffff",
//   fontSize: "20px",
//   fontWeight: "bold",
//   fontFamily: "Kumbh Sans",
// };

// const cardText = {
//   color: "#ffffff",
//   fontSize: "11px",
//   fontFamily: "Kumbh Sans",
//   opacity: "0.7",
// };

const registerBtn = {
  color: "#ffffff",
  background: "#3F292B",
  fontFamily: "Kumbh Sans",
  fontSize: "10px",
  padding: "9px 18px",
  border: "1px solid #3F292B",
  "&:hover": {
    border: "1px solid #3F292B",
    color: "#3F292B",
  },
};
