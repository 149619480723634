import React from "react";
import { Box, IconButton, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";

import { closeIcon } from "../navbar/closeIcon";

import CheckRound from "../../assets/icons/checkRound.png";

const Inquery = ({ openInquery, setInquery }) => {
  return (
    <>
      <Dialog open={openInquery}>
        <Box align="center" sx={{ padding: "14px" }}>
          <IconButton
            sx={{
              position: "absolute",
              top: "4px",
              right: "4px",
            }}
            onClick={() => setInquery(false)}
          >
            {closeIcon()}
          </IconButton>
          <img src={CheckRound} alt="check" width={"70px"} />

          {/* <Typography
            sx={{
              marginTop: "10px",
              fontFamily: "Kumbh Sans",
              fontSize: "20px",
              fontWeight: "bold",
            }}
          >
            May Attend
          </Typography> */}

          <Typography
            sx={{
              marginTop: "10px",
              fontFamily: "Kumbh Sans",
              fontSize: "20px",
              fontWeight: "bold",
              p: 1,
            }}
          >
            We’ve received your Inquery,<br /> give us 48 hours to get back to you.
          </Typography>
        </Box>
      </Dialog>
    </>
  );
};

export default Inquery;
// const button1 = {
//     backgroundColor: "#D1AB42",
//     borderRadius: "8px",
//     fonFamily: "Kumbh Sans",
//     fontWeight: "bold",
//     fontSize: "10px",
//     padding: "14px 50px",
//     "&:hover": {
//       backgroundColor: "#D1AB42",
//       color: "white",
//     },
//   };

//   const button2 = {
//     backgroundColor: "#3F292B",
//     borderRadius: "8px",
//     fonFamily: "Kumbh Sans",
//     fontWeight: "bold",
//     fontSize: "10px",
//     padding: "14px 50px",

//     ":hover": {
//       backgroundColor: "#3F292B",
//       color: "white",
//     },
//   };
