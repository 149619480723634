/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import "./userProfile.css";
import CircularProgress from "@mui/material/CircularProgress";

// ** router
// import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import ChangePasswordDialog from "./ChangePasswordDialog";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import AddEventGoogleMap from "./AddEventGoogleMap";

import axios from "axios";

// ** MUI
// import Snackbar from "@mui/material/Snackbar";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useSelector } from "react-redux";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";

import { Stack } from "@mui/system";
import ChurchPic from "../../assets/icons/admin.png";
import { Link, MenuItem, Select, TextField } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { Country, State, City } from "country-state-city";
import EditIcon from "@mui/icons-material/Edit";

const baseURL = process.env.REACT_APP_BASEURL;
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Setting2 = (props) => {
  const cordinates = useSelector((state) => state.userAuth.latlng);
  console.log({ cordinates });

  const { setActiveItem, activeItem, userData, setTrig, trig } = props;
  const [ChangePass, setChangePass] = React.useState(false);
  const [pastor, setPastor] = useState(false);
  const [church, setChurch] = useState(false);
  const [loaderOn, setLoaderOn] = useState(false);
  const [token, setToken] = React.useState("");
  const [getChurchDetail, setChurchDetail] = useState([]);

  // ** Toast message
  const [openToastSuccess, setOpenToastSuccess] = React.useState(false);
  const handleCloseToastSuccess = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenToastSuccess(false);
  };

  // ** Address dropdown values
  const [countryList, setCountryList] = React.useState([]);
  const [countryCode, setCountryCode] = React.useState("");
  const [stateList, setStateList] = React.useState([]);
  const [stateCode, setStateCode] = React.useState("");
  const [cityList, setCityList] = React.useState([]);
  const [GetDataFromLocal, setGetDataFromLocal] = React.useState();

  // console.log({ ggggggg: GetDataFromLocal });

  // React.useEffect(() => {
  //   setCountryList(Country.getAllCountries());
  //   setStateList(State.getStatesOfCountry(countryCode));
  //   setCityList(City.getCitiesOfState(countryCode, stateCode));
  // }, [countryCode, countryList, stateCode]);

  // console.log("city >>", City.getCitiesOfState(countryCode, stateCode));

  // ** fields state
  const [organization, setOrganization] = useState("");
  const [aboutOrganization, setAboutOrganization] = useState("");
  const [mobile, setMobile] = useState("");

  const [website, setWebsite] = useState("");

  const [street, setStreet] = useState("");
  const [openGeoMap, setOpenGeoMap] = React.useState(false);

  const [area, setArea] = useState("");
  const [zipcode, setZipcode] = useState("");

  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");

  const [lati, setLati] = useState("");
  const [longi, setLongi] = useState("");

  const [pastorName, setPastorName] = useState("");
  const [pastorDesc, setPastorDesc] = useState("");

  const [OpenFrom, setOpenFrom] = useState("");
  const [OpenTo, setOpenTo] = useState("");
  const [OpenClose, setOpenClose] = useState("");

  const [churchImage, setChurchImage] = useState();
  const [pastorImage, setPastorImage] = useState();

  React.useEffect(() => {
    setOrganization(getChurchDetail.user?.fullName);
    setAboutOrganization(getChurchDetail.churchDesc);
    setMobile(getChurchDetail.user?.mobile);
    setWebsite(getChurchDetail?.website);

    setStreet(
      GetDataFromLocal?.area
        ? GetDataFromLocal?.area
        : getChurchDetail?.address?.area
    );
    setArea(
      GetDataFromLocal?.area
        ? GetDataFromLocal?.area
        : getChurchDetail?.address?.area
    );
    setZipcode(
      GetDataFromLocal?.zipcode
        ? GetDataFromLocal?.zipcode
        : getChurchDetail?.address?.zipCode
    );

    setCountry(
      GetDataFromLocal?.country
        ? GetDataFromLocal?.country
        : getChurchDetail?.address?.country
    );
    setState(
      GetDataFromLocal?.state
        ? GetDataFromLocal?.state
        : getChurchDetail?.address?.state
    );
    setCity(
      GetDataFromLocal?.city
        ? GetDataFromLocal?.city
        : getChurchDetail?.address?.city
    );

    setLati(GetDataFromLocal?.lat);
    setLongi(GetDataFromLocal?.lng);

    // setCountryCode(getChurchDetail?.address?.countryCode);
    // setStateCode(getChurchDetail?.address?.stateCode);

    setPastorName(getChurchDetail?.leadName);
    setPastorDesc(getChurchDetail?.leadDesc);

    setOpenFrom(getChurchDetail?.workingDays?.from);
    setOpenTo(getChurchDetail?.workingDays?.to);
    setOpenClose(getChurchDetail?.weekendDays?.open);

    setChurchImage(getChurchDetail.user?.imageUrl);
    setPastorImage(getChurchDetail?.leadImageUrl);
  }, [getChurchDetail, GetDataFromLocal]);

  const [tabVisibility, setTabVisibility] = React.useState(true);

  React.useEffect(() => {
    function handleVisibilityChange() {
      setTabVisibility(document.visibilityState === "visible");
    }
    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);
  const getMapData = JSON.parse(localStorage.getItem("mapData"));

  React.useEffect(() => {
    setGetDataFromLocal(getMapData);
  }, [tabVisibility]);

  React.useEffect(() => {
    const getloginLocalInfo = JSON.parse(
      localStorage.getItem("loginLocalInfo")
    );
    setToken(getloginLocalInfo.token);
    const config = {
      headers: {
        Authorization: `Bearer ${getloginLocalInfo.token}`,
      },
    };

    getChurchDetails(config);
  }, [openToastSuccess]);

  const getChurchDetails = async (tt) => {
    await axios
      .get(`${baseURL}/api/v1/church`, tt)
      .then((response) => {
        if (response.data.success) {
          setChurchDetail(response.data.data);
          setTrig(!trig);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onSubmitFormData = async () => {
    const payData = {
      churchName: organization,
      churchDesc: aboutOrganization,
      leadName: pastorName,
      leadDesc: pastorDesc,
      user: {
        mobile: mobile,
      },
      website: website,

      workingDays: {
        from: OpenFrom,
        to: OpenTo,
      },

      weekendDays: {
        open: OpenClose,
      },

      address: {
        location: {
          type: "Point",
          coordinates: [longi, lati],
        },

        street: street,
        area: area,
        city: city,
        state: state,
        country: country,

        countryCode: countryCode,
        stateCode: stateCode,
      },
    };

    if (cordinates.length === 2) {
      payData.address.location = {
        type: "Point",
        coordinates: cordinates,
      };
    }

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    await axios
      .put(`${baseURL}/api/v1/church`, payData, config)
      .then((response) => {
        if (response.data.success) {
          setTrig(!trig);
          setOpenToastSuccess(true);
          localStorage.removeItem("mapData");
          setGetDataFromLocal(null);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // **** Church Image
  React.useEffect(() => {
    // const churchImg = async () => {
    if (church) {
      setLoaderOn(true);
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const data = new FormData();
      data.append("profile", churchImage);

      axios
        .put(`${baseURL}/api/v1/church/updateChurchImage`, data, config)
        .then((response) => {
          if (response.data.success) {
            setChurch(false);
            setTrig(!trig);
            setOpenToastSuccess(true);
            setLoaderOn(false);
            localStorage.removeItem("mapData");
            setGetDataFromLocal(null);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
    // };
  }, [church]);

  // **** Pastor Image

  React.useEffect(() => {
    if (pastor) {
      setLoaderOn(true);
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const data = new FormData();
      data.append("leadImage", pastorImage);

      axios
        .put(`${baseURL}/api/v1/church/updateChurchLeadImage`, data, config)
        .then((response) => {
          if (response.data.success) {
            setPastor(false);
            setTrig(!trig);
            setOpenToastSuccess(true);
            setLoaderOn(false);
            localStorage.removeItem("mapData");
            setGetDataFromLocal(null);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [pastor]);

  const loader = () => {
    return <CircularProgress sx={{ color: "#D1AB42" }} />;
  };

  return (
    <>
      <Box
        sx={{
          borderRadius: "22px",
          padding: "20px",
          background: "#ffffff",
          height: "100%",
          width: "auto",
          margin: "0 10px",
          overflow: "auto",
          m: 1,
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <Typography sx={tableHeading}>
            Setting | Edit&nbsp;Information
          </Typography>
          <Box>
            <Button
              variant="outlined"
              sx={registerBtn}
              onClick={() => setChangePass(true)}
            >
              Change Password
            </Button>

            <Button
              onClick={onSubmitFormData}
              variant="outlined"
              sx={registerBtn}
            >
              Update
            </Button>
          </Box>
        </Stack>

        <Box sx={{ display: "flex", gap: "40px" }} className="settingStyle">
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
            className="imageUpload"
          >
            <Box align="center" className="imgChange">
              {loaderOn ? (
                loader()
              ) : (
                <img
                  src={churchImage}
                  alt="Uploading, wait..."
                  width="150"
                  height="150"
                  className="imgStyle"
                />
              )}

              <Button
                variant="outlined"
                component="label"
                sx={changeOutline}
                className="imgChangeBtn"
              >
                <EditIcon sx={change} />
                <input
                  type="file"
                  hidden
                  onChange={(e) => {
                    setChurchImage(e.target.files[0]);
                    setChurch(true);
                  }}
                />
              </Button>

              <Typography
                sx={{
                  fontSize: "17px",
                  fontFamily: "Kumbh Sans",
                  fontWeight: "bold",
                }}
              >
                {organization}
              </Typography>
            </Box>

            {userData?.user?.role !== "Artist" && (
              <Box align="center" className="imgChange">
                {loaderOn ? (
                  loader()
                ) : (
                  <img
                    src={pastorImage ? pastorImage : ChurchPic}
                    alt="Uploading, wait..."
                    width="150"
                    height="150"
                    className="imgStyle"
                  />
                )}
                <Button
                  variant="outlined"
                  component="label"
                  sx={changeOutline}
                  className="imgChangeBtn"
                >
                  <EditIcon sx={change} />
                  <input
                    type="file"
                    hidden
                    onChange={(e) => {
                      setPastorImage(e.target.files[0]);
                      setPastor(true);
                    }}
                  />
                </Button>

                <Typography
                  sx={{
                    fontSize: "17px",
                    fontFamily: "Kumbh Sans",
                    fontWeight: "bold",
                  }}
                >
                  {pastorName}
                </Typography>
              </Box>
            )}
          </Box>

          <Box>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={2}
            >
              <Grid item xs={12} sm={12}>
                <Typography sx={labelText}>
                  {userData?.user?.role === "Artist"
                    ? "Artist Profile Name "
                    : "Organization Name"}
                </Typography>

                <TextField
                  id="fullName"
                  name="fullName"
                  sx={{ width: "100%" }}
                  variant="outlined"
                  placeholder="Type here"
                  value={organization}
                  onChange={(e) => setOrganization(e.target.value)}
                />
              </Grid>
            </Grid>

            <Grid item xs={12} sm={12}>
              <Typography sx={labelText}>
                {userData?.user?.role === "Artist"
                  ? "About Artist"
                  : "About Organization"}
              </Typography>
              <TextField
                id="about"
                name="about"
                multiline
                sx={{ width: "100%" }}
                variant="outlined"
                placeholder="Type here"
                value={aboutOrganization}
                onChange={(e) => setAboutOrganization(e.target.value)}
              />
            </Grid>

            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={2}
            >
              <Grid item xs={12} sm={userData?.user?.role === "Church" ? 3 : 6}>
                <Typography sx={labelText}>Contact mobile</Typography>
                <TextField
                  id="mobile"
                  name="mobile"
                  sx={{ width: "100%" }}
                  variant="outlined"
                  placeholder="Type here"
                  value={mobile}
                  onChange={(e) => setMobile(e.target.value)}
                />
              </Grid>

              <Grid item xs={12} sm={userData?.user?.role === "Church" ? 3 : 6}>
                <Typography sx={labelText}>Website</Typography>

                <TextField
                  id="website"
                  Send
                  Inquiry
                  type="website"
                  name="website"
                  sx={{ width: "100%" }}
                  variant="outlined"
                  placeholder="Type here"
                  value={website}
                  onChange={(e) => setWebsite(e.target.value)}
                />
              </Grid>

              {userData?.user?.role === "Church" && (
                <>
                  <Grid item xs={12} sm={3}>
                    <Typography sx={labelText}>
                      Monday - Friday (AM - PM)
                    </Typography>
                    <TextField
                      id="monfri"
                      type="time"
                      name="monfri"
                      sx={{ width: "48%", marginRight: "5px" }}
                      variant="outlined"
                      placeholder="Type here"
                      value={OpenFrom}
                      onChange={(e) => setOpenFrom(e.target.value)}
                    />

                    <TextField
                      id="monfri"
                      type="time"
                      name="monfri"
                      sx={{ width: "48%" }}
                      variant="outlined"
                      placeholder="Type here"
                      value={OpenTo}
                      onChange={(e) => setOpenTo(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <Typography sx={labelText}>Saturday - Sunday</Typography>
                    <Select
                      id="satsun"
                      name="satsun"
                      sx={{ width: "100%" }}
                      variant="outlined"
                      placeholder="Type here"
                      value={OpenClose}
                      onChange={(e) => setOpenClose(e.target.value)}
                    >
                      <MenuItem value={true}>Open</MenuItem>
                      <MenuItem value={false}>Close</MenuItem>
                    </Select>
                  </Grid>
                </>
              )}
            </Grid>

            <Typography sx={labelText}>Address</Typography>

            <Link
              underline="none"
              href="/map1"
              target="_blank"
              sx={{ float: "left" }}
            >
              <Button sx={mapBtn}>
                Add Map Location
                <MyLocationIcon />
              </Button>
            </Link>

            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={2}
            >
              {userData?.user?.role !== "Artist" && (
                <>
                  <AddEventGoogleMap
                    setOpenGeoMap={setOpenGeoMap}
                    openGeoMap={openGeoMap}
                  />

                  <Grid item xs={12} sm={12}>
                    {GetDataFromLocal?.country && (
                      <Box
                        sx={{
                          border: "1px solid lightgrey",
                          padding: "0 10px",
                          marginBottom: "10px",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            gap: "5px",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography sx={labelTextYellow}>
                            New Address :
                          </Typography>
                          <CancelOutlinedIcon
                            sx={labelTextClear}
                            onClick={() => {
                              localStorage.removeItem("mapData");
                              setGetDataFromLocal(null);
                              // setAddress(null);
                            }}
                          />
                        </Box>

                        <Typography sx={labelTextYellow}>
                          {GetDataFromLocal?.area}
                        </Typography>
                        <Typography sx={labelTextYellow}>
                          {GetDataFromLocal?.city} {GetDataFromLocal?.zipcode},{" "}
                          {GetDataFromLocal?.state}, {GetDataFromLocal?.country}
                        </Typography>
                      </Box>
                    )}
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    {country && (
                      <Box
                        sx={{
                          border: "1px solid lightgrey",
                          padding: "0 10px",
                          marginBottom: "10px",
                        }}
                      >
                        <Typography sx={labelText}>{area}</Typography>
                        <Typography sx={labelText}>
                          {city} {zipcode},{state}, {country}
                        </Typography>
                      </Box>
                    )}
                  </Grid>

                  {/* <Grid item xs={12} sm={12}>
                    <TextField
                      id="street"
                      name="street"
                      sx={{ width: "100%" }}
                      variant="outlined"
                      placeholder="Street / Line"
                      value={street}
                      onChange={(e) => setStreet(e.target.value)}
                    />
                  </Grid> */}
                </>
              )}

              {/* <Grid item xs={12} sm={4}>
                <TextField
                  id="country"
                  name="country"
                  sx={{ width: "100%" }}
                  variant="outlined"
                  placeholder="Country"
                  value={country}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  id="state"
                  name="state"
                  sx={{ width: "100%" }}
                  variant="outlined"
                  placeholder="State"
                  value={stateList ? state : "fetching"}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  id="city"
                  name="city"
                  sx={{ width: "100%" }}
                  variant="outlined"
                  placeholder="City"
                  value={city}
                />
              </Grid> */}
            </Grid>

            {userData?.user?.role !== "Artist" && (
              <>
                <Typography sx={tableHeading}>
                  {userData?.user?.role === "Ministry"
                    ? " Founder Details :"
                    : "Paster Details :"}
                </Typography>

                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={2}
                >
                  <Grid item xs={12} sm={12}>
                    <Typography sx={labelText}>
                      {userData?.user?.role === "Ministry"
                        ? "Founder of Ministry"
                        : "Pastor Or Lead Name"}
                    </Typography>
                    <TextField
                      id="pastorName"
                      name="pastorName"
                      sx={{ width: "100%" }}
                      variant="outlined"
                      placeholder="Type here"
                      value={pastorName}
                      onChange={(e) => setPastorName(e.target.value)}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Typography sx={labelText}>About</Typography>
                  <TextField
                    id="pastorDesc"
                    name="pastorDesc"
                    multiline
                    sx={{ width: "100%" }}
                    variant="outlined"
                    placeholder="Type here"
                    value={pastorDesc}
                    onChange={(e) => setPastorDesc(e.target.value)}
                  />
                </Grid>
              </>
            )}
          </Box>
        </Box>
      </Box>
      <Snackbar
        open={openToastSuccess}
        autoHideDuration={2000}
        onClose={handleCloseToastSuccess}
      >
        <Alert
          onClose={handleCloseToastSuccess}
          severity="success"
          sx={{ width: "100%" }}
        >
          Information Updated.
        </Alert>
      </Snackbar>
      <ChangePasswordDialog
        ChangePass={ChangePass}
        setChangePass={setChangePass}
        baseURL={baseURL}
      />
    </>
  );
};

export default Setting2;

const labelText = {
  fontSize: "14px",
  fontFamily: "Kumbh Sans",
  fontWeight: 500,
  marginTop: "20px",
  marginBottom: "8px",
};

const tableHeading = {
  fontSize: "18px",
  fontFamily: "Kumbh Sans",
  fontWeight: "bold",
  padding: "15px",
};
const labelTextYellow = {
  fontSize: "14px",
  fontFamily: "Kumbh Sans",
  fontWeight: 500,
  marginTop: "8px",
  marginBottom: "5px",
  color: "orange",
};

const registerBtn = {
  color: "#ffffff",
  background: "#3F292B",
  fontFamily: "Kumbh Sans",
  fontSize: "10px",
  padding: "6px 20px",
  border: "1px solid #3F292B",
  mx: 1,
  "&:hover": {
    border: "1px solid #3F292B",
    color: "#3F292B",
  },
};

const change = {
  color: "#ffffff",
  background: "#3F292B",
  fontFamily: "Kumbh Sans",
  fontSize: "16px",
  padding: "6px 10px",
  border: "1px solid #3F292B",
  borderRadius: "50%",

  "&:hover": {
    border: "1px solid #3F292B",
    color: "#ffffff",
  },
};

const changeOutline = {
  fontFamily: "Kumbh Sans",
  padding: "10px",
  border: "1px solid transparent",
  borderRadius: "50%",
  "&:hover": {
    border: "1px solid transparent",
  },
};

const labelTextMenu = {
  fontSize: "14px",
  fontFamily: "Kumbh Sans",
  fontWeight: 500,
  // color: "white",
};

const mapBtn = {
  gap: "10px",
  color: "#ffffff",
  background: "#D1AB42",
  textTransform: "capitalize",
  border: "1px solid #D1AB42",
  fontSize: "14px",
  fontFamily: "Kumbh Sans",
  fontWeight: 500,
  px: 2,
  width: "100%",
  height: "39px",
  "&:hover": {
    border: "1px solid #3F292B",
    color: "#3F292B",
  },
};

const labelTextClear = {
  fontSize: "20px",
  fontFamily: "Kumbh Sans",
  fontWeight: 500,
  marginTop: "8px",
  marginBottom: "5px",
  color: "red",
  cursor: "pointer",
};
