import React from "react";
import { latestBlog } from "./TrendingPodcastData";

// Import slider & css files
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { settings } from "./sliderProps";
import "./blog.css";

// ** MUI
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { CardActionArea } from "@mui/material";

const TrendingPodcasts = () => {
  const subHeading = {
    fontFamily: "Kumbh Sans",
    fontSize: "20px",
    fontWeight: "bold",
    marginY: "20px",
    display: "flex",
    justifyContent: "space-between",
    marginLeft: "15px",
  };

  // const subHeading2 = {
  //   fontFamily: "Kumbh Sans",
  //   fontSize: "20px",
  //   fontWeight: "bold",
  //   marginY: "20px",
  //   display: "flex",
  //   justifyContent: "space-between",
  //   marginLeft: '15px'
  // };

  return (
    <Box sx={{ position: "relative" }} align="center">
      <Typography sx={subHeading}>
        Trending&nbsp;
        <br className="breakLine" />
        Podcasts
      </Typography>

      <Box>
        <Slider {...settings}>
          {latestBlog?.map((data, ind) => {
            return (
              <Card sx={{ maxWidth: "90%" }} key={ind}>
                <CardActionArea>
                  <CardMedia
                    component="img"
                    height="140"
                    image={data.img}
                    alt="man"
                    sx={{ borderRadius: "8px" }}
                  />
                  <CardContent align="left">
                    <Typography
                      gutterBottom
                      sx={{
                        fontFamily: "Kumbh Sans",
                        fontSize: "10px",
                        fontWeight: "bold",
                        color: "#D1AB42",
                      }}
                    >
                      {data.author}
                    </Typography>
                    <Typography
                      gutterBottom
                      sx={{
                        fontFamily: "Kumbh Sans",
                        fontSize: "20px",
                        fontWeight: "bold",
                      }}
                    >
                      {data.title}
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: "Kumbh Sans",
                        fontSize: "12px",
                        color: "#91857F",
                      }}
                    >
                      {data.time}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            );
          })}
        </Slider>
      </Box>
    </Box>
  );
};

export default TrendingPodcasts;
