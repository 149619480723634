export const religionItem = [
  //  {
  //    label: "Anglicanism Church",
  //    value: "Anglicanism Church",
  //  },
   {
    label: "All Religions",
    value: "All Religions",
  },
  {
    label: "Baptist Church",
    value: "Baptist Church",
  },
  {
    label: "Catholic Church",
    value: "Catholic Church",
  },
  {
    label: "Non Denominational",
    value: "Non Denominational",
  },
  {
    label: "Episcopal Church",
    value: "Episcopal Church",
  },
  // {
  //   label: "Evangelicalism Church",
  //   value: "Evangelicalism Church",
  // },
  {
    label: "Pentecostal Church",
    value: "Pentecostal Church",
  },
  {
    label: "Seventh Day Adventist Church",
    value: "Seventh Day Adventist Church",
  },
];

export const ReligionItem = [
  {
    label: "Adventism",
    value: "Adventism",
  },
  {
    label: "African Methodist Episcopal Church",
    value: "African Methodist Episcopal Church",
  },
  {
    label: "Anglicanism",
    value: "Anglicanism",
  },
  {
    label: "Assemblies of God",
    value: "Assemblies of God",
  },
  {
    label: "Assyrian Church of the East",
    value: "Assyrian Church of the East",
  },
  {
    label: "Baptist",
    value: "Baptist",
  },
  {
    label: "Catholic Church",
    value: "Catholic Church",
  },
  {
    value: "Church of Denmark",
    label: "Church of Denmark",
  },
  {
    label: "Church of Iceland",
    value: "Church of Iceland",
  },
  {
    label: "Church of Norway",
    value: "Church of Norway",
  },
  {
    value: "Church of Sweden",
    label: "Church of Sweden",
  },
  {
    value: "Church of the Nazarene",
    label: "Church of the Nazarene",
  },
  {
    value: "Continental Reformed Protestantism",
    label: "Continental Reformed Protestantism",
  },
  {
    value: "Eastern Catholic Churches",
    label: "Eastern Catholic Churches",
  },
  {
    value: "Eastern Orthodox Church",
    label: "Eastern Orthodox Church",
  },
  {
    value: "Evangelicalism",
    label: " Evangelicalism",
  },
  {
    value: "International Pentecostal Holiness Church",
    label: "International Pentecostal Holiness Church",
  },
  {
    value: " Lutheran Church",
    label: "  Lutheran Church",
  },
  {
    value: " Methodism",
    label: "  Methodism",
  },
  {
    value: "Orthodox Church",
    label: " Orthodox Church",
  },
  {
    value: "Pentecostalism",
    label: " Pentecostalism",
  },
  {
    value: "Pentecostalism",
    label: " Pentecostalism",
  },
  {
    value: "Presbyterianism",
    label: "Presbyterianism",
  },
  {
    value: "Protestantism",
    label: " Protestantism",
  },
  {
    value: "Quakers",
    label: "Quakers",
  },
  {
    value: "Restorationism",
    label: "Restorationism",
  },
  {
    value: "Seventh-day Adventist Church",
    label: "Seventh-day Adventist Church",
  },
];
