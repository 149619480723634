import React from "react";
import Button from "@mui/material/Button";
import { Box, Divider, IconButton, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import { closeIcon } from "../../navbar/closeIcon";
import Speaker1 from "../../../assets/images/speaker1.jpg";

import { MdOutlineMailOutline } from "react-icons/md";
import { SlLocationPin } from "react-icons/sl";

import { BsTelephone } from "react-icons/bs";

import axios from "axios";
// import { useNavigate } from "react-router-dom";
const baseURL = process.env.REACT_APP_BASEURL;

const ViewDetails = (props) => {
  const {
    openViewDetialpopUp,
    setOpenViewDetialpopUp,
    handleClose,
    particularId,
  } = props;

  const [ChurchData, setChurchData] = React.useState([]);

  React.useEffect(() => {
    // const getloginLocalInfo = JSON.parse(
    //   localStorage.getItem("loginLocalInfo")
    // );
    // const config = {
    //   headers: { Authorization: `Bearer ${getloginLocalInfo.token}` },
    // };

    const getAllEventsRequest = () => {
      axios
        .get(`${baseURL}/api/v1/church/getChurch/${particularId}`)
        .then((response) => {
          if (response.data.success) {
            setChurchData(response?.data?.data);
            handleClose();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };
    getAllEventsRequest();
  }, [openViewDetialpopUp]);

  // console.log("fdassdfasdf =>>>> ", { ChurchData, particularId });

  return (
    <>
      <Dialog open={openViewDetialpopUp}>
        <Box sx={{ padding: "14px" }}>
          <IconButton
            sx={{
              position: "absolute",
              top: "4px",
              right: "4px",
            }}
            onClick={() => setOpenViewDetialpopUp(false)}
          >
            {closeIcon()}
          </IconButton>

          <Box sx={{ p: 4 }}>
            <Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexWrap: "wrap",
                }}
              >
                <Typography sx={eventsHeading}>
                  {ChurchData?.user?.fullName}
                </Typography>
              </Box>

              <Typography sx={eventsTextLocation}>
                <SlLocationPin style={{ fontSize: "13px" }} />
                &nbsp;  {ChurchData?.address?.city},
                <Typography sx={eventsText}>&nbsp;{ChurchData?.address?.state},  {ChurchData?.address?.country}</Typography>
              </Typography>
              <Typography sx={paragraph}>
              {ChurchData?.user?.description}
              </Typography>
            </Box>

            <Divider sx={{ marginY: "20px" }} />

            <Typography sx={titleHeading}>Contact Details</Typography>

            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "start",
              }}
            >
              <Box sx={{ marginRight: "50px" }}>
                <Typography sx={eventDetailsSubHeading}>
                  <BsTelephone className="iconStyle2" />
                  {ChurchData?.user?.mobile}
                </Typography>
                <Typography sx={paragraph}>Contact number</Typography>
              </Box>
              <Box>
                <Typography sx={eventDetailsSubHeading}>
                  <MdOutlineMailOutline className="iconStyle2" />
                  {ChurchData?.user?.email}
                </Typography>
                <Typography sx={paragraph}>Email</Typography>
              </Box>
            </Box>

            <Divider sx={{ marginY: "20px" }} />

            <Typography sx={titleHeading}>Pastor Details</Typography>

            <Box
              sx={{
                display: "flex",
                gap: "20px",
              }}
              className="flexMobile"
            >
              <Box sx={{ display: "inline" }}>
                <img src= {ChurchData?.leadImageUrl} alt="img" width={100} />
              </Box>
              <Box>
                <Typography sx={eventDetailsSubHeading}>
                  {ChurchData?.leadName}
                </Typography>
                <Typography sx={paragraph}>
                {ChurchData?.leadDesc}
                </Typography>
              </Box>
            </Box>

            <Divider />

            {/* <StaffMember /> */}
          </Box>
        </Box>

        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-evenly",
            margin: "0 10px 10px",
          }}
        >
          <Button
            onClick={() => setOpenViewDetialpopUp(false)}
            variant="contained"
            sx={button2}
            className="button"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ViewDetails;

const button2 = {
  backgroundColor: "#3F292B",
  //   borderRadius: "8px",
  fonFamily: "Kumbh Sans",
  fontWeight: "bold",
  fontSize: "10px",
  padding: "10px 30px",

  ":hover": {
    backgroundColor: "#3F292B",
    color: "white",
  },
};

const eventsHeading = {
  fontSize: "1rem",
  fontFamily: "Kumbh Sans",
  fontWeight: "bold",
  color: "#000000",
  marginRight: "30px",
};
const paragraph = {
  marginBottom: "4px",
  marginRight: "12px",
  fontFamily: "Kumbh Sans",
  fontSize: "12px",
  opacity: 0.6,
};

const eventDetailsSubHeading = {
  fontFamily: "Kumbh Sans",
  fontSize: "16px",
  fontWeight: "bold",
};

// const titleHeading = {
//   marginX: "0",
//   marginBottom: "9px",
//   fontFamily: "Kumbh Sans",
//   fontSize: "18px",
//   fontWeight: "bold",
// };

// const eventsHeading = {
//   fontSize: "1.5rem",
//   fontFamily: "Kumbh Sans",
//   fontWeight: "bold",
//   color: "#000000",
//   marginRight: "30px",
// };
// const paragraph = {
//   marginBottom: "3px",
//   marginRight: "10px",
//   fontFamily: "Kumbh Sans",
//   fontSize: "14px",
//   opacity: 0.6,
// };

// const eventDetailsSubHeading = {
//   fontFamily: "Kumbh Sans",
//   fontSize: "18px",
//   fontWeight: "bold",
// };

const titleHeading = {
  marginX: "0",
  marginBottom: "20px",
  fontFamily: "Kumbh Sans",
  fontSize: "18px",
  fontWeight: "bold",
};

const eventsText = {
  fontSize: "0.8rem",
  fontFamily: "Kumbh Sans",
  color: "#000000",
};

const eventsTextLocation = {
  fontSize: "0.8rem",
  fontFamily: "Kumbh Sans",
  color: "#000000",
  marginY: "8px",
  fontWeight: "bold",
  display: "flex",
};

// const inqueryBtn = {
//   ml: 2,
//   px: 3,
//   py: 1,
//   color: "#ffffff",
//   background: "#D1AB42",
//   textTransform: "capitalize",
//   fontFamily: "Kumbh Sans",
//   fontSize: "10px",
//   border: "1px solid #D1AB42",

//   "&:hover": {
//     border: "1px solid #3F292B",
//     color: "#3F292B",
//   },
// };
