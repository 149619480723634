import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Box from "@mui/material/Box";
import Pagination from "@mui/material/Pagination";

import { Button, Typography, Stack, TableContainer } from "@mui/material";
import moment from "moment";

// ** Action Button
import MenuItem from "@mui/material/MenuItem";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import DeleteSweepOutlinedIcon from "@mui/icons-material/DeleteSweepOutlined";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import OpenInNewOutlinedIcon from "@mui/icons-material/OpenInNewOutlined";
import { StyledMenu } from "./ActionButtonStyle";

// ** Popups
import ViewChurchDetailsPopup from "./AllPopUp/ViewChurchDetailsPopup";
import ApproveChurchPopup from "./AllPopUp/ApproveChurchPopUp";
import RejectChurchPopup from "./AllPopUp/RejectChurchPopUp";

import axios from "axios";
const baseURL = process.env.REACT_APP_BASEURL;

export default function ChurchRequest({ setActiveItem }) {

  const [openViewDetialpopUp, setOpenViewDetialpopUp] = React.useState(false);
  const [openApprovepopUp, setApprovepopUp] = React.useState(false);
  const [openRejectpopUp, setRejectpopUp] = React.useState(false);

  // *** Pagination
  const options = [2, 5, 10, 15, 20];
  const [value, setValue] = React.useState(options[2]);
  const [page, setPage] = React.useState(1);
  const [total, setTotal] = React.useState();

  let count = Math.ceil(total / value);
  let start = (page - 1) * value;
  let last = Math.min(start + value - 1, total - 1);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElStatus, setAnchorElStatus] = React.useState(null);

  const [rowData, setRowData] = React.useState([]);
  const [CurrentId, setCurrentId] = React.useState("");
  const [CurrentIdChurch, setCurrentIdChurch] = React.useState("");
  const [toggle, setToggle] = React.useState(false);
  const [FilterStatus, setFilterStatus] = React.useState("");

  const open = Boolean(anchorEl);
  const Open = Boolean(anchorElStatus);

  const handleClick = (event, id, churchId) => {
    setAnchorEl(event.currentTarget);
    setCurrentId(id);
    setCurrentIdChurch(churchId);
    setToggle(!toggle);
  };

  const handleClickStatus = (event) => {
    setAnchorElStatus(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseStatus = () => {
    setAnchorElStatus(null);
  };

  function createData(id, churchId, churchname, email, status, created, role) {
    return { id, churchId, churchname, email, status, created, role };
  }
  const getAllChurchRequest = async (config) => {
    await axios
      .get(
        `${baseURL}/api/v1/admin/getAllChurch/?page_no=${page}&records_per_page=${value}&status=${FilterStatus}`,
        config
      )
      .then((response) => {
        if (response.data.success) {
          setTotal(response.data.count);
          console.log({ adminchurchreq: response.data.data });

          const getAllChurchRequest = response.data.data.map((i) => {
            return {
              render: createData(
                i._id,
                i.user?._id,
                i.user?.fullName,
                i.user?.email,
                i.user?.status,
                i.updatedAt,
                i.user?.role
              ),
            };
          });
          setRowData(getAllChurchRequest);
          setAnchorElStatus(null);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  React.useEffect(() => {
    const getloginLocalInfo = JSON.parse(
      localStorage.getItem("loginLocalInfo")
    );
    const config = {
      headers: { Authorization: `Bearer ${getloginLocalInfo.token}` },
    };

    getAllChurchRequest(config);
  }, [toggle, page, FilterStatus, handleClose]);



  return (
    <Box
      sx={{
        borderRadius: "20px",
        marginY: "20px",
        padding: "20px",
        background: "#ffffff",
        overflow: "auto",
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
      >
        <Typography sx={tableHeading}>Church Requests</Typography>
        {/* <Box>
          <Button
            variant="outlined"
            sx={loginBtn}
            onClick={() => setActiveItem("Church requests")}
          >
            View all
          </Button>
        </Box> */}
      </Stack>

      <TableContainer>
        <Table sx={{ minWidth: "auto" }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell sx={colHeading} align="left">
                Church Name
              </TableCell>

              <TableCell sx={colHeading} align="left">
                Email
              </TableCell>

              <TableCell sx={colHeading} align="left">
                Type
              </TableCell>

              <TableCell sx={colHeading} align="left">
                Last Updated
              </TableCell>

              <TableCell sx={colHeading} align="left">
                <Button
                  id="demo-customized-button"
                  aria-controls={Open ? "demo-customized-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={Open ? "true" : undefined}
                  disableElevation
                  variant="outlined"
                  sx={darkBtn}
                  onClick={(event) => handleClickStatus(event)}
                >
                  {FilterStatus ? FilterStatus : "Status"}
                </Button>
                <StyledMenu
                  anchorEl={anchorElStatus}
                  open={Open}
                  onClose={handleCloseStatus}
                >
                  <MenuItem
                    onClick={() => {
                      setFilterStatus("Approved");
                    }}
                  >
                    Approved
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setFilterStatus("Pending");
                    }}
                  >
                    Pending
                  </MenuItem>

                  <MenuItem
                    onClick={() => {
                      setFilterStatus("Rejected");
                    }}
                  >
                    Rejected
                  </MenuItem>

                  <MenuItem
                    onClick={() => {
                      setFilterStatus("");
                    }}
                  >
                    All
                  </MenuItem>
                </StyledMenu>
              </TableCell>

              <TableCell sx={colHeading} align="left">
                Action
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {rowData.map((rown, i) => {
              const row = rown.render;

              return (
                <TableRow
                  key={i}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell sx={colData} component="th" scope="row">
                    {row.churchname}
                  </TableCell>

                  <TableCell sx={colData} align="left">
                    {row.email}
                  </TableCell>

                  <TableCell sx={colData} align="left">
                    {row.role}
                  </TableCell>

                  <TableCell sx={colData} align="left">
                    {moment(row.created).format("lll")}
                  </TableCell>

                  <TableCell sx={colData} align="left">
                    {row.status === "Pending" ? (
                      <Typography sx={colDataPending}>{row.status}</Typography>
                    ) : row.status === "Approved" ? (
                      <Typography sx={colDataApproved}>{row.status}</Typography>
                    ) : (
                      <Typography sx={colDataRejected}>{row.status}</Typography>
                    )}
                  </TableCell>

                  <TableCell sx={colData} align="left">
                    <Button
                      id="demo-customized-button"
                      aria-controls={open ? "demo-customized-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                      disableElevation
                      variant="outlined"
                      sx={darkBtn}
                      onClick={(event) =>
                        handleClick(event, row?.id, row?.churchId)
                      }
                      endIcon={<KeyboardArrowDownIcon />}
                    >
                      Action
                    </Button>

                    <StyledMenu
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                    >
                      <MenuItem onClick={() => setOpenViewDetialpopUp(true)}>
                        <OpenInNewOutlinedIcon />
                        View Details
                      </MenuItem>
                      <MenuItem onClick={() => setApprovepopUp(true)}>
                        <CheckCircleOutlineOutlinedIcon />
                        Approve
                      </MenuItem>
                      <MenuItem onClick={() => setRejectpopUp(true)}>
                        <DeleteSweepOutlinedIcon />
                        Reject
                      </MenuItem>
                    </StyledMenu>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <Stack direction="row" justifyContent="space-between">
        <Typography variant="button" sx={colData}>
          Showing {total === 0 ? 0 : start + 1} to {last + 1} of {total} entries
        </Typography>

        <Pagination
          count={count}
          page={page}
          onChange={(event, newValue) => {
            setPage(newValue);
          }}
        />
      </Stack>

      <ViewChurchDetailsPopup
        handleClose={handleClose}
        openViewDetialpopUp={openViewDetialpopUp}
        setOpenViewDetialpopUp={setOpenViewDetialpopUp}
        particularId={CurrentIdChurch}
      />

      <ApproveChurchPopup
        handleClose={handleClose}
        openApprovepopUp={openApprovepopUp}
        setApprovepopUp={setApprovepopUp}
        particularId={CurrentId}
      />

      <RejectChurchPopup
        handleClose={handleClose}
        openRejectpopUp={openRejectpopUp}
        setRejectpopUp={setRejectpopUp}
        particularId={CurrentId}
      />
    </Box>
  );
}

const tableHeading = {
  fontSize: "16px",
  fontFamily: "Kumbh Sans",
  fontWeight: "bold",
  padding: "15px",
};
const colHeading = {
  fontSize: "14px",
  fontFamily: "Kumbh Sans",
  fontWeight: "bold",
};

const colData = {
  fontSize: "12px",
  fontFamily: "Kumbh Sans",
};

const darkBtn = {
  fontSize: "10px",
  color: "#ffffff",
  background: "#3F292B",
  textTransform: "capitalize",
  fontFamily: "Kumbh Sans",
  border: "1px solid #3F292B",
  mr: 1,
  "&:hover": {
    border: "1px solid #D1AB42",
    background: "#D1AB42",
  },
};

// const loginBtn = {
//   gap: "10px",
//   color: "#ffffff",
//   background: "#D1AB42",
//   textTransform: "capitalize",
//   fontFamily: "Kumbh Sans",
//   border: "1px solid #D1AB42",
//   fontSize: "10px",
//   "&:hover": {
//     border: "1px solid #3F292B",
//     color: "#3F292B",
//   },
// };
const colDataPending = {
  fontSize: "12px",
  fontFamily: "Kumbh Sans",
  color: "orange",
};

const colDataRejected = {
  fontSize: "12px",
  fontFamily: "Kumbh Sans",
  color: "red",
};

const colDataApproved = {
  fontSize: "12px",
  fontFamily: "Kumbh Sans",
  color: "green",
};
