import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
// import DialogContent from "@mui/material/DialogContent";
// import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

import { useFormik } from "formik";
import * as yup from "yup";
import { Box, TextField } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import axios from "axios";
import { useSelector } from "react-redux";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function CustomizedDialogs({
  baseURL,
  ChangePass,
  setChangePass,
  tokenAPI,
}) {
  const [openToastSuccess, setOpenToastSuccess] = React.useState(false);
  const [openToastFailed, setOpenToastFailed] = React.useState(false);
  const [Error, setError] = React.useState(false);

  const authToken = useSelector((state) => state.userAuth.payToken);
  //   console.log({ authToken.data.token });

  const handleCloseToast = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenToastSuccess(false);
    setOpenToastFailed(false);
  };

  const validationSchema = yup.object({
    oldpass: yup
      .string("Enter Old Password.")
      .min(8, "Min 8 character required")
      .required("Old Password is required."),

    newpass: yup
      .string("Enter New Password.")
      .min(8, "Min 8 character required")
      .required("New Password is required."),

    cnewpass: yup
      .string("Enter New Password.")
      .min(8, "Password not matching as above new password")
      .required("New Password is required."),
  });

  const formik = useFormik({
    initialValues: {
      oldpass: "",
      newpass: "",
      cnewpass: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      const { oldpass, newpass, cnewpass } = values;
      if (newpass === cnewpass) {
        const formData = { oldPassword: oldpass, newPassword: newpass };
        onSubmitChange(formData);
        resetForm({ values: "" });
      } else {
        setError(true);
      }
    },
  });

  const onSubmitChange = (data) => {
    const config = {
      headers: { Authorization: `Bearer ${authToken.data.token}` },
    };

    axios
      .post(`${baseURL}/api/v1/auth/updatePassword`, data, config)
      .then((res) => {
        if (res.data.success) {
          setOpenToastSuccess(true);
        } else {
          setOpenToastFailed(true);
        }
      })
      .catch((error) => {
        setOpenToastFailed(true);
      });
  };

  return (
    <BootstrapDialog
      onClose={() => setChangePass(false)}
      aria-labelledby="customized-dialog-title"
      open={ChangePass}
    >
      <Box sx={{ px: 3 }}>
        <form onSubmit={formik.handleSubmit}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={12} sm={7}>
              <Typography sx={labelText}>Old Password</Typography>
              <TextField
                id="oldpass"
                name="oldpass"
                sx={{ width: "100%" }}
                variant="outlined"
                placeholder="Enter Old Password"
                onFocus={() => setError(false)}
                value={formik.values.oldpass}
                onChange={formik.handleChange}
                error={formik.touched.oldpass && Boolean(formik.errors.oldpass)}
                helperText={formik.touched.oldpass && formik.errors.oldpass}
              />
            </Grid>

            <Grid item xs={12} sm={7}>
              <Typography sx={labelText}>New Password</Typography>

              <TextField
                id="newpass"
                name="newpass"
                sx={{ width: "100%" }}
                variant="outlined"
                placeholder="Enter New Password"
                onFocus={() => setError(false)}
                value={formik.values.newpass}
                onChange={formik.handleChange}
                error={formik.touched.newpass && Boolean(formik.errors.newpass)}
                helperText={formik.touched.newpass && formik.errors.newpass}
              />
            </Grid>

            <Grid item xs={12} sm={7}>
              <Typography sx={labelText}>Conferm New Password</Typography>
              <TextField
                id="cnewpass"
                name="cnewpass"
                sx={{ width: "100%" }}
                variant="outlined"
                placeholder="Enter Again New Password"
                onFocus={() => setError(false)}
                value={formik.values.cnewpass}
                onChange={formik.handleChange}
                error={
                  formik.touched.cnewpass && Boolean(formik.errors.cnewpass)
                }
                helperText={formik.touched.cnewpass && formik.errors.cnewpass}
              />
            </Grid>

            {Error && (
              <Typography sx={labelTextError}>
                New Password & Confirm Password not Matching.
              </Typography>
            )}

            <Grid item xs={12} sm={7} align="center">
              <Button variant="outlined" type="submit" sx={registerBtn}>
                Change & Update
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
      <Snackbar
        open={openToastSuccess || openToastFailed}
        autoHideDuration={2000}
        onClose={handleCloseToast}
      >
        <Alert
          onClose={handleCloseToast}
          severity={openToastSuccess ? "success" : "error"}
          sx={{ width: "100%" }}
        >
          {openToastSuccess
            ? "Password changed successfully"
            : "Old password is not matching."}
        </Alert>
      </Snackbar>
    </BootstrapDialog>
  );
}

const labelText = {
  fontSize: "14px",
  fontFamily: "Kumbh Sans",
  fontWeight: 500,
  marginTop: "20px",
  marginBottom: "8px",
};

const labelTextError = {
  fontSize: "14px",
  fontFamily: "Kumbh Sans",
  fontWeight: 500,
  marginTop: "20px",
  marginBottom: "8px",
  color: "darkred",
};

const registerBtn = {
  color: "#ffffff",
  background: "#3F292B",
  fontFamily: "Kumbh Sans",
  fontSize: "10px",
  padding: "9px 18px",
  border: "1px solid #3F292B",
  marginY: "10px",
  "&:hover": {
    border: "1px solid #3F292B",
    color: "#3F292B",
  },
};
