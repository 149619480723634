import React from "react";
// import { useSelector } from "react-redux";
import "./userProfile.css";
import { useNavigate } from "react-router-dom";

import InsideNavbarLight from "../navbar/InsideNavbarLight";
import Navbar from "./Navbar";
import Footer from "../footer";
import VerticalNavbar from "./verticalNavbar";
import UserPicFile from "./userPicFile";
import AttendingEventsList from "./AttendingEventsList";

import MyVideos from "./myVideos";
import Events from "./ourEvents";
import Inquiries from "./MyInquiries";
import MyBlog from "./MyBlog";
import Settings from "./setting";

// ** MUI
import Box from "@mui/material/Box";
import axios from "axios";
const baseURL = process.env.REACT_APP_BASEURL;

const Index = () => {
  const [activeItem, setActiveItem] = React.useState("Dashboard");
  const [tokenApi, setTokenApi] = React.useState("");
  const [userData, setUserData] = React.useState({});
  const [countData, setCountData] = React.useState([]);

  const navigate = useNavigate();

  // ** Check Role Auth
  React.useEffect(() => {
    const getloginLocalInfo = JSON.parse(
      localStorage.getItem("loginLocalInfo")
    );

    setUserData(getloginLocalInfo);
    setTokenApi(getloginLocalInfo.token);
    // onUserDetails(getloginLocalInfo.token);
    onCount(getloginLocalInfo.token);
  }, []);

  React.useEffect(() => {
    if (activeItem === "Contact us") {
      navigate("/contactus");
    }
  }, [activeItem]);

  // *** USERDATA
  // const onUserDetails = (token) => {
  //   const config = {
  //     headers: { Authorization: `Bearer ${token}` },
  //   };

  //   axios
  //     .get(`${baseURL}/api/v1/user`, config)
  //     .then((res) => {
  //       if (res.data.success) {
  //         setUserData(res.data.data);
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  // *** COUNT
  const onCount = (token) => {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    axios
      .get(`${baseURL}/api/v1/user/totalCount`, config)
      .then((res) => {
        if (res.data.success) {
          setCountData(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <Box className="mobile-body" sx={{ display: "flex" }}>
        <Box sx={{ display: { xs: "none", sm: "none", md: "block" } }}>
          <VerticalNavbar
            activeItem={activeItem}
            setActiveItem={setActiveItem}
          />
        </Box>

        <Box sx={{ width: "100%", height: "100%" }}>
          {/* dashboard menu */}
          <Navbar activeItem={activeItem} setActiveItem={setActiveItem} />

          {/* header menu */}
          {/* <InsideNavbarLight /> */}

          {activeItem === "Dashboard" && (
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-evenly",
                mt: 5,
                flexGrow: 1,
              }}
              className="profile-body"
            >
              <UserPicFile
                tokenApi={tokenApi}
                countData={countData}
                userData={userData}
              />
              <AttendingEventsList
                activeItem={activeItem}
                setActiveItem={setActiveItem}
                tokenApi={tokenApi}
                baseURL={baseURL}
              />
            </Box>
          )}

          {activeItem === "My Events" && (
            <Box>
              <Events tokenApi={tokenApi} baseURL={baseURL} />
            </Box>
          )}

          {activeItem === "My Inquiries" && (
            <Box>
              <Inquiries tokenApi={tokenApi} />
            </Box>
          )}

          {activeItem === "My Videos" && (
            <Box align="center" className="mobile-responsive-body">
              <MyVideos />
            </Box>
          )}

          {activeItem === "My Blog" && (
            <Box align="center" className="mobile-responsive-body">
              <MyBlog tokenApi={tokenApi} />
            </Box>
          )}

          {activeItem === "Settings" && (
            <Box>
              <Settings userData={userData} tokenApi={tokenApi} />
            </Box>
          )}
        </Box>
      </Box>
      <Footer />
    </>
  );
};

export default Index;
