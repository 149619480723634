import React from "react";
import "./userProfile.css";
// import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
// import { MuiPickersUtilsProvider } from "@material-ui/pickers";
// import DateFnsUtils from "@date-io/date-fns";
import axios from "axios";
import InsideNavbarLight from "../navbar/InsideNavbarLight";
// import Navbar from "./Navbar";
import Footer from "../footer";
import VerticalNavbar from "./verticalNavbar";
import ChurchPicFile from "./churchPicFile";
import AttendingEventsList from "./AttendingEventsList";
import MyVideos from "./myVideos";
// import ContactUS from "./contactUs";
// import Contacthead from "../ContactUs/contactHead";
// import Contactform from "../ContactUs/contactform";
import Events from "./OurEvents";
import Inquiries from "./InquiryRecieved";
import Settings from "./setting2";

// ** MUI
import Box from "@mui/material/Box";
import OurStaff from "./OurStaff";
import OurPhotos from "./OurPhotos";

const baseURL = process.env.REACT_APP_BASEURL;

const Index = () => {
  const [activeItem, setActiveItem] = React.useState("Dashboard");
  const [tokenApi, setTokenApi] = React.useState("");
  const [StaffMemberChurch, setStaffMemberChurch] = React.useState([]);
  const [CountData, setCountData] = React.useState("");
  const [openAddStaff, setOpenAddStaff] = React.useState(false);
  const [onActionOpen, setOnActionOpen] = React.useState(false);
  const [upload, setUpload] = React.useState(false);
  const [Loader, setLoader] = React.useState(false);

  const [userRole, setUserRole] = React.useState('');



  // ** User Local Auth
  const [userData, setUserData] = React.useState([]);
  const [trig, setTrig] = React.useState(false);

  const navigate = useNavigate();

  // ** Check Role Auth
  React.useEffect(() => {
    const getloginLocalInfo = JSON.parse(
      localStorage.getItem("loginLocalInfo")
    );

    const config = {
      headers: {
        Authorization: `Bearer ${getloginLocalInfo.token}`,
      },
    };

    setUserRole(getloginLocalInfo.role)
    setTokenApi(getloginLocalInfo.token);
    getChurchDetails(config);
    getStaffDetails(config);
    getDashboardCount(config);
  }, [trig, openAddStaff, onActionOpen, upload, Loader]);

  console.log("userRole", userRole);

  const getChurchDetails = async (tt) => {
    await axios
      .get(`${baseURL}/api/v1/church`, tt)
      .then((response) => {
        if (response.data.success) {
          setUserData(response.data.data);
          setLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  React.useEffect(() => {
    if (activeItem === "Contact us") {
      navigate("/contactus");
    }
  }, [activeItem]);

  const getStaffDetails = async (tt) => {
    await axios
      .get(`${baseURL}/api/v1/church/staff/getStaffDetails`, tt)
      .then((response) => {
        if (response.data.success) {
          setStaffMemberChurch(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getDashboardCount = async (tt) => {
    await axios
      .get(`${baseURL}/api/v1/church/totalCount`, tt)
      .then((response) => {
        if (response.data.success) {
          setCountData(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <Box className="mobile-body" sx={{ display: "flex" }}>
        <Box sx={{ display: { xs: "none", sm: "none", md: "block" } }}>
          <VerticalNavbar
            activeItem={activeItem}
            setActiveItem={setActiveItem}
            userRole={userRole}
          />
        </Box>

        <Box sx={{ width: "100%" }}>
          {/* header menu */}
          <InsideNavbarLight />

          {/* <Navbar activeItem={activeItem} setActiveItem={setActiveItem} /> */}

          {activeItem === "Dashboard" && (
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-evenly",
                mt: 5,
                gap: "20px",
              }}
              className="profile-body"
            >
              <ChurchPicFile
                userData={userData}
                activeItem={activeItem}
                setActiveItem={setActiveItem}
                CountData={CountData}
                userRole={userRole}
              />
              <AttendingEventsList
                activeItem={activeItem}
                userData={userData}
                setActiveItem={setActiveItem}
                staffDataRedux={StaffMemberChurch}
                baseURL={baseURL}
                userRole={userRole}
              />
            </Box>
          )}

          {activeItem === "Our Events" && (
            <Box className="profile-body">
              <Events tokenApi={tokenApi} userRole={userRole} />
            </Box>
          )}
          {activeItem === "Inquiry Recieved" && (
            <Box className="profile-body">
              <Inquiries tokenApi={tokenApi} />
            </Box>
          )}

          {activeItem === "Our Album" && (
            <Box className="profile-body">
              <OurPhotos
                userData={userData}
                tokenApi={tokenApi}
                upload={upload}
                setUpload={setUpload}
                Loader={Loader}
                setLoader={setLoader}
              />
            </Box>
          )}



          {activeItem === "My Videos" && (
            <Box>
              <MyVideos
                userData={userData}
                baseURL={baseURL}
                tokenApi={tokenApi}
                Loader={Loader}
                setLoader={setLoader}
              />
            </Box>
          )}

          {activeItem === "Our Staff" && (
            <Box>
              <OurStaff
                tokenApi={tokenApi}
                StaffMemberChurch={StaffMemberChurch}
                openAddStaff={openAddStaff}
                setOpenAddStaff={setOpenAddStaff}
                onActionOpen={onActionOpen}
                setOnActionOpen={setOnActionOpen}
              />
            </Box>
          )}


          {activeItem === "Settings" && (
            <Box>
              <Settings
                userData={userData}
                setTrig={setTrig}
                trig={trig}
                Loader={Loader}
                setLoader={setLoader}
              />
            </Box>
          )}
        </Box>
      </Box>
      <Footer />
    </>
  );
};

export default Index;
