import React from "react";
import { latestBlog } from "./LatestBlogData";
import ViewBlog from "./ViewBlog";

// Import slider & css files
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { settings } from "./sliderProps";
import "./blog.css";

// ** MUI
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { CardActionArea } from "@mui/material";

import axios from "axios";
const baseURL = process.env.REACT_APP_BASEURL;

const LatestBlog = () => {
  const [LatestBlog, setLatestBlog] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [BlogViewData, setBlogViewData] = React.useState([]);

  React.useEffect(() => {
    // const config = {
    //   headers: { Authorization: `Bearer ${reduxPayToken.data.token}` },
    // };

    axios
      .get(`${baseURL}/api/v1/blog/getAllBlogs`)
      .then((response) => {
        if (response.data.success) {
          setLatestBlog(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <Box sx={{ position: "relative" }} align="center">
      <Typography sx={subHeading}>Latest blogs</Typography>

      <Box>
        <Slider {...settings}>
          {LatestBlog?.map((data, ind) => {
            return (
              <Card
                sx={{ maxWidth: "90%" }}
                key={ind}
                onClick={() => {
                  setOpen(true);
                  setBlogViewData(data);
                }}
              >
                <CardActionArea>
                  <CardMedia
                    component="img"
                    height="180"
                    image={data.imageUrl}
                    alt="man"
                    sx={{ borderRadius: "8px" }}
                  />
                  <CardContent align="left">
                    <Typography
                      gutterBottom
                      sx={{
                        fontFamily: "Kumbh Sans",
                        fontSize: "10px",
                        fontWeight: "bold",
                        color: "#D1AB42",
                      }}
                    >
                      {data?.authur?.fullName}
                    </Typography>
                    <Typography
                      gutterBottom
                      sx={{
                        fontFamily: "Kumbh Sans",
                        fontSize: "20px",
                        fontWeight: "bold",
                      }}
                    >
                      {data.title}
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: "Kumbh Sans",
                        fontSize: "13px",
                        color: "#91857F",
                      }}
                    >
                      {data?.body?.substring(0, 50)} {" read more..."}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            );
          })}
        </Slider>
      </Box>

      <ViewBlog setOpen={setOpen} open={open} BlogViewData={BlogViewData} />
    </Box>
  );
};

export default LatestBlog;

const subHeading = {
  fontFamily: "Kumbh Sans",
  fontSize: "20px",
  fontWeight: "bold",
  marginY: "20px",
  display: "flex",
  justifyContent: "space-between",
  marginLeft: "15px",
};
