import React from "react";
import Button from "@mui/material/Button";
import { Box, IconButton, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";

import { closeIcon } from "../navbar/closeIcon";

import NotIcon from "../../assets/icons/NotRegisterIcon.png";
import { useNavigate } from "react-router-dom";

const Thanksdialog = ({ openRegister, setOpenRegister }) => {
  const navigate = useNavigate();
  const button2 = {
    backgroundColor: "#3F292B",
    borderRadius: "8px",
    fonFamily: "Kumbh Sans",
    fontWeight: "bold",
    fontSize: "10px",
    padding: "14px 50px",

    ":hover": {
      backgroundColor: "#3F292B",
      color: "white",
    },
  };

  return (
    <>
      <Dialog open={openRegister}>
        <Box align="center" sx={{ padding: "14px" }}>
          <IconButton
            sx={{
              position: "absolute",
              top: "4px",
              right: "4px",
            }}
            onClick={() => setOpenRegister(false)}
          >
            {closeIcon()}
          </IconButton>
          <img src={NotIcon} alt="check" width={"90px"} />

          <Typography
            sx={{
              marginTop: "10px",
              fontFamily: "Kumbh Sans",
              fontSize: "24px",
              fontWeight: "bold",
            }}
          >
            You're not register or login.
          </Typography>

          <Typography
            sx={{
              marginY: "10px",
              fontFamily: "Kumbh Sans",
              fontSize: "14px",
            }}
          >
            Only registered & login users are able to send inquries to
            <br />
            Church/Organization/Ministries
          </Typography>
        </Box>

        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-evenly",
            margin: "0 10px 10px",
          }}
        >
          <Button
            onClick={() => navigate("/registration")}
            variant="contained"
            sx={button2}
            className="button"
          >
            Register / Login
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Thanksdialog;
