// ** MUI
import React from "react";
import InsideNavbar from "../navbar/InsideNavbar";
import Footer from "../footer";
import DialogRegister from "./dialogNotRegister";
import DialogInquery from "./dialogInqueryResponse";

import { Box, Stack, Button, Typography } from "@mui/material";
// import Radio from "@mui/material/Radio";
// import RadioGroup from "@mui/material/RadioGroup";
// import FormControlLabel from "@mui/material/FormControlLabel";
// import FormControl from "@mui/material/FormControl";
// import { Grid } from "@mui/material";
import TextField from "@mui/material/TextField";
import { useParams } from "react-router-dom";
import axios from "axios";
// import moment from "moment";
const baseURL = process.env.REACT_APP_BASEURL;

const InqueryPage = () => {
  // const navigate = useNavigate();
  let { id } = useParams();
  const [openRegister, setOpenRegister] = React.useState(false);
  const [openInquery, setInquery] = React.useState(false);

  const [inquireData, setInquireData] = React.useState({
    topic: "Others",
    contact: "",
    question: "",
  });
  // console.log("datadatadata >>", inquireData);
  const inquerySend = () => {
    const getloginLocalInfo = JSON.parse(
      localStorage.getItem("loginLocalInfo")
    );

    if (getloginLocalInfo) {
      const config = {
        headers: { Authorization: `Bearer ${getloginLocalInfo.token}` },
      };

      const PayData = {
        inquiryType: inquireData.topic,
        contact: inquireData.contact,
        inquiryDescription: inquireData.question,
      };

      axios
        .put(`${baseURL}/api/v1/inquiry/sendInquiry/${id}`, PayData, config)
        .then((res) => {
          if (res.data.success) {
            setInquery(true);
            setInquireData({
              topic: "Others",
              contact: "",
              question: "",
            });
          }
        })
        .catch((error) => {
          console.log(error);
          setOpenRegister(true);
        });
    } else {
      setOpenRegister(true);
    }
  };

  return (
    <>
      <InsideNavbar />
      <Box sx={{ my: 5 }}>
        <Box align="center">
          <Typography sx={eventsHeadingg} className="heading-album">
            Send Inquiry
            {/* to&nbsp;
            <Typography sx={eventsHeading} className="heading-album">
              Church 
            </Typography> */}
          </Typography>
        </Box>
      </Box>

      <Box align="center">
        <Box sx={{ display: "flex", justifyContent: "center", pb: 5 }}>
          {/* <Grid item container align="center"> */}
          {/* <Grid item xs={12} sm={5} align="center">
            <FormControl
              sx={{
                display: "flex",
                alignItems: "center",
                alignContent: "center",
                mb: 5,
              }}
            >
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="Wedding ceremony"
                name="radio-buttons-group"
                value={inquireData.topic}
                onChange={(e) =>
                  setInquireData({
                    ...inquireData,
                    topic: e.target.value,
                  })
                }
              >
                <FormControlLabel
                  value="Baptism"
                  control={<Radio sx={btncolor} />}
                  label={<Typography sx={txt}>Baptism</Typography>}
                />
                <FormControlLabel
                  value="Wedding ceremony"
                  control={<Radio sx={btncolor} />}
                  label={
                    <Typography sx={txt}>Wedding&nbsp;ceremony</Typography>
                  }
                />
                <FormControlLabel
                  value="Baby dedication"
                  control={<Radio sx={btncolor} />}
                  label={<Typography sx={txt}>Baby dedication</Typography>}
                />
                <FormControlLabel
                  value="Funeral service"
                  control={<Radio sx={btncolor} />}
                  label={<Typography sx={txt}>Funeral service</Typography>}
                />
                <FormControlLabel
                  value="Others"
                  control={<Radio sx={btncolor} />}
                  label={<Typography sx={txt}>Others</Typography>}
                />
              </RadioGroup>
            </FormControl>
          </Grid> */}

          {/* <Grid item xs={12} sm={6} align="center"> */}
          {/* <Form /> */}
          <Stack
          // sx={{
          //   mr: {
          //     xs: "0px",
          //     sm: "0px",
          //     md: "0px",
          //     lg: "324px",
          //     xl: "324px",
          //   },
          // }}
          >
            <TextField
              label={<Typography sx={text}>Your Contact Number</Typography>}
              variant="outlined"
              sx={{
                width: {
                  xs: "auto",
                  sm: "auto",
                  md: "450px",
                  lg: "576px",
                  xl: "776px",
                },
                height: "60px",
                "& fieldset": { borderRadius: "4px" },
              }}
              value={inquireData.contact}
              onChange={(e) =>
                setInquireData({
                  ...inquireData,
                  contact: e.target.value,
                })
              }
            />

            <TextField
              label={<Typography sx={text}>Type Here</Typography>}
              variant="outlined"
              multiline
              rows={5}
              sx={{
                mt: "20px",
                width: {
                  xs: "auto",
                  sm: "auto",
                  md: "450px",
                  lg: "576px",
                  xl: "776px",
                },
                "& fieldset": { borderRadius: "4px" },
              }}
              value={inquireData.question}
              onChange={(e) =>
                setInquireData({
                  ...inquireData,
                  question: e.target.value,
                })
              }
            />

            <Button onClick={inquerySend} sx={signupBtn}>
              SEND INQUIRY
            </Button>
          </Stack>
        </Box>
        {/* </Grid>
        </Grid> */}
      </Box>

      <DialogRegister
        openRegister={openRegister}
        setOpenRegister={setOpenRegister}
      />

      <DialogInquery openInquery={openInquery} setInquery={setInquery} />

      <Footer />
    </>
  );
};

export default InqueryPage;

// ** Styles
const signupBtn = {
  color: "#ffffff",
  background: "#3F292B",
  textTransform: "capitalize",
  fontFamily: "Kumbh Sans",
  border: "1px solid #3F292B",
  my: 2,
  py: 1.2,
  width: "160px",
  fontSize: "11px",
  "&:hover": {
    border: "1px solid #3F292B",
    color: "#3F292B",
  },
};

const txt = {
  fontFamily: "Kumbh Sans",
  fontWeight: "Regular",
  fontSize: "18px",
  color: "#240C00",
};

const eventsHeading = {
  fontSize: "2rem",
  fontFamily: "Kumbh Sans",
  fontWeight: "bold",
  color: "#D1AB42",
  display: "inline",
  justifyContent: "center",
};
const eventsHeadingg = {
  fontSize: "2rem",
  fontFamily: "Kumbh Sans",
  fontWeight: "bold",
  color: "#000000",
  justifyContent: "center",
  display: "inline",
};
const text = {
  fontFamily: "Kumbh Sans",
  fontSize: "13px",
  color: "#91857F",
};

const btncolor = {
  fontSize: "20px",
  "&.Mui-checked": {
    color: "#D1AB42",
  },
};
