import React from "react";
import InsideNavbar from "../navbar/InsideNavbar";
import Footer from "../footer";
import "./registration.css";
import SuccessDialog from "./successDialog";
// import OTPchurch from "./OTPchurch";
import axios from "axios";
import OtpInput from "react-otp-input";
import { useDispatch } from "react-redux";
import { paymentToken } from "../../redux/userAuthSlice";

// ** images
// import Church from "../../assets/images/church1.jpg";
// import UserDark from "../../assets/images/userDark1.jpg";
import Package1 from "../../assets/images/churchPackage1.jpg";
import uploadIcon from "../../assets/images/uploadIcon.png";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

// ** router
// import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";

// ** MUI
// import Snackbar from "@mui/material/Snackbar";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
// import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { useNavigate } from "react-router-dom";

import LoadingButton from "@mui/lab/LoadingButton";
import {
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
} from "@mui/material";

// import Select from "@mui/material/Select";
// import MenuItem from "@mui/material/MenuItem";

const baseURL = process.env.REACT_APP_BASEURL;
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ChurchRegister = () => {
  const [open, setOpen] = React.useState(false);
  // const [profileImg, setProfileImg] = React.useState();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [showChurchOTP, setShowChurchOTP] = React.useState(false);
  const [uploadImg1, setuploadImg1] = React.useState(null);
  const [uploadImg, setuploadImg] = React.useState(null);
  const [OtpToast, setOtpToast] = React.useState(false);
  const [otp, setOtp] = React.useState();
  const [EmailForOTP, setEmailForOTP] = React.useState("");
  const [AfterOTPToken, setAfterOTPToken] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [EmailError, setEmailError] = React.useState(false);
  const [RoleType, setRoleType] = React.useState("Church");

  const handleCloseToast = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOtpToast(false);
  };

  const onAddImage = (file) => {
    setuploadImg(file.target.files[0]);
    window.URL.revokeObjectURL(uploadImg1);
    setuploadImg1(window.URL.createObjectURL(file.target.files[0]));
  };

  const validationSchema = yup.object({
    // pastorName: yup
    //   .string("Enter pastor name / lead name")
    //   .min(3, "min 3 character required")
    //   .required("Name is required."),

    fullName: yup
      .string("Enter contact name")
      .min(3, "min 3 character required")
      .required("Fullname is required."),

    // role: yup
    //   .string("Select Account Type")
    //   .required("Account type is required."),

    mobile: yup
      .string("Enter contact mobile")
      .min(8, "Wrong input required")
      .required("Mobile number is required."),

    email: yup
      .string("Enter your email")
      .email("Enter a valid email")
      .required("Email is required."),
    password: yup
      .string("Enter your password")
      .min(8, "Password should be of minimum 8 characters length")
      .required("Password is required."),

    cpassword: yup
      .string("Enter your password")
      .min(8, "Password not match")
      .required("Confirm password is required."),
  });

  const formik = useFormik({
    initialValues: {
      pastorName: "",
      fullName: "",
      mobile: "",
      email: "",
      password: "",
      cpassword: "",
      role: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      const { pastorName, fullName, email, mobile, password, role } = values;

      setEmailForOTP(email);
      // const payData = {
      //   pastorName,
      //   fullName,
      //   mobile,
      //   email,
      //   password,
      //   role,
      // };

      let payData1 = new FormData();
      payData1.append("profile", uploadImg);
      if (RoleType !== "Artist") payData1.append("pastorName", pastorName);
      payData1.append("fullName", fullName);
      payData1.append("email", email);
      payData1.append("mobile", mobile);
      payData1.append("password", password);
      payData1.append("role", RoleType);

      registerUserData(payData1);
    },
  });

  const registerUserData = async (payData1) => {
    setLoading(true);

    await axios
      .post(`${baseURL}/api/v1/auth/signup`, payData1)
      .then((response) => {
        if (response.data.success) {
          setOtpToast(true);
          setShowChurchOTP(true);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status === 409) {
          setEmailError(true);
          setShowChurchOTP(false);
          setLoading(false);
        }
      });
  };

  const onSubmitOTP = () => {
    setLoading(true);
    const data = {
      email: EmailForOTP,
      otp: parseInt(otp),
    };
    axios
      .post(`${baseURL}/api/v1/auth/verifyOtp`, data)
      .then((response) => {
        if (response.data.success) {
          setAfterOTPToken(response.data);

          // removed-payment
          // navigate("/paymentDetails"); 
          // dispatch(paymentToken(response?.data));
          // console.log("token from OTP", response.data);

          formik.resetForm({ values: "" });
          setOtpToast(false);
          setOtp()
          setLoading(false);
          setOpen(true)
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <InsideNavbar />

      <Box
        sx={{
          marginTop: "5%",
        }}
        className="mobile-Body"
      >
        <Typography
          variant="h1"
          align="center"
          sx={{
            fontFamily: "Kumbh Sans",
            fontSize: "30px",
            fontWeight: "bold",
            display: "flex",
            justifyContent: "center",
          }}
        >
          Sign Up
        </Typography>

        <form onSubmit={formik.handleSubmit}>
          <Box>
            <Box align="center" component="label">
              <Box sx={uploadPicSection}>
                {uploadImg1 && <img src={uploadImg1} alt="kk" />}
                {!uploadImg1 && (
                  <>
                    <Box>
                      <img src={uploadIcon} alt="img" width={20} />
                    </Box>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontFamily: "Kumbh Sans",
                        marginTop: "20px",
                      }}
                    >
                      Upload&nbsp;Photo
                    </Typography>
                  </>
                )}
                <input
                  type="file"
                  accept="image/png, image/jpeg"
                  hidden
                  onChange={(e) => {
                    onAddImage(e);
                  }}
                />
              </Box>

              {EmailError && (
                <Typography sx={labelTextError}>
                  This Email is Already Exist.
                </Typography>
              )}

              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  defaultValue="Church"
                >
                  <FormControlLabel
                    onClick={() => setRoleType("Church")}
                    value="Church"
                    control={<Radio />}
                    label="Church"
                  />
                  <FormControlLabel
                    onClick={() => setRoleType("Artist")}
                    value="Artist"
                    control={<Radio />}
                    label="Artist"
                  />

                  <FormControlLabel
                    onClick={() => setRoleType("Ministry")}
                    value="Ministry"
                    control={<Radio />}
                    label="Ministry"
                  />
                </RadioGroup>
              </Box>
            </Box>

            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={2}
            >
              <Grid
                item
                xs={12}
                sm={4}
                sx={{ display: RoleType === "Artist" ? "none" : "block" }}
              >
                <Typography sx={labelText}>
                  {RoleType === "Ministry"
                    ? "Name Of Founder"
                    : "Pastor Or Lead Name"}
                </Typography>
                <TextField
                  id="pastorName"
                  name="pastorName"
                  sx={{ width: "100%" }}
                  variant="outlined"
                  placeholder="Type here"
                  value={formik.values.pastorName}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.pastorName &&
                    Boolean(formik.errors.pastorName)
                  }
                  helperText={
                    formik.touched.pastorName && formik.errors.pastorName
                  }
                />
              </Grid>
              <Grid item xs={12} sm={RoleType === "Artist" ? 8 : 4}>
                <Typography sx={labelText}>
                  {RoleType === "Artist"
                    ? "First Name & Last Name"
                    : RoleType === "Ministry"
                      ? "Ministry Name"
                      : "Church Name"}
                </Typography>

                <TextField
                  id="fullName"
                  name="fullName"
                  sx={{ width: "100%" }}
                  variant="outlined"
                  placeholder="Type here"
                  value={formik.values.fullName}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.fullName && Boolean(formik.errors.fullName)
                  }
                  helperText={formik.touched.fullName && formik.errors.fullName}
                />
              </Grid>
            </Grid>

            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={2}
            >
              <Grid item xs={12} sm={4}>
                <Typography sx={labelText}>Contact Mobile</Typography>
                <TextField
                  id="mobile"
                  name="mobile"
                  sx={{ width: "100%" }}
                  variant="outlined"
                  placeholder="Type here"
                  value={formik.values.mobile}
                  onChange={formik.handleChange}
                  error={formik.touched.mobile && Boolean(formik.errors.mobile)}
                  helperText={formik.touched.mobile && formik.errors.mobile}
                />
              </Grid>

              {/* <Grid item xs={12} sm={2.5}>
                <Typography sx={labelText}>
                  Church / Artist / Ministry
                </Typography>

                <Select
                  id="role"
                  name="role"
                  sx={{ width: "100%" }}
                  variant="outlined"
                  value={formik.values.role}
                  onChange={formik.handleChange}
                  error={formik.touched.role && Boolean(formik.errors.role)}
                  helperText={formik.touched.role && formik.errors.role}
                >
                  <MenuItem value={"Church"}>Church</MenuItem>
                  <MenuItem value={"Artist"}>Artist</MenuItem>
                  <MenuItem value={"Ministry"}>Ministry</MenuItem>
                </Select>
              </Grid> */}
              <Grid item xs={12} sm={4}>
                <Typography sx={labelText}>Contact Email</Typography>

                <TextField
                  id="email"
                  name="email"
                  sx={{ width: "100%" }}
                  variant="outlined"
                  onFocus={() => setEmailError(false)}
                  placeholder="Type here"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
              </Grid>
            </Grid>

            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={2}
            >
              <Grid item xs={12} sm={4}>
                <Typography sx={labelText}>Password</Typography>
                <TextField
                  id="password"
                  name="password"
                  type="password"
                  sx={{ width: "100%" }}
                  variant="outlined"
                  placeholder="Type here"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.password && Boolean(formik.errors.password)
                  }
                  helperText={formik.touched.password && formik.errors.password}
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <Typography sx={labelText}>Confirm Password</Typography>

                <TextField
                  id="cpassword"
                  name="cpassword"
                  type="password"
                  sx={{ width: "100%" }}
                  variant="outlined"
                  placeholder="Type here"
                  value={formik.values.cpassword}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.cpassword && Boolean(formik.errors.cpassword)
                  }
                  helperText={
                    formik.touched.cpassword && formik.errors.cpassword
                  }
                />
              </Grid>
            </Grid>

            <SuccessDialog open={open} setOpen={setOpen} />

            {/* <OTPchurch
              setShowChurchOTP={setShowChurchOTP}
              showChurchOTP={showChurchOTP}
            /> */}

            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
                marginY: "30px",
              }}
            >
              <Box
                sx={{
                  position: "relative",
                }}
              >
                <Box>
                  <img
                    src={Package1}
                    alt="img"
                    width="400"
                    className="card-width-mobile"
                  />
                </Box>

                {!showChurchOTP && (
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      gap: "40px",

                      position: "absolute",
                      top: 40,
                      left: "32%",
                    }}
                  >
                    {/* <Box
                      className="package"
                    >
                      <Typography sx={cardHeading1}>PACKAGE PRICE</Typography>
                      <Typography sx={cardHeading2}>$20/month</Typography>
                    </Box> */}

                    <Box sx={regBtn}>
                      <LoadingButton
                        sx={loading ? loginBtnLoading : registerBtn}
                        loading={loading}
                        loadingPosition="end"
                        type="submit"
                        className="btn-register-mobile"
                        variant="outlined"
                      >
                        <span>{loading ? "Sending OTP" : "Register Now"}</span>
                      </LoadingButton>
                    </Box>
                  </Box>
                )}

                {showChurchOTP && (
                  <Box
                    sx={{
                      position: "absolute",
                      width: "100%",
                      top: 20,

                      display: "flex",
                      justifyContent: "center",
                    }}
                    classname="register-card-data"
                  >
                    <Box sx={{ margin: "auto" }}>
                      <OtpInput
                        value={otp}
                        onChange={(e) => setOtp(e)}
                        numInputs={4}
                        separator={<span> &nbsp;&nbsp; </span>}
                        placeholder="0000"
                        inputStyle="otpStyle"
                      />
                      <br />
                      <Box sx={regBtn}>
                        <LoadingButton
                          sx={loading ? loginBtnLoading : registerBtn}
                          loading={loading}
                          loadingPosition="end"
                          onClick={onSubmitOTP}
                          className="btn-register-mobile"
                          variant="outlined"
                        >
                          <span>{loading ? "Verifying" : "Verify"}</span>
                        </LoadingButton>
                      </Box>
                    </Box>
                  </Box>
                )}

              </Box>
            </Box>
          </Box>
        </form>
      </Box>

      <Snackbar
        open={OtpToast}
        autoHideDuration={5000}
        onClose={handleCloseToast}
      >
        <Alert
          onClose={handleCloseToast}
          severity="success"
          sx={{ width: "100%" }}
        >
          OTP Send, Check Your Mail.
        </Alert>
      </Snackbar>

      <Footer />
    </>
  );
};

export default ChurchRegister;
const regBtn = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginX: "auto",
};
const labelText = {
  fontSize: "14px",
  fontFamily: "Kumbh Sans",
  fontWeight: 500,
  marginTop: "20px",
  marginBottom: "8px",
};

const uploadPicSection = {
  border: "1px solid",
  width: "90px",
  height: "90px",
  padding: "20px 20px",
  display: "flex",
  borderRadius: 2,
  borderColor: "grey.500",
  borderStyle: "dashed",
  justifyContent: "center",
  margin: "10px 45%",
  flexDirection: "column",
};

const cardHeading1 = {
  color: "#ffffff",
  fontSize: "14px",
  fontWeight: "bold",
  fontFamily: "Kumbh Sans",
};

const cardHeading2 = {
  color: "#ffffff",
  fontSize: "20px",
  fontWeight: "bold",
  fontFamily: "Kumbh Sans",
};

const cardText = {
  color: "#ffffff",
  fontSize: "11px",
  fontFamily: "Kumbh Sans",
  opacity: "0.7",
};

const registerBtn = {
  color: "#ffffff",
  background: "#3F292B",
  fontFamily: "Kumbh Sans",
  fontSize: "10px",
  padding: "12px 32px",
  border: "1px solid #3F292B",
  "&:hover": {
    border: "1px solid #3F292B",
    color: "#3F292B",
  },
};
const loginBtnLoading = {
  gap: "10px",
  color: "#ffffff",
  // background: "#D1AB42",
  textTransform: "capitalize",
  border: "1px solid grey",
  fontSize: "14px",
  fontFamily: "Kumbh Sans",
  fontWeight: 500,
  px: 2,
};
const labelTextError = {
  fontSize: "14px",
  fontFamily: "Kumbh Sans",
  fontWeight: 500,
  marginTop: "20px",
  marginBottom: "8px",
  color: "darkred",
};
