import React from "react";
import "./events.css";
import "../FirstPage/firstpage.css";
import Footer from "../footer";
import InsideNavbar from "../navbar/InsideNavbar";
// ** MUI
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
// import { useNavigate } from "react-router-dom";
const ChurchAlbum = () => {
  // const navigate = useNavigate();

  return (
    <>
      <InsideNavbar />

      <Box sx={{ marginBottom: "30px" }} className="mobile-responsive-body">
        <Typography sx={eventsHeadingg} className="heading-album">
          Photos of&nbsp;
          <Typography sx={eventsHeading} className="heading-album">
            Saint Peters Church
          </Typography>
        </Typography>
      </Box>
      <Footer />
    </>
  );
};

export default ChurchAlbum;
const eventsHeading = {
  fontSize: "2rem",
  fontFamily: "Kumbh Sans",
  fontWeight: "bold",
  color: "#D1AB42",
  display: "flex",
  justifyContent: "center",
};
const eventsHeadingg = {
  fontSize: "2rem",
  fontFamily: "Kumbh Sans",
  fontWeight: "bold",
  color: "#000000",
  justifyContent: "center",
  display: "flex",
  flexWrap: "wrap",
};
