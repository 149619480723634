// ** Icons
import GridViewIcon from "@mui/icons-material/GridView";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import InsertCommentOutlinedIcon from "@mui/icons-material/InsertCommentOutlined";
// import VideocamOutlinedIcon from "@mui/icons-material/VideocamOutlined";
// import HeadsetMicOutlinedIcon from "@mui/icons-material/HeadsetMicOutlined";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";

export const dashboardItem = [
  {
    name: "Dashboard",
    icon: <GridViewIcon fontSize="small" />,
  },
  {
    name: "My Events",
    paths: "/events",
    icon: <CalendarMonthIcon fontSize="small" />,
  },
  {
    name: "My Inquiries",
    icon: <InsertCommentOutlinedIcon fontSize="small" />,
  },
  // {
  //   name: "My Videos",
  //   icon: <VideocamOutlinedIcon fontSize="small" />,
  // },

  // {
  //   name: "My Blog",
  //   icon: <InsertCommentOutlinedIcon fontSize="small" />,
  // },

  // {
  //   name: "Contact us",
  //   icon: <HeadsetMicOutlinedIcon fontSize="small" />,
  // },
  {
    name: "Settings",
    icon: <TuneOutlinedIcon fontSize="small" />,
  },
];
