import React from "react";
import InsideNavbar from "../navbar/InsideNavbar";
import Footer from "../footer";
import "./registration.css";
// import SuccessDialog from "./successDialog";

// import { makeStyles } from "@material-ui/core/styles";

// ** images
// import Church from "../../assets/images/church1.jpg";
import uploadIcon from "../../assets/images/uploadIcon.png";
import { religionItem } from "../../constant/ReligionItems";

// ** router
// import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";

// ** MUI
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
// import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import OtpInput from "react-otp-input";
import LoadingButton from "@mui/lab/LoadingButton";

// ** APIs
import axios from "axios";
const baseURL = process.env.REACT_APP_BASEURL;

const UserRegister = () => {
  const [open, setOpen] = React.useState(false);
  // const [userdata, setUserdata] = React.useState({});
  // const [profileImg, setProfileImg] = React.useState();
  const [uploadImg, setuploadImg] = React.useState(null);
  const [uploadImg1, setuploadImg1] = React.useState(null);
  const [showChurchOTP, setShowChurchOTP] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [otp, setOtp] = React.useState();
  const [EmailForOTP, setEmailForOTP] = React.useState("");
  const [PassError, setPassError] = React.useState(false);
  const [EmailError, setEmailError] = React.useState(false);

  const onAddImage = (file) => {
    setuploadImg(file.target.files[0]);
    window.URL.revokeObjectURL(uploadImg1);
    setuploadImg1(window.URL.createObjectURL(file.target.files[0]));
  };
  // const uploadImages = (e) => {
  //   setProfileImg(e.target.files);
  // };

  const validationSchema = yup.object({
    fullName: yup
      .string("Enter your fullName")
      .min(3, "min 3 character required")
      .required("Fullname is required."),

    email: yup
      .string("Enter your email")
      .email("Enter a valid email")
      .required("Email is required."),

    age: yup.string("Select your age range").required("Age is required."),

    religion: yup
      .string("Select your religion")
      .required("Religion is required."),
    password: yup
      .string("Enter your password")
      .min(8, "Password should be of minimum 8 characters length")
      .required("Password is required."),

    cpassword: yup
      .string("Confirm again your password")
      .min(8, "Password not matching.")
      .required("Confirm password is required."),
  });

  const formik = useFormik({
    initialValues: {
      fullName: "",
      email: "",
      age: "selectPlaceholder",
      religion: "selectR",
      password: "",
      cpassword: "",
      role: "User",
    },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      const { fullName, email, age, religion, password, cpassword, role } =
        values;
      // const payData = {
      //   fullName,
      //   email,
      //   age,
      //   religion,
      //   password,
      //   role,
      // };

      setEmailForOTP(email);

      if (password === cpassword) {
        let payData1 = new FormData();
        payData1.append("profile", uploadImg);
        payData1.append("fullName", fullName);
        payData1.append("email", email);
        payData1.append("age", age);
        payData1.append("religion", religion);
        payData1.append("password", password);
        payData1.append("role", role);

        registerUserData(payData1);
        resetForm({ values: "" });
      } else {
        setPassError(true);
      }
    },
  });

  const registerUserData = async (payData1) => {
    setLoading(true);

    await axios
      .post(`${baseURL}/api/v1/auth/signup`, payData1)
      .then((response) => {
        if (response.data.success) {
          setOpen(true);
          setShowChurchOTP(true);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status === 409) {
          setEmailError(true);
          setShowChurchOTP(false);
          setLoading(false);
        }
      });
  };

  const onSubmitOTP = () => {
    setLoading(true);

    const data = {
      email: EmailForOTP,
      otp: parseInt(otp),
    };
    axios
      .post(`${baseURL}/api/v1/auth/verifyOtp`, data)
      .then((response) => {
        if (response.data.success) {
          setLoading(false);
          setShowChurchOTP(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <InsideNavbar />

      <Box
        sx={{
          marginTop: "5%",
        }}
        className="mobile-Body"
      >
        <Typography
          variant="h1"
          align="center"
          sx={{
            fontFamily: "Kumbh Sans",
            fontSize: "30px",
            fontWeight: "bold",
            display: "flex",
            justifyContent: "center",
          }}
        >
          Sign Up
          {/* for a <Box sx={{ color: "#D1AB42" }}>&nbsp; user</Box> */}
        </Typography>
        {/* <Typography
          align="center"
          sx={{
            marginX: "15%",
            marginTop: "13px",
            fontFamily: "Kumbh Sans",
            fontSize: "16px",
            opacity: 0.5,
          }}
        >
          Dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit
          aspernaturaut odit aut fugit, sed quia consequuntur. Dicta sunt
          explicabo. Nemo enim ipsam voluptatem quia voluptas.
        </Typography> */}

        <form onSubmit={formik.handleSubmit}>
          <Box>
            <Box align="center" component="label">
              <Box sx={uploadPicSection}>
                {uploadImg1 && <img src={uploadImg1} alt="kk" />}
                {!uploadImg1 && (
                  <Box align="center">
                    <Box>
                      <img src={uploadIcon} alt="img" width={20} />
                    </Box>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontFamily: "Kumbh Sans",
                        marginTop: "20px",
                      }}
                    >
                      Upload&nbsp;Photo
                    </Typography>
                  </Box>
                )}
                <input
                  type="file"
                  accept="image/png, image/jpeg"
                  hidden
                  onChange={(e) => {
                    onAddImage(e);
                  }}
                />
              </Box>
            </Box>

            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={2}
            >
              <Grid item xs={12} sm={4}>
                <Typography sx={labelText}>Full Name</Typography>
                <TextField
                  id="fullName"
                  name="fullName"
                  sx={{ width: "100%" }}
                  variant="outlined"
                  placeholder="Type here"
                  value={formik.values.fullName}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.fullName && Boolean(formik.errors.fullName)
                  }
                  helperText={formik.touched.fullName && formik.errors.fullName}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography sx={labelText}>Email</Typography>

                <TextField
                  id="email"
                  name="email"
                  sx={{ width: "100%" }}
                  variant="outlined"
                  onFocus={() => setEmailError(false)}
                  placeholder="Type here"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
              </Grid>
            </Grid>

            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={2}
            >
              <Grid item xs={12} sm={4}>
                <Typography sx={labelText}>Age</Typography>
                <Select
                  id="age"
                  name="age"
                  sx={{
                    width: "100%",
                    color:
                      formik.values.age === "selectPlaceholder"
                        ? "rgba(1, 1,1, 0.4)"
                        : "black",
                  }}
                  variant="outlined"
                  value={formik.values.age}
                  onChange={formik.handleChange}
                  error={formik.touched.age && Boolean(formik.errors.age)}
                  helperText={formik.touched.age && formik.errors.age}
                >
                  <MenuItem
                    value={"selectPlaceholder"}
                    sx={{ display: "none" }}
                  >
                    Select here
                  </MenuItem>
                  <MenuItem value={"0-20"}>0 to 20</MenuItem>
                  <MenuItem value={"21-40"}>20 to 40</MenuItem>
                  <MenuItem value={"41-60"}>40 to 60</MenuItem>
                  <MenuItem value={"61-80"}>60 to 80</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography sx={labelText}>Religion</Typography>

                <Select
                  id="religion"
                  name="religion"
                  sx={{
                    width: "100%",
                    color:
                      formik.values.religion === "selectR"
                        ? "rgba(1, 1,1, 0.4)"
                        : "black",
                  }}
                  variant="outlined"
                  value={formik.values.religion}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.religion && Boolean(formik.errors.religion)
                  }
                  helperText={formik.touched.religion && formik.errors.religion}
                >
                  <MenuItem value={"selectR"} sx={{ display: "none" }}>
                    Select here
                  </MenuItem>
                  {/* {religionItem.map(() => (
                    <MenuItem value={lable}>{lable}</MenuItem>
                  ))} */}
                  {religionItem?.map((ele) => (
                    <MenuItem value={ele.label}>{ele.label}</MenuItem>
                  ))}
                </Select>
              </Grid>
            </Grid>

            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={2}
            >
              <Grid item xs={12} sm={4}>
                <Typography sx={labelText}>Password</Typography>
                <TextField
                  id="password"
                  name="password"
                  type="password"
                  sx={{ width: "100%" }}
                  variant="outlined"
                  placeholder="Type here"
                  value={formik.values.password}
                  onFocus={() => setPassError(false)}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.password && Boolean(formik.errors.password)
                  }
                  helperText={formik.touched.password && formik.errors.password}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography sx={labelText}>Confirm Password</Typography>

                <TextField
                  id="cpassword"
                  name="cpassword"
                  type="password"
                  sx={{ width: "100%" }}
                  variant="outlined"
                  placeholder="Type here"
                  onFocus={() => setPassError(false)}
                  value={formik.values.cpassword}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.cpassword && Boolean(formik.errors.cpassword)
                  }
                  helperText={
                    formik.touched.cpassword && formik.errors.cpassword
                  }
                />
              </Grid>
            </Grid>

            {showChurchOTP && (
              <Box
                sx={{
                  // position: "absolute",
                  // width: "100%",
                  // top: 20,
                  my: 5,
                  display: "flex",
                  justifyContent: "center",
                }}
                classname="register-card-data"
              >
                <Box sx={{ margin: "auto" }}>
                  <OtpInput
                    value={otp}
                    onChange={(e) => setOtp(e)}
                    numInputs={4}
                    separator={<span> &nbsp;&nbsp; </span>}
                    placeholder="0000"
                    inputStyle="otpStyle"
                  />
                  <br />
                  <Box sx={regBtn}>
                    <LoadingButton
                      sx={loading ? loginBtnLoading : registerBtn}
                      loading={loading}
                      loadingPosition="end"
                      onClick={onSubmitOTP}
                      className="btn-register-mobile"
                      variant="outlined"
                    >
                      <span>{loading ? "Verifying" : "Verify"}</span>
                    </LoadingButton>
                  </Box>
                </Box>
              </Box>
            )}

            {!showChurchOTP && (
              <Box align="center" sx={{ margin: "30px 0" }}>
                {PassError && (
                  <Typography sx={labelTextError}>
                    Password & Confirm Password Not Matching.
                  </Typography>
                )}

                {EmailError && (
                  <Typography sx={labelTextError}>
                    This Email is Already Exist.
                  </Typography>
                )}

                <LoadingButton
                  sx={loading ? loginBtnLoading : registerBtn}
                  // endIcon={<SendIcon />}
                  loading={loading}
                  loadingPosition="end"
                  type="submit"
                  className="btn-register-mobile"
                  variant="outlined"
                >
                  <span>{loading ? "Sending OTP" : "Register Now"}</span>
                </LoadingButton>

                {/* <SuccessDialog open={open} setOpen={setOpen} /> */}
              </Box>
            )}
          </Box>
        </form>
      </Box>
      <Footer />
    </>
  );
};

export default UserRegister;

const regBtn = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginX: "auto",
};

const registerBtn = {
  color: "#ffffff",
  background: "#3F292B",
  fontFamily: "Kumbh Sans",
  fontSize: "10px",
  padding: "12px 32px",
  border: "1px solid #3F292B",
  "&:hover": {
    border: "1px solid #3F292B",
    color: "#3F292B",
  },
};
const loginBtnLoading = {
  gap: "10px",
  color: "#ffffff",
  // background: "#D1AB42",
  textTransform: "capitalize",
  border: "1px solid grey",
  fontSize: "14px",
  fontFamily: "Kumbh Sans",
  fontWeight: 500,
  px: 2,
};

const labelText = {
  fontSize: "14px",
  fontFamily: "Kumbh Sans",
  fontWeight: 500,
  marginTop: "20px",
  marginBottom: "8px",
};

const labelTextError = {
  fontSize: "14px",
  fontFamily: "Kumbh Sans",
  fontWeight: 500,
  marginTop: "20px",
  marginBottom: "8px",
  color: "darkred",
};

const uploadPicSection = {
  border: "1px solid",
  width: "90px",
  height: "90px",
  padding: "20px 20px",
  display: "flex",
  borderRadius: 2,
  borderColor: "grey.500",
  borderStyle: "dashed",
  justifyContent: "center",
  margin: "10px auto",
  flexDirection: "column",
};
