import * as React from "react";
import "../navbar/navbar.css";
import Loginform from "../navbar/Loginform";
import LoginUserAvatar from "../navbar/LoginUserAvatar";

// ** MUI
import PropTypes from "prop-types";
// import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import MenuIcon from "@mui/icons-material/Menu";
import Drawer from "@mui/material/Drawer";
// import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
// import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";

import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
// import ListItemText from "@mui/material/ListItemText";
import { Icon } from "@mui/material";
// import MenuItem from "@mui/material/MenuItem";

// // ** Icons
// import GridViewIcon from "@mui/icons-material/GridView";
// import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
// import InsertCommentOutlinedIcon from "@mui/icons-material/InsertCommentOutlined";
// import VideocamOutlinedIcon from "@mui/icons-material/VideocamOutlined";
// import HeadsetMicOutlinedIcon from "@mui/icons-material/HeadsetMicOutlined";
// import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";

// ** components
// import Home from "../navbar/StartNavbar";
// ** router
import { Link, useNavigate } from "react-router-dom";
import { dashboardItem } from "./dashboardData";

const drawerWidth = 200;

const navItems = [
  { name: "Home", paths: "/" },
  { name: "About Us", paths: "/about" },
  { name: "Events", paths: "/events" },
  { name: "Contact Us", paths: "/contactus" },
  // { name: "Media", paths: "/media" },
  // { name: "Blogs & Podcasts", paths: "/blog-podcast" },
  // { name: "Games", paths: "games" },
];

function DrawerAppBar(props) {
  const { window, activeItem, setActiveItem } = props;
  const navigate = useNavigate();

  const [open, setOpen] = React.useState(false);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  // const [someonelogin, setSomeonelogin] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const menuItem = {
    fontFamily: "Kumbh Sans",
    fontSize: "14px",
    marginX: "4px",
    my: 1,
  };

  const menuItemHover = {
    background: "#3F292B",
    color: "#FAF6F4",
    my: 1,
    borderRadius: "10px",
    fontFamily: "Kumbh Sans",
    fontSize: "10px",
    "&:hover": {
      background: "#3F292B",
      color: "#FAF6F4",
    },
  };

  const drawer = (
    <Box
      onClick={handleDrawerToggle}
      sx={{ textAlign: "center", background: "#FAF6F4", height: "100%", pt: 3 }}
    >
      <Typography
        variant="h6"
        sx={{ my: 1, fontFamily: "Kumbh Sans" }}
        onClick={() => navigate("/")}
      >
        Faith Lens
      </Typography>

      <Divider sx={{ marginBottom: "10px" }} />

      <List sx={{ padding: "15px" }}>
        {dashboardItem.map((item, i) => (
          <ListItem
            key={i}
            onClick={() => {
              setActiveItem(item.name);
            }}
            disablePadding
          >
            <ListItemButton
              align="left"
              sx={activeItem === item.name && menuItemHover}
            >
              <Icon>{item.icon}</Icon>
              <Typography sx={menuItem}>{item.name}</Typography>
            </ListItemButton>
          </ListItem>
        ))}
      </List>

      <List></List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  // ** Logout Status
  const [isLogout, setIsLogout] = React.useState(true);
  React.useEffect(() => {
    const getLogoutStatus = localStorage.getItem("logoutStatus");

    setIsLogout(getLogoutStatus);
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        height: "64px",
        backgroundColor: "transparent",
        color: "#000000",
        justifyContent: "end",
        // pr: 2,
        m: 1,
      }}
    >
      <IconButton
        color="inherit"
        aria-label="open drawer"
        className="menu-icon-position"
        onClick={handleDrawerToggle}
        sx={{ display: { md: "none" }, justifyContent: "end" }}
      >
        <MenuIcon />
      </IconButton>

      <Box sx={{ display: { xs: "none", sm: "none", md: "block" } }}>
        <Box
          sx={{
            display: "flex",
            alignItem: "center",
            justifyContent: "center",
            fontSize: "14px",
            mt: 2,
            "& > :not(style) + :not(style)": {
              mx: 2,
            },
          }}
        >
          {navItems?.map((item, i) => (
            <Link
              to={item.paths}
              key={i}
              underline="none"
              sx={{ color: "red", cursor: "pointer" }}
            >
              {item.name}
            </Link>
          ))}
        </Box>
      </Box>

      <Box sx={{ display: { xs: "none", sm: "none", md: "block" } }}>
        <LoginUserAvatar isLogout={isLogout} setIsLogout={setIsLogout} />
      </Box>

      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", sm: "block", md: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>

      <Loginform setOpen={setOpen} open={open} />
    </Box>
  );
}

DrawerAppBar.propTypes = {
  window: PropTypes.func,
};

export default DrawerAppBar;
