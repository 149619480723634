import React from "react";
import InsideNavbar from "../navbar/InsideNavbar";
import Footer from "../footer";
import AddAttendance from "./AddAttendance";
import "./events.css";
import "../FirstPage/firstpage.css";

import ChurchPic from "../../assets/images/registerEvents.jpg";
import Speaker1 from "../../assets/icons/admin.png";
import homePageCross from "../../assets/images/homePageCross.png";

// ** MUI
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import moment from "moment";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";

// ** Icons
import EmailIcon from "@mui/icons-material/Email";
import { FaFacebookF } from "react-icons/fa";
import { MdOutlineMailOutline } from "react-icons/md";
import { SlLocationPin } from "react-icons/sl";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// ** React-Share
import {
  FacebookShareButton,
  TwitterShareButton,
  // LinkedinShareButton,
  EmailShareButton,
  // InstapaperShareButton,
} from "react-share";

import {
  BsTwitter,
  BsInstagram,
  // BsLinkedin,
  BsTelephone,
} from "react-icons/bs";

// import { ShareButton } from "react-share-buttons";
// import "react-share-buttons/dist/main.css";

import { useNavigate, useParams } from "react-router-dom";
import EventComments from "./EventComments";
import axios from "axios";
import { Link, Tooltip } from "@mui/material";
// import ShareButton from "react-share/lib/ShareButton";
// import moment from "moment";
const baseURL = process.env.REACT_APP_BASEURL;

const RegisterEvent = () => {
  const navigate = useNavigate();
  let { id } = useParams();
  const [registerEventData, setregisterEventData] = React.useState([]);
  const [RouteError, setRouteError] = React.useState(false);
  const [expand, setExpand] = React.useState(false);

  React.useEffect(() => {
    const getAllEventsRequest = async () => {
      await axios
        .get(`${baseURL}/api/v1/event/${id}`)
        .then((response) => {
          setregisterEventData(response.data.data);
          // console.log({ registerEventData });
        })
        .catch((error) => {
          console.log(error);
        });
    };
    getAllEventsRequest();
  }, [id]);

  React.useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page
  }, []);

  console.log({ addressevent: registerEventData });

  const getClickChurch = (idd) => {
    navigate(`/events/eventRegister/churchDetails/${idd}`);
  };

  // console.log("fb_shareUrl >>>", window.location.href);

  const fb_shareUrl = window.location.href;
  const instaRef = React.useRef();
  console.log({ instaRef });

  // const shareOnInstagram = () => {
  //   const shareUrl = `https://www.instagram.com/share?url=${encodeURIComponent(
  //     ChurchPic
  //   )}`;
  //   window.open(shareUrl, "_blank");
  // };
  // const shareUrl = `https://www.instagram.com/share?url=${encodeURIComponent(
  //   ChurchPic
  // )}`;
  // const insta_shareUrl = `https://www.instagram.com/faithlens`;

  const handleCopy = () => {
    navigator.clipboard.writeText(fb_shareUrl);
    showToastMessage();
  };

  const showToastMessage = () => {
    toast.success("Copied Event Link.", {
      position: toast.POSITION.BOTTOM_LEFT,
    });
  };

  const getEventLocationMap = (lati, longi, ename) => {
    // navigate(`/events/eventRegister/eventLocation/${lati}/${longi}/${ename}`);

    if (lati && longi) {
      setRouteError(false);
      window.open(
        `/events/eventRegister/eventLocation/${lati}/${longi}/${ename}`
      );
    } else {
      setRouteError(true);
    }
  };

  return (
    <>
      <InsideNavbar />

      <Box>
        <img
          src={
            registerEventData?.imageUrl
              ? registerEventData?.imageUrl
              : ChurchPic
          }
          alt="eventImage"
          height="500px"
          width="100%"
        />
      </Box>

      <Grid container spacing={2} className="mobile-responsive-body">
        <Grid xs={12} sm={7}>
          <Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                flexWrap: "wrap",
              }}
            >
              <Typography sx={eventsHeading}>
                {registerEventData.name}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginRight: "20px",
                }}
              >
                <Typography className="iconsStyle" sx={paragraph}>
                  Share:
                </Typography>

                <EmailShareButton url={fb_shareUrl}>
                  <EmailIcon fontSize="small" className="iconsStyle" />
                </EmailShareButton>

                <FacebookShareButton url={fb_shareUrl}>
                  <FaFacebookF className="iconsStyle" />
                </FacebookShareButton>

                <TwitterShareButton url={fb_shareUrl}>
                  <BsTwitter className="iconsStyle" />
                </TwitterShareButton>

                <BsInstagram className="iconsStyle" onClick={handleCopy} />

                <ToastContainer />
              </Box>
            </Box>

            <Typography sx={paragraph}>23.2k views</Typography>

            {registerEventData.paymentLink && (
              <>
                <Link
                  sx={linkStyle}
                  href={registerEventData?.paymentLink}
                  target="_blank"
                  rel="noreferrer"
                >
                  Go to Payment
                </Link>
                <br />
                <br />
              </>
            )}

            <Box
              sx={{
                display: "flex",
              }}
            >
              <Typography>
                <LocationOnIcon sx={shortHead} />
              </Typography>
              <Typography
                sx={{
                  marginX: "0",
                  marginBottom: "9px",
                  fontFamily: "Kumbh Sans",
                  fontSize: "14px",
                  fontWeight: "bold",
                  textTransform: "capitalize"
                }}
              >
                &nbsp;
                {registerEventData?.address?.street},&nbsp;
                {registerEventData?.address?.city},&nbsp;
                {registerEventData?.address?.state},&nbsp;
                {registerEventData?.address?.country}&nbsp;
              </Typography>

              <Tooltip
                title={
                  RouteError
                    ? "Route Not Available"
                    : `View Route on Google Maps`
                }
                placement="right-start"
                sx={{ background: "red" }}
              >
                <MyLocationIcon
                  onClick={() =>
                    getEventLocationMap(
                      registerEventData?.address?.location?.coordinates[1],
                      registerEventData?.address?.location?.coordinates[0],
                      registerEventData.name
                    )
                  }
                  sx={{
                    fontSize: "22px",
                    cursor: "pointer",
                    color: "#D1AB42",
                    "&:hover": { color: "#D1AB42", fontSize: "23px" },
                  }}
                />
              </Tooltip>
            </Box>

            <Box
              sx={{
                display: "flex",
              }}
            >
              <Typography>
                <CalendarMonthIcon sx={shortHead} />
              </Typography>
              <Typography
                sx={{
                  marginX: "0",
                  marginBottom: "9px",
                  fontFamily: "Kumbh Sans",
                  fontSize: "13px",
                }}
              >
                &nbsp;{moment(registerEventData?.fromDate).format("lll")} -&nbsp;
                {moment(registerEventData?.toDate).format("lll")}
              </Typography>
            </Box>

            <Typography sx={paragraph}>
              {registerEventData.desc?.length > 250 ? (
                <span>
                  {expand
                    ? registerEventData.desc
                    : `${registerEventData.desc.slice(0, 250)} `}
                  <p
                    onClick={() => setExpand(!expand)}
                    style={{ cursor: "pointer" }}
                  >
                    {expand ? "See less" : "See more.."}
                  </p>
                </span>
              ) : (
                registerEventData.desc
              )}
            </Typography>
          </Box>

          <Typography sx={titleHeading}>Event Details</Typography>

          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-between",
            }}
          >
            {registerEventData?.hostedBy?.role === "Church" && (
              <Box>
                <Typography sx={eventDetailsSubHeading}>
                  {registerEventData.eventTheme}
                </Typography>
                <Typography sx={paragraph}>Theme of event</Typography>
              </Box>
            )}

            {registerEventData?.hostedBy?.role !== "Artist" && (
              <Box>
                <Typography sx={eventDetailsSubHeading}>
                  {registerEventData.ageGroup}
                </Typography>
                <Typography sx={paragraph}>Age group</Typography>
              </Box>
            )}

            {registerEventData?.hostedBy?.role !== "Ministry" && (
              <Box>
                <Typography sx={eventDetailsSubHeading}>
                  {registerEventData?.category}
                </Typography>
                <Typography sx={paragraph}>Category</Typography>
              </Box>
            )}

            <Box>
              <Typography sx={eventDetailsSubHeading}>
                {registerEventData.parking ? "Available" : "Not Available"}
              </Typography>
              <Typography sx={paragraph}>Parking space</Typography>
            </Box>
          </Box>

          {/* <Divider sx={{ marginY: "20px" }} />

          <Typography sx={titleHeading}>Contact Details</Typography>

          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Typography sx={eventDetailsSubHeading}>
                <BsTelephone className="iconStyle2" />
                {registerEventData.contact}
              </Typography>
              <Typography sx={paragraph}>Contact number</Typography>
            </Box>
            <Box>
              <Typography sx={eventDetailsSubHeading}>
                <MdOutlineMailOutline className="iconStyle2" />
                {registerEventData.email}
              </Typography>
              <Typography sx={paragraph}>Email</Typography>
            </Box>
          </Box> */}

          <Divider sx={{ marginY: "20px" }} />

          <Typography sx={titleHeading}>
            {registerEventData.hostedBy?.role === "Artist"
              ? "Artist Details"
              : registerEventData.hostedBy?.role === "Ministry"
              ? "Ministry Details"
              : "Speaker Details"}
          </Typography>

          <Box
            sx={{
              display: "flex",
              // flexWrap: "wrap",
            }}
            className="flexMobile"
          >
            <Box sx={{ display: "inline" }}>
              <img
                src={
                  registerEventData?.speakerDetails?.imageUrl
                    ? registerEventData?.speakerDetails?.imageUrl
                    : Speaker1
                }
                alt="img"
                width={150}
                className="speakerImage"
              />
            </Box>
            <Box sx={{ margin: "20px" }}>
              <Typography sx={eventDetailsSubHeading}>
                {registerEventData.speakerDetails?.name}
              </Typography>
              <Typography sx={paragraph}>
                {registerEventData.speakerDetails?.description?.length > 250 ? (
                  <span>
                    {expand
                      ? registerEventData.speakerDetails?.description
                      : `${registerEventData.speakerDetails?.description.slice(
                          0,
                          250
                        )} `}
                    <p
                      onClick={() => setExpand(!expand)}
                      style={{ cursor: "pointer" }}
                    >
                      {expand ? "See less" : "See more.."}
                    </p>
                  </span>
                ) : (
                  registerEventData.speakerDetails?.description
                )}
              </Typography>
            </Box>
          </Box>

          <Divider sx={{ marginY: "20px" }} />

          <Typography sx={titleHeading}>Hosted by</Typography>

          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
            }}
            className="flexMobile"
          >
            <Box sx={{ display: "inline" }}>
              <img
                src={
                  registerEventData?.hostedBy?.imageUrl
                    ? registerEventData.hostedBy?.imageUrl
                    : homePageCross
                }
                alt="img"
                width={150}
                className="img-responsive"
              />
            </Box>
            <Box sx={{ margin: "20px 25px" }}>
              <Typography sx={eventDetailsSubHeading}>
                {registerEventData.hostedBy?.fullName}
              </Typography>

              {registerEventData?.hostedBy?.church?.address?.country && (
                <Typography
                  sx={{
                    marginX: "0",
                    marginBottom: "9px",
                    fontFamily: "Kumbh Sans",
                    fontSize: "12px",
                    fontWeight: "bold",
                  }}
                >
                  <SlLocationPin style={{ fontSize: "13px" }} />
                  &nbsp; {registerEventData?.hostedBy?.church?.address?.city},
                  &nbsp;
                  {registerEventData?.hostedBy?.church?.address?.state}, &nbsp;
                  {registerEventData?.hostedBy?.church?.address?.country}
                </Typography>
              )}
              {/* <Typography sx={paragraph}>
                {registerEventData.hostedBy?.church?.churchDesc}
              </Typography> */}

              <Button
                onClick={() => getClickChurch(registerEventData.hostedBy?._id)}
                sx={{
                  color: "#ffffff",
                  background: "#3F292B",
                  fontFamily: "Kumbh Sans",
                  fontSize: "10px",
                  padding: "10px 16px",
                  border: "1px solid #3F292B",

                  "&:hover": {
                    border: "1px solid #3F292B",
                    color: "#3F292B",
                  },
                }}
                className="attendance-btn-Width"
              >
                {registerEventData.hostedBy?.role === "Artist"
                  ? "SEE ARTIST PROFILE"
                  : registerEventData.hostedBy?.role === "Church"
                  ? "SEE CHURCH PROFILE"
                  : "SEE MINISTRY PROFILE"}
              </Button>
            </Box>
          </Box>

          <Divider sx={{ marginY: "20px" }} />

          <EventComments baseURL={baseURL} id={registerEventData._id} />
        </Grid>

        <Grid xs={12} sm={12} md={4}>
          <AddAttendance
            fromDate={registerEventData.fromDate}
            toDate={registerEventData.toDate}
            id={registerEventData._id}
            name={registerEventData.hostedBy?.fullName}
            location={registerEventData?.address}
          />
        </Grid>
      </Grid>

      <Footer />
    </>
  );
};

export default RegisterEvent;

const eventsHeading = {
  fontSize: "1.5rem",
  fontFamily: "Kumbh Sans",
  fontWeight: "bold",
  color: "#000000",
  marginRight: "30px",
  textTransform: "capitalize",

};
const paragraph = {
  marginBottom: "4px",
  marginRight: "10px",
  fontFamily: "Kumbh Sans",
  fontSize: "14px",
  opacity: 0.6,
};

const linkStyle = {
  fontFamily: "Kumbh Sans",
  fontSize: "12px",
  color: "white",
  padding: "5px",
  borderRadius: "4px",
  background: "#d1ab42",
  marginY: "20px",
  textDecoration: "none",

  // "&:hover": {
  //   border: "1px solid #3F292B",
  //   color: "#3F292B",
  //   boxShadow: "inset 4em 0 0 0 white",
  // },
};

const eventDetailsSubHeading = {
  // marginY: "0px",
  fontFamily: "Kumbh Sans",
  fontSize: "18px",
  fontWeight: "bold",
};

const titleHeading = {
  marginX: "0",
  marginBottom: "9px",
  fontFamily: "Kumbh Sans",
  fontSize: "18px",
  fontWeight: "bold",
};

const shortHead = {
  marginX: "0",
  marginBottom: "9px",
  fontFamily: "Kumbh Sans",
  fontSize: "20px",
};
