import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import Instagram from "../../assets/icons/Instagram_icon.png";
import React from "react";
import Link from "@mui/material/Link";

const AboutFaithlens = () => {
  const heading = {
    fontFamily: "Kumbh Sans",
    fontSize: "30px",
    fontWeight: "bold",
    display: "flex",
    justifyContent: "center",
  };

  // const paragraph = {
  //   marginTop: "12px",
  //   fontFamily: "Kumbh Sans",
  //   fontSize: "14px",
  //   opacity: 0.5,
  //   display: "flex",
  //   justifyContent: "center",
  //   alignItems: 'center'
  // };

  return (
    <>
      <Box sx={{ paddingY: "70px", paddingX: "20px", background: "#FAF6F4" }}>
        <Typography sx={heading}>About Faithlens</Typography>
      
        <Box sx={heading}>
          <Link
            href="https://www.instagram.com/faithlens/"
            target="_blank"
            variant="body2"
            sx={{ color: "#ffffff" }}
          >
            <img
              src={Instagram}
              alt="instagram link"
              style={{ height: "70px", marginTop: "20px" }}
            />
          </Link>
        </Box>
        {/* <Typography sx={paragraph}>
          Dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit
          aspernaturaut odit aut fugit,
          <br />sed quia consequuntur. Dicta sunt explicabo. Nemo enim ipsam
          voluptatem quia voluptas.
        </Typography> */}
      </Box>
    </>
  );
};

export default AboutFaithlens;
