import React from "react";
import "./registration.css";

// ** images
// import CardDetailImage from "../../assets/images/cardDetails2.jpg";
import paymentOffer from "../../assets/payment/paymentOffer.png";

// ** MUI
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const cardDetails = () => {
  const styles = {
    orderSummaryStyle: {
      backgroundImage: `url(${paymentOffer})`,
      padding: "30px",
      backgroundRepeat: "no-repeat",
      backgroundSize: "auto",
      // backgroundAttachment: "fixed",
      backgroundPosition: "right",
      borderRadius: "20px",
    },
    cardText1: {
      color: "#240C00",
      fontSize: "14px",
      fontFamily: "Kumbh Sans",
    },

    cardText2: {
      color: "#240C00",
      fontSize: "11px",
      fontFamily: "Kumbh Sans",
      margin: "23px 0",
    },
    cardHeading1: {
      color: "#240C00",
      fontSize: "18px",
      fontWeight: "bold",
      fontFamily: "Kumbh Sans",
    },
    cardMonth: {
      color: "#240C00",
      fontSize: "30px",
      fontWeight: "bold",
      fontFamily: "Kumbh Sans",
    },
    darkDiv: {
      background: "#3F292B",
      color: "#ffffff",
      borderRadius: "10px",
      padding: "15px",
    },
    price: {
      color: "#240C00",
      fontSize: "16px",
      fontFamily: "Kumbh Sans",
    },
    priceTotal: {
      color: "#240C00",
      fontSize: "16px",
      fontWeight: "bold",
      fontFamily: "Kumbh Sans",
    },
  };
  return (
    <Box style={styles.orderSummaryStyle}>
      <Typography style={styles.cardHeading1}>Order Summary</Typography>
      <Typography style={styles.cardMonth} className="cardDetailsHeading">
        $20/Month
      </Typography>
      <Typography style={styles.cardText1}>Per Month Billed Anually</Typography>

      <Box style={styles.darkDiv}>
        <Typography sx={{ fontFamily: "Kumbh Sans", fontSize: "14px" }}>
          Church Registeration
        </Typography>
        <Typography
          sx={{ fontFamily: "Kumbh Sans", fontSize: "12px", my: "5px" }}
        >
          Individual (Monthly Plan)
        </Typography>
        <Typography sx={{ fontFamily: "Kumbh Sans", fontSize: "12px" }}>
          Billed Monthly At $20 + Local Tax
        </Typography>
      </Box>

      <Typography style={styles.cardText2}>
        Your subscription will automatically renew each year at the price below.
        See <Box sx={{ textDecoration: "underline" }}>User Terms.</Box>
      </Typography>

      <Box>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography style={styles.price}>Price</Typography>
          <Typography style={styles.priceTotal}>$20</Typography>
        </Box>

        <Box
          sx={{ display: "flex", justifyContent: "space-between", my: "10px" }}
        >
          <Typography style={styles.price}>Tax</Typography>
          <Typography style={styles.priceTotal}>$0.00/Mon</Typography>
        </Box>

        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography style={styles.price}>Total</Typography>
          <Typography style={styles.priceTotal}>USD $20.00/Mon</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default cardDetails;
