import Blog1 from "../../assets/images/blog1.png";
import Blog2 from "../../assets/images/blog1.png";
import Blog3 from "../../assets/images/blog1.png";

export const latestBlog = [
  {
    id: "#1",
    author: "BY DANIEL WALSH",
    img: Blog1,
    title: "Always say prayers",
    time: "1h 15min | 15 hours ago",
  },
  {
    id: "#1",
    author: "BY DANIEL WALSH",
    img: Blog2,
    title: "Always say prayers",
    time: "1h 15min | 15 hours ago",
  },
  {
    id: "#1",
    author: "BY DANIEL WALSH",
    img: Blog3,
    title: "Always say prayers",
    time: "1h 15min | 15 hours ago",
  },
  {
    id: "#1",
    author: "BY DANIEL WALSH",
    img: "https://cdn.pixabay.com/photo/2017/03/27/15/16/man-2179326_960_720.jpg",
    title: "Always say prayers",
    time: "1h 15min | 15 hours ago, ",
  },

  {
    id: "#1",
    author: "BY DANIEL WALSH",
    img: Blog2,
    title: "Always say prayers",
    time: "1h 15min | 15 hours ago, ",
  },
];
