import * as React from "react";
import { dashboardItem } from "./dashboardChurchData";
import { useNavigate } from "react-router-dom";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import { Icon, Box, Divider } from "@mui/material";
import Typography from "@mui/material/Typography";
import { DisplaySettingsOutlined } from "@mui/icons-material";

// import Home from "../navbar/StartNavbar";

export default function VerticalNavbar({
  activeItem,
  setActiveItem,
  userRole,
}) {
  const navigate = useNavigate();
  const menuItem = {
    fontFamily: "Kumbh Sans",
    fontSize: "14px",
    marginX: "5px",
    marginY: "5px",
  };

  const menuItemHover = {
    background: "#3F292B",
    color: "#FAF6F4",
    // my: 1,
    borderRadius: "10px",
    fontFamily: "Kumbh Sans",
    fontSize: "10px",

    "&:hover": {
      background: "#3F292B",
      color: "#FAF6F4",
    },
  };

  return (
    <Box
      sx={{
        background: "#FAF6F4",
        height: "100vh",
        width: "230px",
        borderRight: "1px solid white",
        display: { xs: "none", sm: "block" },
        // position: 'fixed',
        // zIndex: 999999,
      }}
    >
      <Typography
        variant="h6"
        sx={{ mt: 2, fontFamily: "Kumbh Sans", cursor: "pointer" }}
        align="center"
        onClick={() => navigate("/")}
      >
        Faith Lens
      </Typography>

      <List sx={{ padding: "15px", mt: 3 }}>
        {dashboardItem.map((item, i) => {
          // if (userRole !== "Artist") {
          return (
            <ListItem
              key={i}
              onClick={() => {
                setActiveItem(item.name);
              }}
              disablePadding
              sx={{ display: item.isHide === userRole ? "none" : "block" }}
            >
              <ListItemButton
                align="left"
                sx={activeItem === item.name && menuItemHover}
              >
                <Icon>{item.icon}</Icon>
                <Typography sx={menuItem}>{item.name}</Typography>
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
    </Box>
  );
}
