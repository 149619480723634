import * as React from "react";

import { myUploadVideos } from "./MyVideosUploadData";
import MyVideoUpload from "./myVideoAdd";

// import Table from "@mui/material/Table";
// import TableBody from "@mui/material/TableBody";
// import TableCell from "@mui/material/TableCell";
// import TableContainer from "@mui/material/TableContainer";
// import TableHead from "@mui/material/TableHead";
// import TableRow from "@mui/material/TableRow";
// import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import { Button, Typography, Stack, Divider } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { CardActionArea } from "@mui/material";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";

const tableHeading = {
  fontSize: "16px",
  fontFamily: "Kumbh Sans",
  fontWeight: "bold",
  padding: "15px",
};
// const colHeading = {
//   fontSize: "14px",
//   fontFamily: "Kumbh Sans",
//   fontWeight: "bold",
// };

// const colData = {
//   fontSize: "12px",
//   fontFamily: "Kumbh Sans",
// };

const loginBtn = {
  gap: "10px",
  color: "#ffffff",
  background: "#D1AB42",
  textTransform: "capitalize",
  fontFamily: "Kumbh Sans",
  border: "1px solid #D1AB42",
  fontSize: "10px",
  "&:hover": {
    border: "1px solid #3F292B",
    color: "#3F292B",
  },
};

const editBtn = {
  position: "absolute",
  top: "10px",
  right: "10px",
  background: "#3F292B",
  color: "#ffffff",
  border: "1px solid #3F292B",
  fontSize: "10px",
  textTransform: "capitalize",
  "&:hover": {
    background: "transparent",
    border: "1px solid #3F292B",
    color: "#000000",
  },
};

export default function MyVideos() {
  const [openAddVideo, setOpenAddVideo] = React.useState(false);
  return (
    <Box
      sx={{
        borderRadius: "20px",
        padding: "20px",
        background: "#ffffff",
        height: "100%",
        // width: "100%",
        overflow: "auto",
      }}
      align="center"
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
      >
        <Typography sx={tableHeading}>My&nbsp;Videos</Typography>
        <Button
          variant="outlined"
          sx={loginBtn}
          onClick={() => setOpenAddVideo(true)}
        >
          Upload&nbsp;Video
        </Button>
      </Stack>

      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          gap: "15px",
          justifyContent: "center",
        }}
      >
        {myUploadVideos.map((data, ind) => {
          return (
            <Card sx={{ maxWidth: "90%", position: "relative" }} key={ind}>
              <CardActionArea>
                <CardMedia
                  component="img"
                  height="140"
                  image={data.video}
                  alt="man"
                  sx={{ borderRadius: "8px" }}
                />

                <Button variant="outlined" sx={editBtn}>
                  Edit
                </Button>
                <CardContent align="left">
                  <Typography
                    gutterBottom
                    sx={{
                      fontFamily: "Kumbh Sans",
                      fontSize: "18px",
                      fontWeight: "bold",
                    }}
                  >
                    {data.title}
                  </Typography>

                  <Typography
                    sx={{
                      fontFamily: "Kumbh Sans",
                      fontSize: "12px",
                      color: "#91857F",
                      display: "flex",
                      gap: "5px",
                    }}
                  >
                    <PlayArrowIcon sx={{ fontSize: "16px" }} /> {data.time}
                  </Typography>

                  <Box sx={{ display: "flex", gap: "5px", mt: 1 }}>
                    <Box sx={{ borderRadius: "5px" }}>
                      <CardMedia
                        component="img"
                        height="20"
                        image={data.img}
                        alt="uploder"
                        sx={{ borderRadius: "50%" }}
                      />
                    </Box>
                    <Typography
                      gutterBottom
                      sx={{
                        fontFamily: "Kumbh Sans",
                        fontSize: "12px",
                      }}
                    >
                      {data.imgName}
                    </Typography>
                  </Box>
                </CardContent>
              </CardActionArea>
            </Card>
          );
        })}
      </Box>
      <MyVideoUpload
        openAddVideo={openAddVideo}
        setOpenAddVideo={setOpenAddVideo}
      />
    </Box>
  );
}
