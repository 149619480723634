import React from "react";
import "./userProfile.css";

// ** router
// import { Link } from "react-router-dom";
// import { useFormik } from "formik";
// import * as yup from "yup";
import ChangePasswordDialog from "./ChangePasswordDialog";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

// ** MUI
// import Snackbar from "@mui/material/Snackbar";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { Stack } from "@mui/system";
// import Select from "@mui/material/Select";
// import MenuItem from "@mui/material/MenuItem";
// import ChurchPic from "../../assets/images/churchPic.png";
import { MenuItem, Select } from "@mui/material";
// import { useSelector } from "react-redux";
import axios from "axios";

const baseURL = process.env.REACT_APP_BASEURL;
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Setting = ({ setActiveItem, activeItem, userData, tokenAPI }) => {
  const [ChangePass, setChangePass] = React.useState(false);
  const [openToastSuccess, setOpenToastSuccess] = React.useState(false);
  const [openToastFailed, setOpenToastFailed] = React.useState(false);
  const [userpicstatus, setuserpicstatus] = React.useState(false);

  const [userDatas, setUserDatas] = React.useState({
    fullname: "",
    age: "",
    religion: "",
    email: "",
    userpic: null,
  });

  const handleCloseToast = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenToastSuccess(false);
    setOpenToastFailed(false);
  };

  // const authToken = useSelector((state) => state.userAuth.payToken);

  React.useEffect(() => {
    // console.log({ userData });

    const config = {
      headers: { Authorization: `Bearer ${userData.token}` },
    };

    axios
      .get(`${baseURL}/api/v1/user`, config)
      .then((res) => {
        if (res.data.success) {
          setUserDatas({
            fullname: res.data.data.fullName,
            age: res.data.data.age,
            religion: res.data.data.religion,
            email: res.data.data.email,
            userpic: res.data.data.imageUrl,
          });
        }
      })
      .catch((err) => {
        setOpenToastFailed(true);
      });
  }, [userpicstatus]);

  const onSubmitClick = () => {
    const config = {
      headers: { Authorization: `Bearer ${userData.token}` },
    };

    const body = {
      age: userDatas.age,
      religion: userDatas.religion,
      fullName: userDatas.fullname,
    };

    axios
      .put(`${baseURL}/api/v1/user`, body, config)
      .then((res) => {
        if (res.data.success) {
          setOpenToastSuccess(true);
        }
      })
      .catch((err) => {
        setOpenToastFailed(true);
      });
  };

  React.useEffect(() => {
    if (userpicstatus) {
      const config = {
        headers: { Authorization: `Bearer ${userData.token}` },
      };

      const formData = new FormData();
      formData.append("profile", userDatas.userpic);

      axios
        .put(`${baseURL}/api/v1/user/updateImage`, formData, config)
        .then((res) => {
          if (res.data.success) {
            setOpenToastSuccess(true);
            setuserpicstatus(false);
          }
        })
        .catch((err) => {
          setOpenToastFailed(true);
          setuserpicstatus(false);
        });
    }
  }, [userpicstatus]);

  return (
    <>
      <Box
        sx={{
          borderRadius: "22px",
          padding: "20px",
          background: "#ffffff",
          height: "100%",
          width: "auto",
          margin: "0 10px",
          overflow: "auto",
          m: 1,
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <Typography sx={tableHeading}>
            Setting | Edit&nbsp;Information
          </Typography>

          <Box>
            <Button
              variant="outlined"
              sx={registerBtn}
              onClick={() => setChangePass(true)}
            >
              Change Password
            </Button>

            <Button variant="outlined" sx={registerBtn} onClick={onSubmitClick}>
              Update
            </Button>
          </Box>
        </Stack>

        <Box
          sx={{ display: "flex", gap: "40px", flexWrap: "wrap" }}
          className="settingStyle"
        >
          <Box align="center">
            <Box className="imgChange1">
              <img
                src={userDatas.userpic}
                alt="img"
                width="150"
                style={{ borderRadius: "10px" }}
              />

              <Button
                variant="outlined"
                component="label"
                sx={change}
                className="imgChangeBtn1"
              >
                Change
                <input
                  type="file"
                  hidden
                  onChange={(e) => {
                    setuserpicstatus(true);
                    setUserDatas({
                      ...userDatas,
                      userpic: e.target.files[0],
                    });
                  }}
                />
              </Button>
            </Box>

            <Typography
              sx={{
                fontSize: "20px",
                fontFamily: "Kumbh Sans",
                fontWeight: "bold",
              }}
            >
              {userDatas.fullname}
            </Typography>
            <Typography
              sx={{
                fontSize: "12px",
                fontFamily: "Kumbh Sans",
              }}
            >
              {userDatas.email}
            </Typography>
          </Box>

          <Box>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={2}
            >
              <Grid item xs={12} sm={12}>
                <Typography sx={labelText}>Full Name</Typography>
                <TextField
                  id="fullname"
                  name="fullname"
                  sx={{ width: "100%" }}
                  variant="outlined"
                  placeholder="Type here"
                  value={userDatas.fullname}
                  onChange={(e) => {
                    setUserDatas({
                      ...userDatas,
                      fullname: e.target.value,
                    });
                  }}
                />
              </Grid>
            </Grid>

            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={2}
            >
              <Grid item xs={12} sm={6}>
                <Typography sx={labelText}>Religion</Typography>

                <Select
                  id="religion"
                  name="religion"
                  sx={{
                    width: "100%",
                  }}
                  variant="outlined"
                  value={userDatas.religion}
                  onChange={(e) => {
                    setUserDatas({
                      ...userDatas,
                      religion: e.target.value,
                    });
                  }}
                >
                  {religionItem?.map((ele) => (
                    <MenuItem value={ele.label}>{ele.label}</MenuItem>
                  ))}
                </Select>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography sx={labelText}>Age</Typography>
                <Select
                  id="age"
                  name="age"
                  sx={{
                    width: "100%",
                  }}
                  variant="outlined"
                  value={userDatas.age}
                  onChange={(e) => {
                    setUserDatas({
                      ...userDatas,
                      age: e.target.value,
                    });
                  }}
                >
                  <MenuItem value={"0-20"}>0 to 20</MenuItem>
                  <MenuItem value={"21-40"}>20 to 40</MenuItem>
                  <MenuItem value={"41-60"}>40 to 60</MenuItem>
                  <MenuItem value={"61-80"}>60 to 80</MenuItem>
                </Select>
              </Grid>
            </Grid>
          </Box>
          {/* </form> */}
        </Box>
      </Box>

      <ChangePasswordDialog
        ChangePass={ChangePass}
        setChangePass={setChangePass}
        tokenAPI={tokenAPI}
        baseURL={baseURL}
      />

      <Snackbar
        open={openToastSuccess || openToastFailed}
        autoHideDuration={2000}
        onClose={handleCloseToast}
      >
        <Alert
          onClose={handleCloseToast}
          severity={openToastSuccess ? "success" : "error"}
          sx={{ width: "100%" }}
        >
          {openToastSuccess
            ? "Information update successfully."
            : "Something is wrong."}
        </Alert>
      </Snackbar>
    </>
  );
};

export default Setting;
const labelText = {
  fontSize: "14px",
  fontFamily: "Kumbh Sans",
  fontWeight: 500,
  marginTop: "20px",
  marginBottom: "8px",
};

const tableHeading = {
  fontSize: "18px",
  fontFamily: "Kumbh Sans",
  fontWeight: "bold",
  padding: "15px",
};

const registerBtn = {
  color: "#ffffff",
  background: "#3F292B",
  fontFamily: "Kumbh Sans",
  fontSize: "10px",
  padding: "6px 20px",
  border: "1px solid #3F292B",
  m: 1,
  "&:hover": {
    border: "1px solid #3F292B",
    color: "#3F292B",
  },
};

const change = {
  color: "#ffffff",
  background: "#3F292B",
  fontFamily: "Kumbh Sans",
  fontSize: "7px",
  padding: "6px 5px",
  border: "1px solid #3F292B",
  textTransform: "capitalize",
  "&:hover": {
    border: "1px solid #3F292B",
    color: "#3F292B",
  },
};

const religionItem = [
  {
    label: "Adventism",
    value: "Adventism",
  },
  {
    label: "African Methodist Episcopal Church",
    value: "African Methodist Episcopal Church",
  },
  {
    label: "Anglicanism",
    value: "Anglicanism",
  },
  {
    label: "Assemblies of God",
    value: "Assemblies of God",
  },
  {
    label: "Assyrian Church of the East",
    value: "Assyrian Church of the East",
  },
  {
    label: "Baptist",
    value: "Baptist",
  },
  {
    label: "Catholic Church",
    value: "Catholic Church",
  },
  {
    value: "Church of Denmark",
    label: "Church of Denmark",
  },
  {
    label: "Church of Iceland",
    value: "Church of Iceland",
  },
  {
    label: "Church of Norway",
    value: "Church of Norway",
  },
  {
    value: "Church of Sweden",
    label: "Church of Sweden",
  },
  {
    value: "Church of the Nazarene",
    label: "Church of the Nazarene",
  },
  {
    value: "Continental Reformed Protestantism",
    label: "Continental Reformed Protestantism",
  },
  {
    value: "Eastern Catholic Churches",
    label: "Eastern Catholic Churches",
  },
  {
    value: "Eastern Orthodox Church",
    label: "Eastern Orthodox Church",
  },
  {
    value: "Evangelicalism",
    label: " Evangelicalism",
  },
  {
    value: "International Pentecostal Holiness Church",
    label: "  International Pentecostal Holiness Church",
  },
  {
    value: " Lutheran Church",
    label: "  Lutheran Church",
  },
  {
    value: " Methodism",
    label: "  Methodism",
  },
  {
    value: "Orthodox Church",
    label: " Orthodox Church",
  },
  {
    value: "Pentecostalism",
    label: " Pentecostalism",
  },
  {
    value: "Pentecostalism",
    label: " Pentecostalism",
  },
  {
    value: "Presbyterianism",
    label: "Presbyterianism",
  },
  {
    value: "Protestantism",
    label: " Protestantism",
  },
  {
    value: "Quakers",
    label: "Quakers",
  },
  {
    value: "Restorationism",
    label: "Restorationism",
  },
  {
    value: "Seventh-day Adventist Church",
    label: "Seventh-day Adventist Church",
  },
];
