import React from "react";
import "./adminProfile.css";

import { useFormik } from "formik";
import * as yup from "yup";

// ** MUI
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { Stack } from "@mui/system";

import ChurchPic from "../../assets/images/churchPic.png";
import axios from "axios";
// const baseURL = process.env.REACT_APP_BASEURL;

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const AdminSetting = ({
  setActiveItem,
  activeItem,
  userData,
  tokenAPI,
  baseURL,
}) => {
  const [openToastSuccess, setOpenToastSuccess] = React.useState(false);
  const [openToastFailed, setOpenToastFailed] = React.useState(false);

  const handleCloseToast = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenToastSuccess(false);
    setOpenToastFailed(false);
  };

  const validationSchema = yup.object({
    oldpass: yup
      .string("Enter Old Password.")
      .min(8, "Min 8 character required")
      .required("Old Password is required."),

    newpass: yup
      .string("Enter New Password.")
      .min(8, "Min 8 character required")
      .required("New Password is required."),

    cnewpass: yup
      .string("Enter New Password.")
      .min(8, "Password not matching as above new password")
      .required("New Password is required."),
  });

  const formik = useFormik({
    initialValues: {
      oldpass: "",
      newpass: "",
      cnewpass: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      const { oldpass, newpass } = values;

      const formData = { oldPassword: oldpass, newPassword: newpass };
      onSubmitChange(formData);
      resetForm({ values: "" });
    },
  });

  const onSubmitChange = (data) => {
    const config = {
      headers: { Authorization: `Bearer ${tokenAPI}` },
    };

    axios
      .post(`${baseURL}/api/v1/auth/updatePassword`, data, config)
      .then((res) => {
        if (res.data.success) {
          setOpenToastSuccess(true);
        } else {
          setOpenToastFailed(true);
        }
      })
      .catch((error) => {
        setOpenToastFailed(true);
      });
  };

  return (
    <Box
      sx={{
        borderRadius: "22px",
        padding: "20px",
        background: "#ffffff",
        height: "100%",
        width: "auto",
        margin: "0 10px",
        overflow: "auto",
        m: 1,
      }}
    >
      <form onSubmit={formik.handleSubmit}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
          flexWrap="wrap"
        >
          <Typography sx={tableHeading}>
            Setting | Change&nbsp;Password
          </Typography>

          <Button variant="outlined" type="submit" sx={registerBtn}>
            Change & Update
          </Button>
        </Stack>

        <Box
          sx={{ display: "flex", flexWrap: "wrap" }}
          className="settingStyle"
        >
          <Box align="center" className="imgChange2" sx={{ p: 5 }}>
            <img src={ChurchPic} alt="img" width="150" />

            <Typography
              sx={{
                fontSize: "20px",
                fontFamily: "Kumbh Sans",
                fontWeight: "bold",
              }}
            >
              {userData.fullName}
            </Typography>
            <Typography
              sx={{
                fontSize: "14px",
                fontFamily: "Kumbh Sans",
              }}
            >
              {userData?.email}
            </Typography>
          </Box>

          <Box>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={12} sm={7}>
                <Typography sx={labelText}>Old Password</Typography>
                <TextField
                  id="oldpass"
                  name="oldpass"
                  sx={{ width: "100%" }}
                  variant="outlined"
                  placeholder="Enter Old Password"
                  value={formik.values.oldpass}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.oldpass && Boolean(formik.errors.oldpass)
                  }
                  helperText={formik.touched.oldpass && formik.errors.oldpass}
                />
              </Grid>

              <Grid item xs={12} sm={7}>
                <Typography sx={labelText}>New Password</Typography>

                <TextField
                  id="newpass"
                  name="newpass"
                  sx={{ width: "100%" }}
                  variant="outlined"
                  placeholder="Enter New Password"
                  value={formik.values.newpass}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.newpass && Boolean(formik.errors.newpass)
                  }
                  helperText={formik.touched.newpass && formik.errors.newpass}
                />
              </Grid>

              <Grid item xs={12} sm={7}>
                <Typography sx={labelText}>Conferm New Password</Typography>
                <TextField
                  id="cnewpass"
                  name="cnewpass"
                  sx={{ width: "100%" }}
                  variant="outlined"
                  placeholder="Enter Again New Password"
                  value={formik.values.cnewpass}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.cnewpass && Boolean(formik.errors.cnewpass)
                  }
                  helperText={formik.touched.cnewpass && formik.errors.cnewpass}
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </form>

      <Snackbar
        open={openToastSuccess || openToastFailed}
        autoHideDuration={2000}
        onClose={handleCloseToast}
      >
        <Alert
          onClose={handleCloseToast}
          severity={openToastSuccess ? "success" : "error"}
          sx={{ width: "100%" }}
        >
          {openToastSuccess
            ? "Password changed successfully"
            : "Old password is not matching."}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default AdminSetting;

const labelText = {
  fontSize: "14px",
  fontFamily: "Kumbh Sans",
  fontWeight: 500,
  marginTop: "20px",
  marginBottom: "8px",
};

const tableHeading = {
  fontSize: "18px",
  fontFamily: "Kumbh Sans",
  fontWeight: "bold",
  padding: "15px",
};

const registerBtn = {
  color: "#ffffff",
  background: "#3F292B",
  fontFamily: "Kumbh Sans",
  fontSize: "10px",
  padding: "6px 20px",
  border: "1px solid #3F292B",
  "&:hover": {
    border: "1px solid #3F292B",
    color: "#3F292B",
  },
};
