import * as React from "react";
import "./events.css";
// import { EventsArray } from "./EventsData.js";
// import events1 from "../../assets/images/events1.png";

import Pagination from "@mui/material/Pagination";
// import Stack from "@mui/material/Stack";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";

// ** icons
import { SlLocationPin } from "react-icons/sl";
import { BsCalendar4Event } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

import axios from "axios";
import moment from "moment";
const baseURL = process.env.REACT_APP_BASEURL;

const PAllEvents = ({ partcularId }) => {
  const navigate = useNavigate();
  const [eventsArray, setEventsArray] = React.useState([]);

  // *** Pagination
  const options = [2, 3, 5, 10, 15];
  const [value, setValue] = React.useState(options[1]);
  const [page, setPage] = React.useState(1);
  const [total, setTotal] = React.useState();

  let count = Math.ceil(total / value);
  // let start = (page - 1) * value;
  // let last = Math.min(start + value - 1, total - 1);

  React.useEffect(() => {
    const getAllEventsRequest = async () => {
      await axios
        .get(
          `${baseURL}/api/v1/event?page_no=${page}&records_per_page=${value}&churchId=${partcularId}`
        )
        .then((response) => {
          // console.log({ response });
          setTotal(response.data);

          const valuex = response.data.data.map((ele) => {
            return {
              data: {
                id: ele._id,
                date: {
                  day: moment(ele.fromDate).format("DD"),
                  month: moment(ele.fromDate).format("MMM YYYY"),
                },

                eventImage: ele.imageUrl,
                eventName: ele.name,
                fromDateTime: moment(ele.fromDate).format("lll"),
                toDateTime: moment(ele.toDate).format("lll"),
                eventLocationChurch: ele.hostedBy?.fullName,
                eventLocationCity: ele.address,
                eventDescription: ele.desc,
                eventRegister: "/eventRegister",
              },
            };
          });

          setEventsArray(valuex);
        })
        .catch((error) => {
          console.log(error);
        });
    };
    getAllEventsRequest();
  }, [page]);

  const openRegisterEvent = (id) => {
    navigate(`/events/eventRegister/${id}`);
  };

  return (
    <>
      {
        // eventsArray?.length > 0 &&
        <>
          <Typography sx={titleHeading}>Upcoming Events</Typography>

          <Box sx={{ my: 4 }}>
            <Pagination
              variant="outlined"
              count={count}
              page={page}
              onChange={(event, newValue) => {
                setPage(newValue);
              }}
            />
          </Box>

          {eventsArray?.map((ele, ind) => {
            const data = ele.data;

            return (
              <>
                <Box
                  key={ind + 1}
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                    }}
                  >
                    <Typography sx={eventsHeading}>
                      {data.date.day}
                      <Typography sx={eventsText}>{data.date.month}</Typography>
                    </Typography>
                    <Box sx={{ maxWidth: "360px" }}>
                      <CardMedia
                        component="img"
                        image={data.eventImage}
                        alt="events1"
                        sx={{
                          width: "280px",
                          height: "170px",
                          marginTop: "10px",
                          borderRadius: "10px",
                        }}
                      />
                    </Box>
                  </Box>

                  <Box
                    sx={{ width: "50%", display: "flex" }}
                    className="eventsContent"
                  >
                    <Box>
                      <Typography sx={eventsHeading}>
                        {data.eventName}
                      </Typography>
                      <Typography sx={eventsText}>
                        <BsCalendar4Event style={{ fontSize: "12px" }} />
                        &nbsp; &nbsp;
                        {data.fromDateTime} - {data.toDateTime}
                      </Typography>
                      <Typography sx={eventsTextLocation}>
                        <SlLocationPin style={{ fontSize: "13px" }} />
                        &nbsp;
                        {/* {data.eventLocationChurch}, */}
                        <Typography sx={eventsText}>
                          {data.eventLocationCity?.city}, &nbsp;
                          {data.eventLocationCity?.state}, &nbsp;
                          {data.eventLocationCity?.country}.
                        </Typography>
                      </Typography>
                      <Typography sx={paragraph}>
                        {data.eventDescription}
                      </Typography>
                    </Box>

                    <Box className="mobile-responsive-register-btn">
                      <Button
                        onClick={() => openRegisterEvent(data.id)}
                        variant="outlined"
                        sx={registerBtn}
                        className="register-btn-mobile"
                      >
                        View
                      </Button>
                    </Box>
                  </Box>
                </Box>
                <Divider sx={{ width: "95%", margin: "40px auto" }} />
              </>
            );
          })}
        </>
      }
    </>
  );
};

export default PAllEvents;

const paragraph = {
  fontSize: "0.8rem",
  fontFamily: "Kumbh Sans",
  color: "#000000",
  marginRight: "40px",
  lineHeight: "25px",
  opacity: "0.6",
  maxWidth: "400px",
};

const eventsHeading = {
  fontSize: "1.6rem",
  fontFamily: "Kumbh Sans",
  fontWeight: "bold",
  color: "#000000",
  marginRight: "30px",
};

const eventsText = {
  fontSize: "0.8rem",
  fontFamily: "Kumbh Sans",
  color: "#000000",
};

const eventsTextLocation = {
  fontSize: "0.8rem",
  fontFamily: "Kumbh Sans",
  color: "#000000",
  marginY: "8px",
  fontWeight: "bold",
  display: "flex",
};

const registerBtn = {
  color: "#ffffff",
  background: "#D1AB42",
  fontFamily: "Kumbh Sans",
  fontSize: "10px",
  padding: "12px 35px",
  marginTop: "20px",
  width: "auto",
  border: "1px solid #D1AB42",

  "&:hover": {
    border: "1px solid #3F292B",
    color: "#3F292B",
  },
};

const titleHeading = {
  marginX: "0",
  marginBottom: "20px",
  fontFamily: "Kumbh Sans",
  fontSize: "18px",
  fontWeight: "bold",
};
