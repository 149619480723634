/* eslint-disable no-undef */
import React from "react";
import { TermServices } from "../../constant/TermServices";
import InsideNavbar from "../navbar/InsideNavbar";
import Footer from "../footer";
import { Box } from "@mui/system";

const TermServicess = () => {
  return (
    <>
      <InsideNavbar />
      <Box sx={{ m: 4 }}>
        <div
          dangerouslySetInnerHTML={{
            __html: TermServices,
          }}
        />
      </Box>
      <Footer />
    </>
  );
};

export default TermServicess;