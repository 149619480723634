// ** Icons
import GridViewIcon from "@mui/icons-material/GridView";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import InsertCommentOutlinedIcon from "@mui/icons-material/InsertCommentOutlined";
// import VideocamOutlinedIcon from "@mui/icons-material/VideocamOutlined";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import ChurchOutlinedIcon from "@mui/icons-material/ChurchOutlined";
import PaymentsOutlinedIcon from "@mui/icons-material/PaymentsOutlined";

export const dashboardItem = [
  {
    name: "Dashboard",
    icon: <GridViewIcon fontSize="small" />,
  },
  {
    name: "Events",
    icon: <CalendarMonthIcon fontSize="small" />,
  },
  {
    name: "Inquiries",
    icon: <InsertCommentOutlinedIcon fontSize="small" />,
  },
  // {
  //   name: "Videos",
  //   icon: <VideocamOutlinedIcon fontSize="small" />,
  // },
  {
    name: "Church requests",
    icon: <ChurchOutlinedIcon fontSize="small" />,
  },
  // {
  //   name: "Revenue",
  //   icon: <PaymentsOutlinedIcon fontSize="small" />,
  // },
  {
    name: "Settings",
    icon: <TuneOutlinedIcon fontSize="small" />,
  },
];
