import * as React from "react";

import OurEventsAdd from "./OurEventsAdd";
import OurEventsEdit from "./OurEventsEdit";
// import OurEventsDelete from "./OurEventsDelete";
import CircularProgress from "@mui/material/CircularProgress";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
// import Paper from "@mui/material/Paper";
// import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
// import FormControlLabel from "@mui/material/FormControlLabel";
// import Switch from "@mui/material/Switch";
import DeleteIcon from "@mui/icons-material/Delete";
// import FilterListIcon from "@mui/icons-material/FilterList";
import { visuallyHidden } from "@mui/utils";

// import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import VisibilityIcon from "@mui/icons-material/Visibility";
// import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import { Button, Stack } from "@mui/material";

import moment from "moment";
import axios from "axios";
const baseURL = process.env.REACT_APP_BASEURL;

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Events name",
  },
  {
    id: "date",
    numeric: false,
    disablePadding: false,
    label: "Start date",
  },
  {
    id: "speaker",
    numeric: false,
    disablePadding: false,
    label: "Speaker",
  },
  {
    id: "response",
    numeric: false,
    disablePadding: false,
    label: "Response",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Status",
  },
  {
    id: "actions",
    numeric: false,
    disablePadding: false,
    label: "Actions",
  },
];

function EnhancedTableHead(props) {
  const {
    // onSelectAllClick,
    order,
    orderBy,
    // numSelected,
    // rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell> */}
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "left" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={colHeading}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const { numSelected, setOpenAdd } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <Typography
            sx={tableHeading}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            Our Events
          </Typography>

          <Button
            variant="outlined"
            sx={loginBtn}
            onClick={() => setOpenAdd(true)}
          >
            Add events
          </Button>
        </Stack>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : null}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function EnhancedTable({ tokenApi }) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [loaderOn1, setLoaderOn1] = React.useState(false);

  const [openAdd, setOpenAdd] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [particalarId, setParticularId] = React.useState("");
  const [openDelete, setOpenDelete] = React.useState(false);
  const [WholeData, setWholeData] = React.useState([])

  const [trig, setTrig] = React.useState(false);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  // const handleClick = (event, name) => {
  //   const selectedIndex = selected.indexOf(name);
  //   let newSelected = [];

  //   if (selectedIndex === -1) {
  //     newSelected = newSelected.concat(selected, name);
  //   } else if (selectedIndex === 0) {
  //     newSelected = newSelected.concat(selected.slice(1));
  //   } else if (selectedIndex === selected.length - 1) {
  //     newSelected = newSelected.concat(selected.slice(0, -1));
  //   } else if (selectedIndex > 0) {
  //     newSelected = newSelected.concat(
  //       selected.slice(0, selectedIndex),
  //       selected.slice(selectedIndex + 1)
  //     );
  //   }

  //   setSelected(newSelected);
  // };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;
  const [rows, setRowData] = React.useState([]);

  function createData(
    name,
    date,
    speaker,
    status,
    id,
    attending,
    mayattending
  ) {
    return {
      name,
      date,
      speaker,
      status,
      id,
      attending,
      mayattending,
    };
  }

  React.useEffect(() => {
    const config = {
      headers: { Authorization: `Bearer ${tokenApi}` },
    };

    const getAllEventsRequest = async () => {
      setLoaderOn1(true);
      await axios
        .get(`${baseURL}/api/v1/church/getChurchEvent`, config)
        .then((response) => {
          // console.log(response.data.data, "<<< responseeeeee");
          if (response.data.success) {
            const valxxx = response.data.data.map((i) => {
              return {
                render: createData(
                  i.name,
                  moment(i.fromDate).format("lll"),
                  i.speakerDetails?.name,
                  i.status,
                  i._id,
                  i.Attending,
                  i.MayAttend
                ),
              };
            });

            setRowData(valxxx);
            setWholeData(response.data.data)
            setLoaderOn1(false);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };
    getAllEventsRequest();
  }, [openAdd, openDelete, trig]);

  const loader = () => {
    return (
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <CircularProgress sx={{ color: "#D1AB42" }} />
      </Box>
    );
  };

  return (
    <>
      <Box
        sx={{
          borderRadius: "20px",
          padding: "20px",
          background: "#ffffff",
          height: "100%",
          width: "auto",
          overflow: "auto",
          m: 2,
        }}
      >
        <EnhancedTableToolbar
          numSelected={selected.length}
          openAdd={openAdd}
          setOpenAdd={setOpenAdd}
        />
        <TableContainer>
          <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((rowxx, index) => {
                      const row = rowxx.render;

                      const isItemSelected = isSelected(row.name);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TableRow
                          hover
                          // onClick={(event) => handleClick(event, row.name)}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.name}
                          selected={isItemSelected}
                        >
                          {/* <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            "aria-labelledby": labelId,
                          }}
                        />
                      </TableCell> */}

                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="none"
                            sx={colData}
                            align="left"
                          >
                            {row.name}
                          </TableCell>

                          <TableCell align="left" sx={colData}>
                            {row.date}
                          </TableCell>

                          <TableCell align="left" sx={colData}>
                            {row.speaker}
                          </TableCell>

                          <TableCell sx={colData} align="left">
                            {/* {"231 attending, 23 May attend"} */}
                            {row.attending} Attending, &nbsp;
                            {row.mayattending} May Attend
                          </TableCell>

                          <TableCell align="left" sx={colData}>
                            {row.status === "Pending" ? (
                              <Typography sx={colDataPending}>
                                {row.status}
                              </Typography>
                            ) : row.status === "Approved" ? (
                              <Typography sx={colDataApproved}>
                                {row.status}
                              </Typography>
                            ) : row.status === "Rejected" ? (
                              <Typography sx={colDataRejected}>
                                {row.status}
                              </Typography>
                            ) : (
                              <Typography sx={colDataCompleted}>
                                {row.status}
                              </Typography>
                            )}
                          </TableCell>

                          <TableCell
                            sx={{
                              display: "flex",
                              justifyContent: "space-evenly",
                              color: "#D1AB42",
                            }}
                          >
                            <VisibilityIcon
                              sx={mouse}
                              onClick={() => {
                                setOpenEdit(true);
                                setParticularId(row.id);
                              }}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 15, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>

      <OurEventsAdd openAdd={openAdd} setOpenAdd={setOpenAdd} />
      <OurEventsEdit
        openEdit={openEdit}
        setOpenEdit={setOpenEdit}
        particalarId={particalarId}
        tokenApi={tokenApi}
        openDelete={openDelete}
        setOpenDelete={setOpenDelete}
        trig={trig}
        setTrig={setTrig}
        WholeData={WholeData}
      />
      {/* <OurEventsDelete openDelete={openDelete} setOpenDelete={setOpenDelete} /> */}
    </>
  );
}

const tableHeading = {
  fontSize: "18px",
  fontFamily: "Kumbh Sans",
  fontWeight: "bold",
  padding: "15px",
};
const colHeading = {
  fontSize: "14px",
  fontFamily: "Kumbh Sans",
  fontWeight: "bold",
};

const mouse = {
  cursor: "pointer",
};

const colData = {
  fontSize: "12px",
  fontFamily: "Kumbh Sans",
};
const loginBtn = {
  gap: "10px",
  color: "#ffffff",
  background: "#D1AB42",
  textTransform: "capitalize",
  fontFamily: "Kumbh Sans",
  border: "1px solid #D1AB42",
  fontSize: "10px",
  mr: 1,
  "&:hover": {
    border: "1px solid #3F292B",
    color: "#3F292B",
  },
};

// const deleteBtn = {
//   color: "#ffffff",
//   background: "darkred",
//   textTransform: "capitalize",
//   fontFamily: "Kumbh Sans",
//   border: "1px solid darkred",
//   fontSize: "10px",
//   mr: 1,
//   "&:hover": {
//     border: "1px solid #3F292B",
//     color: "#3F292B",
//   },
// };

const colDataPending = {
  fontSize: "12px",
  fontFamily: "Kumbh Sans",
  color: "orange",
};

const colDataRejected = {
  fontSize: "12px",
  fontFamily: "Kumbh Sans",
  color: "red",
};

const colDataApproved = {
  fontSize: "12px",
  fontFamily: "Kumbh Sans",
  color: "green",
};

const colDataCompleted = {
  fontSize: "12px",
  fontFamily: "Kumbh Sans",
  color: "navy",
};
