import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import moment from "moment";

// import ListItemText from "@mui/material/ListItemText";
// import ListItem from "@mui/material/ListItem";
// import List from "@mui/material/List";
// import Divider from "@mui/material/Divider";
// import AppBar from "@mui/material/AppBar";
// import Toolbar from "@mui/material/Toolbar";
// import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
// import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { Box, Stack } from "@mui/system";

// import Card from "@mui/material/Card";
// import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
// import { CardActionArea } from "@mui/material";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenDialog({ open, setOpen, BlogViewData }) {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <Box sx={blogHead}>
          <Typography sx={subHeading}>{BlogViewData?.title}</Typography>
          <Button sx={postBtn} onClick={handleClose}>
            Back
          </Button>
        </Box>

        <CardMedia
          component="img"
          height="400px"
          width="100%"
          image={BlogViewData?.imageUrl}
          alt={BlogViewData.title}
        />

        <Box align="left" className="mobile-responsive-body">
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              justifyItems: "center",
            }}
          >
            <Typography gutterBottom sx={author}>
              {BlogViewData?.authur?.fullName}
            </Typography>
            <Typography gutterBottom sx={published}>
              Published : {moment(BlogViewData?.updatedAt).format("lll")}
            </Typography>
          </Box>

          <Typography gutterBottom sx={title}>
            {BlogViewData.title}
          </Typography>
          <Typography sx={body}>{BlogViewData?.body}</Typography>
        </Box>
      </Dialog>
    </>
  );
}

const subHeading = {
  fontFamily: "Kumbh Sans",
  fontSize: "20px",
  fontWeight: "bold",
};

const postBtn = {
  color: "#ffffff",
  background: "#3F292B",
  fontFamily: "Kumbh Sans",
  fontSize: "10px",
  marginBottom: "12px",
  padding: "8px 26px",
  border: "1px solid #3F292B",

  "&:hover": {
    border: "1px solid #3F292B",
    color: "#3F292B",
  },
};

const author = {
  fontFamily: "Kumbh Sans",
  fontSize: "13px",
  fontWeight: "bold",
  color: "#D1AB42",
};

const published = {
  fontFamily: "Kumbh Sans",
  fontSize: "13px",
  fontWeight: "bold",
  color: "#91857F",
};

const title = {
  fontFamily: "Kumbh Sans",
  fontSize: "28px",
  fontWeight: "bold",
};

const body = {
  fontFamily: "Kumbh Sans",
  fontSize: "15px",
  color: "#91857F",
};

const blogHead = {
  position: "relative",
  background: "#ffffff",
  padding: "12px 20px 0px",
  display: "flex",
  justifyContent: "space-between",
  justifyItems: "center",
};
