import React from "react";
import "./events.css";
// ** router
// import { Link } from "react-router-dom";
import Select from "react-select";
import { religionItem } from "../../constant/ReligionItems";
// ** MUI
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
// import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Divider from "@mui/material/Divider";
import { LoadingButton } from "@mui/lab";
import { Tooltip } from "@mui/material";

const SearchEvents = (props) => {
  const {
    zipCode,
    setZipCode,
    category,
    setCategory,
    eventDate,
    setEventDate,
    params,
    // setParams,
    Trig,
    setTrig,
    eventError,
    setEventError,
    setChurchName,
    ChurchName,
    Religion,
    setReligion,
    eventType,
    setEventType,
  } = props;

  const onSearchClick = () => {
    // if (zipCode) {
    //   setParams({
    //     ...params,
    //     zipCode: zipCode,
    //   });
    // }
    // if (category) {
    //   setParams({
    //     ...params,
    //     category: category,
    //   });
    // }
    // if (toDate) {
    //   setParams({
    //     ...params,
    //     toDate: toDate,
    //   });
    // }
    if (zipCode) params.zipCode = zipCode;
    if (category) params.category = category;
    if (eventDate) params.eventDate = eventDate;
    if (ChurchName) params.church = ChurchName;
    if (Religion) params.religion = Religion;
    if (eventType) params.eventType = eventType;

    // if (eventType) params.eventType = eventType;
    // if (eventType) params.eventType = eventType;

    setTrig(true);
  };

  // React.useEffect(() => {
  //   if (Trig) {

  //   }
  // }, [Trig]);
  return (
    <>
      <Box sx={{ marginBottom: "4%" }}>
        <Typography
          variant="h1"
          align="center"
          sx={{
            fontFamily: "Kumbh Sans",
            fontSize: "30px",
            fontWeight: "bold",
            display: "flex",
            justifyContent: "center",
          }}
        >
          Events
        </Typography>

        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Box
            align="center"
            sx={{
              borderRadius: "10px",
              padding: "20px",
              marginY: "2%",
              boxShadow: "0px 23px 99px #00000017",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
                // justifyContent: "space-evenly",
              }}
            >
              <Box className="field-align-responsive">
                <TextField
                  variant="standard"
                  type="select"
                  InputProps={{
                    disableUnderline: true,
                  }}
                  placeholder="Zip Code"
                  onFocus={() => setEventError(false)}
                  sx={fieldStyles}
                  value={zipCode}
                  onChange={(e) => setZipCode(e.target.value)}
                />
              </Box>

              <Divider
                orientation="vertical"
                variant="middle"
                flexItem
                sx={{ display: { xs: "none", sm: "block" } }}
              />
              {/* 
              <Box sx={boxAlign} className="field-align-responsive">
                <Typography sx={labelText}>Radius:&nbsp;</Typography>

                <Select
                  className="select-styles"
                  options={radiusItems}
                  styles="select-styles"
                />
              </Box> */}

              <Box sx={boxAlign} className="field-align-responsive">
                <Typography sx={labelText}>Event Type:&nbsp;</Typography>

                <Select
                  className="select-styles"
                  options={eventTypeItems}
                  styles="select-styles"
                  onChange={(e) => setEventType(e?.value)}
                  onFocus={() => setEventError(false)}
                />
              </Box>

              <Divider
                orientation="vertical"
                variant="middle"
                flexItem
                sx={{ display: { xs: "none", sm: "block" } }}
              />

              <Tooltip
                title={
                  eventType !== "Church"
                    ? "No religion in Music & Ministry"
                    : null
                }
                placement="top"
              >
                <Box sx={boxAlign} className="field-align-responsive ">
                  <Typography sx={labelText}>Religion:&nbsp;</Typography>

                  <Select
                    className="select-styles"
                    options={religionItem}
                    styles="select-styles"
                    onChange={(e) => setReligion(e?.value)}
                    onFocus={() => setEventError(false)}
                    isDisabled={eventType === "Church" ? false : true}
                  />
                </Box>
              </Tooltip>
            </Box>

            <Divider
              sx={{ marginY: "10px", display: { xs: "none", sm: "block" } }}
            />

            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
              }}
            >
              <Tooltip
                title={
                  eventType === "Ministry Focused"
                    ? "No category in Ministry"
                    : null
                }
                placement="bottom"
              >
                <Box sx={boxAlign} className="field-align-responsive">
                  <Typography sx={labelText}>Category:&nbsp;</Typography>
                  <Select
                    className="select-styles"
                    options={
                      eventType === "Church" ? categoryList : categoryListArtist
                    }
                    styles="select-styles"
                    onChange={(e) => setCategory(e?.value)}
                    onFocus={() => setEventError(false)}
                    isDisabled={eventType === "Ministry Focused" ? true : false}
                  />
                </Box>
              </Tooltip>

              <Divider
                sx={{ marginY: "10px", display: { xs: "block", sm: "none" } }}
              />

              <Divider
                orientation="vertical"
                variant="middle"
                flexItem
                sx={{ marginX: "10px", display: { xs: "none", sm: "block" } }}
              />

              <Box sx={boxAlign} className="field-align-responsive">
                <Typography sx={labelText}>Date:&nbsp;</Typography>
                <TextField
                  id="outlined-basic"
                  variant="standard"
                  type="date"
                  // value={new Date()}
                  className="select-mobile-width"
                  InputProps={{
                    disableUnderline: true,
                  }}
                  placeholder="28/12/1996"
                  sx={fieldStyles}
                  IconComponent={() => <KeyboardArrowDownIcon />}
                  value={eventDate}
                  onChange={(e) => setEventDate(e.target.value)}
                  onFocus={() => setEventError(false)}
                />
              </Box>

              <Divider
                orientation="vertical"
                variant="middle"
                flexItem
                sx={{ marginX: "10px", display: { xs: "none", sm: "block" } }}
              />

              <Box className="field-align-responsive">
                <TextField
                  variant="standard"
                  type="select"
                  InputProps={{
                    disableUnderline: true,
                  }}
                  placeholder="Name (Optional)"
                  width="100%"
                  sx={fieldStyles}
                  value={ChurchName}
                  onChange={(e) => setChurchName(e.target.value)}
                  onFocus={() => setEventError(false)}
                />
              </Box>
            </Box>

            <Box align="center" sx={{ marginTop: "15px" }}>
              {/* <Link to="#" align="center">
                <Button
                  variant="outlined"
                  sx={searchBtn}
                  onClick={onSearchClick}
                >
                  {Trig ? "Searching..." : "Search"}
                </Button>
              </Link> */}

              <LoadingButton
                sx={Trig ? searchBtn : searchBtn}
                // endIcon={<SendIcon />}
                loading={Trig}
                loadingPosition="end"
                onClick={onSearchClick}
              >
                <span>{Trig ? "Search" : " Search "}</span>
              </LoadingButton>

              {eventError && (
                <Typography sx={labelTextError}>Event Not Found</Typography>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default SearchEvents;

const labelText = {
  fontSize: "15px",
  fontFamily: "Kumbh Sans",
  fontWeight: 400,
};

const labelTextError = {
  fontSize: "15px",
  fontFamily: "Kumbh Sans",
  fontWeight: "bold",
  color: "darkred",
  mt: 1,
};

// const paragraph = {
//   marginX: "15%",
//   marginTop: "13px",
//   fontFamily: "Kumbh Sans",
//   fontSize: "16px",
//   opacity: 0.5,
// };

const fieldStyles = {
  width: "170px",
  margin: "10px",
  boxShadow: "none",
  ".MuiOutlinedInput-notchedOutline": { border: 0 },
};

const boxAlign = {
  display: "flex",
  alignItems: "center",
  // justifyContent: 'space-between'
};

const searchBtn = {
  color: "#ffffff",
  background: "#3F292B",
  fontFamily: "Kumbh Sans",
  fontSize: "13px",
  padding: "10px 45px",
  border: "1px solid #3F292B",
  "&:hover": {
    border: "1px solid #3F292B",
    color: "#3F292B",

    boxShadow: "inset 10em 0 0 0 white",
  },
};

// const radiusItems = [
//   {
//     label: "10-30 Miles",
//     value: "10-30",
//   },
//   {
//     label: "40-60 Miles",
//     value: "40-60",
//   },
//   {
//     label: "All",
//     value: "All",
//   },
// ];

const eventTypeItems = [
  {
    label: "Music",
    value: "Music",
  },
  {
    label: "Church",
    value: "Church",
  },
  {
    label: "Ministry Focused",
    value: "Ministry Focused",
  },
];

const categoryList = [
  {
    value: "Youth Service",
    label: "Youth Service",
  },
  {
    value: "Youth Prayer Night",
    label: "Youth Prayer Night",
  },
  {
    value: "Youth Social",
    label: "Youth Social",
  },
  {
    value: "Adult Prayer Night",
    label: "Adult Prayer Night",
  },
  {
    value: "Adult Church Service",
    label: "Adult Church Service",
  },
  {
    value: "Women Prayer Group",
    label: "Women Prayer Group",
  },
  {
    value: " Women bible study",
    label: " Women bible study",
  },
  {
    value: "Male Prayer Group",
    label: "Male Prayer Group",
  },
  {
    value: "Concert",
    label: "Concert",
  },
  {
    value: " Week of Prayer",
    label: " Week of Prayer",
  },
  {
    value: "Crusades",
    label: "Crusades",
  },
  {
    value: " Conference",
    label: " Conference",
  },

  {
    value: " CoED Bible Study",
    label: " CoED Bible Study",
  },
  {
    value: " CoEd Prayer Group",
    label: " CoEd Prayer Group",
  },
];

const categoryListArtist = [
  {
    value: "Concert",
    label: "Concert",
  },
  {
    value: "Worship Session",
    label: "Worship Session",
  },
  {
    value: "Album Release",
    label: "Album Release",
  },
];
