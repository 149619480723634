import React from "react";
import { Box, IconButton, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import { closeIcon } from "../navbar/closeIcon";
import GeoMap from "../GoogleMap/MapSetter";

const AddGoogleLocation = ({
  setOpenGeoMap,
  openGeoMap,
  handleChildValue,
  geoLocation,
}) => {
  //   const cordinatedHandler = (obj) => {
  //     console.log("AddGoogleLocation", obj);
  //     handleChildValue(obj);
  //   };

  return (
    <Dialog open={openGeoMap} sx={{}}>
      <Box align="center" sx={{ padding: "14px", width: "500px" }}>
        <IconButton
          sx={{
            position: "absolute",
            top: "4px",
            right: "4px",
          }}
          onClick={() => setOpenGeoMap(false)}
        >
          {closeIcon()}
        </IconButton>

        <Typography
          sx={{
            marginY: "6px",
            fontFamily: "Kumbh Sans",
            fontSize: "20px",
            fontWeight: "bold",
          }}
        >
          Click on Location!
        </Typography>

        {/* <GeoMap data={cordinatedHandler} /> */}
        <GeoMap />
      </Box>
    </Dialog>
  );
};

export default AddGoogleLocation;
