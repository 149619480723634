import * as React from "react";
import MyVideoAdd from "./myVideoAdd";
import Box from "@mui/joy/Box";
import Card from "@mui/joy/Card";
import CardCover from "@mui/joy/CardCover";
import CardContent from "@mui/joy/CardContent";
import Typography from "@mui/joy/Typography";
import { Button, Stack } from "@mui/material";
import axios from "axios";


export default function MyVideos({
  userData,
  baseURL,
  tokenApi,
  Loader,
  setLoader,
}) {
  const [openAddVideo, setOpenAddVideo] = React.useState(false);
  const [play, setPlay] = React.useState(false);
  const [removing, setRemoving] = React.useState(false);

  const onRemoveVideo = async (vdoId) => {
    setRemoving(true);
    if (tokenApi && baseURL && vdoId) {
      const config = {
        headers: {
          Authorization: `Bearer ${tokenApi}`,
        },
      };

      const data = {
        imageArray: [vdoId],
      };

      await axios
        .post(`${baseURL}/api/v1/church/deleteChurchImages`, data, config)
        .then((response) => {
          if (response.data.success) {
            setLoader(true);
            setRemoving(false);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      alert("elseeeee");
    }
  };

  return (
    <Box
      sx={{
        borderRadius: "20px",
        padding: "20px",
        background: "#ffffff",
        height: "100%",
        // width: "100%",
        overflow: "auto",
      }}
      // className="AttendingStyle"
      align="center"
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
      >
        <Typography sx={tableHeading}>
          {removing ? "Deleting Video..." : "My Videos"}
        </Typography>
        <Button
          variant="outlined"
          sx={loginBtn}
          onClick={() => setOpenAddVideo(true)}
        >
          Upload&nbsp;Video
        </Button>
      </Stack>

      <Box
        component="ul"
        sx={{ display: "flex", gap: 2, flexWrap: "wrap", p: 0, m: 1 }}
      >
        {userData?.video?.map((data, ind) => {
          return (
            <Card
              component="li"
              sx={{
                minWidth: 230,
                maxWidth: 300,
                flexGrow: 1,
                position: "relative",
                border: "1px solid grey",
              }}
            >
              <CardCover>
                <video
                  autoPlay={!play}
                  loop
                  muted
                  poster="https://assets.codepen.io/6093409/river.jpg"
                >
                  <source src={data.videoUrl} type="video/mp4" />
                </video>
              </CardCover>

              <Button
                variant="outlined"
                sx={removeBtn}
                onClick={() => onRemoveVideo(data?.videoId)}
              >
                Remove
              </Button>

              <CardContent>
                <Typography
                  level="h6"
                  fontWeight="lg"
                  textColor="#fff"
                  mt={{ xs: 12, sm: 18 }}
                  sx={{ background: "rgba(1, 1,1, 0.5)", p: 1.5 }}
                >
                  {data.videoTitle}
                </Typography>
              </CardContent>
            </Card>
          );
        })}
      </Box>

      <MyVideoAdd
        setOpenAddVideo={setOpenAddVideo}
        openAddVideo={openAddVideo}
        baseURL={baseURL}
        tokenApi={tokenApi}
        Loader={Loader}
        setLoader={setLoader}
      />
    </Box>
  );
}

const tableHeading = {
  fontSize: "20px",
  fontFamily: "Kumbh Sans",
  fontWeight: "bold",
  padding: "15px",
};
// const colHeading = {
//   fontSize: "14px",
//   fontFamily: "Kumbh Sans",
//   fontWeight: "bold",
// };

// const colData = {
//   fontSize: "12px",
//   fontFamily: "Kumbh Sans",
// };

const loginBtn = {
  gap: "10px",
  color: "#ffffff",
  background: "#D1AB42",
  textTransform: "capitalize",
  fontFamily: "Kumbh Sans",
  border: "1px solid #D1AB42",
  fontSize: "10px",
  "&:hover": {
    border: "1px solid #3F292B",
    color: "#3F292B",
  },
};

const editBtn = {
  position: "absolute",
  top: "8px",
  right: "8px",
  background: "#3F292B",
  color: "#ffffff",
  border: "1px solid #3F292B",
  fontSize: "10px",
  textTransform: "capitalize",
  cursor: "pointer",
  "&:hover": {
    background: "transparent",
    border: "1px solid #3F292B",
    color: "#000000",
  },
};
const removeBtn = {
  position: "absolute",
  top: "10px",
  right: "10px",
  color: "#ffffff",
  background: "#D1AB42",
  textTransform: "capitalize",
  fontFamily: "Kumbh Sans",
  border: "1px solid #D1AB42",
  fontSize: "8px",
  "&:hover": {
    border: "1px solid #3F292B",
    color: "#3F292B",
  },
};
