import * as React from "react";

// import { myUploadVideos } from "./MyVideosUploadData";
import MyBlogAdd from "./MyBlogAdd";
import MyBlogView from "./MyBlogEdit";

// import Blog1 from "../../assets/images/blog1.png";
// import DP from "../../assets/About/contactus2.png";
// import { useSelector } from "react-redux";
import moment from "moment";

// import Table from "@mui/material/Table";
// import TableBody from "@mui/material/TableBody";
// import TableCell from "@mui/material/TableCell";
// import TableContainer from "@mui/material/TableContainer";
// import TableHead from "@mui/material/TableHead";
// import TableRow from "@mui/material/TableRow";
// import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import { Button, Typography, Stack } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { CardActionArea } from "@mui/material";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import axios from "axios";
const baseURL = process.env.REACT_APP_BASEURL;

// export const myBlogData = [
//   {
//     title: "Sermon at Saints Peter",
//     img: DP,
//     imgName: "The Saint Peters Union",
//     blogImg: Blog1,
//     date: "28 Dec 2023 ",
//   },
//   {
//     title: "Sermon at Saints Peter",
//     img: DP,
//     imgName: "The Saint Peters Union",
//     blogImg: Blog1,
//     date: "28 Dec 2023 ",
//   },
// ];

export default function MyBlog({ tokenApi }) {
  const [openAddVideo, setOpenAddVideo] = React.useState(false);
  const [MyBlogData, setMyBlogData] = React.useState();
  const [ViewOpen, setViewOpen] = React.useState(false);
  const [ViewData, setViewData] = React.useState([]);

  // let reduxPayToken = useSelector((state) => state.userAuth.payToken);

  // console.log("token from blog", reduxPayToken);

  React.useEffect(() => {
    const config = {
      headers: { Authorization: `Bearer ${tokenApi}` },
    };

    axios
      .get(`${baseURL}/api/v1/blog/getUserBlogs`, config)
      .then((response) => {
        if (response.data.success) {
          setMyBlogData(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [ViewOpen, openAddVideo]);

  return (
    <Box
      sx={{
        borderRadius: "20px",
        padding: "20px",
        background: "#ffffff",
        height: "100%",
        overflow: "auto",
      }}
      align="center"
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
      >
        <Typography sx={tableHeading}>My&nbsp;Blogs</Typography>
        <Button
          variant="outlined"
          sx={loginBtn}
          onClick={() => setOpenAddVideo(true)}
        >
          Post&nbsp;Blog
        </Button>
      </Stack>

      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          gap: "15px",
          justifyContent: "center",
        }}
      >
        {MyBlogData?.map((data, ind) => {
          return (
            <Card sx={{ maxWidth: "90%", position: "relative" }} key={ind}>
              <CardActionArea>
                <CardMedia
                  component="img"
                  image={data.imageUrl}
                  alt="man"
                  sx={{
                    borderRadius: "8px",
                    width: "180px",
                    height: "140px",
                  }}
                />

                <Button
                  variant="outlined"
                  sx={editBtn}
                  onClick={() => {
                    setViewOpen(true);
                    setViewData(data);
                  }}
                >
                  view
                </Button>
                <CardContent align="left">
                  <Typography
                    gutterBottom
                    sx={{
                      fontFamily: "Kumbh Sans",
                      fontSize: "11px",
                      fontWeight: "bold",
                      color: data.isPublished ? "#D1AB42" : "darkred",
                    }}
                  >
                    {data.isPublished ? "Published" : "Draft"}
                  </Typography>

                  <Typography
                    gutterBottom
                    sx={{
                      fontFamily: "Kumbh Sans",
                      fontSize: "18px",
                      fontWeight: "bold",
                    }}
                  >
                    {data.title}
                  </Typography>

                  <Typography
                    sx={{
                      fontFamily: "Kumbh Sans",
                      fontSize: "12px",
                      color: "#91857F",
                      display: "flex",
                      gap: "5px",
                    }}
                  >
                    <PlayArrowIcon sx={{ fontSize: "16px" }} />{" "}
                    {moment(data?.updatedAt).format("lll")}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          );
        })}
      </Box>
      <MyBlogAdd
        openAddVideo={openAddVideo}
        setOpenAddVideo={setOpenAddVideo}
        tokenApi={tokenApi}
        baseURL={baseURL}
      />

      <MyBlogView
        ViewOpen={ViewOpen}
        setViewOpen={setViewOpen}
        tokenApi={tokenApi}
        baseURL={baseURL}
        ViewData={ViewData}
      />
    </Box>
  );
}

const tableHeading = {
  fontSize: "16px",
  fontFamily: "Kumbh Sans",
  fontWeight: "bold",
  padding: "15px",
};

// const colHeading = {
//   fontSize: "14px",
//   fontFamily: "Kumbh Sans",
//   fontWeight: "bold",
// };

// const colData = {
//   fontSize: "12px",
//   fontFamily: "Kumbh Sans",
// };

const loginBtn = {
  gap: "10px",
  color: "#ffffff",
  background: "#D1AB42",
  textTransform: "capitalize",
  fontFamily: "Kumbh Sans",
  border: "1px solid #D1AB42",
  fontSize: "10px",
  "&:hover": {
    border: "1px solid #3F292B",
    color: "#3F292B",
  },
};

const editBtn = {
  position: "absolute",
  top: "10px",
  right: "10px",
  background: "#3F292B",
  color: "#ffffff",
  border: "1px solid #3F292B",
  fontSize: "10px",
  textTransform: "capitalize",
  "&:hover": {
    background: "transparent",
    border: "1px solid #3F292B",
    color: "#000000",
  },
};
