import * as React from "react";
import "./events.css";
// import { EventsArray } from "./EventsData.js";
// import events1 from "../../assets/images/events1.png";

import Pagination from "@mui/material/Pagination";
// import Stack from "@mui/material/Stack";
import DefaultImg from "../../assets/images/church2.jpg"


import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";

// ** icons
import { SlLocationPin } from "react-icons/sl";
import { BsCalendar4Event } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

import axios from "axios";
import moment from "moment";
const baseURL = process.env.REACT_APP_BASEURL;

const AllEvents = (props) => {
  const {
    // zipCode,
    // setZipCode,
    // category,
    // setCategory,
    // eventDate,
    // setEventDate,
    params,
    Trig,
    setTrig,

    // eventError,
    setEventError,
  } = props;

  const navigate = useNavigate();
  const [eventsArray, setEventsArray] = React.useState([]);

  // *** Pagination
  const options = [2, 3, 5, 10, 15];
  const [value, setValue] = React.useState(options[3]);
  const [page, setPage] = React.useState(1);
  const [total, setTotal] = React.useState();

  let count = Math.ceil(total / value);

  let coor = ["long", "lat"];
  // if(long) coor.push(long)
  // if(lat)coor.push(long)

  React.useEffect(() => {
    axios
      .get(
        `${baseURL}/api/v1/event?page_no=${page}&records_per_page=${value}&coordinate=${coor}`,
        { params: params }
      )
      .then((response) => {
        setTotal(response.data);

        console.log(response.data.data, "datadatadtata");

        setTrig(false);
        if (params) params.zipCode = "";
        if (params) params.toDate = null;

        const valuex = response.data.data.map((ele) => {
          return {
            data: {
              id: ele._id,
              date: {
                day: moment(ele.fromDate).format("DD"),
                month: moment(ele.fromDate).format("MMM YYYY"),
              },

              eventImage: ele?.imageUrl ? ele.imageUrl : DefaultImg,
              eventName: ele.name,
              fromDateTime: moment(ele.fromDate).format("lll"),
              toDateTime: moment(ele.toDate).format("lll"),
              eventLocationChurch: ele.hostedBy?.fullName,
              eventLocationCity: ele?.address,
              eventDescription: ele.desc,
              eventRegister: "/eventRegister",
              role: ele?.hostedBy?.role,
              eventChurchId: ele?.hostedBy?._id,
            },
          };
        });

        setEventsArray(valuex);
      })
      .catch((error) => {
        // console.log(error);
        setTrig(false);
        setEventError(true);
      });
  }, [page, Trig]);

  const openRegisterEvent = (id) => {
    navigate(`/events/eventRegister/${id}`);
  };

  const getClickChurch = (idd) => {
    navigate(`/events/eventRegister/churchDetails/${idd}`);
  };

  return (
    <>
      <Box sx={{ my: 4 }}>
        <Pagination
          variant="outlined"
          count={count}
          page={page}
          onChange={(event, newValue) => {
            setPage(newValue);
          }}
        />
      </Box>

      {eventsArray?.map((ele, ind) => {
        const data = ele.data;

        return (
          <>
            <Box
              key={ele}
              sx={{
                display: "flex",
                flexWrap: "wrap",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                }}
              >
                <Typography sx={eventsHeading}>
                  {data.date.day}
                  <Typography sx={eventsText}>{data.date.month}</Typography>
                </Typography>
                <Box sx={{ maxWidth: "280px" }}>
                  <CardMedia
                    component="img"
                    image={data.eventImage}
                    alt="events1"
                    sx={{
                      minWidth: "280px",
                      height: "170px",
                      marginTop: "10px",
                      borderRadius: "10px",
                    }}
                  />
                </Box>
              </Box>

              <Box
                sx={{ width: "50%", display: "flex" }}
                className="eventsContent"
              >
                <Box>
                  <Typography sx={eventsHeading}>{data.eventName}</Typography>
                  <Typography sx={eventsText}>
                    <BsCalendar4Event style={{ fontSize: "12px" }} />
                    &nbsp; &nbsp;
                    {data.fromDateTime} - {data.toDateTime}
                  </Typography>
                  {/* {data.role !== "Artist" && ( */}
                  <Typography sx={eventsTextLocation}>
                    <SlLocationPin style={{ fontSize: "13px" }} /> &nbsp;
                    <Typography
                      sx={eventsTextLink}
                      onClick={() => getClickChurch(data?.eventChurchId)}
                    >
                      {data.eventLocationChurch === "Super Admin" ||
                        data.eventLocationChurch === "Admin"
                        ? ""
                        : data.eventLocationChurch}&nbsp;
                    </Typography>
                    <Typography sx={eventsText}><span style={{ textTransform: "capitalize" }}>
                      {data.eventLocationCity?.street},&nbsp;
                      {data.eventLocationCity?.city},&nbsp;
                      {data.eventLocationCity?.state},&nbsp;
                      {data.eventLocationCity?.country}</span>
                    </Typography>
                  </Typography>
                  {/* )} */}
                  <Typography sx={paragraph}>
                    {data?.eventDescription?.length > 150
                      ? `${data.eventDescription.slice(0, 150)} see more...`
                      : data?.eventDescription}
                  </Typography>
                </Box>

                <Box className="mobile-responsive-register-btn">
                  <Button
                    onClick={() => openRegisterEvent(data.id)}
                    variant="outlined"
                    sx={registerBtn}
                    className="register-btn-mobile"
                  >
                    View
                  </Button>
                </Box>
              </Box>
            </Box>
            <Divider sx={{ width: "95%", margin: "40px auto" }} />
          </>
        );
      })}
    </>
  );
};

export default AllEvents;

const eventsTextLink = {
  fontSize: "0.8rem",
  fontFamily: "Kumbh Sans",
  color: "#000000",
  fontWeight: "bold",
  cursor: "pointer",
  "&:hover": {
    color: "#D1AB42",
  },
};

const paragraph = {
  fontSize: "0.8rem",
  fontFamily: "Kumbh Sans",
  color: "#000000",
  marginRight: "40px",
  lineHeight: "25px",
  opacity: "0.6",
  maxWidth: "400px",
};

const eventsHeading = {
  fontSize: "1.6rem",
  fontFamily: "Kumbh Sans",
  fontWeight: "bold",
  color: "#000000",
  marginRight: "30px",
  textTransform: "capitalize",
};

const eventsText = {
  fontSize: "0.8rem",
  fontFamily: "Kumbh Sans",
  color: "#000000",
};

const eventsTextLocation = {
  fontSize: "0.8rem",
  fontFamily: "Kumbh Sans",
  color: "#000000",
  marginY: "8px",
  fontWeight: "bold",
  display: "flex",
};

const registerBtn = {
  color: "#ffffff",
  background: "#D1AB42",
  fontFamily: "Kumbh Sans",
  fontSize: "10px",
  padding: "12px 35px",
  marginTop: "20px",
  width: "auto",
  border: "1px solid #D1AB42",

  "&:hover": {
    border: "1px solid #3F292B",
    color: "#3F292B",

    boxShadow: "inset 10em 0 0 0 white",
  },
};
